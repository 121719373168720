import React, { Component } from "react";
import Header from '../../components/mobile/header'
import Detail from "../../components/mobile/detail";
import './index.less'
import configs from "./config";
import classnames from 'classnames'
import { report, count } from '../../common/js/tools'
report()
count()

export default class Business extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...configs,
            tabIndex: 0,
            selectedIndex: -1,
            per: 0,
            showDetail: false,
            currentCases: [],
            detail: {},
            canUp: false,
            canDown: false,
            transType: ''
        }
    }

    moving = false
    initX = 0
    initY = 0

    componentDidMount() {
        this.handleCases()
        let container = document.getElementById('business-content')
        container.addEventListener('scroll', this.handleScroll)
        window.addEventListener('touchstart', this.touchStart)
        window.addEventListener('touchend', this.touchEnd)
        window.addEventListener('touchmove', this.touchMove)
    }

    componentWillUnmount() {
        let container = document.getElementById('business-content')
        container.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('touchstart', this.touchStart)
        window.removeEventListener('touchend', this.touchEnd)
        window.removeEventListener('touchmove', this.touchMove)
    }

    touchStart = (e) => {
        if (this.state.showDetail) return
        this.moving = true
    }

    touchEnd = (e) => {
        this.moving = false
        this.initX = 0
        this.initY = 0
    }

    touchMove = (e) => {
        if (!this.moving) return
        let initY = this.initY
        let initX = this.initX
        let currentX = e.touches[0].pageX
        let currentY = e.touches[0].pageY
        if (initY === 0 || initX === 0) {
            this.initX = currentX
            this.initY = currentY
            return
        }
        let diffX = currentX - initX
        let diffY = currentY - initY
        if (Math.abs(diffX) > 1 || Math.abs(diffY) > 1) {
            let dir = (Math.abs(diffX) > Math.abs(diffY)) ? 'x' : 'y'
            let diff = (Math.abs(diffX) > Math.abs(diffY)) ? diffX : diffY
            this.handleTouch(dir, diff)
            this.moving = false
            this.initX = 0
            this.initY = 0
        }
    }

    handleTouch(dir, diff) {
        let { tabIndex, transType, canUp, canDown } = this.state
        if (diff < 0) {
            if (dir === 'y' && !canUp) return
            transType = dir === 'x' ? 'left' : 'up'
            if (tabIndex === 2) {
                tabIndex = 0
            } else {
                tabIndex++
            }
        } else {
            if (dir === 'y' && !canDown) return
                transType = dir === 'x' ? 'right' : 'down'
            if (tabIndex === 0) {
                tabIndex = 2
            } else {
                tabIndex--
            }
        }
        setTimeout(() => {
            this.setState({
                tabIndex
            }, () => {
                this.handleCases()
            })
        }, 400)
        this.setState({
            transType
        }, () => {
            setTimeout(() => {
                this.setState({
                    transType: ''
                })
            }, 1000)
        })
    }

    handleContainer() {
        let container = document.getElementById('business-content')
        let { scrollTop, clientHeight, scrollHeight } = container
        let canUp = (scrollTop + clientHeight >= scrollHeight - 1)
        let canDown = (scrollTop === 0)
        this.setState({
            canUp,
            canDown
        })
    }

    handleScroll = (e) => {
        let { scrollTop, clientHeight, scrollHeight } = e.srcElement
        let canUp = (scrollTop + clientHeight >= scrollHeight - 1)
        let canDown = (scrollTop === 0)
        this.setState({
            canUp,
            canDown
        })
    }

    handleCases() {
        let { tabIndex, cases } = this.state
        let currentCases = cases[tabIndex].map((item, index) => {
            let { name } = item
            item.url = require(`../../common/imgs/business/logo/${name}.png`).default
            return item
        })
        this.setState({
            currentCases
        }, () => {
            setTimeout(() => {
                this.handleContainer()
            }, 1)
        })
    }

    handleClickTab(index) {
        let currentIndex = this.state.tabIndex
        let transType = ''
        if (index-currentIndex===1 || (currentIndex===2 && index===0)) transType = 'left'
        if (currentIndex-index===1 || (currentIndex===0 && index===2)) transType = 'right'
        setTimeout(() => {
            this.setState({
                tabIndex: index
            }, () => {
                this.handleCases()
            })
        }, 400)
        this.setState({
            transType
        }, () => {
            setTimeout(() => {
                this.setState({
                    transType: ''
                })
            }, 1000)
        })
    }

    handleClickItem(item, index) {
        let { name, type, hasSub } = item
        if (type === 1) {
            item.main = require(`../../common/imgs/business/main/${name}.png`).default
            hasSub && (item.sub = require(`../../common/imgs/business/sub/${name}.png`).default)
            this.setState({
                detail: item,
                selectedIndex: index,
                showDetail: true
            })
        } else if (type === 2) {
            window.open(item.link, '_blank')
        }
    }

    handleChangeItem(type) {
        let { selectedIndex, currentCases } = this.state
        let len = currentCases.length
        let item
        let func = function () {
            if (type === 'left') {
                selectedIndex = selectedIndex === 0 ? len - 1 : selectedIndex - 1
            } else {
                selectedIndex = selectedIndex === len - 1 ? 0 : selectedIndex + 1
            }
            item = currentCases[selectedIndex]
        }
        func()
        while (item.type > 1) {
            func()
        }
        this.handleClickItem(item, selectedIndex)
    }

    handleChild() {
        this.setState({
            showDetail: false,
            selectedIndex: -1
        })
    }

    render() {
        const { bg, title, tabs, tabIndex, currentCases, selectedIndex, showDetail, detail, transType } = this.state
        return (
            <div className="mobile-page mobile-business">
                <Header theme="dark" />
                <Detail showDetail={showDetail} parent={this} detail={detail} />
                <img src={bg} className="bg" alt="" />
                <div className="top-cover"></div>
                <div className="page-content flex-column flex-row" id="business-content">
                    <img src={title} className="page-title" alt="" />
                    <div className="business-tip">已助力逾60家企业，完成了超过200亿元的融资</div>
                    <div className="tabs-container">
                        <div className="tabs flex-row flex-between">
                            {tabs.map((item, index) => {
                                return (
                                    <div key={index} className={classnames('tab-item flex-center', { 'selected-tab': tabIndex === index })} onClick={this.handleClickTab.bind(this, index)}>{item.label}</div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="cases">
                        <div className={classnames('cases-outer', { [`to-${transType}`]: !!transType })}>
                            {currentCases.map((item, index) => {
                                return (
                                    <div key={index} className={classnames('case-item', {
                                        'selected-item': selectedIndex === index
                                    })} onClick={this.handleClickItem.bind(this, item, index)}>
                                        <div className="business-top flex-center">
                                            <img src={item.url} className="business-img" alt="" />
                                        </div>
                                        <div className="business-bottom flex-center">{item.name}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="fix-bottom"></div>
                </div>
            </div>
        )
    }
}