const list = [
    {
        name: '220720',
        title: '致远资本交易 | 行知行机器人完成A轮融资，致远资本跟投并担任独家财务顾问',
        desc: '近日，智能特种机器人领军企业行知行机器人完成A轮融资，该轮融资由国内知名机构君联资本领投，国科嘉和、致远资本跟投，老股东前海母基金持续加注，致远资本担任独家财务顾问。',
        date: '2022.07.27',
        noMain: true,
        content: [
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/220720/1.png').default
            },
            {
                type: 'html',
                text: `<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem;">近日，智能特种机器人领军企业深圳市行知行机器人技术有限公司（以下简称“行知行机器人”）完成A轮融资，该轮融资由国内知名机构君联资本领投，国科嘉和、
                <span style="color: rgb(50, 90, 160); font-weight: 500;">致远资本跟投</span>，老股东前海母基金持续加注，
                <span style="color: rgb(50, 90, 160); font-weight: 500;">致远资本担任独家财务顾问</span>。本轮资金将用于加大研发投入，加速特种机器人系列产品市场拓展和海外市场的布局，践行 “洁净世界，安全作业”的企业使命。</div>`
            },
            '行知行机器人成立于2017年，现已成长为全球顶尖，为大型钢结构立面的除锈、清洗、喷涂、打磨、检测等多场景提供智能特种机器人及绿色综合解决方案的领导者，其机器人产品已广泛应用于船舶、石化、风电等行业。',
            '在船舶领域，公司推出的壁龙“W”系列轮式爬壁机器人和“T”系列履带式爬壁机器人，填补了国内船舶超高压水除锈清洗机器人的空白。将爬壁机器人、超高压水除锈清洗技术、和污水回收处理技术三者集成于一体，利用爬壁机器人搭载超高压水清洗器进行除锈，采用“SR”系列污水回收机回收废水废渣，防止返锈，回收的废水再经过“ST”系列污水处理机进行处理，实现绿色环保净水直排。与传统人工喷砂除锈相比，效率提高4-6倍，每平米清洗成本降低40%。此外，行知行机器人即将推出水下清洗机器人系列产品，用于清除船舶水下海生物，将有效降低船舶航行油耗，进一步促进绿色低碳。',
            '当前随着人口红利消退，老龄化问题的加重，以及国家生态环境保护督察呈现持续“高压”态势，诸如喷砂、打磨、手工喷涂等简单粗放、高污染的传统工艺已经难以为继，机器替人迫在眉睫，船舶行业开始迎来市场拐点。多数企业已经开始逐步采用机器人搭载超高压水除锈清洗代替传统的人工打砂作业。目前，公司已经与中船集团、中远集团、招商集团以及民营的多家头部企业展开合作，有效解决环境污染，降低能耗，并大大提高了作业效率和大幅降低成本，实现全行业的降本增效。公司自2020年下半年开始展开商业化并快速落地，2021年即实现国内船舶行业机器人设备全年出货量第一。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/220720/2.jpg').default
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; text-align: center;">修船行业机器人应用场景</div>'
            },
            '在石化领域，每年由于储罐的爆炸、有毒有害气体扩散等因素造成重大的人员伤亡和经济损失，安全生产成为重中之重。行知行机器人推出了“Ex”系列防爆机器人，采用II区防爆设计，机器人自动清洗或者工人远程遥控操作。与传统人工作业方法相比，机器人作业无需搭建脚手架，节省了大量的时间和成本，并真正实现一站式绿色环保、高效高质量除锈清洗和喷涂作业。目前，公司已经与中国石化等多家客户展开合作。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/220720/3.jpg').default
            },
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/220720/4.jpg').default
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; text-align: center;">石化领域机器人应用场景</div>'
            },
            '在风电领域，行知行机器人推出了 “Wp”系列风电叶片除冰机器人。在我国“30碳中和”，“60碳达峰”以及2030年风电、光伏总装量12亿千瓦以上的双重目标下，风电行业迎来了黄金发展期。传统的除冰解决方案是采用蜘蛛人、吊篮等方式，危险系数高、作业效率低。“Wp”系列叶片除冰机器人具有自重小、负载大、吸附和抗风能力强、移动灵活稳定等特点，有效作业高度可达120m，搭载喷射系统并通过视觉引导对风机叶片进行精准喷射融冰剂，实现叶片高效安全除冰，目前已经在陕西、内蒙古、湖北等多家风电场进行了除冰作业。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/220720/5.jpg').default
            }, 
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; text-align: center;">风电领域机器人应用场景</div>'
            },
            '在特种爬壁机器人领域，行知行机器人拥有国内唯一的核心智能控制算法，实现半自动和全自动智能路径规划、智能人机交互和可参数化编程控制；机器人采用特殊的磁吸附设计和柔性结构设计，可适应90%以上作业区域；一体化驱动IP67等级防护设计和独特的温控系统，能适应高温、高湿、高盐等复杂恶劣环境；采用模块化设计，机器人易于快速组装适配不同应用行业与场景。行知行机器人打造了领先的标准产品矩阵+综合解决方案，能够满足不同行业的客户需求，全面引领行业的自动化、智能化和绿色低碳化转型升级。',
            '行知行机器人创始团队源自中科院深圳先进技术研究院机器人领域专家团队，创始人是中国科学院关键技术人才，科技部重点研发项目首席科学家，博士生导师，教授级高级工程师，具备深厚的技术研发和运营管理的经验。以填补国产特种机器人领域空白为创业初心，在爬壁机器人核心技术、智能控制算法、控制器、超高压水射流技术、机器视觉等领域获得各种专利及软件著作近百项。',
            '君联资本联席首席投资官陈瑞表示：君联资本持续关注机器人领域的投资机会，当前特种机器人在船舶、石化、风电等行业应用面临拐点型契机，未来有着广阔的市场空间与前景，能够有效替代恶劣环境下的人工作业，真正在工业生产和社会生活中发挥巨大价值。行知行团队低调务实，多年来潜心打磨产品，已经率先在船舶领域形成规模化应用，未来有望成为特种机器人领域的头部企业。君联资本非常荣幸能够领投公司本轮融资，期待行知行未来能在技术、产品和商业化上有更多突破，我们也将通过积极主动的增值服务，助力行知行成为全球化、平台型的特种机器人公司。',
            '国科嘉和基金总经理陈洪武表示：国科嘉和持续挖掘满足国家需求和战略规划，并且最具有技术价值和积极影响力的优质硬科技企业。⾏知⾏核⼼团队均来⾃中科院深圳先进技术研究院，在国内特种机器⼈领域积累了深厚的学术经验和商业化基础，领先行业攻克在高空、曲面等工作环境下的重大技术难题，成为首家实现在船舶、石化、风电行业应用的特种机器人公司，在国产化、自主可控的大背景下具有强大的增长潜力。我们非常看好公司的技术实力和未来发展，并将通过“技术资本”的纽带作用，持续为行知行提供技术赋能，助力行知行成为特种机器人行业领导者。'
        ]
    },
    {
        name: '220127',
        title: '对话新核云CEO陶滨江：为离散制造业提供全栈式解决方案，助力数字化转型升级',
        desc: '获取优秀创业者的行业经验分享，走进企业融资背后的真实历程。',
        date: '2022.01.27',
        noMain: true,
        content: [
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/220127/1.png').default
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500; text-align: center;">捕捉新经济行业正在崛起的品牌</div>'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500; text-align: center;">获取优秀创业者的创业经验分享</div>'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500; text-align: center;">走进企业融资背后的真实历程</div>'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem;">欢迎走进致远资本「对话CEO」栏目的第二期，本期我们邀请到工业智能化软件服务商<span style="color: rgb(50, 90, 160); font-weight: 500;">新核云CEO陶滨江</span>，来和大家分享下国内离散制造业的发展情况，以及自己的创业经验。</div>'
            },
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/220127/2.png').default
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            {
                type: 'bold',
                text: '陶总好，先请您和大家介绍下自己和公司吧。'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍新核云CEO陶滨江：</div>'
            },
            '大家好，我是新核云的CEO陶滨江，新核云是面向国内离散制造业的数字化工厂全栈式解决方案。全栈的理解是我们既有软件部分的产品，也有硬件IoT工业网关的产品，希望通过这种软硬件结合的方式，帮助国内的离散制造业完成数字化转型升级。',
            '具体产品包括云MES、云ERP、TPM和TQM四大类。我们面向的行业主要是汽车零部件制造、装备制造、五金金属及电子装配行业。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            {
                type: 'bold',
                text: '我们知道您之前是在国外生活和工作的，后来回国是因为要创办新核云吗？是什么样的契机，让您萌生创办新核云的想法呢？新核云这个名字有什么内涵吗？'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍新核云CEO陶滨江：</div>'
            },
            '2013年~2014年，我是在瑞典爱立信从事与物联网IoT相关的工作，然后在德国SoftwareAG 负责业务流程管理系统开发，主要工作是为奔驰、DHL这类知名企业提供内部生产、制造的管理系统。在工作期间，我发现这个领域很多事都是比较确定的，如果一直保持这样安稳的状态很难有提升，正好那时候国内互联网兴起，我就想回国做一些事情。',
            '在回国之前，我列了很多我想做的事情，回国之后就逐一做尝试。因为偶然的契机，14年我去温州走访，发现当地有很多制造业工厂，规模也不小，但内部的信息化非常落后，跟我原来在欧洲看到的工厂完全是两个极端。',
            '当时，我觉得这可能是一个机会，所以就拉着几个同学快速写了一个原型产品，放在移动端上，通过不断的反馈调整和沟通做出了最初的软件形态。',
            '在14年7月11日，我们成立了新核云。“新核”的意思是给工厂新增一个“核core”，好让它运转地更快速更智能，类似于双核计算机处理器之于单核设备。“云”则意味着这套系统将架设在云平台之上。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            {
                type: 'bold',
                text: '能不能和我们分享下这个产品原型出来之后，新核云是怎么一步步打开市场的？在过程中，必然会遇到一些与您最初设想不同的地方，您是怎么解决和调整的呢？有没有可以和其它创业者分享的经验？'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍新核云CEO陶滨江：</div>'
            },
            '很多早期技术创业者会面临的问题就是对市场敬畏不够，我常常反思自己是否存在这种情况。以前我认为好产品自然而然能卖出去，但市场和想象不一样。我们早期遇到一个天使客户，需要他给到一些反馈，可一旦谈及付费问题，他就不会再提供反馈。所以怎样才能让客户接受我们的产品，从而愿意为产品本身付钱，这个很关键。',
            '打开市场的第一步，就是找到目标客户。我们想了一个办法，就是去工厂面试，在招聘网站找哪些厂家在招生产统计员，那么他可能就对MES有潜在需求。选定目标之后，我们就去“面试”，先通过HR，再找到老板，和老板聊的时候就会聊到他生产过程中的问题，然后再告诉老板，我有产品能够帮你解决问题。',
            '当时来讲，一天跑六家企业最后能见一个老板就已经很不错，在愿意见我们的老板当中，有一半会觉得我可能是骗子，只有一半愿意听我们聊聊。这就是我们早期开拓客户最原始的方法，没有太大的收益，但能让我们和客户之间搭建沟通的桥梁，让他们给我们最真实的反馈，通过反馈再逐步完善产品。',
            '后来拿到拙朴投资的天使轮资金后，我们就去参加展会，这个过程中也积累了一些客户。但是展会有地域上的限制，无法规模化复制。所以我们在18年的时候就做了线上投放，不过我们没有选择传统的SEO关键词，而是选择了抖音头条。',
            '想成为市场的搅局者，用传统方法很难有突破。新方法也不代表一定能成功，但不出奇招肯定没有突破口。大胆下手，不停试错，这就是我们从挖客户，到展会，再到线上推广，一步步演变的过程。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            {
                type: 'bold',
                text: '这一路走来真的很不容易，您能再帮我们详细分享一下新核云的模式和产品做了哪些创新，给行业带来了什么样的变化和体验吗？'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍新核云CEO陶滨江：</div>'
            },
            '我认为，第一点是我们将互联网技术全面融入到传统企业服务当中。从SaaS模式，我们实现了按需付费，随时可用；到整个移动端设备的引入，员工使用手机或统一配置的平板电脑就能够完成一系列操作；这可能是我们对行业做的不一样一点，我们希望将移动端的设备变成促进生产力的工具。',
            '第二点，我们在软硬件的结合上投入比较多，很多人会认为SaaS是纯软件产品，是云端化的服务。但我们不会给自己设限，我们会根据目标客户核心场景的数字化需求提供相应的服务，所以在软件之外，我们还做了IoT产品，帮助客户完成底层生产设备的数据采集和上层软件产品的集成。',
            '第三点，我们全面引入了微服务的架构，在底层架构上做了很多创新。这样的好处就是我们接下来规模化复制的时候，能看到边际成本和效率，不会出现服务客户多，成本也会增加的情况，所以从长期来看，我们做的越多，那我们提供的服务也会变得更好。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            {
                type: 'bold',
                text: '您认为新核云有没有自己的核心壁垒，可以始终处于行业领先地位？'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍新核云CEO陶滨江：</div>'
            },
            '在整个企业服务当中，其实没有绝对的技术壁垒。但也有要求，这个要求是通过技术和行业的结合，让产品更懂客户。同样的，产品背后的人，从创始团队、到销售团队、顾问团队、产研团队也需要更懂客户，这样就会慢慢的形成你的优势，积累更多垂直行业的客户。',
            '到现在基本上我们去打一个潜在客户的时候，他就已经是我们已知客户供应链的上下游了，这样就会逐渐形成一个网状的结构。',
            '技术层面的投入是所有企业主公司的本职工作，但是绝对不要指望技术本身就能够建立壁垒，最重要的还是结合行业客户的需求。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            {
                type: 'bold',
                text: '从2014年到现在，新核云已经走进第八个年头，想必这8年的创业路也是荆棘和喜悦共存，能不能给我们分享一下这8年来自己或者团队有哪些记忆深刻或者意义重大的时刻？'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍新核云CEO陶滨江：</div>'
            },
            '意义重大的时刻有很多，在生活上，我们几个合伙人，从26岁到现在成家立业，每一个人生的重大时刻我们都在互相参与，互相见证。',
            '在工作上，印象比较深刻的是我们一起拿下新奥动力等上市公司客户。在目前我们的行业内，中小型民营企业占到离散型制造业的97%，只有3%是集团性的企业，与新奥动力等集团企业的合作，其实是说明我们得到了主流市场的认可。',
            '同时在服务这类KA客户的过程中，我们也在同步制定行业的标准，然后用标准化的产品再去上游帮助整个中小企业完成数字化的转型。我们把这个叫做“1+1战略”，通过头部的“1”带动其他供应链上的“1”个个客户，逐步完成整个行业的数据化转型。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            {
                type: 'bold',
                text: '我们看到咱们团队的几位创始人都是您的大学同学，但是其实同学和事业合伙人的关系处理模式还是有些不同的，能不能分享下您们之间是如何处理在工作上的协作关系呢？有没有遇到过意见想法不一致的时候，这个时候您一般都会如何处理呢？'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍新核云CEO陶滨江：</div>'
            },
            '“上阵父子兵，创业同学帮”这话还是有道理的，因为同学之间会比较信任。尤其早期创业的时候，需要付出很多，如果没有信任关系，就算能力再强也是熬不过去。',
            '在信任的基础上，大家在能力上一定要互补，即便没有互补，也需要快速学习。',
            '遇到想法不一致的时候，对我们来说直接吵一架就好了，早期我们经常会吵架，遇到不可调和的问题就直接去客户现场，让客户给出更客观的评价。当然，性格上的融合也能让很多事情更快的推进，如果每个人都太自我，那这个团队肯定会崩掉的，所以在日常经营的问题上我们不会有过多争辩。',
            '我们早期的6位合伙人，到现在公司成立了8年还在一起，我觉得有两个原因，第一极致的公开透明，有问题就直接表达出来，不要猜，大家在场的时候直接提；第二我们不是小圈子，我们会积极主动地去吸纳外部的人才加入我们。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            {
                type: 'bold',
                text: '在2021年新核云接连完成了B+轮和C轮的融资，可以和我们分享下有了资本的支持后，咱们后续发力方向是哪些方面吗？'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍新核云CEO陶滨江：</div>'
            },
            '重点还是投在产品当中，我们会把大部分资金花在产品研发上，给客户带来最好的体验和服务，提升客户的满意度，由此逐渐形成一种正向循环。',
            '另外，我们也会在团队的扩张上有一些安排，因为KA客户需要专属团队来提供专业服务，所以我们会将区域销售和KA销售区分开，这两个是目前融资的主要用途。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            {
                type: 'bold',
                text: '聊聊您当初对FA的选择以及后面和致远资本合作的感受吧？有没有什么记忆深刻的小故事？也非常感谢陶总一直以来对我们的信任和支持。'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍新核云CEO陶滨江：</div>'
            },
            '实话实说，在致远资本之前，其实我接触了很多FA，但是一直没有做选择，因为大部分的FA不太了解我们这个行业，也不知道我们想要做的事情。',
            '致远资本有很大的不同，首先致远的创始人Cynthia 很有激情，对这个行业也懂，在我们沟通的时候，从她的言语当中能感受到她对职业的热爱，所以创始人本身是很大的加分项。',
            '其次是致远资本在很细分的领域做企业服务，针对工业互联网也有自己的研究。当时我们在聊一些指标的时候，无论是像CAC获客成本，包括营销投入、营销模型，致远资本都能讲得很清楚，能够理解我们在做的事情。',
            '到现在我们其实已经合作两年多了，还是很满意的。相比合作关系，其实我们更像是朋友一样，生活中工作上有什么问题，我也会找致远资本的小伙伴聊聊。人和人之间的信任关系其实就是通过不断地交流沟通建立起来的，所以整个过程下来是比较愉悦的。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            {
                type: 'bold',
                text: '谢谢陶总给我们这么高的评价，我们会继续努力。最后，能不能给同在创业路上的兄弟姐妹们，分享一句创业心得或建议。'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍新核云CEO陶滨江：</div>'
            },
            '坚持，坚持，再坚持。只要你想好了，想准了，愿意为这件事All In，这种All In不是嘴上说说，而是要牺牲很多其他人应有的快乐，那么坚持做下去，市场会给你一个正向的反馈。如果没有得到反馈也不要着急，可能只是时间没到。',
            {
                type: 'bold',
                text: '本期「对话CEO」的内容我们就分享到这里，后续我们会定期输出更多优秀创业者的创业经历，如果有任何想同我们交流的问题，欢迎和我们沟通。'
            }
        ]
    },
    {
        name: '211231',
        title: '2022年，和致远资本一起来谈谈收获吧！',
        desc: '致远资本始终坚持以投行专业服务能力汇聚资本力量，推动新经济商业变革，成就新时代伟大企业。',
        date: '2021.12.31',
        noMain: true,
        content: [
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211231/1.jpg').default
            },
            '2021年已经过去，回望来路，这一年我们经历了许多难忘的事。在发现具有商业价值的好项目时，会有兴奋和激动；在项目服务过程中遭遇坎坷时，会有困难和挑战；但更重要的是，把好项目成功链接资本市场后，那极大的喜悦和成就感。',
            '所有的经历都是宝贵财富，岁末年初，我们选取了一些2021年服务过的代表项目，希望透过复盘这些代表性品牌的动态，带大家一起感知大消费、高科技等赛道2021年的发展态势。',
            '2022年，我们整装待发，愿携手资本市场，发现具有长期价值的项目，成就更多行业独角兽，共同创造未来新世界。',
            {
                type: 'bold',
                text: '01 大消费领域'
            },
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211231/2.png').default
            },
            '01关于美尚股份',
            '2018年成立的美尚股份是目前中国领先的多品牌美妆集团之一。其旗下的国潮彩妆先锋品牌珂拉琪Colorkey，仅用了不到3年便一跃而上，跻身国潮彩妆品牌前列。',
            '02融资历程',
            '2020年4月，美尚股份宣布完成近2亿元A轮融资，投资方包括红星美凯龙、创新工场、易凯资本、微光创投。',
            '2021年1月，美尚股份又获得总额4亿元人民币的B轮融资，由金镒资本领投，高盛、君川等跟投，老股东持续加码。',
            '03发展现状',
            '2021年美尚股份旗下品牌珂拉琪Colorkey持续发力，5月官宣签下迪丽热巴为其全球代言人，掀起全网热议，话题曝光高达5.5亿，互动量300w+。',
            '据ECdataway发布的《天猫618彩妆/香水品牌预售榜单》显示，在随后的天猫2021年618预售国货彩妆榜单中珂拉琪Colorkey一举拿下TOP1，仅次于YSL、雅诗兰黛等国际高奢彩妆品牌；在2021双十一当天，全渠道销售额直接破亿。在行业竞争不断加剧的情况下，珂拉琪Colorkey成为国产彩妆当之无愧的头部品牌。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211231/3.png').default
            },
            '01关于五爷拌面',
            '创立于2018年5月的五爷拌面，是国内新兴的创新餐饮连锁品牌。五爷拌面以“让世界爱上中国面”为使命，从东北三省起步，逐步拓展至河北、山东、内蒙、广东等省市，是一匹不折不扣的“快餐黑马”',
            '02融资历程',
            '2021年6月，五爷拌面获得由鼎晖VGC领投的3亿元A轮融资。',
            '2021年7月，五爷拌面又获得由高瓴创投的A+轮投资。',
            '03发展现状',
            '通过自营+加盟的方式，五爷拌面正以飞快的速度扩张全国，2021年10月五爷拌面全资建设3家工厂；2021年11月五爷拌面门店突破1000家，在拌面品类中，门店数量位居全国第一。未来五爷拌面将可达成万店连锁，成为中式快餐第一品牌。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211231/4.png').default
            },
            '01关于鹿优数科',
            '鹿优数科成立于2020年，以工艺和研发数字化为抓手，ODM以销定产柔性制造为模式，全面打造面料数字供应链生态，实现数据和技术驱动的纺织行业智能制造云工厂。',
            '02融资历程',
            '2021年9月，鹿优数科宣布完成超亿元A轮融资。本轮融资由新犁资本领投，致远资本跟投。',
            '2021年12月，鹿优数科获得由光速中国独家投资的A+轮过亿元融资。',
            '03发展现状',
            '2021年8月，鹿优数科获得“国家功能面料柔性智造基地”称号。2021年10月，鹿优数科成为中国纺织工业联合会“专精特新”中小企业。',
            '这一年，鹿优数科，一方面着手升级供给能力，并通过云工厂的端端链接，实现产能最大化；另一方面与新老内衣头部品牌爱慕、曼妮芬、蕉内、蕉下、内外、华歌尔、黛安芬等一批优质客户建立合作关系，并快速布局需求端的市场。',
            '鹿优数科正以数字化改革撬动中国纺织印染行业的高质量发展，推动这一行业的绿色产业升级。',
            {
                type: 'bold',
                text: '02 高科技&企业服务'
            },
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211231/5.png').default
            },
            '01XSKY星辰天合',
            '成立于2015年的XSKY星辰天合，是一家专注于软件定义基础架构业务的中国高新技术企业，基于软件定义的统一技术架构和混合云模式的数据管理方案，为政企客户提供以数据价值为核心的产品和服务体系。',
            '02融资历程',
            '2020年7月，星辰天合（北京）数据科技有限公司（XSKY星辰天合）获3.2亿元人民币D轮融资。',
            '2021年9月，XSKY星辰天合获得E轮7.1亿元人民币股权融资。',
            '2021年12月，XSKY星辰天合完成4亿元人民币F轮融资，本轮融资由源码资本、云晖资本参与。',
            '03发展现状',
            '12月22日，XSKY星辰天合入选“2021数字经济产业TOP100”，荣登《产业独角兽TOP50》榜单。',
            '12月23日，在“2021 ICT企业家大会”上，XSKY星辰天合CEO胥昕入选 “年度杰出人物”。',
            '根据IDC等第三方权威报告，XSKY星辰天合在SDS中国市场整体份额中，位居前五，是TOP5 中仅有的一家专业软件定义存储厂商。其中，对象存储细分市场排名第二，仅次于华为，在专业软件定义存储厂商中已经连续4年排名第一。XSKY星辰天合已成为其所在赛道当之无愧的独角兽企业。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211231/6.png').default
            },
            '01关于新核云',
            '新核云是面向离散制造业的工厂数字化全栈式解决方案（云MES+ERP），聚焦工厂核心业务场景的数字化，结合设备层实时数据获取能力，为汽车零部件制造、装备制造等机加工离散行业提供数字化转型升级服务。',
            '02融资历程',
            '2021年1月，新核云完成同创伟业和IDG资本领投，北极光创投跟投的1.5亿元B+轮融资。',
            '2021年8月，新核云完成2亿元C轮融资，由小米战投、顺为资本领投，北极光创投与同创伟业持续加码。',
            '03发展现状',
            '2021年6月，新核云被IDC评为《IDC创新者：中国工业互联网云MES.2021》。2021年10月，新核云获得华为云全国工业互联网应用创新大赛一等奖。在2021《中国制造业MES市场份额报告》中，新核云进入IDC中国制造业MES八强。',
            '除了在技术方面不断进取，新核云也在积极参与各大云平台的生态搭建，作为国内离散型制造业云MES领域的佼佼者，新核云的发展我们有目共睹。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211231/7.png').default
            },
            '01关于九章云极',
            '成立于2013年的北京九章云极科技有限公司（简称九章云极DataCanvas），专注自动化数据科学平台的持续开发与建设，着重为数据科学家，AI从业者提供一整套开发平台，为政府和企业智能化升级和转型提供全面配套服务。',
            '02融资历程',
            '2021年5月，九章云极DataCanvas宣布完成C轮融资，由尚珹资本、赛富投资基金领投，君紫投资、领沨资本等投资机构跟投，融资金额3亿元。',
            '03发展现状',
            '2021年10月，在首届全国人工智能创新应用大赛-智能网络专题赛中，九章云极DataCanvas摘获“网络云化设备异常及根因分析”赛题冠军一等奖和“4G/5G小区级多指标预测”赛题三等奖。',
            '2021年12月，九章云极DataCanvas获得「WISE2021新经济之王」前沿科技领域·年度硬核企业榜。',
            '随着人工智能迅猛发展，科技创新始终是企业发展的能动力。凭借多个行业的深耕经验和技术与业务知识的成熟融合，可以预见九章云极将是名副其实的未来智能领军者。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211231/8.png').default
            },
            '01关于芯翼信息科技',
            '成立于2017年的芯翼信息科技是一家专注于物联网智能终端系统SoC芯片研发的高新技术企业。',
            '公司具有完备且国际顶尖的芯片研发能力，自主研发的超高集成度5G NB-IOT系统单芯片SoC XY100已率先推出并实现大规模商用。',
            '2020年6月，公司牵头获得了科技部“国家重点研发计划”光电子与微电子器件及集成重点专项项目。 ',
            '02融资历程',
            '2021年9月，芯翼信息科技（上海）有限公司完成近5亿元B轮融资。',
            '03发展现状',
            '2021年11月，芯翼信息科技进行了全面品牌升级，基于全球化视野，深耕行业应用，确立了为不同细分行业智能终端设计系统SoC芯片的发展战略。',
            '根据芯翼信息科技公开信息显示，2021年芯翼信息科技在人员规模实现了50%以上的增长；申请各类知识产权50件以上；在出货规模上实现月均出货百万片以上；产品研发进展取得重大突破。芯翼信息科技不断开拓创新的姿态，将持续引领行业发展。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211231/9.png').default
            },
            '01关于融慧金科',
            '融慧金科成立于2017年，取意“通过科技，让金融更智慧”，是一家基于大数据技术和人工智能建模实战经验，为持牌金融机构提供智慧风控决策和系统化解决方案的一站式高端金融科技服务商。',
            '02融资历程',
            '2021年6月，融慧金科宣布完成数千万美元B+轮融资，由优山资本领投，澳洲电信投资跟投。',
            '03发展现状',
            '截至目前融慧金科已申请多项国家发明专利和软著，连续两年入选IDC中国Fintech50强榜单、猎云网最具独角兽潜力创新企业等，在互联网周刊发布的《2021年度未来银行科技服务商TOP100》中，融慧金科数字化风控解决方案名列前茅。',
            '作为一站式高端金融科技服务商，融慧金科在金融科技赛道上奔跑已进入第五个年头，在上海、深圳、成都等地均设立了运营中心，为百余家银行、消金、互金等头部持牌金融机构提供风控咨询方案和数字化解决方案，树立了众多标杆案例。',
            '2021年这些各领域的新晋黑马，在资本赋能下，用探索与努力交出了一份靓丽的答卷。致远资本始终坚持以投行专业服务能力汇聚资本力量，推动新经济商业变革，成就新时代伟大企业。初心如磐，笃行致远。行而不辍，未来可期。',
            '祝大家新年快乐！'
        ]
    },
    {
        name: '211223',
        title: '对话鹿优CEO张帅：数字驱动供需协同，重塑纺服ODM供应链生态',
        desc: '捕捉到新经济行业正在崛起的品牌，获取优秀创业者的创业经验分享。',
        date: '2021.12.23',
        noMain: true,
        content: [
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211223/1.png').default
            },
            '欢迎走进致远资本「对话CEO」栏目，作为链接资本市场和创业者的精品投资银行，我们一直在努力用专业触角去发现好项目，并携手资本市场，助力其成为新经济领域的独角兽。希望在这里，能让您：',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500; text-align: center;">捕捉到新经济行业正在崛起的品牌</div>'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500; text-align: center;">获取优秀创业者的创业经验分享</div>'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500; text-align: center;">走进企业融资背后的真实历程</div>'
            },
            '同时，也对致远资本多一份生动又翔实的了解。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem;">本期「对话CEO」我们邀请到纺织产业<span style="color: rgb(50, 90, 160); font-weight: 500;">互联网领域新晋品牌-鹿优数科CEO张帅</span>，来和大家分享下纺织互联产业的现状以及鹿优数科是如何在友商众多的赛道上，寻找到新的赛道切入点的。</div>'
            },
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/211223/2.png').default
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            '张总好，先恭喜下您，在2021年下半年内就带领鹿优数科喜获两轮数亿元融资，能不能和大家介绍下自己和鹿优数科？',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍鹿优数科CEO张帅：</div>'
            },
            '大家好，我是鹿优数科的CEO张帅，鹿优数科是纺织互联产业ODM的领军者及赋能者。我们以工艺和研发作为抓手，ODM以销定产柔性制造模式，全面打造面料数字供应链生态，实现数据和技术驱动的纺织行业智能制造云工厂。',
            '我自己本身是东华大学材料学博士后，已经在纺织服装行业从业10多年。曾经担任过Decathlon迪卡侬业务主管，全面负责全球科技面料开发；后来作为阿里巴巴犀牛智造的联合创始人，负责犀牛智造整体业务设计及供应链的管理，自己10多年的行业学习和工作经历，让我清晰地看到这个行业迭代变革的过程及未来的发展态势，所以我们选择了从打造面料数字供应链生态切入，基于产业链条的底层架构去布局数字化。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            '听说您原来一直定居在上海，现在为了创业需要常驻在山东烟台，能聊聊是什么样的动力让您愿意只身前往异乡创业呢？',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍鹿优数科CEO张帅：</div>'
            },
            '纺织服装行业自身就是一个全球性行业，产业互联网更是让各行各业无国界无边界。我们不能忽视当前传统制造业实业的基地还在三四线城市为主的事实。但所有的事业总要有一个起点，所以从山东起步真的非常正常，第一位是先做事情，其他的真的没有考虑太多。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            '在纺织互联产业赛道上，几年前，就已经涌现出一批引领行业发展的友商品牌，鹿优数科如今想用什么样的商业模式，引领这个赛道再度革新呢？',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍鹿优数科CEO张帅：</div>'
            },
            '是的，纺织互联产业其实已经经历了三个阶段的变革。第一个阶段是随着供需两端小企业的快速增加，涌现出一批以撮合交易为核心价值的信息类平台，这些平台其实是不介入生态链链条的，产业价值比较低；而第二阶段是随着供需两端企业规模快速扩张，需求端对性价比的诉求越来越高，由此产生了一批以规模化降本为主的自营交易类平台，以产定销，OEM代工模式为主；随着全球消费需求多元化和快时尚品牌的崛起，对成衣环节小单快反的需求越来越强烈，整个纺织互联产业，正式进入第三个阶段，ODM以销定产的柔性制造阶段。',
            '正是在这样的产业变革背景下，当行业转型还停留在简单把互联网模式套用到整个产业的时候，我们看到了从产业底层去变革，会给整个行业带来天翻地覆变化的机会点，所以从纺织服装上下游链条中，最为复杂的面料工艺入手，以面料生态数字化作为切入点，搭建了六大数字中心，包括能够实现产能预定的数字营销中心；以需求大数据为导向，实现面料的按需精准“创造”的数字创新中心；以智能染厂为快速工艺开发的基础设施，打造行业通用工艺标准的数字工艺中心；以数字连接、数字供给、数字工艺为基础设施，通过算法聚单、智能调度的数字生产中心；以“犀有芯”及“数织/染通”为核心传感器，实现生态内织染工厂数据链通的数字连接中心；以原料价格发现为核心能力，打造中央仓-区域仓模式，实现纱线坯布及染化料的统一配送的数字供给中心。通过六大数字中心的建构，让产业大数据去赋能智能化生产，从而构建起整个产业的智能智造云工厂。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            '人们常说创业需要天时地利人和，您是怎么看待鹿优数科的天时地利人和呢？',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍鹿优数科CEO张帅：</div>'
            },
            '天时可以从两个方面来理解吧，一方面就像前边和大家分享的，随着整个产业的发展演变，这个行业已经到了需要转型升级的关键时刻，谁有实力去布局推动产业转型升级，谁就可能在变革期成为改变行业的领军者；另一方面是国家整体政策导向是会快速推进纺织制造产业向智能制造转型，淘汰一大批不合规的企业，这会为我们快速推进标准化、数字化链通提供很好的基础。',
            '地利很好理解，因为鹿优数科本身就坐落在纺织大省山东，拥有烟台经济技术开发区及烟台高新技术产业开发区两处实体生产工厂，并已设立深圳办事处、广州办事处、 上海办事处等 3 家办事处，能够很好地辐射供需两侧。',
            '人和这点我觉得特别重要，我觉得最基础的，也是最核心的人和是我们创始团队的人和，我的两个合伙人，一个是拥有近30年产业经验的资深产业人，一个是已经成功落地过产业互联项目的互联网人，大家能够有很好的互补和融合。同时，集团拥有员工 400 余人，其中纺织产业人 300 余人，互联网人 100 余人，这样的团队组合能够有力地去支撑产业互联融合的落地。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            '能不能和我们分享一下管理经验，咱们的创始团队成员是如何分工的？如果大家产生决策分歧，会怎么处理呢？',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍鹿优数科CEO张帅：</div>'
            },
            '谈不上管理经验，说说自己的认知吧。这是一个产业互联网项目，产业互联网=产业+互联网，对各行业都是刚刚起步，有一些是互联网基础上+产业，有一些是深入产业基础上+互联网。',
            '我们是选择了第二种做法，因此我们的团队是更侧重产业属性，我们创始团队3个人自身能力经验可以比较好的互补，因此阶段的分工是基于我们三个核心能力建设上各领一块，分别是面料数字化解决方案、工艺数字化解决方案以及产能互联解决方案。我们在各自负责领域上拥有决策主导权，在交叉领域如出现分歧则以CEO决策主导。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            '我们看到爱慕、安莉芳、曼妮芬、蕉内、蕉下、内外、华歌尔、黛安芬都是咱们的合作品牌，是如何和这些大品牌达成合作的呢？',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍鹿优数科CEO张帅：</div>'
            },
            '我们始终关注客户需求价值，根据我们的研究判断，终端品牌需求侧目前存在2个趋势，一是常规品牌为降低库存风险越来越多采用测款+翻单模式，需要全供应链“快反力”；二是新品牌打开市场都采取的“商品力”。',
            '快反力的核心关键是“工艺能力”；商品力的核心关键是“研发力”。我们预计市场首先是品牌侧的常规品牌“快反”供应链替换，以及持续崛起的新品牌“商品力”供应链绑定。在此基础上的纺织面料供应链基础设施尚未出现，也就是说还没有任何企业或者平台真正在做面料供给侧的赋能，有的只是简单的面上产能“连接”。',
            '在全国范围内纺织印染行业“退城进园”及“关停并转”的行动后，全行业面料印染生产企业约有数万家，规模以上约1600余家。行业大部分印染产能以做“加工单”为主，过于依托“老师傅”而没有“工艺沉淀“，每一次订单的启动都是重新的“0-1”的“小缸-头缸-大货”流程，效率低损耗大，完全无法响应“快反”。',
            '同时，没有“研发”动机和能力，未能够为需求侧创造额外价值，不能够满足越来越多品牌ODM商品力的需求。传统品牌做新赛道或者新品牌创立，无论寻求“商品力”或者“快反模式”定位，未来都将会第一时间寻求鹿优数科的支持，鹿优数科将能够真正帮助品牌方实现“多、快、好、省”：更多的商品选择、更快的上线上新、更满足消费者的品质要求、更合理的成本价格。这也是鹿优能和上述提到的大品牌合作的关键。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            '咱们未来会是一手推进供应端云工厂的连通？一手推进需求端的市场开拓吗？您觉得哪个更迫切些？能不能分享下后续的推进规划？',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍鹿优数科CEO张帅：</div>'
            },
            '这两个没有哪个更迫切这个说法，可以说是同等重要，我们后续会一方面着手升级供给能力，并通过云工厂的端端链接，实现产能最大化；一方面快速布局需求端的市场，我们在华北、华南和华东的重要城市，都开办了办事处，去近距离靠近需求端市场，并筹备打开海外市场，通过大数据落地以销定产的服务模式。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            '非常感谢张总给我们分享这么多纺织互联产业的真实历程，我们都知道鹿优数科这个月又拿到一轮融资，钱找到了，咱们又有这么好的团队组合，能不能和我们畅想下鹿优数科能给行业带来哪些可以感知的改变呢？',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍鹿优数科CEO张帅：</div>'
            },
            '我们现在在做的事情是在改变行业生产力，而生产力决定生产关系，所以当未来我们所推进的商业模式渗透进行业生态链条的每个环节时，是会颠覆整个行业生产关系的。这种改变最大的体现就是，整个行业将形成大供应链+小品牌的格局。',
            '换个角度来看，无论是传统品牌做新赛道，还是新品牌新创立，从需求端角度来看，他们都需要寻找商品力或快反能力，来满足成衣端快时尚的需求，而鹿优数科想打造的产业生态是通过前边提到的六大数字中心，为需求端提供更多的商品选择、更快的上线和上新速度、更贴合消费者需求的商品，以及更合理的价格，也就是说我们会把最合适的订单调给最合适的工厂，并第一时间反馈给品牌方，进而通过品牌方反馈给消费者。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            '聊聊和我们合作的感受吧？',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍鹿优数科CEO张帅：</div>'
            },
            '我们这个行业技术壁垒很高，只有懂行业的财务顾问，才能够更懂我们，致远团队非常让我们放心和信赖，在估值分析、商业价值点梳理、基金筛选、路演安排、融资时间点把控等各个方面，都给了我们非常多的专业支持。在很多需要决策的时候，给予我们基于理性分析和实践经验的中肯建议。非常感谢Cynthia带领的致远团队，既有专业深度又有服务的温度，在融资过程中作为独家FA支持鹿优5个月斩获2轮数亿融资，大家一路携手同行，关系上更似友人而非顾问。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍致远资本：</div>'
            },
            '最后，能不能给同在创业路上的兄弟姐妹们，分享一句创业金句或创业心得？',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍鹿优数科CEO张帅：</div>'
            },
            '抬头看天、低头走路，人无远虑必有近忧。',
            '本期「对话CEO」的内容我们就分享到这里，后续我们会定期输出更多优秀创业者的创业经历，如果有任何想同我们交流的问题，欢迎后台留言。'
        ]
    },
    {
        name: '星辰天合',
        title: '致远资本交易|XSKY星辰天合获F轮4亿元人民币融资，致远资本连续担任独家财务顾问',
        desc: 'XSKY星辰天合作为软件定义存储领域的头部厂商，致力于用”软件定义“赋能新型数据基础设施，并获得了市场的高度认可。致远非常荣幸陪伴并见证星辰天合的成长，期待星辰天合乘政策之风，蓄腾飞之势！',
        date: '2021.12.08',
        noMain: true,
        content: [
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/星辰天合/banner.png').default
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem">继2021年9月宣布完成E轮股权融资之后，近日，星辰天合（北京）数据科技有限公司（XSKY星辰天合）宣布完成<span style="color: rgb(50, 90, 160); font-weight: 500">4亿元人民币F轮融资</span>，本轮融资由源码资本、云晖资本参与。</div>'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">致远资本连续担任XSKY星辰天合D轮、E轮、F轮三轮融资的独家财务顾问，累计助力其斩获近15亿元人民币融资。</div>'
            },
            'XSKY连续三轮和致远合作，作为合作伙伴，致远既能够快速洞察行业和业务的最新发展趋势，又能够精益求精地推敲各项材料细节，因此合作一直非常愉快。大家常说行百里者半九十，快速成长中也不总是一帆风顺的，致远作为同舟共济的合作伙伴，常提醒我们要顺境不惰，逆境不馁，正像他们slogan所说的，只有坚定初心，笃行不辍方可致远。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; text-align: right">—XSKY星辰天合CEO胥昕</div>'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍“软件定义”赋能实体经济新变革</div>'
            },
            '11月30日工信部发布的《“十四五”软件和信息技术服务业发展规划》对软件业提出了非常明晰的未来五年的战略规划，对软件业的定位提出了非常高的期望：“软件是新一代信息技术的灵魂，是数字经济发展的基础， 是制造强国、网络强国、数字中国建设的关键支撑。发展软件和信息技术服务业，对于加快建设现代产业体系具有重要意义。”  规划文件中还首次强调“软件定义”扩展了产品功能，变革了产品价值创造模式，催生新型制造模式，赋予企业新型能力和基础设施灵活性。”',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍存储赛道上的“小巨人</div>'
            },
            '在2021年7月23日工信部公布的《关于第三批专精特新“小巨人”企业名单的公示》中，XSKY星辰天合位列其中。XSKY星辰天合目前申请和获得390项自主知识产权，约70项核心算法专利，牵头参与了云存储资源管理和分布式存储的国家标准及行业标准的制定，是十多个国际、国内技术组织的成员单位。XSKY软件定义存储（SDS）技术与产业生态发展人才培养项目成果已纳入工信部人才交流中心产业人才培训体系。',
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem; color: rgb(50, 90, 160); font-weight: 500">▍继续加大力度投入技术研发</div>'
            },
            '在“十四五”发展规划的指引下，市场对于软件定义存储细分领域高度认可。XSKY星辰天合将利用所融资金进一步加大核心技术研发投入，构建产业链拉通的核心技术能力，加强对关键行业客户与场景的服务能力。',
            '未来，XSKY星辰天合将持续深耕海量数据基础设施市场，为新型数据基础设施注入新动能，并以数据常青为使命，致力于成为全球领先的数据基础设施技术平台提供商。',
            {
                type: 'bold',
                text: '致远观点'
            },
            '致远资本创始人李悦表示，“软件定义”是数字时代的重要基础，代表了新一代信息技术的未来。“十四五“发展规划高度重视软件业的发展，并首次强调了“软件定义”的价值所在。XSKY星辰天合作为软件定义存储领域的头部厂商，致力于用”软件定义“赋能新型数据基础设施，并获得了市场的高度认可。致远非常荣幸陪伴并见证星辰天合的成长，期待星辰天合乘政策之风，蓄腾飞之势！'
        ]
    },
    {
        name: '鹿优数科',
        title: '鹿优数科再获A+轮过亿元融资，致远资本连续担任独家财务顾问',
        desc: '致远资本期待鹿优数科全面推动行业数字化改革，成为纺织行业产业互联网新领航者。',
        date: '2021.12.01',
        noMain: true,
        content: [
            {
                type: 'html',
                text: '<div style="font-size: 1.38rem; line-height: 2.38rem; margin-bottom: 2.38rem">继2021年9月对外公布完成A轮超亿元融资后，纺织领域产业互联网领军企业犀有数科今日（12月1日）再度宣布完成A+轮过亿元融资，同时正式更名为鹿优数科。本轮融资由光速中国独家投资，<span style="color:#000;font-weight: 500">致远资本持续担任财务顾问</span>。</div>'
            },
            '鹿优数科（原犀有数科）本轮融资将主要用于升级数字化印染生态构建、加速海内外销售网络建设、扩展跨品类产能等方面，推动纺织行业的智慧智造进一步实现降本增效，尽快实现产业结构的良性转型。',
            '据悉，鹿优数科成立于2020年，以工艺和研发数字化为抓手，主打以销定产的柔性制造模式，全面打造面料数字供应链生态，实现数据和研发驱动的纺织行业智能制造。目前鹿优已构建ODM面料数字供应链生态，成为纺织领域产业互联网品牌的新领军者。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/鹿优数科/pic1.png').default
            },
            '根据信达证券研究，截至2020年，包括纺织业、服饰业、鞋履皮革业在内的广义纺织服装产业增加值，约占中国制造业总量的6%，接近汽车制造业。但值得注意的是，虽然纺织服装产业规模庞大，却极度分散，纺织制造数字化水平在制造业中几乎垫底。',
            '数字化转型与产业升级在工业4.0时代已经成为必然趋势，智能制造将成为提升国家整体制造业水平不可忽略的增长引擎。但是由于过去几十年纺织业一直是吃着增量时代的红利，如今在重资产、重资本、重人力的制约下，这一行业要想从根源上完成产业结构向智能制造和柔性生产的升级转型并非易事。',
            '说到产业转型中的一些问题，鹿优数科CEO张帅表示：“由于印染行业对于资质要求严格，目前全国只有一万多家印染厂，规模以上的有1600家左右，从供需角度来看，应该属于供不应求的状态。但实际情况却是印染行业存在严重的结构性过剩。”',
            '张帅解释道：“首先是由于时间和空间的错配，导致印染厂在忙季的时候处于供不应求的状态，但是非旺季的时候又处于停滞状态，综合下来，其全年的平均开机率其实只有70%；其次，由于大部分印染产能是以做加工为主，过度于依赖“老师傅”，没有工艺上的沉淀，根本无法实现需求侧要求的快速反应；再次，由于印染厂大部分是接单干活的状态，没有自己的独立商品，在交易中，本质上根本没有议价权，也不能够为需求侧创造额外价值。与此同时，由于印染过程需要多台机器协同作业，对于订单的准确性要求会更高。但是，当前行业面料侧解决方案仅仅停留在纺纱及织造相对简单及偏标品环节，附加值较低，对技术难度及资金密集程度最高的印染环节却望而却步，印染环节的互联互通还需要投入更多的精力去实现。”',
            '近些年，随着全球消费需求多元化和快时尚品牌的崛起，对成衣环节小单快反的需求越来越强烈。小单快反是指品牌为规避库存，用多款式、小批量订单的模式生产面料及服装，通过直播或平销上市3天内测试消费者需求，根据需求情况快速组织再生产，一般要求15天内商品发货，因此对供应链的小订单兼容性及敏捷反应要求极高。纺织印染作为成衣制造生态链条中的重要一环，其需求侧也在不断发生着变化，原有的市场现货及连续化规模生产模式，已经不再能够同时匹配“丰富度”及“确定性”的需求，柔性快反成为整个纺织印染产业发展的瓶颈，一旦突破柔性快反的瓶颈，整个产业必将迎来第二增长曲线。',
            '鹿优数科正是紧紧盯住了这样的市场空白点，跳脱了单纯把互联网模式套用在纺织印染行业的误区，凭借产业经验先做深产业，用工艺和数字化两条腿走路，一方面，通过数字化研发赋能需求侧，数字化工艺赋能供给侧；一方面，通过定义面料颜色、触感、理化性能等标准，输出产业统一标准的工艺包，最终通过ODM以销定产柔性制造模式，打造面料数字供应链生态。',
            '之所以能够进行前瞻性的战略布局，选择以打造ODM面料数字供应链生态作为转型切入点，同鹿优数科团队的产业人+互联网人的组合特色有关。鹿优数科的CEO张帅是东华大学材料学博士后，在纺织服装行业拥有超过10年的行业经验。他是前阿里巴巴犀牛智造联合创始人，任职期间带领业务团队完成ODM成衣柔性快反模式的落地；在迪卡侬（Decathlon）任职期间曾完成多项科技面料的研发。董事长&CPO（首席产品官&首席工艺官）卫金龙是中国最早的染整专业科班出身，已经深耕印染行业27年，成功领导裕民针织上市（HK00844）。COO陶张木也有10年以上纺织印染行业从业经验，加入鹿优之前是智布互联的联合创始人、高级副总裁，曾经从0到1成功打造纺织印染行业数字供应链信息化平台。',
            '目前，基于打造ODM面料数字供应链柔性供给的战略部署，鹿优数科一方面顺应国家环保低耗等产业政策，提前进行绿色能源及生物基材料等环保布局；另一方面，迅速搭建国内销售网络，目前已经或将在广州、上海、烟台、台湾等地筹建办事处。',
            '未来随着国家加大对外开放的力度，鹿优数科将提前着眼布局海外市场，更大范围地覆盖海外需求旺盛的区域，从而更近距离地实现与需求侧的链通，有的放矢地推进产业变革。',
            '光速中国合伙人高健凯表示：“经济新常态下，作为传统制造业的纺织印染工业，正在被重新定位。但这一行业的数字化升级受限于标准化一站式生产的难度，缺乏优质的解决方案。鹿优数科团队对于行业有着深厚认知和丰富经验，以数据和技术驱动纺织印染行业的智能化和协同化，他们的创新模式在智能制造和柔性供应链领域的效果已被验证。我们期待公司更快更好的发展，以数字化改革撬动中国纺织印染行业的高质量发展，同时推动这一行业的绿色产业升级。”',
            {
                type: 'bold',
                text: '致远观点'
            },
            '致远资本创始人李悦表示：“纺织服装行业正处于向智能制造、产业协同的变革时期， 鹿优数科集结产业+互联网的行业顶配团队，以数字化研发和数字化工艺为核心，用大数据驱动实现智能化柔性快反，构建数字面料供应链生态。致远很荣幸陪伴鹿优数科共同成长，见证公司光速成为行业“黑马”。我们期待鹿优数科全面推动行业数字化改革，成为纺织行业产业互联网新领航者！”'
        ]
    },
    {
        name: '双十一',
        title: '致远资本大消费品牌，双11首战创佳绩！',
        desc: `致远资本一直在密切关注着大消费领域曾经或正在并肩奋斗的伙伴们，今年双11第一波销售状况如何？Let's go，让我们“开”着购物车，去探一探这些新晋消费品牌的战况吧！`,
        date: '2021.11.08',
        extraHeight: 10.6,
        noMain: true,
        content: [
            {
                type: 'img',
                url: require('../../common/imgs/development/cover/双十一.png').default
            },
            '用专业财务顾问视角，捕捉分享新经济行业动态。',
            `在双11第一波销售结束之际，各大品牌的销售战役暂告一个段落。致远资本一直在密切关注着大消费领域曾经或正在并肩奋斗的伙伴们，今年双11第一波销售状况如何？Let's go，让我们“开”着购物车，去探一探这些新晋消费品牌的战况吧！`,
            {
                type: 'bold',
                text: '彩妆品牌：COLORKEY'
            },
            '「COLORKEY」是为Z世代酷女孩所打造的彩妆品牌，新晋国货彩妆品牌中的佼佼者；来自COLORKEY品牌方的战报显示，11月1日当天，全渠道销售额已经破亿。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/双十一/colorkey珂拉琪.jpg').default
            },
            {
                type: 'bold-mt25',
                text: '彩妆品牌：Blank ME'
            },
            '「Blank ME」专攻国货彩妆品牌鲜有重点布局的底妆品类，致力于做一款为亚洲女性打造的底妆品牌。今年双11预售首日，开启不到10秒，超过去年双十一20天整体成交，预售金额达1个亿。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/双十一/Blank me.jpg').default
            },
            {
                type: 'bold-mt25',
                text: '美容美体仪：Comper'
            },
            '「Comper」致力于打造年轻人的第一台美容仪，将医疗级美容器械小型化、家用化，让每位女性在家也能享受专业级的医疗美容产品。今年双11拉开帷幕后，销售业绩位居国货品牌排名第1名，整体排名第6名。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/双十一/comperfinal.jpg').default
            },
            {
                type: 'bold-mt25',
                text: '内衣品牌：素肌良品'
            },
            '「素肌良品」探索轻奢的软支撑舒适内衣品牌。首创“素肌果冻条”取代传统钢圈，以高级的专业度打造更有支撑力的无感舒适内衣。据品牌方战报显示，今年双11，其创造了开售2小时超去年全天业绩等一系列辉煌战绩。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/双十一/素肌良品.jpg').default
            },
            {
                type: 'bold-mt25',
                text: '染发品牌：Yivikii'
            },
            '「Yivikii」主推简单便捷、染护一体的染发用品,3分钟即可上色，染头就像梳头一样简单。双11期间，Yivikii携手张馨予直播间首秀，通过明星带货，走进年轻时尚群体的视野，预祝Yivikii双11大卖。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/双十一/yivikii.png').default
            },
            {
                type: 'bold-mt25',
                text: '护肤品牌：花皙蔻'
            },
            '「花皙蔻」专攻Clean Beauty纯净美妆品类，建立国内首个2000+Never List风险原料清单，致力于做一款能够实现天然与科学新平衡的美妆品牌。双11期间，花皙蔻大力度推出了一系列促销活动，预祝今年双11有个好战绩。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/双十一/花皙蔻.png').default
            },
            {
                type: 'bold-mt25',
                text: '食品品牌：食族人'
            },
            '「食族人」从高端酸辣粉切入市场，精准抓住新一代消费人群，差异化定位填补行业空白，打造国潮方便速食第一品牌。今年11月1日开售当天，销售业绩位列京东米粉米线类目排名第5。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/双十一/食族人战报.jpg').default
            },
            {
                type: 'bold-mt25',
                text: '食品品牌：好麦多'
            },
            '「好麦多」从水果麦片的细分品类入局中国代餐市场，秉持食品科学主义信仰，打造好麦多食品科学主义品牌，营造核心竞争力。2021年3月，好麦多全网销售额累积突破10亿元，拿下了天猫麦片品类旗舰店销量第一、天猫“水果麦片”品牌偏好第一，期待今年双11再战辉煌。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/双十一/好麦多.png').default
            },
            {
                type: 'bold-mt25',
                text: '家具品牌：样子生活'
            },
            '「样子生活」是漾美集团旗下，依托于高品质产品设计能力，专注为新生代群体提供高性价比家居生活方式产品和解决方案的品牌。期待今年双11，样子生活能够创造多款产品热销的佳绩。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/双十一/样子生活.jpg').default
            },
            {
                type: 'mt25',
                text: '致远资本一直坚持用专业触角，发掘有市场价值的高品质项目，在此预祝这些大消费领域的新晋黑马，在今年双11第二波销售中，能够创造更多销售佳绩。'
            },
            {
                type: 'html',
                text: '<div style="font-size: 1rem; color: #666; line-height: 2.38rem">图片：截取自品牌官方渠道侵删</div>'
            },
            {
                type: 'bold-mt25',
                text: '关于致远资本：'
            },
            '「致远资本」是一家专注为大消费、企业服务和高科技等行业优质企业，提供财务顾问服务的精品投资银行。',
            {
                type: 'img',
                url: require('../../common/imgs/development/detail/双十一/致远交易.png').default
            },
            '「致远资本」曾经成功助力五爷拌面、COLORKEY珂拉琪、好麦多、素肌良品、AKOKO、心上、每日优鲜、辣妈帮、蘑菇街、MISS CANDY、达达快送、小猪短租、河狸家等消费品牌完成融资。致远资本一直致力于用专业触角，发现有价值的好项目，并携手资本方，成就新经济行业的独角兽。'
        ]
    },
    {
    name: '金峰集团',
    title: '「金峰集团」完成超2亿元D轮融资，致远资本持续担任财务顾问',
    desc: '致远资本看好智慧物流，担任苏州金峰物流设备有限公司财务顾问，助其完成超2亿元D轮融资。',
    date: '2021.10.18',
    content: [
        {
            type: 'html',
            text: '<div style="font-size: 1.38rem; line-height: 2.38rem">近日，苏州金峰物流设备有限公司(简称"金峰集团"）已顺利完成超2亿元D轮融资。本轮融资由深圳前海母基金、上海道禾资本共同领投，原股东江苏毅达第二次参与跟投，<span style="font-weight: 500">致远资本持续担任财务顾问</span>。</div>'
        },
        '本轮融资的主要用途是加大产品研发投入和加强高素质人才队伍建设，拓展海外业务，提升公司海内外综合服务能力和技术创新能力。与此同时，充分利用行业供应链资源优势和技术优势，积极拓展国内外市场，进一步提高公司的核心竞争力和市场占有率，力争成为全球行业知名的物流系统集成商。',
        {
            type: 'html',
            text: '<div style="font-size: 1rem; color: #666; line-height: 2.38rem; margin-bottom: 4rem"> ——本文转载自金峰集团公众号</div>'
        },
        {
            type: 'bold-mb0',
            text: '三十年经验沉淀厚积薄发'
        },
        {
            type: 'img',
            url: require('../../common/imgs/development/detail/金峰集团/1.jpg').default
        },
        '金峰集团的创始人蔡熙先生在物流行业深耕逾三十年，是物流装备行业中知名的资深专家。曾参与中国邮政第一套交叉带分拣设备系统的引进和建设，与国家邮电部专家共同命名“CROSS BELT SORTER”，官方中文专业名称为“交叉带分拣机”，并全力协助中国邮政制定并实施了“三状（信函、扁平件和包裹）二箱（两个集装容器）一条码”标准，并于1998年在广州邮区枢纽中心中推动实施，为中国邮政后续的自动化建设奠定了基础。',
        {
            type: 'bold',
            text: '智能物流和智能制造“两条腿”走路'
        },
        {
            type: 'mb0',
            text: '金峰集团是一家专注于智能物流和智能制造领域物流自动化业务的综合方案提供商和关键核心产品制造商。专注为客户提供系统规划设计方案、项目实施运维及售后服务等一体化的解决方案，助力客户向自动化、智能化、数字化升级转型。金峰集团自2012年成立以来，已经累计交付1000+智能物流自动化项目，应用场景覆盖了邮政快递快运、商超物流、电商仓储、机场烟草、医药化妆、鞋服时尚、智能制造等诸多行业。'
        },
        {
            type: 'img',
            url: require('../../common/imgs/development/detail/金峰集团/2.jpg').default
        },
        {
            type: 'mb0',
            text: '电商、快递、快运等行业的发展正在带动商业配送物流自动化行业加速崛起，同时，工业4.0智能制造、柔性生产制造等使得工业生产物流自动化和智能化升级转型也成为势不可挡的趋势。自此，中国物流自动化装备市场已全面进入蓬勃发展的智能化与无人化新纪元。同时，伴随着新一轮的科技革命和产业变革，制造业正在不断突破新技术、催生新业态，智能制造已成为推动制造业转型升级、加快制造业高速发展的重要抓手。'
        },
        {
            type: 'mb0',
            text: '金峰提供从设备到解决方案完整产业链服务，涵盖物流系统整体集成、系统化整体方案设计、智能化电控与信息技术、自动化物流装备，适用不同领域的各种场景。金峰作为一站式智能工厂自动化解决方案提供商，为智能制造、智慧物流行业提供最具有成本效率及最优化的自动化解决方案，全球领先集成商采用的管理模式，采用Stage-Gate的管理模型，由高层管理员层层把关，实现零失误。'
        },
        {
            type: 'img',
            url: require('../../common/imgs/development/detail/金峰集团/3.jpg').default
        },
        '金峰集团凭借顶尖的产品能力和技术研发实力，致力于全面推动行业创新，打造行业最佳实践，引领行业发展。金峰集团高度重视技术研发，专利数量领先同行。在国内获得数百项专利和软件著作权，拥有完整的自主知识产权。金峰集团以创新型自动分拣系统切入，自主研发先进实用的物流自动化系统，构筑强大的产品与技术壁垒。金峰集团提供从设备到解决方案完整的产业链服务，不断开拓和深耕不同领域的各种场景。',
        {
            type: 'bold',
            text: '“一带一路”政策下积极布局海外'
        },
        '近年来，跨境电商行业政策环境利好，国家加大对外开放力度，加强与海外国家的交流，大力鼓励跨境电商的发展，这也刺激和推动跨境物流自动化的兴起。金峰集团顺势而为，乘势而上，借着跨境电商崛起的东风，积极布局海外市场。2021年因疫情原因，海外仓交易量不断上升，为了积极应对海外市场激增的需求，金峰集团将国内先进的物流技术服务于广阔的“一带一路”沿线国家市场，让中国品牌助力东南亚电商快递行业快速发展。继成功助力越南邮政上线首套交叉带分拣机系统之后，又再次承接了泰国虾皮在东南亚的首个大型自动分拣项目。截至今年9月，大量优质海外客户寻找到金峰洽谈合作，目前，海外业务量占到金峰总业绩的25%左右。金峰不断助力客户提升自动化、智能化能力，大大提高了场站的运作效率和分拣的准确率，降低了物流人工成本，从而促进了跨境电商的高速发展。',
        {
            type: 'bold',
            text: '人才永远是第一资源'
        },
        '金峰集团一直专注于物流自动化系统集成领域，经过长期实践和经验积累，金峰集团所提供的智能分拣设备及系统的运行效率、准确率及稳定性等方面在行业中已获得广大客户的高度认可和一致好评。未来，金峰集团将不断进行技术创新和管理创新，着重加快人才建设步伐，让事业激励人才，让人才成就事业，竭力打造成全球知名物流系统集成商和服务商。',
        {
            type: 'bold',
            text: '致远观点'
        },
        '致远资本创始合伙人李悦女士表示，“经济增长促进物流行业全面发展，催生了物流自动化装备产业链市场的巨大需求。我们持续看好金峰在此领域的发展机会和头部地位。金峰从智能分拣切入，提供领先的智能输送分拣系统整体解决方案。凭借顶尖的产品能力和技术研发实力，打造行业最佳实践，引领和推动行业的创新与发展。金峰始终以先进的创新产品为根基，以智能科技为核心，以“使物流更畅通”为使命，致力于成为国际物流自动化装备和服务行业的领军企业。”'
    ]
    }, {
        name: '犀有数科',
        title: '「犀有数科」完成A轮超亿元融资，致远资本担任独家财务顾问',
        desc: '近日，犀有数字科技(下称"犀有数科"）宣布完成超亿元A轮融资。本轮融资由新犁资本领投，致远资本跟投，致远资本担任独家财务顾问。',
        date: '2021.09.16',
        content: [
            '近日，犀有数字科技(下称"犀有数科"）宣布完成超亿元A轮融资。本轮融资由新犁资本领投，致远资本跟投，致远资本担任独家财务顾问。本轮所募集资金将用于智慧印染生态升级、产业大数据建设、数字化面料实验室扩容等。',

            '犀有数科成立于2020年，定位以工艺和研发数字化为抓手，ODM以销定产柔性制造为模式，全面打造面料数字供应链生态，实现数据和技术驱动的纺织行业智能制造云工厂。犀有数科是产业互联网3.0阶段的领军企业，打造“端端链接+商品标准->柔性供应”。',

            '犀有数科通过数字化体系，定向研发及推荐面料到品牌客户，通过列为中国制造2025标杆的自营智能工厂承接客户的测款首单并沉淀可复制的数字化工艺，然后基于客户销售情况调度工艺及产能最为匹配的生态伙伴协同快反交付犀有数科标准的面料商品。犀有数科CEO张帅预测 “ODM型面料快反的市场增长空间首先会覆盖原现货面料供应链市场，每年会有千亿级的供给替代需求。”',

            '犀有数科拥有“宽度+深度“兼备的顶配团队。CEO张帅是材料学博士后，作为阿里巴巴犀牛智造联合创始人，完成和见证了犀牛智造”从0到1”的落地，在Decathlon任职期间带领团队研发成功用于冲锋衣的“防风防水透湿透气”的纳米科技微多孔膜。董事长&CPO（首席产品官&首席工艺官）卫金龙先生是中国最早染整专业科班出身，深耕印染近30年，被誉为针织印染行业工匠第1人、数字化印染工厂成功实践第1人，曾领导裕民针织成功上市（HK00844）。COO陶张木先生是前智布互联联合创始人，高级副总裁，拥有10年以上纺织印染行业从业及行业级工业互联网项目成功落地经验，是行业最早成功从0到1打造纺织印染行业数字供应链信息化平台的领军人才。',

            '新犁资本主管合伙人李绪富表示：“这是我们近三年来布局和打造的第十二个产业生态共同体：以产业为标的、以全局为视野、以数字孪生为特征、自迭代自演绎自成长的生态共同体。与时俱进的是，过往我们更多的从供应链着手，犀有科技已经超越供应链，在数字化研发和数字化工艺的基础上直接切入智能生产和智能驱动，底层驱动也是在积累行业经验、知识、技术、共识的基础上融合了互联网技术、大数据技术和人工智能技术，因而我们的产业生态共同体也迭代为更具象的表达：产业大脑+订单入口+智能驱动+供应链平台，很是期待。”',
        ]
    },
    {
        name: '芯翼信息科技',
        title: '「芯翼信息科技」完成B轮5亿元融资，致远资本连续担任财务顾问',
        desc: '近日，芯翼信息科技完成近5亿元B轮融资，本轮投资由中金甲子、招银国际联合领投，招商局资本、宁水集团、亚昌投资等跟投，另外老股东峰瑞资本、晨道资本、华睿资本等持续加注，致远资本持续担任公司财务顾问参与本轮融资。',
        date: '2021.09.14',
        content: [
            '近日，物联网智能终端系统SoC芯片提供商芯翼信息科技（上海）有限公司（以下简称：芯翼信息科技或公司）完成近5亿元B轮融资，资金主要用于加强芯片产品研发、完善生产制造供应链、扩充核心团队等。本轮投资由中金甲子、招银国际联合领投，招商局资本、宁水集团、亚昌投资等跟投，另外老股东峰瑞资本、晨道资本、华睿资本等持续加注，致远资本持续担任公司财务顾问参与本轮融资。',

            '芯翼信息科技是一家专注于物联网智能终端系统SoC芯片研发的高新技术企业。公司创始人及核心研发团队来自于美国博通、迈凌、瑞昱、海思、展锐、中兴等全球知名芯片设计和通信公司，毕业于TAMU、UCLA、UT Dallas、NUS、北大、浙大、东南、电子科大等海内外知名高校，具有专业技术完备且国际顶尖的芯片研发能力。2020年6月，公司牵头获得了科技部“国家重点研发计划”光电子与微电子器件及集成重点专项项目，成为为数极少的获此殊荣的初创企业。',

            '2018年，芯翼信息科技推出的NB-IoT系统SoC芯片XY1100，是全球首颗片内集成CMOS PA的NB-IoT芯片，具有超高集成度、超低功耗、灵活性强、成本低等四大核心优势。这款芯片率先以完全自主研发、自主创新的架构，突破了全球蜂窝通信芯片开发的集成度瓶颈，首次流片即获成功、顺利实现量产，并引领了截止目前全球NB-IoT芯片的技术发展潮流。目前已广泛用于智能表计（燃气表、水表等）、智能烟感、资产追踪、智能换电、共享电单车管理、智能家居等物联网应用场景。',

            '同时，公司自主研发的下一代NB-IoT系统SoC芯片XY2100，以替代外置低功耗MCU的独家创新，再一次突破了业界在片内集成独立式MCU的架构，解决了在极致低功耗和极致睡眠唤醒时间的性能瓶颈问题。目前，XY2100芯片已经研发成功，预计将在不久后推向市场。另外，科技部项目支持的片内集成了NB-IoT/GNSS/BLE等的XY3100芯片，可实现高性能、低功耗、多模块自适应切换以及低成本，预计明年商用。除此之外，公司还可针对物联网不同应用场景的差异化需求，为客户提供定制开发服务。',

            '除了NB-IoT系列产品之外，高度集成的Cat.1系统SoC芯片也正在紧锣密鼓研发中，目前进展顺利，预计将于明年推向市场。同时，随着5G时代的到来，公司也已经率先投入5G中速的RedCap的研发。',

            '展望未来，满足物联网智能终端各项需求的系统SoC芯片将成为刚需，并将拥有比物联网连接芯片更加广阔的市场空间。芯翼信息科技创始人兼CEO肖建宏博士表示：“芯翼信息科技的核心研发团队大部分来自海内外知名的芯片设计公司，具有丰富的研发经验，同时我们有着自初代产品以来形成的技术创新精神，因此，我们独家提出了矢志成为业界领先的物联网智能终端系统SoC芯片企业的愿景。在新一轮融资的加持下，公司将一如既往的开拓创新、引领发展，为传统行业智能化、物理世界数字化升级赋能，为共同富裕和美好生活赋能。”',

            '中金甲子创始团队成员、 执行总经理周伟表示： 物联网智能终端芯片未来将融合感知、 通信、 边缘计算、 安全、 节能等多维能力， 使得数据的传输和使用实现闭环， 将更多商业价值体现出来。 芯翼信息科技兼具国际顶尖的芯片研发能力与产业化经验， 我们相信公司在创始人肖博士的带领下， 将抓住这一历史性的机遇、 不断取得突破， 推动中国经济的数字化转型升级。',

            '招银国际执行董事汪灿博士表示： 广域的中低速物联网终端将成为继PC、 手机、 汽车之后下一个海量终端市场， 芯翼信息科技团队在初代产品NB - IoT领域展现出极强的技术功底、 创新精神和落地能力， 我们看好其成为全球广域物联网终端系统SoC领导者的潜力。',
        ]
    },
    {
        name: '星辰天合',
        title: '「XSKY」完成E轮7.1亿元融资，致远资本连续担任财务顾问',
        desc: '近日，XSKY星辰天合宣布完成E轮7.1亿元人民币股权融资。本轮融资由博裕投资领投，君联资本、中金甲子、博华资本、昆仑资本、恒生电子、苏州毅商等跟投，原股东北极光创投、启明创投等亦参与跟投，致远资本连续担任财务顾问。',
        date: '2021.09.03',
        content: [
            '2021 年9月3日， 星辰天合（ 北京） 数据科技有限公司（“ XSKY星辰天合”） 宣布完成E轮7 .1 亿元人民币股权融资。 本轮融资由博裕投资领投， 君联资本、 中金甲子、 博华资本、 昆仑资本、 恒生电子、 苏州毅商等跟投， 原股东北极光创投、 启明创投等亦参与跟投， 致远资本连续担任财务顾问。',
            'XSKY星辰天合成立于2015年， 是一家专注于软件定义基础架构业务的中国高新技术企业。 在六年时间里成长为国内领先的数据基础设施技术平台提供商， 产品和服务获得了超1000家大型政企机构的大规模商业化部署， 客户涵盖政府、 金融、 运营商、 科技、 医疗、 制造、 航天、 司法、 广电、 教育、 能源、 交通、 物流等数字经济社会的关键领域， 支撑着行业云、 私有云、 桌面云、 数据库资源池、 海量媒体数据、 影像数据、 智能制造数据， Hadoop 大数据等不同类型的应用场景。',

            'IDC数据显示， 星辰天合在2020年SDS中国市场整体份额分布中， 是榜单前五中唯一一家专业的软件定义存储厂商。 特别在大数据和云存储有关的对象存储领域， 星辰天合连续3年占据国内市场第一位。 公司目前申请和获得350项自主知识产权， 约70项核心算法专利， 并多次牵头参与云存储资源管理、 分布式存储等国家标准及行业标准的制定。 在2021年7月23日工信部公布的《 关于第三批专精特新“ 小巨人” 企业名单的公示》 中， XSKY星辰天合位列其中。',

            '公司将利用本次所融资金进一步加大核心技术研发投入， 拓展云边协同的新产品形态和商业模式， 加强对关键行业客户的服务能力。 未来， 公司将持续为以数字基础设施为核心的“ 新基建” 注入新动能， 并以数据常青为使命， 致力于成为全球领先的数据基础设施技术平台提供商。'
        ]
    },
    {
        name: '新核云',
        title: '「新核云」完成C轮近2亿元融资，致远资本连续担任独家财务顾问',
        desc: '近日，「新核云」宣布完成2亿元C轮融资，由小米战投、顺为资本领投，北极光创投与同创伟业持续加码，致远资本连续担任独家财务顾问。',
        date: '2021.08.23',
        content: [
            '近日，「 新核云」 宣布完成2亿元C轮融资， 由小米战投、 顺为资本领投， 北极光创投与同创伟业持续加码， 致远资本连续担任独家财务顾问。 这是今年以来， 这家工业智能软件服务商完成的第二轮融资， 年初新核云刚刚完成由同创伟业、 IDG资本领投， 北极光创投和蓝江投资跟投的1 .5 亿元B + 轮融资。',

            '新核云为上海纽酷信息科技有限公司（ 纽酷） 打造的一款面向离散型制造业工厂的数字化系统产品， 其包括云MES、 云ERP、 TPM、 TQM四大模块， 可通过数据采集、 分析、 决策助力工厂完成数字化转型， 目前产品主要落地在汽车零部件制造领域， 客户包括： 新奥集团、 中轴集团等',

            '纽酷是一家工业智能化软件服务商， 成立于2014年， 企业专注于工业物联网、 工业大数据、 以及AI技术在工业场景中的落地应用， 赋能工厂逐步实现向数字化、 联网化、 智能化的升级。',

            '新核云的MES系统本质上是一套面向制造企业车间执行层的管理系统， 通过将设备联网， 可向企业提供包括制造数据管理、 计划排产管理、 生产调度管理、 库存管理、 质量管理等多种功能模块。 即使一家大型企业可能会使用到CRM、 PLM、 ERP、 MES等多种数字化管理系统， 但对于制造业企业来说， MES系统服务于车间制造流程， 是执行层与管理层的连接中枢， 是数字化生产的核心。 我国早在2016年印发的《 关于开展2016年智能制造试点示范项目推荐的通知》 中就指出， 离散型智能制造模式和流程型智能制造模式必须建立MES系统。',

            '近年来MES系统受到了资本和市场的双方青睐。 据公开资料显示， 2020 年MES系统在我国工业软件市场中占比为29 % ，在今年上半年， 国内市场获得融资的MES企业近十家， 其中就包括了新核云。 市场方面， 随着产业发展， 大量的中小型工厂也正在逐渐摆脱“ 催物料、 吵计划、 抄记录” 的境况， 这极大刺激了国内制造业企业对MES系统的需求。',

            '今年2月初， 工业软件入选了科技部国家重点研发计划首批重点专项， 这标志着工业软件已成为国家科技领域最高级别的战略部署。 同时， 已经持续近两年新冠疫情加速了制造业尤其是中小型制造业数字化转型的进程， 企业对生产管理系统的付费意愿明显提升， 并希望利用管理系统提高自身的生产效率。',

            '新核云的云MES系统可在车间层对排产、 报工、 质量、 生产等业务流程实现数字化， 并可通过云ERP系统将车间信息与其他协同系统打通， 解决了管理与执行之间的割裂问题。 结合自主研发的工业网关， 新核云在进行解决方案部署时可以更加高效地帮助用户采集、 分析设备数据。',

            '黄培博士的《 2020 智能工厂建设与MES应用报告》 指出， 目前国内MES系统的产品化程度仍较低， 制造企业更多以自主研发或合作开发的方式实施MES系统。 一些MES系统提供商由于缺少行业经验， 无法真正理解用户的需求， 用户的工程人员和提供商的IT人员需要进行长期的诉求确认， 在这种情况下， 以项目形式推动解决方案的落地将存在很大风险。',

            '新核云CEO陶滨江表示：“ 很多MES系统项目落地的周期都非常长， 有一些甚至会不了了之。”',

            '新核云的MES系统以SaaS模式， 为领域制造企业提供云MES、 云ERP、 TPM、 TQM业务服务。 除了面向大型客户可提供定制化的总包服务外， 面对行业腰部客户， 新核云的产品可独立成60多个功能模组进行单独售卖， 如生产管理、 WEB端报工、 过程质量管理、 来料质量、 订单、 采购、 库存、 库存结存、 成本核算等等。 用户可根据自身实际需求自主选择， 避免交付周期长和项目烂尾情况。',

            '随着制造业的数字化开始向生产环节的上下游渗透， 游新核云作为零部件制造领域的数字化解决方案提供商， 也正通过开放接口， 引入各领域合作伙伴来打造数字化生态体系。 目前已实现与CRM、 OA、 ERP、 PLM、 PDM、 数字孪生、 MDC、 SCRM等细分领域服务商的产品联通， 可为用户提供覆盖企业多部门。 多业务场景的集成方案。',

            '根据新核云提供的数据显示， 采用新核云MES产品或解决方案后， 企业设备OEE平均提升10 % 以上； 库存周转次数平均提升15 % 以上； 设备故障率平均下降3 % ；质量报废率平均下降15 % 。目前新核云拥有用户超过1000家， 其营收大部分也来源于产品年费， 2020 年全年营收超亿元。',

            '新核云在汽车零部件制造业的长期积累使得新核云的产品使用门槛较低， 相关用户可以快速上线。 事实上， 专注于某一行业也是国内MES系统厂商需要重点布局的方向， 例如摩尔元数聚焦于电子组装、 PCBA行业； 黑湖聚焦于食品、 医药领域等。',

            '业界普遍认为， MES系统的可复制性较低， 需要依托细分行业进行发展。 长远来看， MES系统或很难做到跨多个行业的标准化， 尤其对于国产MES系统厂商来说， 专注某一领域是建立行业壁垒的重要途径。',
        ]
    },
    {
        name: '最福利',
        title: '「最福利」获数千万美元A轮融资，致远资本担任独家财务顾问',
        desc: '近日，最福利完成近千亿元A轮融资，由领沨资本领投，德同资本跟投，致远资本担任本轮独家财务顾问。',
        date: '2021.08.10',
        content: [
            '近日， 集福利、 激励、 健康于一体的员工体验平台—— 最福利完成近亿元A轮融资， 由领沨资本领投， 德同资本跟投， 致远资本担任本轮独家财务顾问。 本轮融资主要用于团队扩充、 产品研发及深耕员工健康保险场景解决方案， 为客户持续创造更大价值。',

            '最福利的员工体验平台以积分体系为核心， 从员工福利的高频场景切入， 逐渐扩展到激励认可、 企业文化、 员工健康及保险等职场场景， 为企业提供移动端、 一体化、 场景化的SaaS + 积分权益平台。',

            '在消费端， 最福利接入数千种消费互联网场景， 通过供应链端长期的积累， 建立了包括消费购物、 生活服务、 线上场景、 健康医疗的生态矩阵。',

            '在健康场景中， 最福利整合了员工福利保险和职场健康生态体系， 汇聚互联网医院， 线上理赔， 用医问药， 绿色通道等多项资源， 为企业提供从方案制定、 线上投保、 理赔到数据分析的数字化员工健康管理解决方案。',

            '产品层面， 最福利是一个开放的、 可扩展的一体化平台， 产品形态可以是App， 也支持灵活的的兼容性和流程引擎设计， 无缝对接企业微信、 钉钉、 飞书等主流协同办公平台。',

            '员工体验平台作为一个相对“ 崭新” 的概念， 很多人对它还比较陌生， 但在人力资源行业已经成为热点趋势。 Forrester的报告指出： 78 % 的人力资源主管认为， 在两年内， 员工体验将成为影响企业实现业务目标之能力的最重要因素之一。',

            '目前国内市场， C端用户在移动互联网蓬勃发展的带动下， 对于移动端体验更加精益求精。 而传统的HR软件更多聚焦自上而下的企业管理， 对于真正使用产品的C端员工缺少体验优化， 软件的商业模式只能停留在B端层面， 失去了更大的商业想象力。',

            '最福利CEO张旭告诉36氪：“ 国内移动消费互联网的蓬勃发展催生了影响整个社会消费行为的数字化变革， 而在企业服务市场上， 最福利从初创之始就希望通过移动端数字化产品体验， 链接职场场景和员工的生活消费， 重新定义员工体验平台。”',

            '创立至今， 最福利已服务逾9, 000 家国内外知名企业， 服务员工数超600万人， 包括众安保险、 软通动力、 兴业银行、 必维集团、 万宝盛华、 鲁能集团、 亨氏、 戴尔、 嘉银金科、 味全、 途虎养车等企业。 在营收方面保持每年翻倍增长。',

            '最福利还表示， 此轮融资将进一步夯实最福利在员工体验平台领域的领先地位， 接下来最福利将进一步深化健康保险场景化能力， 洞察企业数字化转型的痛点， 创新员工职场全生命周期解决方案， 为企业提供更加创新的员工体验平台。',

            '作为本轮融资的领投方， 领沨资本致力于投资产业数字化和数字金融领域的优质的成长期企业， 领沨资本创始合伙人马宁表示： 员工体验平台是HR Tech领域的发展趋势， 在国内仍处于蓝海， 有很大的发展空间。 弹性福利行业未来将更将重视福利业务拓展、 技术升级和深挖空间服务等方向， 最福利的产品特性和发展规划与之契合度极高。 公司产品已打通福利板块和人资SaaS板块， 并提供整套解决方案； 公司产品轻且灵活， 已在企业微信上架， 并通过企业微信B端连接到大量C端员工， 形成优质且巨大的流量池。 领沨资本长期关注HR Tech在2B领域的应用和发展。',
        ]
    },
    {
        name: '五爷拌面',
        title: '「五爷拌面」获高瓴创投A+轮投资，致远资本持续担任独家财务顾问',
        desc: '近日五爷拌面又获得了高瓴创投的A+轮投资，致远资本持续担任独家财务顾问。',
        date: '2021.07.15',
        content: [
            '继2021年6月五爷拌面宣布完成了“ 餐饮界最大一笔” A轮融资3亿元后， 近日五爷拌面又获得了高瓴创投的A + 轮投资， 这也是高瓴创投首次投资餐饮品牌。 本轮由高瓴创投领投， 致远资本持续担任独家财务顾问。 短短几个月， 五爷拌面得到多个头部投资机构青睐， 可见其品牌实力雄厚， 发展速度迅猛。 五爷拌面的本轮融资金将用于加速强化供应链体系、 加速自有工厂建设、 迭代信息化智能系统、 打造国内顶级运营团队及加速布局全国门店网络。',

            '五爷拌面创立于2018年5月， 是国内新兴的创新餐饮连锁品牌。 公司通过自营 + 加盟的方式， 以东北三省黑吉辽为起步， 已拓展至京津冀、 江浙沪、 珠三角、 山东、 内蒙等省市， 实现了标准化、 规模化高速扩张。 据五爷拌面创始人透露， A轮融资后， 他们正以飞快的速度扩张全国， 预计年末突破1500家店指日可待。 届时五爷拌面也将会成为中式面条品牌中门店数量的冠军品牌。 据预测， 2023 年五爷拌面全国门店数量有望突破7000家。',

            '高瓴创投的投资理念， 是坚持长期结构性价值投资， 所选择的行业必然是常青且有长期需求的。 餐饮行业正是高瓴创投近几年来， 深度关注的行业， 因为消费领域有稳定市场和固定消费者。 高瓴创投一年多以来， 研究对比了很多的餐饮品牌， 最终确定投资五爷拌面。 之所以选择五爷拌面， 是因为其具备非常好的品牌的优势和爆发力， 运营团队实力极强。 业界众所周知高瓴创投出手， 必会选择该细分领域的龙头品牌。',

            '五爷拌面并不是一个泛加盟式的品牌， 而是一个有品牌、 有服务、 标准化的准直营店管理模式的连锁品牌。 作为全国优选拌面集合店， 五爷拌面融合了全国各地最受欢迎的多种拌面味道及做法， 加以改良优化， 并严苛把控品质， 力求做到“ 料更好， 味更浓”。 同时， 公司产品组合比较丰富， 除了拌面外， 也有汤面、 米饭等其他产品， 还会根据当地口味推出特色菜单， 符合更广泛人群的口味需求。 同时， 五爷拌面正全面打造自己的新零售产品， 加强新品及线上流通类产品的研发， 目前已在各大电商及各地商超上架销售。',

            '五爷拌面创始人说， 面条赛道市场基数足够大， 拥有广泛的大众消费基础。 2021 年， 在餐饮新机遇下， 五爷拌面创始人更加看好中式面条品类未来的市场表现和市场容量， 并就此为五爷拌面定下了“ 万家店” 的宏大目标。 他希望， 五爷老爷爷能像肯德基老爷爷一样， 遍布全球。 让世界爱上中国面！',

            '本轮投资方高瓴创投表示， 五爷拌面团队在创始人的带领下， 运用科技工具建立数字化体系， 通过领先的信息管理系统和精细化运营为用户带来标准化体验， 提升了门店的运营效率。 凭借快速迭代能力和超强的执行力， 公司在创立短短三年内就实现了门店快速增长。 我们期待五爷拌面团队能够发挥自身的禀赋优势， 创造更多长期价值， 为中国消费者带来更多美好滋味。',

            'A轮领投方鼎晖VGC基金管理合伙人表示：“ 星星之火， 可以燎原”， 五爷拌面完成A轮融资后， 团队在五爷拌面创始人的带领下快速成长， 外建品牌和供应链， 内强体系和组织力， 现在单月拓展门店超过300家， 已经驶入有质量快速发展的高速公路。 对五爷拌面的融资与资本运作， 鼎晖一如既往地欢迎优秀投资机构共同参与合作， 一起为企业发展不断增砖添瓦， 共同成就优秀的企业家， 并为改善行业贡献资源和力量。',
        ]
    },
    {
        name: '五爷拌面',
        title: '「五爷拌面」获3亿元A轮融资，致远资本担任独家财务顾问',
        desc: '6月24日，创新餐饮连锁企业——五爷拌面宣布完成3亿元A轮融资，本轮融资由鼎晖VGC（鼎晖创新与成长基金）领投，五爷拌面天使轮投资人王岑创立的B资本跟投，致远资本担任独家财务顾问。',
        date: '2021.06.24',
        content: [
            '6 月24日， 创新餐饮连锁企业—— 五爷拌面宣布完成3亿元A轮融资， 本轮融资由鼎晖VGC（ 鼎晖创新与成长基金） 领投， 五爷拌面天使轮投资人王岑创立的B资本跟投， 致远资本担任独家财务顾问。 据悉， 本次高达3亿元的融资额， 是目前市场上已知的餐饮连锁业最大的一笔A轮融资， 所融资金将被用于强化供应链体系并打造自有工厂、 信息化智能系统升级、 打造国内顶级运营团队、 加快拓展全国门店网络。',

            '五爷拌面创立于2018年5月， 是国内新兴的主打拌面的创新餐饮连锁品牌。 公司通过自营 + 加盟的方式， 以东北三省为起步， 已拓展至河北、 山东、 内蒙、 广东等省市， 实现了标准化、 规模化高速扩张。 截至目前， 五爷拌面门店数量已突破700家， 预计今年年末门店数量将突破1000家， 在中式面条品牌中成为门店数量冠军。',

            '五爷拌面并不是一个泛加盟式的品牌， 而是一个有品牌、 有服务、 标准化的准直营店管理模式的连锁品牌。 作为全国优选拌面集合店， 五爷拌面融合了全国各地最受欢迎的多种拌面味道及做法， 加以改良优化， 并严苛把控品质， 力求做到“ 料更好， 味更浓”。 同时， 公司产品组合比较丰富， 除了拌面外， 也有汤面、 饭等其他菜品， 还会根据当地口味推出特色菜单， 符合更广泛人群的口味需求。 同时， 五爷拌面正全面打造自己的新零售产品， 加强新品及线上流通类产品的研发， 目前已在各大电商及各地商超上架销售。',

            '五爷拌面创始人孙雷表示：“ 感谢投资人对五爷拌面的认可， 获得A轮投资将是我们新的未来的启程。 面， 是中国第一大刚需， 看起来平平无奇， 但却是一个生命周期超长的品类， 在中国人的餐桌上扮演着不可替代的角色。 当前全国市场， 面条类餐饮门店数量众多， 需求量非常大， 同时中国人最爱吃的十款面条中， 多以拌面为主。 例如炸酱面、 热干面、 葱油拌面等。 2021 年， 在快餐新机遇下我们更加看好面类未来的市场表现和市场容量， 从而定下了‘ 万家店’ 的宏大目标。 希望五爷老爷爷能像肯德基老爷爷一样， 遍布全球， 让世界爱上中国面！”',

            '鼎晖VGC管理合伙人张海峰表示，“ 好行业、 好业务、 好团队、 好交易是鼎晖坚持精品投资的一贯标准， 五爷拌面则在精品中尤其优异。 行业时点上， 随着供应链、 线上支付、 信息化等基础设施不断完善， 中国连锁餐饮业将进入十年黄金发展期， 这一历史性机遇窗口会成就多家千亿市值公司。 五爷拌面以高度标准化的品类和“ 好吃不贵” 的定位， 切入大众社区广大蓝海市场， 并融合直营与加盟的优点， 把加盟模式做到准直营式管控， 不仅实现业内最高增长扩张速度， 还构建了高质量门店赋能管理体系。 随着公司向全国快速拓展， 团队在孙总带领下已经形成科学方法论和高效组织力。 我们将坚定支持五爷拌面成为中式快餐领域领军企业。”',

            'B资本董事长王岑表示，“ 非常荣幸见证和参与了五爷拌面的一路成长。 作为见证人， 我不仅2019 年个人投资了五爷拌面, 2021 年新设立的B资本(BrandVest Capital, 简称B资本) 成立后也坚定不移地参与此次企业A轮融资。 格局、 执行力、 学习能力是我看好创始人孙雷及其团队的核心能力。 快速的启动、 快速的信息化、 快速的整合互补资源、 快速的下沉开店、 快速地进入上海、 快速的布局各省背后是五爷拌面战略的极其专注、 是团队的渴望与激情、 是对供应链及信息化的深度理解、 是市场消费升级对中式快餐的巨大需求、 是五爷拌面化繁为简商业模式的成功。”',
        ]
    },
    {
        name: '融慧金科',
        title: '「融慧金科」获数千万美元B+轮融资，致远资本担任独家财务顾问',
        desc: '近日，融慧金科宣布完成数千万美元B+轮融资。本轮融资由优山资本领投，澳洲电信投资跟投，致远资本担任独家财务顾问。',
        date: '2021.06.15',
        content: [
            '近日， 融慧金科宣布完成数千万美元B + 轮融资。 本轮融资由优山资本领投， 澳洲电信投资跟投， 致远资本担任独家财务顾问。 截至目前， 融慧金科共计完成了四轮融资， 投资方囊括了红杉资本中国、 澳洲电信投资、 华创资本、 优山资本等一线明星投资机构。 本轮融资主要用于技术研发、 大数据研发投入和产品服务体系升级打造等。',

            '融慧金科成立于2017年， 三位创始人王劲、 张羽和盛军均具有长达二十年的国内外甲方风控经验。 自成立之初， 便确定了致力于为持牌金融机构提供一站式金融科技风控服务的路径， 是行业中极少数具备“ 闭环服务” 能力的金融科技公司。',

            '在金融科技风控赛道， 基于辅助运营BOT模式（ Build - Operate - Transfer）， 融慧金科首创三个行业第一。 在产品交付中， 更是坚持以合规性引领金融科技市场， 并严格要求自身以甲方的标准在做乙方的业务， 致力于做最懂甲方的合作伙伴。 值得一提的是， 在提供产品及服务的过程中， 融慧金科保持高度开放的态度， 首创“ 白盒服务” 模式， 乐于和金融机构分享实战中的技术能力及经验沉淀， 助力其快速发展。',

            '正是由于自身专业的金融风控经验、 深厚的技术积累、 完善的企业级产品布局、 成熟的全流程闭环服务体系， 以及开放心态的助力， 尽管成立时间只有短短四年， 融慧金科却已经实现了上百家包括国有银行、 股份制银行、 头部城商行、 持牌消金、 头部互金等在内的持牌金融机构客户的方案交付， 成功跻身金融科技赛道第一梯队。',

            '融慧金科创始人兼CEO王劲表示， 融慧金科是一个基于自主、 快速、 健康基因而诞生的绿色金融科技企业， 我们的使命是利用端到端的全流程闭环服务， 协助银行、 消金等持牌机构获得业务上持续、 快速、 健康的增长。',

            '优山资本合伙人顾锐表示， 互联网消费信贷历经多年发展， 已成长为规模超4万亿的巨大市场。 相较于传统的消费信贷形式， 其及时性、 便利性的特点更受到年轻群体的青睐， 未来行业需求将持续增长。 伴随行业监管逐步完善， 我们相信互联网消费信贷行业将向着持牌机构主导的正规化、 良性化的方向发展。 大数据风控是互联网消费信贷行业核心的业务环节， 以大数据风控为主的金融科技服务具备超百亿的市场空间。 在持牌金融机构逐渐主导消费信贷行业进程中， 围绕着持牌机构风控需求， 提供定制化、 端到端的大数据分析和软件服务将具备巨大的价值创造空间。 在行业发展趋势的判断上， 融慧金科与我们是一致的。'
        ]
    },
    {
        name: '九章云极',
        title: '「九章云极」获3亿元C轮融资，致远资本担任独家财务顾问',
        desc: '近日，九章云极DataCanvas宣布完成C轮融资，由尚珹资本、赛富投资基金领投，君紫投资、领沨资本等投资机构跟投，融资金额3亿元，致远资本担任独家财务顾问。',
        date: '2021.05.18',
        content: [
            '近日， 九章云极DataCanvas宣布完成C轮融资， 由尚珹资本、 赛富投资基金领投， 君紫投资、 领沨资本等投资机构跟投， 融资金额3亿元， 致远资本担任独家财务顾问。 本轮融资备受一线投资机构认可， 资方长期关注科技创新领域， 青睐人工智能领域中技术壁垒高、 产品领先优势显著、 业务发展潜力巨大的头部企业。',

            '九章云极DataCanvas此次C轮融资距前次B + 轮1 .2 亿元融资不到一年， 充分体现了资本市场对九章云极DataCanvas业务战略和未来资本路径的信心。',

            '九章云极DataCanvas创始人兼董事长方磊表示， 此轮融资将进一步夯实公司在人工智能平台领域的领头羊地位： 九章云极以自主研发的“ 硬科技” 立身， 守正出新， 以自主研发的标准化AI基础架构软件为基石， 通过将AI技术从研发到生产化过程中各个环节“ 自动化”， 不断开创各行各业新业务新场景的领先应用； 同时， 以发展的眼光赋能AI生态， 开源一系列国际领先的AutoML模型算法、 工具和架构， 通过提供统一的开发标准和规则， 加速跨行业AI应用的研发和AI能力的沉淀。',

            {
                type: 'mb0',
                text: '自主AI， 赋能企业数智化升级'
            },
            '作为国际领先的数据科学平台提供商， 九章云极DataCanvas坚守以数据科学赋能企业AI 的使命， 通过低代码、 自动化的人工智能平台实现AI技术的企业级应用， 帮助企业客户沉淀自主AI建设能力、 积累数据资产、 提升数智化升级能效。 近年来， 九章云极DataCanvas的数据科学产品市场占有率稳居中国前三， 已服务金融、 通信、 航空、 制造、 交通、 政府等众多头部客户， 各项业务在持续高速增长之中。',

            '九章云极的核心产品线—— DataCanvas数据科学平台家族—— 为企业客户自主研发AI应用提供坚实基础架构的同时， 通过ModelOps为客户提供云原生、 可解释、 大规模、 直击业务痛点的AI模型全生命周期管理服务。 这是企业数智化升级过程中自主创新、 高效协同、 加快AI生产化并提升业务收益的重大创新。',

            {
                type: 'mb0',
                text: '硬科技 + 标准化， 定义AI基础架构未来'
            },
            '自主研发的AI技术、 创新的业务应用和良好的客户口碑使得九章云极DataCanvas在千变万化的市场环境下实现业务超预期增长， 凭借多个行业的深耕经验和技术与业务知识的成熟融合， 其标准化的AI平台产品实现了更大规模的覆盖， 大客户数量较去年翻倍增长， 并在通信行业成功复制应用； 同时， 九章云极DataCanvas正在加速产品线的创新和迭代， 以服务与日俱增的巨量数智化升级市场需求。',

            '以“ 硬科技” 立身的九章云极DataCanvas在技术创新方面成果瞩目， 多项创新技术频频收获国际机构认可， 此前凭借独创的开源项目DeepTables， 在齐聚1万3千多家参赛队伍的全球数据科学顶级Kaggle竞赛中， 摘获第一名， 全新定义了深度学习技术在结构化数据分析领域的价值。 基于持续的技术自主创新， 九章云极DataCanvas目前已积累200多件自主知识产权， 其中包含50多件发明专利。',

            '据悉， 新一轮融资将用于进一步完善AI基础架构软件的版图， 九章云极DataCanvas将推出新一代HSAP实时数据库， 满足人工智能时代对新一代数据库的需求， 成为AI数据库的定义者和领导者。 与此同时， 九章云极DataCanvas将通过颠覆性的AutoML、 AutoDL技术创新， 强化在金融、 通信、 政府等行业应用优势， 为各行业企业数智化升级提供新动能。',
        ]
    },
    {
        name: '美尚股份',
        title: '「美尚股份」获4亿元B轮融资，致远资本连续担任财务顾问',
        desc: '新锐品牌珂拉琪COLORKEY母公司美尚股份正式宣布完成总额4亿元人民币的最新融资。本次B轮融资由金镒资本领投，高盛、君川等跟投，老股东持续加码。致远资本连续担任财务顾问。',
        date: '2021.01.29',
        hasSub: true,
        content: [
            '新锐品牌珂拉琪COLORKEY母公司美尚股份正式宣布完成总额4亿元人民币的最新融资。 本次B轮融资由金镒资本领投， 高盛、 君川等跟投， 老股东持续加码。 致远资本连续担任财务顾问， 这是继2020年4月协助公司顺利完成A轮2亿元融资后， 又一次成功合作。',

            '美尚股份于2018年创立， 旗下拥有潮酷彩妆品牌COLORKEY珂拉琪、 专业彩妆品牌SUPERFACE秀芭斐、 科研护肤品牌LAB101瑞沛。 自创立以来， 潮酷彩妆品牌COLORKEY珂拉琪致力于引领千禧一代“ 敢于不同” 的当代潮流趋势， 主打单品空气唇釉深受市场认可。 创始人Cici Lee曾任中外化妆品集团高管职务， 拥有20多年丰富的美妆全产业链经验。 创立之初， 获得红杉资本等近亿元天使轮投资。 2020 年4月， 再获2亿元A轮融资， 投资方包括红星美凯龙、 创新工场、 微光创投和易凯资本。',

            '美尚股份始终奉行“ 品牌金字塔矩阵” 战略—— 以差异化定位的多品牌集团模式布局美妆市场， 满足中国庞大市场之下不同地域、 不同年龄、 不同人群的各色需求。 2019 年初， 美尚集团旗下潮酷彩妆品牌COLORKEY珂拉琪正式上线天猫， 主打唇釉系列开辟出全新的细分市场， 上线3月占领类目第一。 随后销售一路高歌猛进， 双11渠道销售额突破1 .6 亿元， 成为入驻天猫不到三年双11破亿仅有的三家彩妆品牌公司之一。 同步开启全渠道策略， 线下新零售渠道迅速覆盖超3000家， 始终稳占“ 唇釉第一” 品牌， 夯实了金三角底部。',

            '花开两枝， 早在2018年美尚股份便已积极布局“ 品牌金字塔” 腰部。 引入韩国及全球市场知名的专业彩妆品牌SUPERFACE秀芭斐， 以及重金投入科研护肤品牌LAB101瑞沛。 前者将进一步满足于摆脱大众美妆进入追求品质的中端彩妆用户产品升级需求； 后者抓住细分护肤领域， 更是“ 疫情” 后中国女性转而寻求安全、 高效的重要趋势， 2021 年LAB101瑞沛将打造以研发、 生产、 推广三大核心的新护城河， 并将升级品牌主张， 为中、 高端消费者提供更科学有效的深度护肤解决方案。',

            '本次， B轮融资领投方金镒资本是以专业、 稳健著称的市场化PE机构， 过去15年里专注于投资新零售、 消费升级， 以及产业升级领域之中最具潜力的龙头企业。 因共同看好中国市场的长期发展， 尤其在当下， 恰逢下沉市场消费升级带来的庞大增长， 数字化经济的转型红利， 并有着中国供应链产业集群的强大支撑， 这正是美妆品牌难得一见的发展机遇。',

            '“ 如果说彩妆是短跑， 那护肤赛道是马拉松长跑。 过去2年我们已经获得短跑的阶段性胜利， 接下来我们将重点投入到专业护肤市场的发展。” 美尚股份创始人Cici Lee表示：“ 中国是全球最大市场， 但中国化妆品产业链还未走向成熟期， 关键在于没出现高端品牌公司。 美尚股份整个团队具备十五年以上的行业资深经验， 熟悉国际高端市场， 又有打造单赛道销冠品牌的成功案例， 同时也有着全球最顶尖的供应商加持。 有实力、 有信心、 更有使命， 去成为中国美妆行业高端市场拓荒者。”'
        ]
    },
    {
        name: '新核云',
        title: '「新核云」获1.5亿元B+轮融资，致远资本担任独家财务顾问',
        desc: '近日，「新核云」完成同创伟业和IDG资本领投，北极光创投跟投的1.5亿元B+轮融资，致远资本担任独家财务顾问。',
        date: '2021.07.15',
        content: [
            '近日，「 新核云」 完成同创伟业和IDG资本领投， 北极光创投跟投的1 .5 亿元B + 轮融资， 致远资本担任独家财务顾问。 本轮资金将加速新核云在增强研发团队、 产品研发以及拓展销售渠道等方面的步伐， 进一步巩固新核云在离散型制造业数字化转型服务领域中的领先地位。',

            '自2014年成立以来， 新核云致力于为离散型制造业提供数字化工厂全栈式解决方案， 自始至终聚焦于汽车零部件、 通用设备、 专用设备、 金属加工领域的数字化转型服务， 针对工厂在生产、 计划、 订单、 采购、 库存、 质量等核心业务场景， 面向中型和集团型制造企业提供云ERP + 云MES产品， 帮助企业客户构建业务协同、 数据治理及智能决策能力， 为企业智能制造夯实基础。',

            '纵观全球竞争格局制造业的数字化转型迫在眉睫， CEO陶滨江认为生产是制造业的核心， 而生产业务数据离不开MES（ 生产执行系统）， 这也让MES成为工厂数字化转型的核心。 新核云的战略定位是做好底层租户、 权限和账号的体系管理， 通过开放接口， 与其他系统在接口层面达成合作， 实现对数据的整合。',

            '聚焦目标领域， 持续深耕， 达到不断积累垂直领域行业知识， 是新核云成立以来一直坚持的思路。 团队具有丰富的行业背景， 有曾为奔驰、 新奥动力、 东风日产等龙头企业实施过MES系统的资深从业者， 还有来自汽车和装备制造企业的工业专家。 公司从德国引进先进管理规范， 参照IATF16949质量体系要求， 研发出规范化、 标准化的行业解决产品， 能够有效实现客户企业基于垂直行业的需求。 CEO陶滨江表示， MES行业对于垂直领域know - how的要求非常高， 公司经过过去几年在汽车和装备制造行业的积累， 目前已经在细分场景下取得较为明显的产品优势。',

            '截至目前， 新核云已服务客户超800家， 2020 年续费率达110 % ，2021 年续费率预期将达到130 % 。2020 年新核云营收超过1亿人民币， 2021 年营收增长预计将达100 - 150 % 。目前所服务的制造业客户， 年产值分布在5千万— 10 亿元人民币， 主要分布于汽车制造、 通用设备制造、 专用设备制造、 金属制品制造等领域。',
        ]
    },
    {
        name: '好麦多',
        title: '「好麦多」获1.5亿新一轮融资，致远资本担任独家财务顾问',
        desc: '健康食品品牌「好麦多」获得1.5亿元新一轮融资，众为资本领投，清科母基金跟投，老股东星陀资本、前海母基金等持续加持，致远资本担任独家财务顾问。',
        date: '2020.12.02',
        content: [
            '近日， 健康食品品牌「 好麦多」 获得1 .5 亿元新一轮融资， 众为资本领投， 清科母基金跟投， 老股东星陀资本、 前海母基金等持续加持， 致远资本担任独家财务顾问。 本轮资金将用于加强产品研发和品牌建设， 以及供应链的改造等。 今年7月， 好麦多已获来自星陀资本、 前海母基金等数千万元战略融资。',

            '公司的定位是“ 时尚健康食品企业”， 好麦多品牌从水果麦片来切入， 逐步探索围绕谷物食品的新品类， 核心用户是18 - 35 岁的都市用户， 产品场景以早餐为主、 代餐零食为辅， 目前主打的水果麦片品类， 共有10余个SKU。',

            '自品牌诞生以来， 好麦多的成绩一直很亮眼： 在2019年上线首月， 线上销售额就突破了200万， 3 个月后月销售额突破2000万， 不到两年累计全渠道销售额超10亿。 在疫情期间， 好麦多销量增速同比增长超过1000 % 。',

            '其中好麦多的酸奶桃桃、 桂花胶原等创新谷物产品长期高居各大平台及榜单前列， 酸奶桃桃单品更是荣获天猫V榜产品， 累计销售超过500万袋； 桂花胶原蛋白系列荣获2020年中食展TOP创新产品奖。',

            '根据第一财经商业数据中心的数据， 85 后和95后对抗糖需求提升明显， 80 - 90 后对谷物型零食消费需求相对较高。 好麦多的创始人兼CEO方翔告诉36氪， 国内的健康食品品牌还没有成规模地成长起来， 欧美有相当一部分健康食品品牌， 但是其口味不能满足中国消费者对“ 美食” 的需求， 洞察到这一需求后，“ 更好吃” 成为好麦多产品研发的首选考量标准。',


            '根据日本休闲食品上市公司卡乐比的麦片演进方式， 混合麦片可大致分为两类， 普通混合麦片（ 慕斯里Muesli） 和水果麦片（ Granola谷兰诺拉）， 好麦多的麦片属于水果麦片， 采用特殊工艺， 将复合谷物加工使之成为谷物团簇， 再配以水果制成不同口感的麦片。',

            '好麦多最重视的部分在供应链和产品， 而这也是公司的核心壁垒。 团队有多年的海内外供应链专业背景及从业经验， 用相对较优的成本在全球直采到较高品质的原料， 并在近日收购了南美哈利斯科奇亚籽产区。 产品方面， 则沿用了互联网公司的产品思维， 通过最小可视化产品原理， 每个SKU投入约10万~20 万人民币， 从口味、 原料等不同维度量化， 通过数据分析进行敏捷的参数调整， 从而实现迭代升级。',

            '营销方面， 好麦多并不推崇拉新、 裂变等流量玩法， 虽然目前已有关晓彤、 宋威龙和谭松韵三位明星作为代言人加持， 但好麦多仍然认为产品比营销更重要， 立足于对大数据的观察与用户反馈， 将更多的利润投入研发。 好麦多采用是全渠道模式， 线上进驻天猫、 京东、 抖音、 小红书等多家渠道， 线下与沃尔玛、 Ole、 永辉等商超、 连锁店合作， 采用多消费者触点的方式， 而不会依赖单一渠道。',

            '方翔表示， 水果麦片作为新品类不能像其他品类那样标准化是一个遗憾， 公司内部制定出一项“ HON金标准”， 即Healthy好健康、 Original好食材、 Nice好美味。 具体来看， 好麦多HON金标准包括：',

            '一是使配方营养健康， 来应对消费者对专业营养搭配的需要以及非油炸、 高饱腹、 低 GI 糖等基础性需要， 好麦多将每餐卡路里摄入由通常的 880 Kcal， 降低至134Kcal， 相当于一个苹果的热量， 从而与传统早餐做出差异化定位。',

            '二是原料渠道的全球优化整合， 基于独特的地理环境孕育优质食材的理解， 好麦多在近日收购了南美哈利斯科奇亚籽黄金产区土地， 从原材料层面提高产品品质的同时建立了竞争优势。',

            '三是采用先进的技术实现对口感的提升， 好麦多的产品采用了双道烘焙非油炸技术保持酥脆口感， 并且大力投入水果冷泡技术的研发， 让果香和麦片完美融合， 在不加防腐剂的状态下还可以保质期长达 10 个月， 做到“ 不用泡都好吃”。',

            '好麦多创始团队来自清华大学、 香港中文大学、 加州大学伯克利等中外知名高等学府， 除了拥有营养学专业背景， 还成功操盘过多项千万级项目， 具有丰富的商业营销经验。',
        ]
    },
    {
        name: '素肌良品',
        title: '「素肌良品」获数千万元A轮融资，致远资本担任独家财务顾问',
        desc: '内衣新品牌“素肌良品”完成数千万元A轮融资，本轮由众晖资本投资，致远资本担任独家财务顾问。',
        date: '2020.11.30',
        content: [
            '内衣新锐品牌“ 素肌良品” 完成数千万元A轮融资， 本轮由众晖资本投资， 致远资本担任独家财务顾问。 此轮融资将用于新品研发、 市场营销和用户体验升级。',

            '素肌良品致力打造兼具舒适感与功能性的高端内衣品牌， 2019 年6月进驻天猫， 不到一年即斩获天猫新锐品牌榜及文胸类目TOP20等荣誉， 今年销售额同比增长超20倍。 今年“ 双十一” 素肌良品9分钟销售额达到去年“ 双十一” 全天销售额。',

            '随着新一代年轻女性挑选内衣不再将“ 性感” 作为唯一标准， 越来越多消费者从有钢圈的功能型内衣转向无钢圈无束缚的舒适型内衣。 需求端转变催生供给端快速变革， 从有钢圈到无钢圈、 从有胸垫到无胸垫， 不仅是女性内衣的一次重大革新， 也是女性内衣的消费升级。 作为贴身衣物， 女性内衣最终还是以“ 品质说话”。',

            '在内衣“ 去钢圈” 风潮中， 素肌良品瞄准注重自身感受， 注重商品体验感与高颜值的用户群体， 选择了在有钢圈与无钢圈之间的第三种解决方案——“ 软支撑” 内衣。 素肌良品爆款“ 素肌果冻条内衣”， 使用了公司首创的“ 软支撑” 工艺。 功能上满足了既去掉钢圈， 但又是“ 无钢圈” 文胸的升级版， 让消费者既不会有传统内衣的束缚感， 又能享受到稳定的支撑力， 实现“ 无钢圈” 又很有安全感的穿戴体验。',

            '同时素肌良品主打“ 奢品质” 和“ 高颜值”， 公司团队运用时尚年轻化的剪裁设计， 结合低饱和度高级感的配色， 更加贴合年轻用户审美。 团队还选用了能够对标千元品牌的供应链和欧洲进口面料， 满足用户对穿着体验的要求。 目前， 素肌良品内衣售价在220元左右。',

            '此外， 素肌良品设置了极高的出品标准， 每件新产品从新工艺测试到版型的细微调整都有半年以上的打磨期， 目前公司每两个月上新一次， 并经过大量用户内测反馈， 以保证产品的优秀穿着体验。 素肌良品对每一批次的产品， 都会抽样经过30次洗水测试与拉力机剥离测试。 同时通过数据系统赋能， 素肌良品的库存周转期已经缩短至30天到60天。',

            '素肌良品的核心竞争力是对于高端无穿感内衣产品的持续开发能力， 创始团队成员均在内衣行业有9 - 16 年从业经验。 供应链方面， 依托过往多年海内外高端内衣品牌B端业务积累， 公司与一线内衣品牌供应商建立了长期战略合作。 同时素肌良品主导与供应链合作的新技术开发， 目前已拥有数十项知识产权。',

            '未来， 素肌良品将持续推出适应不同场景并兼顾功能性和舒适感的高端内衣。 同时2021年公司将会在广州等一线城市布局线下门店。',
        ]
    },
    {
        name: '星辰天合',
        title: '「XSKY」获3.2亿元D轮融资，致远资本担任独家财务顾问',
        desc: 'XSKY 星辰天合获3.2亿元人民币D轮融资。本轮由中国国有资本风险投资基金领投，清华控股旗下清控华义创投跟投，在持股东启明创投等亦跟投，致远资本担任独家财务顾问。',
        date: '2020.07.02',
        content: [
            'XSKY 星辰天合获3 .2 亿元人民币D轮融资。 本轮由中国国有资本风险投资基金领投， 清华控股旗下清控华义创投跟投， 在持股东启明创投等亦跟投， 致远资本担任独家财务顾问。 据悉， XSKY此轮融资将用于发展国产化信创存储基础技术研发， 混合云数据管理方案构建， 以及软件定义存储产品在下沉市场的推广。',

            '这是自2015年创立以来， XSKY完成的第5轮融资， 截止目前， 公司累积融资金额达7 .52 亿元。',

            'XSKY星辰天合是一家专注中国软件定义存储的企业。 公司提供企业级分布式软件定义存储产品， 帮助客户实现数据中心架构革新。 XSKY的业务以融合存储软件、 分布式数据存储平台， 以及存储混合云解决方案为主。 XSKY提供的产品包括： XEDP统一数据平台、 XCBS和XEBS分布式块存储、 XEUS分布式统一存储、 XEOS分布式对象存储、 XSCALER融合存储一体机等。',

            '源于大型互联网数据中心的软件定义存储（ SDS）， 不同于基于专有硬件的传统网络附加存储(NAS) 或存储区域网络(SAN) 系统。 SDS 一般在行业标准服务器或 x86 系统上运行， 通过软件与硬件的解耦， 帮助用户获得按需扩展存储的能力。 SDS具有经济高效、 可扩展性强、 海量空间、 云原生、 自动化等特点。',

            '在交付方面， 公司的内部产品组织体系以用户业务性质为组织结构划分原则， 分为敏态和稳态。 敏态以软件交付为核心， 面向客户快速创新的业务需求； 稳态以纯软， 软硬一体等多元化的交付形态， 场景化驱动， 一站式满足客户的业务需求。',

            'XSKY目前在政府、 金融、 电信、 广电、 教育、 交通、 医疗、 能源、 制造等多个领域有广泛商业化部署， 支撑各行业的生产系统存储云化改造和科技创新应用。 截止目前， 累计服务大中型直接客户超过600家， 接近EB级的生产部署容量。 XSKY的典型客户包括： 中国人民银行， 中国人寿， 太平洋保险， 中信银行信用卡中心， 江苏农信， 国家电网， 中国移动， 中国电信， 中国联通， 中国一汽， 上汽集团， 吉利汽车， 东风汽车， 长虹集团， 徐工集团， 扬子江药业， 东方航空， 厦门航空等。',

            '2019 年上半年， 超融合市场较去年同期实现了56 .7 % 的增长， 软件定义存储市场达到了52 .8 % 的同比增长率。 边缘计算、 物联网、 AI、 实时分析等工作负载对可快速扩展的基础架构的需求， 将推动分布式存储市场的增长。 根据IDC《 2019 Q4 China SDS Market Overview》 显示： 截止2019年， XSKY在中国软件定义存储（ SDS） 整体市场份额排名中位列第四， 在对象存储细分市场排名第一， 而且已经是连续三年在该领域保持第一。',
        ]
    },
    {
        name: '德风科技',
        title: '「德风科技」完成8000万A轮融资，致远资本担任独家财务顾问',
        desc: '全栈工业互联网产品提供商“德风科技”宣布完成8000万人民币A轮融资，由招商局创投与创新工场联合领投，原股东云启资本跟投，致远资本担任独家财务顾问。',
        date: '2020.06.22',
        content: [
            '全栈工业互联网产品提供商“ 德风科技” 宣布完成8000万人民币A轮融资， 由招商局创投与创新工场联合领投， 原股东云启资本跟投， 致远资本担任独家财务顾问。 据了解， 本轮资金将主要用于“ 新基建” 工业互联网产品研发升级及行业生态拓展。 此前， 德风科技曾获上市公司创始人天使轮投资， 云启资本Pre - A轮投资。',

            '德风科技是一家专注于能源电力、 石油化工、 烟草、 工业制造业等行业的工业互联网技术公司， 提供先进的工业互联网平台、 智能安全物联网平台、 工业大数据平台等全栈产品和解决方案， 如5G多站融合解决方案、 综合能源互联网云平台、 智慧电厂、 智慧油田、 数字孪生、 智能安全物联网方案等。',
            {
                type: 'bold-mb0',
                text: '构建“工业大脑”'
            },
            {
                type: 'bold',
                text: '押注千亿市场空间'
            },

            '开年以来，“ 新基建” 成为经济热词， 人工智能、 工业互联网等新基建七大领域站在风口， 但真正落地且可以大规模应用的场景仍屈指可数， 而传统产业等经典场景融合人工智能和工业互联网的技术进行转型升级， 释放出巨大优势和市场价值。',

            '不过各个行业特点不一， 在经过深度的市场调研之后， 德风科技选择能源电力、 石油化工、 烟草等作为工业互联网的切入点。',

            '德风科技创始人兼CEO王清杰认为， 首先， 能源电力、 烟草、 石油化工等传统行业抗经济周期能力极强， 进入门槛高、 壁垒深； 其次， 这三大行业存在业务数字化、 商业安全管理信息化、 降本提效等诸多行业痛点， 数字化升级需求亟待满足， 信息化预算充足， 付费能力强， 通过工业互联网、 人工智能和大数据去赋能这三个传统行业， 可拥有千亿元价值的市场空间。',

            '“ 以降本提效为例， 目前石油化工企业维护成本在桶油操作费中占比达到25 % 左右， 仅大数据分析的预测维护技术就可将维护成本降低13 % 。”王清杰说。',

            '德风科技成立5年， 已拿下中国烟草、 国家电网、 中石油、 中海油、 中国中车等多个传统巨头的订单。 王清杰表示， 能拿下巨头订单， 德风有几个核心竞争优势。',

            '首先是团队的技术落地实力。 德风科技核心团队来自IBM、 西门子、 普华永道、 华为等业内复合型人才， 平均具备超过15年的工业互联网产品经验与能源电力、 烟草、 石油化工行业经验， 拥有30多项专利， 70 多个软著。 团队擅长技术产品化， 同时能高效落地到使用场景， 切实提供企业的生产效率。 德风CEO王清杰是IBM前高管， 负责产品及解决方案， 具有18年经营管理经验， 丰富的能源电力、 工业制造、 石油化工行业的客户资源和项目经验。',

            '其次是能提供工业互联网全栈产品及解决方法。 德风科技提出了工业互联网4PE模型， 即基于工业互联网平台（ Platform）， 通过顶层设计（ Planning） 为客户规划完整的工业互联网技术路线图， 结合生产（ Production） 互联和产品（ Product） 互联， 做到生态（ Ecosystem） 互联。',

            '以工业互联网平台为支撑， 德风科技为客户解决三个层面痛点： 第一是生产设备层（ APM）， 包括设备状态监测、 PHM（ 故障预测与健康管理）、 预测性维修保障、 质量缺陷分析及优化、 可靠性分析及预测等； 第二是生产运营层（ OPM）， 包括能源管理及能耗监测分析、 线损治理及耗损分析、 安全双控管理等； 第三是经营决策层（ ET）， 包括工业大脑、 数字化运营监测、 应急指挥调度、 安全预警预判等。',

            '“ 德风产品能力覆盖终端数据采集层、 边缘计算层、 工业PaaS平台层及应用SaaS层， 通过工业大数据、 安全物联网、 工业互联网平台三大产品矩阵， 为行业客户构建工业大脑。” 王清杰介绍， 德风产品有几个特点： 一是德风科技方案层次完整。 自主研发多层次解决方案， 可独立完成系统设计、 产品跨层积木式组装、 可快速满足企业客户需要； 二是产品化能力强。 抽象客户需求， 定义标准、 产品模块， 极大程度降低定制化交付成本； 三是数据赋能企业。 通过大数据和AI能力提升企业客户运营效果， 沉淀海量客户数据和场景， 更高效匹配社会资源。',

            '据了解，“ 智慧能源” 是德风科技以工业互联网、 人工智能赋能能源电力行业的经典案例。 电力公司采用“ 智慧能源管理” 解决方案， 可以通过互联网和物联网实现能源设备集中控制； 同时， 通过PHM历史数据模式学习， 实现保养计划的智能制定； 通过历史发电数据的机器学习， 提升发电量3 % 以上， 最终让公司运维成本降低30 % 。',

            '而在烟草行业， 德风科技已经与国家烟草局全国23个省份烟草工业和商业客户达成合作， 在安全物联网和安全大数据方面市场份额处于第一。“ 经评测， 德风产品落地场景在烟草安全生产三维定位、 安全领导管理驾驶舱、 安全大数据移动应用、 全方位安全管控及安全大数据分析等方面， 能把烟草生产安全事故率降低15 .6 % 。”王清杰说。',

            {
                type: 'mb0',
                text: '年复合增长率近100 %'
            },
            '打造“ 中国工业互联网SAP”',
            {
                type: 'mb0',
                text: '德风科技作为一家TO B端的高科技初创企业， 目前已经建立具备了造血能力。',
            },

            '王清杰透露， 德风科技自成立以来， 每年保持近100 % 的复合增长率， 服务超过200家大型企业， 并连续盈利。 经过四年多的发展， 德风科技已具备从物联网数据采集、 处理、 存储、 分析、 深度挖掘、 场景应用和算法优化的综合一体化服务和项目落地能力。',

            '值得一提的是， 在此次疫情期间， 德风科技一季度业绩相比2019年同期， 增长了约80 % 。',

            '“我们的造血能力有两大方面。 一是商业落地能力。 凭借团队对行业的深刻理解与领先的技术水平， 公司具备极强的商业落地能力， 收入规模以及增长率、 复购率行业领先。 二是有可持续的收入模式。 德风为石油化工、 能源电力、 烟草以及智能制造等多个500强企业提供全套IT + OT的解决方案， 服务头部客户形成强大的标杆效应， 可实现规模化横向复制拓展。” 王清杰提到。',

            '而此次融资的资金将用于产品研发、 业务拓展、 人才建设与营运资金补充。“ 我们的下一个目标是成为中国工业互联网领域的SAP。” 王清杰介绍。',

            '本轮投资机构招商局创投总经理吕克俭表示：“ 工业互联网是中国“ 新基建” 七大核心之一， 目前已经进入了高速发展期。 我们希望德风科技将其“ 工业互联网最佳实践” 深耕行业， 为中国大型企业的智能化和数字化贡献一份积极的力量。 招商局创投也将从资本、 市场、 人才等方面， 为德风科技提供持续支持， 共同助力我国新基建工业互联网的发展。“',

            '创新工场董事长李开复表示：“ 从传统IT到IIoT工业互联网、 基于数据积累和算法优化的人工智能为基础， 德风科技把IT与OT高度融合， 为企业解决了“ 降本、 提质、 增效” 的痛点， 工业互联网是助力企业数字化升级及产业转型的有效工具， 德风科技作为领先的工业互联网产品商将为企业转型升级提供支持及保障。 创新工场十分看好德风科技的行业经验和发展前景， 将与德风科技一道协助能源电力、 石油化工、 烟草工商等行业更好地实现数字化转型， 并整合创新工场旗下云服务、 大数据和人工智能等技术资源， 打造中国“ 新基建” 工业互联网的生态体系。”',

            '本轮投资机构云启资本创始合伙人毛丞宇表示：“ 云启资本长期关注“ 技术赋能产业升级” 早中期投资， 深耕布局“ 新基建” 的多个关键领域， 其中“ 工业互联网” 是重点关注方向之一， 我们看好德风科技团队在2B领域耕耘了十余年的行业Know - How和核心技术， 并且正在协助能源电力、 石油化工、 烟草等行业进行赋能提升和产业升级， 相信在中国大型企业未来十年间向数字化智能化转型的新时代新机遇下， 德风可以创造更大的商业和社会价值。”',
        ]
    },
    {
        name: '芯翼信息科技',
        title: '「芯翼信息科技」完成近2亿元A+轮融资，致远资本担任独家财务顾问',
        desc: '物联网芯片公司芯翼信息科技获近两亿元A+轮融资，本轮融资由和利资本领投，华睿资本及另外3家老股东峰瑞资本、东方嘉富、七匹狼跟投，致远资本担任独家财务顾问。',
        date: '2020.06.10',
        content: [
            '物联网芯片公司芯翼信息科技获近两亿元A + 轮融资， 本轮融资由和利资本领投， 华睿资本及另外3家老股东峰瑞资本、 东方嘉富、 七匹狼跟投， 致远资本担任独家财务顾问。 本轮融资备受资本市场追棒， 创造迄今为止NBIoT行业单笔金额最大的融资。 本轮资金将主要用于生产制造现有的NB - IoT芯片产品、 研发设计下一代芯片产品以及开拓下游细分市场， 加速行业应用落地。',

            '另外获悉业务层面芯翼信息产品斩获两大运营商订单， 成为NBIoT行业创业项目商业化进展最头部的企业。 近日， 中国移动旗下中移物联网公司采用单一来源集采方式采购芯翼信息XY100产品200万片的订单。 此外， 在前段时间中国电信NB - IoT模组招标结果中， 集成了芯翼信息科技XY1100芯片的高新兴物联NB - IoT模组成为该项目标包二的第一中标人， 独家占据中国电信标包二30 % 以上份额。',

            '芯翼信息科技于2017年3月在上海张江创立， 创始人肖建宏博士曾任全球top3芯片设计公司Broadcom的资深首席科学家， 拥有丰富的射频、 连接芯片设计经验。 核心团队来自Broadcom、 Intel、 Maxlinear、 Atmel等国际知名半导体企业， 平均拥有十余年芯片行业经验， 高端“ 行家” 汇聚逐梦， 无疑构成公司强大核心竞争力。 公司创立伊始， 获得峰瑞资本、 中科创星、 普渡科技的数千万元的天使轮融资。 3 个月后获得金卡智能数千万元的PreA战略投资。 随后， 其又在所谓“ 资本寒冬” 的2018年10月完成数千万的A轮融资， 投资方包括邦盛资本、 前海母基金、 七匹狼、 东方嘉富、 晨晖创投等。',

            '根据公开信息， 芯翼信息科技自主研发的NB - IoT芯片XY1100具有集成度高、 成本低、 低功耗、 接收灵敏度高等特点。 该芯片不仅集成了PMU / TRX / BB / AP， 还集成了CMOSPA， 可以支持690 - 960 MHz和1 .71 - 2.2 GHz的多频段； 同时其高集成度可以为客户大幅节省外围BOM的成本， 无需再配置DC - DC和SAW， 模组器件数减少60 % ，模组成本下降30 % ；其功耗低， PSM模式即深度睡眠状态下电流为0 .7 uA， idleDRX及eDRX状态下电流低至0 .02 mA； 该芯片单传接收灵敏度可达 - 118 dBm， 128 次重传接收灵敏度达到 - 137 dBm； 并且XY1100芯片采用QFN52封装方式， 模组大小可达到10 * 10 mm。 另外， 芯翼信息科技这款芯片采用SDR架构， 可支持NB - IoT和扩展支持其他私有通讯协议， 满足碎片化市场需求。',

            'NB - IoT（ NarrowBand Internet of Things） 即窄带物联网， 是物联网络的一个重要分支， 可以支持低功耗设备在广域网的蜂窝数据连接。 5 月7日， 工信部办公厅正式发布了《 关于深入推进移动物联网全面发展的通知》。 通知指出， 要引导新增物联网终端不再使用2G / 3 G网络， 推动存量2G / 3 G物联网业务向NB - IoT / 4 G（ Cat1） / 5 G网络迁移， 并推动NB - IoT标准纳入ITUIMT - 2020 5 G标准。 这意味着NB - IoT将成为5G三种应用场景中mMTC的事实标准， 且2G网络的腾退将有助于NB - IoT加快部署。',

            '除了政策方面的支持， NB - IoT本身模组成本的下降也会促进其部署， 模组成本随着芯片成本降低而下降到与2G模组相同水平， 预计未来将进一步降至2美元以内。 工信部计划2020年NB - IoT基站规模达到150万个， 总连接数超过6亿。',

            '具有低功耗、 大连接、 覆盖广、 低流量等特点的NB - IoT将支持占据物联网连接60 % 的低速率、 高时延市场。 NB - IoT目前主要应用在公共事业应用场景即智能表计、 智能烟感等。 同时NB - IoT可应用在农业生产领域的温度， 湿度监测等方面。 而在消费领域中， NB - IoT可应用于智能家居、 共享单车和可穿戴智能设备方面。',

            '芯翼信息科技会和下游行业的合作伙伴紧密合作， 针对行业的差异化需求特点开发定制化的物联网芯片。 例如针对室内环境和共享单车场景将WiFi等近场通讯技术集成在芯片产品中， 提供可以同时实现近场通讯和远场通讯的物联网芯片完整解决方案。',

            '芯翼信息科技表示未来芯片产品也将集成GNSS北斗定位系统， 应用于资产追踪领域。 资产追踪主要集中在物流场景中， 例如冷链物流和医药物流等。 同时新能源汽车及电池领域也将是芯翼信息科技NB - IoT产品重点布局的方向， 致力于成为全球创新的物联网终端SoC领导者'
        ]
    },
    {
        name: '美尚股份',
        title: '「美尚股份」完成A轮近2亿元融资，致远资本担任独家财务顾问',
        desc: '美尚股份宣布完成了近2亿元A轮融资，投资方包括红星美凯龙、创新工场、易凯资本、微光创投，致远资本担任独家财务顾问。',
        date: '2020.04.07',
        content: [
            '近日， 美尚股份宣布完成了近2亿元A轮融资， 投资方包括红星美凯龙、 创新工场、 易凯资本、 微光创投， 致远资本担任独家财务顾问。 本轮融资备受资本市场追捧， 创造迄今为止美妆行业A轮单笔最大融资金额。',

            '美尚股份于2018年1月成立， 创始人李琴娅（ Cici Lee） 曾任中外化妆品集团高管职位， 拥有丰富的化妆品全产业链经验。 公司创立伊始即获得红杉资本和刘强东的宿迁天强投资公司近亿元的天使轮投资基金， 创行业天使轮融资金额新高。 美尚强大团队来自于世界500强、 国际4A、 头部互联网、 MCN机构、 全球供应链等一流企业， 高端行家汇聚“ 追梦”， 无疑将全面构建美尚股份的核心竞争力。',

            '2020 年， 中国进入万元美金社会， 在转入服务业驱动的经济模式中， 女性将成为社会发展和消费的主流人群。 美尚旗下拥有国产潮牌colorkey珂拉琪、 韩国进口专业彩妆品牌superface秀芭斐、 台湾护肤品牌Lab101瑞沛三个品牌， 针对中国主流中产女性需求， 通过多品牌战略布局， 攻占细分市场， 满足不同阶层不同收入女性的美妆需求。',

            'colorkey珂拉琪： 天猫上市一年销售破2亿， 半年跻身天猫国货彩妆排行TOP5、 2019 年双11当天爆款空气唇釉爆卖70万支、 斩获天猫唇釉类目TOP1！ 珂拉琪已覆盖包括天猫、 京东、 云集在内的主要销售平台， 还借助入局美妆零售的新物种走向了线下， 开辟出另一条赛道。 目前， 珂拉琪的线下分销渠道覆盖了全国11个城市的苏宁极物、 WOW COLOUR、 奥买家等。',

            '专业彩妆品牌superface秀芭斐诞生于韩国首尔， 2018 年被美尚股份纳入麾下。 目前该品牌在美国、 日本、 韩国、 新加坡等市场快速扩张， 2019 年9月发力中国市场。',

            '美尚控股的中国台湾科研修护品牌Lab101瑞沛， 致力于皮肤抗衰老研究及医学美容领域服务， 业务范围逐步发展至美国、 韩国、 中国等多个国家及地区。',

            'A轮融资完成后， CEO李琴娅表示， 紧扣“ 她世代” 中国女性消费需求， 美尚股份在多品牌 + 多渠道的清晰战略下， 正朝着“ 悦中国 跃世界” 的愿景全力冲刺—— 走进国内消费者的心， 走出世界的大门。',
        ]
    },
    {
        name: '领筑智造',
        title: '「Indeco」完成C轮2000万美元融资，致远资本担任独家财务顾问',
        desc: '国内一站式互联网空间解决方案供应商inDeco领筑智造，近期已完成2000万美元的C轮融资，本轮融资由斯道资本领投，两家全球500强企业：化工企业巴斯夫公司和建筑材料企业圣戈班集团跟投，老股东源码资本、高利盛竹继续加码，致远资本担任独家财务顾问。',
        date: '2020.04.07',
        content: [
            '国内一站式互联网空间解决方案供应商inDeco领筑智造， 近期已完成2000万美元的C轮融资， 本轮融资由斯道资本领投， 两家全球500强企业： 化工企业巴斯夫公司和建筑材料企业圣戈班集团跟投， 老股东源码资本、 高利盛竹继续加码， 致远资本担任独家财务顾问。',

            '2018 年， 公司曾获得源码资本领投， 真格基金、 光大安石、 巢生资本跟投的A轮融资， 19 年初完成由彤程新材、 艾想投资、 高利盛竹、 上海千章等机构参与的B轮融资。 加上本轮， 总融资额2 .4 亿元， 是目前已公开披露的信息中， 互联网公装行业融资金额最多的企业。',

            '传统公装一直以来都面临着两个问题： 一是对人的依赖程度高， 同时又面临工人老龄化、 用工荒等问题； 二是信息化程度低， 施工管理过程基本上靠手动填写表格来完成。 这两个问题导致整个行业利润较低且难以形成规模化效应。',

            'inDeco领筑智造的互联网公装解决方案通过技术手段为生产环节降本增效， 解放个人生产力。 公司的技术发展大致会经历三个阶段：',

            {
                type: 'bold-mb0',
                text: '· inDeco 1.0：实现业务流程全面线上化，信息化'
            },

            'inDeco在2019年上线“内部BIM“系统，实现业务流程全面线上化，包括商务系统、工程管理系统、供应链系统、成本系统等，实现一键式报价，一键式发包等的业务动作，同时解决因为项目服务流程长、时间长导致的前后信息不一致。流程线上化可以很大程度上推动项目管理及服务标准化，而在系统中沉淀下来的数据，也将为下一步自动化解决方案打下基础。',

            {
                type: 'bold-mb0',
                text: '· inDeco 2.0：加速研发一站式自动化解决方案'
            },

            '科学的内部流程可以极大的提高人效。 对此， inDeco将加速研发公装一站式自动化方案， inDeco 创始人Randy介绍， 今年4月份会推出施工图自动化设计系统， 5 月份会推出自动化报价系统和排期系统， 今年会加速整个平台实现自动化。',

            '在生产环节， 公司也尽可能利用自动化技术， 减少纯机械性或纯体力工作。 比如， 在设计环节， 利用AI图像识别技术将客户需求转化成设计语言， 在后台跟供应链系统打通， 系统根据客户喜欢的风格自动识别材料， 提升设计师选型效率。',

            {
                type: 'bold-mb0',
                text: '· inDeco 3.0 智能化布局，进一步降本增效'
            },

            '传统装修对人的依赖很大， 不确定性高，“ 去人工化” 是解决行业问题的关键。 Randy认为， 智能硬件可以代替其中部分工作的基础环节。 比如， 在场地测量环节， 相较于传统量房模式， 依靠3D扫描设备可以节约2个人力和一半的时间， 测量结束后同步生成图像， 并在后期直接与供应链打通， 快速进入生产。',

            '目前， 公司总客户数在1500个， 地域主要分布在北京、 上海、 深圳。 互联网行业客户居多， 也有像海底捞这样的连锁餐饮客户。 今年， 公司会将业务拓展至成都。 平均来看， 公司的客端价在80万元， 平均项目交付周期在35天左右。',

            '在收入利润方面， inDeco预计今年营收会在7亿左右， 并有望实现盈利。 在过去三年， 公司的营收都实现了翻倍的增长， Randy认为， 实现这一增长的核心要素在于inDeco为客户提供的业务价值清晰， 老客户复购率高， 并且通过口碑传播和市场营销， 做到了持续的获客。',

            '团队方面， inDeco员工已有260人， 其中研发团队占20 % ，此次融资的40 % 将用于技术人才和相关研发工作的投入。 30 % 的资金用于搭建供应链。 其余将用于市场开拓和品牌建设。',
        ]
    },
    {
        name: '秒优科技',
        title: '「秒优科技」完成Pre-A轮5000万融资，致远资本担任独家财务顾问',
        desc: '秒优科技已完成5000万元Pre-A轮融资，投资方为绿洲资本，天使轮投资机构源码资本追投，致远资本担任独家财务顾问。',
        date: '2020.02.28',
        content: [
            '秒优科技已完成5000万元Pre - A轮融资， 投资方为绿洲资本， 天使轮投资机构源码资本追投， 致远资本担任独家财务顾问。 本轮融资资金， 秒优科技将主要用于对服装云工厂相关产品和技术的研发， 和市场团队的搭建。',

            '秒优科技是一家服装领域提供智能制造解决方案的企业， 成立于2009年。 早年以管理咨询 + 软件服务起步， 逐步形成了全套智能制造解决方案， 包括服装云供应链管理系统、 服装智能制造执行系统（ MES）、 服装标准工时系统（ GST） 等， 作为软件厂商服务的服装生产企业累计达200余家。',

            '自2019年起， 秒优科技试水新业务—— 云工厂， 整合多家服装生产企业的厂能， 通过承接服装品牌的订单， 将订单拆分给合作的工厂， 从而建立起具备柔性快反能力的服装供应链网络， 最快 "7天"可完成一款新品的设计生产交付周期， 而行业内的顶尖水平维持在21天。',

            '随着服装领域电商渠道、 电商品牌的兴起， 以及消费者个性化消费的趋势加强， 服装行业对供应链提出了新需求—— 柔性、 快反， 前者要求灵活生产， 后者要求小批量。',

            '而秒优科技的思路， 正是通过云工厂进行社会产能的整合与调度， 提高整个供应链的柔性和响应速度。',

            '具体来说， 基于过去十年秒优科技服务服装生产企业的经验和数据， 掌握着大量服装品类、 工艺、 企业生产特征等信息， 秒优科技通过订单集约化之后， 将工艺重合度高的品类集中分配给专业化工厂进行生产。 由于成衣的工序复杂多样， 为了能够通过算法对订单进行整合归类， 秒优科技将成衣生产工艺拆分成118个标准动作并打上标签， 从而进行同类项合并。',

            '咨询出身的秒优科技擅长通过精益流程管理为企业提效。 罗建军指出， 在服装企业里， 从设计打样、 面辅料采购到生产、 物流， 要经历近四十个环节， 差不多有十几个部门协同合作， 而在秒优科技过去的成绩里， 平均能通过整套解决方案为企业提效20 % -30 % ，最早甚至达到100 % 。',

            '除了生产环节的效率提升， 秒优科技同样重视全流程的效率提升， 为了配合云工厂这一新业务的发展， 秒优科技正逐渐从软件业务中抽调人员进行轻量化SaaS的研发， 提供给生产企业、 电商服装企业使用， 优化工厂原有的生产排单系统和服装企业内部的供应链管理系统， 从而将以往串行工作流调整为并行工作流。 罗建军认为， 通过一整套解决方案的应用， 秒优科技有望将生产周期从7天压缩至5 - 6 天。',

            '罗建军表示， 市场上对能够有质量实现柔性快反的供应链有强烈需求， 而供给端无法满足， 因而秒优科技的 "7天"效率就是强有力的支点。 而效率的维持与进一步提升上， 秒优科技优势正在于过去十年积累了庞大的数据基础， 并能够基于对服装行业的了解， 对数据进行清理和建立算法模型， 真正发挥数据的价值， 同时， 在秒优科技的团队中， 有大量来自华为、 腾讯的产品和技术人员， 这样的团队组成在市场上本身是稀缺的。',

            '在市场方面， 以往秒优科技主要通过口碑获客。 罗建军认为， 2 B生意中产品是根本， 通过营销拉新的客户如果缺少承接能力， 则毫无意义。 因而秒优科技会重点打磨产品和技术， 让长处更长， 形成马太效应， 但与此同时， 秒优科技也引进了一位市场方面的副总裁。',

            '绿洲资本张津剑表示：“ 过去互联网是先有规模再有质量， 今天产业是先有质量再有规模。 很多产业里面十年磨一剑的质量公司迎来规模红利， 秒优科技是一个典型代表。”',
        ]
    },
    {
        name: '斗象科技',
        title: '「斗象科技」完成数亿元C轮融资，致远资本担任独家财务顾问',
        desc: '斗象科技近日宣布完成C轮数亿元融资，由钟鼎资本领投，同创伟业、惠友资本、云栖创投、线性资本跟投，致远资本担任独家财务顾问。',
        date: '2020.02.24',
        content: [
            '斗象科技近日宣布完成C轮数亿元融资， 由钟鼎资本领投， 同创伟业、 惠友资本、 云栖创投、 线性资本跟投， 致远资本担任独家财务顾问。 本轮融资后， 斗象科技将进一步推进智能网络安全产品矩阵的研发和服务能力提升， 加速数据中心至云端的解决方案覆盖， 加大力度打造行业社区平台开放生态。',

            '斗象科技为用户提供互联网化的网络安全服务以及智能安全分析产品解决方案。 业务包括网络安全众测服务平台“ 漏洞盒子”， 全息安全监测与数据分析产品“ 斗象智能安全平台”。 斗象科技旗下还运营着老牌网络安全社区“ FreeBuf”， 积累了70万网安从业者用户， 形成了广泛的行业影响力和良好的业界口碑， 是全球知名的网络安全技术社区领导品牌。',

            '截至2019年底， 斗象科技的产品与服务已经广泛应用于金融、 互联网、 零售、 航空、 物联网 & 车联网、 政府等多个领域超过600家大中型客户， 为众多行业龙头加筑网络安全防线， 为企业数字化转型保驾护航， 为政府信息化安全保障提供支撑。 通过创新的产品服务与过硬的技术实力， 斗象科技在2014年创立至2019年间， 营收实现每年超过90 % 的复合增长率， 斗象的业务增速和客户数量持续领跑同行业创业厂商。',

            '斗象科技致力于将服务客户的一线经验， 快速落地转化为解决方案。 2019 年， 斗象科技围绕PRS - NTA全流量大数据智能安全分析系统、 ARS漏洞智能扫描系统、 CRS云端安全中心等多款产品构建的智能安全平台， 与“ 白帽专家驱动” 的漏洞盒子安全众测服务、 VMS漏洞管理平台等产品实现数据交融打通， 在当下“ 重攻防， 重实战” 的行业趋势背景下， 帮助客户切实提高网络安全攻防实战能力， 显著提升威胁分析、 风险数据挖掘以及自动化安全运营能力。',

            '对于此次C轮融资， 斗象科技CEO谢忱表示：“ 我们很荣幸得到新老股东的认可与支持， 斗象将始终以客户第一为己任， 进一步完善产品服务体系， 打造兼顾创新与落地性的行业解决方案， 成为新一代网络安全领头羊。”',
        ]
    },
    {
        name: '优地科技',
        title: '「优地科技」完成数千万B2轮融资，致远资本担任独家财务顾问',
        desc: '优地科技今日宣布完成数千万B2轮融资，投资方为上海索道投资管理有限公司，致远资本担任独家财务顾问。',
        date: '2019.10.09',
        content: [
            '深圳优地科技有限公司（ 以下简称： 优地科技） 今日宣布完成数千万B2轮融资， 投资方为上海索道投资管理有限公司， 致远资本担任独家财务顾问。 本轮融资资金将用于产能扩充、 市场拓展， 加速优地科技在室内配送领域的市场拓展及室外配送的落地应用。',

            '深圳优地科技有限公司成立于2013年3月， 是全球领先的配送服务机器人制造商， 也是业内最早实现无人驾驶设备量产商用的企业之一， 其自有品牌机器人已经遍布全国50多个城市， 为近千家商业场所， 提供平均每天超50000人次的引领、 配送等服务。 同时， 优地科技也已经利用其成熟的技术， 为众多业内公司提供机器人行走方案。',

            '目前， 优地科技已推出的产品有： 楼宇配送机器人、 酒店配送机器人、 KTV配送机器人、 送餐机器人、 室外配送机器人， 截止2019年第三季度， 已累计上线运营的机器人数量突破1500台。“ 优小妹” 和“ 优小弟” 在KTV场景的市场占有率雄居第一。 室外机器人“ 优小哥” 自2018年推出市场以来， 已展开多项落地测试， 目前已升级至第三代产品， 正在进行5G落地应用的实测。',

            '据中物联 & 美团点评发布的《 2018 中国即时配送行业发展报告》 显示， 中国即时配送行业2017年年收入突破800亿元， 订单量89亿件， 预计2018年订单量将超过120亿件； 2017 年全年中国外卖市场交易额近3000亿元， 预计2018年将突破3600亿元。 随着人力成本上涨， 即时配送面临着派送人员流动性大、 管理困难、 供需平衡难以匹配等困难和问题， 优地科技认为， 在100米—— 3000 米的末端配送场景内尚无服务机器人独角兽企业， 但是4000亿劳务市场 / 年的前景是非常可观的， 人机协作不仅可以提升效率， 也可以从一定程度上降低配送成本。',

            '上海索道投资管理有限公司创立于2016年， 专注于投资布局人工智能、 智能机器人领域， 具有持续发展潜质的大赛道和赛道中领先的团队， 通过前瞻性的产业布局形成被投企业之间的协同效应。',

            '索道投资石总认为“ 室内配送本质上是自动驾驶的一个分支技术落地场景， 而且目前看来是最先落地的场景， 未来通过配送机器人网络， 甚至会形成类似互联网一样的网络效应， 带来流量、 数据、 供应链整合的强大壁垒和竞争优势。 而优地科技来自UT斯达康， 具有成熟的管理团队， 因此在短短的3年内就完成了在KTV和酒店场景的大批量场景落地， 在此过程中我们看到了团队对行业的深刻理解， 具有强大的市场推广和团队组织能力。 我们非常看好优地科技未来能够成为具有综合配送能力的机器人配送公司， 而不仅仅只是一家机器人产品研发生产公司”。',

            '优地科技董事长卢鹰表示“ 我们从2013年成立至今， 获得了天使轮、 A轮、 B轮和本次的B2轮融资， 感谢投资人的认可。 我们看好机器人在未来的整个产业布局， 也坚信我们定位于室内外配送领域的方向是正确的， 相信由此将带来真实的经济效益和社会价值。 我们的使命是“ 让广大的劳动者不再劳累”， 而我们也将继续保持着激动之心、 敬畏之心、 专注之心， 做好产品与服务， 坚定地追寻人工智能造福人类的伟大梦想！”',

            '优地科技曾于2018年4月获得元禾润新和君联资本联合领投的数千万B轮融资， 2016 年9月获得北京新恒基投资管理集团有限公司(简称新恒基) 及科大讯飞的数千万A轮融资， 2013 年获得来自UT斯达康的天使轮。',
        ]
    },
    {
        name: '因爱科技',
        title: '「因爱科技」完成B轮融资，致远资本担任独家财务顾问',
        desc: '领先的创新型家园共育平台‘多鹿’的母公司因爱科技宣布完成B轮融资，本轮由红杉资本领投，老股东纪源资本跟投，致远资本担任独家财务顾问。',
        date: '2019.09.23',
        content: [
            '2019 年9月， 领先的创新型家园共育平台‘ 多鹿’ 的母公司因爱科技宣布完成B轮融资， 本轮由红杉资本领投， 老股东纪源资本跟投， 致远资本担任独家财务顾问。 这是因爱科技在成立一年左右的时间获得的第四轮融资。 因爱科技之前分别获得部分互联网资深人士的天使轮投资、 昆仲资本（ PRE - A轮）、 GGV纪源资本（ A轮领投） 的投资。',

            {
                type: 'mb0',
                text: '2018 年7月底， 王加彬创立了因爱科技。'
            },
            '2018 年12月底， 因爱科技推出了第一款产品： 多鹿和多鹿老师。',

            '毕业于北京大学物理学专业的王加彬， 从百度到阿里巴巴， 积累了在大平台上做产品、 带团队、 建文化的经验。 因爱科技的现有合伙人都是百度和阿里巴巴背景的同事， 分别在运营、 产品、 销售、 渠道、 技术等方面各有所长， 王加彬曾表示：“ 我们这些人凑在一起， 能充分感受到彼此的信任， 大家对于未来有着同样的梦想， 坚持同一个使命和愿景， 这也许就是创业最好的感觉。”',

            '“ 让童年更美好， 让老师更快乐” 作为因爱科技的使命， 并以“ 服务1亿中国家庭， 成为最有爱， 最有幸福感的公司” 为愿景。 在0 - 6 岁的学前教育领域， 家长对宝宝的健康安全、 启蒙教育、 亲子娱乐以及在园期间的动态 / 成长等有刚性的需求， 这是一个聚集了1 .2 亿宝宝、 3 亿家长的巨大市场。 我们希望通过互联网的产品和技术能力以及创新的商业模式， 始终跟我们的合作伙伴站在一起， 赋能机构、 老师和家长真正的实现家园共育。',

            '2019 年2月中旬推出产品后， 在不到6个月的时间内已经获得了超过二十万幼师和四百多万用户的安装和使用。 获得了数万园所和机构的使用和支持， 取得了数百万家长的使用和满意。 非常欣喜的是多鹿和多鹿老师在活跃度和粘性上、 长期留存上， 数据表现也较好， 也印证了在这个领域， 家长的需求可以被持续满足， 老师端的效率和服务可以持续提升。“ 我们希望在明年（ 2020 年） 上半年， 服务一千万用户， 实现三百万日活， 这会是我们的一个阶段里程碑。 我们做到第一个一百万用户时， 整个团队都非常开心， 我们希望再往前走一步。” 王加彬对短期的业务目标做了这样的阐述。',

            '目前， 多鹿已服务数万园所和机构用户， 某幼教集团的赵老师谈到多鹿时， 开心之情溢于言表：“ 用多鹿4个多月了， 把平时工作通过简单的操作展示给家长， 家长实实在在看到我们的付出， 教师节那天原来班级的家委会负责人特意送了一束花让我感动不已， 这都是多鹿的功劳！ 还有坚持用多鹿每天的小惊喜， 感谢多鹿平台！”',

            '某幼师幼教集团苏园长就曾表示：“ 正是因为集团总部的引进， 让我们幼儿园接触到了多鹿平台， 在多鹿之前我们也使用过其它平台， 但使用了一段时间老师和家长们又纷纷走回老路又使用了微信， 对管理上造成了比较多的麻烦， 自从引进多鹿以后， 多鹿带给我们耳目一新的感觉， 它紧贴时代像幼儿园版的抖音， 功能使用非常方便， 最关键的是也能获得家长的正面反馈和认可， 老师们都会每天给孩子们拍一些照片和视频发给家长， 家长更满意了， 老师们的工作热情也不用我们操心了！”',

            '渠道伙伴张老师， 是合作最密切最持久的渠道伙伴， 谈到这段经历， 他也给出了自己的见解：“ 多鹿是我接触的多款幼儿领域产品中的其中一种， 起初只是抱着参与尝试的态度加入了多鹿渠道大家庭， 但随着对产品的使用和与一线幼师园长的沟通， 他们的反馈让我对多鹿有了更深的认知。 这些老师和园长们经常对我说的是， 用了多鹿后， 时间变得更宽裕， 整个人都轻松了起来， 教学工作也更有效率了。 区别于其他软件， 更轻量， 更便捷， 还因此感谢我的推荐。 这种反馈带给我的成就感也是我一直坚持做多鹿的原因。”',

            {
                type: 'mb0',
                text: '因爱科技的融资历程：'
            },
            {
                type: 'mb0',
                text: '2018 年7月， 天使轮， 近千万人民币， 包括向海龙（ 原百度公司高级副总裁、 百度搜索公司总裁） / 魏强（ 原深圳一达通创始人） 在内的天使投资人；'
            },
            {
                type: 'mb0',
                text: '2018 年10月， Pre - A轮， 数千万人民币， 昆仲资本领投；'
            },
            {
                type: 'mb0',
                text: '2019 年4月， A轮， 数千万美金， 纪源资本（ GGV） 领投， 昆仲资本跟投；'
            },
            '2019 年8月， B轮， 金额暂未透露， 红杉资本领投， 纪源资本跟投；',

            '在资本寒冬的大背景下， 一年的时间内完成了四轮融资， 充分说明了因爱科技的使命愿景受到了资本的认可， 团队受到了信任， 更可贵的是， 也再一次把互联网对学前领域的改造和升级优化， 这个大的领域， 让更多人看到， 让同行受到鼓舞， 大家可以一起为整个行业的繁荣做出努力和贡献。',

            '多鹿团队目前已经接近一百人， 业务在高速发展过程中， 王加彬多次提及了对于人才和技术人员的重视和渴望“ 我们希望继续扩大团队规模， 吸纳更多优秀的人与我们一起奋斗。 我们相信创业就是事在人为， 什么样的一群人聚在一起， 就能做成一件什么样的事。 希望更多更优秀的伙伴加入我们！”',
        ]
    },
    {
        name: '扩博智能',
        title: '「扩博智能」完成Pre-B轮融资，致远资本担任独家财务顾问',
        desc: '全球领先的机器视觉技术公司扩博智能近期宣布完成PRE-B轮2,200万美元的融资。本轮融资由CMC资本（CMC Capital）和中华开发（CDIB）领投，GGV纪源资本、开发国际、首建投资本跟投，致远资本担任独家财务顾问。',
        date: '2019.08.08',
        content: [
            '全球领先的机器视觉技术公司扩博智能近期宣布完成PRE - B轮2, 200 万美元的融资。 本轮融资由CMC资本（ CMC Capital） 和中华开发（ CDIB） 领投， GGV纪源资本、 开发国际、 首建投资本跟投， 致远资本担任独家财务顾问。 融资完成后， 扩博智能将持续推进以机器视觉技术为核心的产品服务， 在零售、 风电新能源等领域继续纵深拓展， 此外进一步加码在海外市场， 特别是美国和欧洲市场的拓展， 服务更多优质的全球客户。',

            '同时， 原中国国务院参事， 原科技部党组成员、 秘书长, 中国可再生能源学会理事长石定寰先生加入扩博智能公司的战略委员会， 未来将在新能源领域的战略规划、 科技推动等方面为扩博智能添砖加瓦。',

            {
                type: 'mb0',
                text: '成功并非侥幸“ 技术领先” 第一位'
            },
            '目前， 扩博智能已经在人工智能技术赋能传统业务上实现了规模化、 产品化、 商业化、 效能化的横向落地和纵深发展。 在“ AI + 场景” 引领市场的背后， 是实力的体现—— 拥有多项自主知识产权的技术， 申请及获得相关专利80余项。 同时， 扩博智能“ 软硬结合” 的技术实力独树一帜， 不论是零售还是风电新能源领域， 自主可控的硬件作为数据采集的入口， 保障提供高质量的数据， 软件作为处理和服务的载体， 同时搭配硬件为用户提供一个“ 端到端” 的解决方案。 而扩博智能的半自动化人工智能标注学习系统， 则比传统纯人工辅助方法效率提高了40倍。',

            '比起“ 无所不能” 的人工智能概念， 将人工智能真正在传统行业落地才是硬道理， 扩博智能通过与产业深度结合， 利用先进技术来解决真正的业务痛点， 而不是创造痛点， 这一点着实不易。',

            {
                type: 'mb0',
                text: 'AI + 多个场景落地 掷地有声'
            },
            '扩博智能主要业务涵盖风电新能源和零售两大版块。 在风电领域， 扩博智能自主研发了全球首个基于无人机全自动飞行与机器视觉技术的风机叶片巡检数据采集和数据服务平台—— 通过特种飞行机器人与人工智能技术的有效结合， 能够100 % 全自动完成巡检， 相比传统方式效率提升10倍。 目前该套解决方案已经服务于欧洲和中国地区的客户， 并正在美国地区积极拓展业务。',

            '而在零售行业， 公司通过人工智能技术， IoT边缘计算， 和商业模式帮助传统零售行业实时获取动态市场数据， 商品识别准确率达95 % 。零售行业的数字化进程虽然今非昔比， 厂商、 零售商和消费者都仍各自苦于“ 语言不通、 渠道不畅”， 造成的信息不对称、 欠流动、 少透明度， 各方都因此损失了时间、 机会， 效率与体验。 扩博智能的智慧零售方案以“ 机器视觉 + 智能硬件” 为技术核心， 对图像快速准确解析、 智能分析和实时沟通， 搭建起产品、 店铺与消费者之间的智慧桥梁， 实现三赢。 零售业务的解决方案目前服务于中国、 东南亚， 以及北美等地区的客户， 并在积极拓展南美市场。',

            {
                type: 'mb0',
                text: '从中国起步 拓展全球'
            },
            '在过去的两年， 扩博智能已成功在包括可口可乐、 沃尔玛、 宝洁等多个国际知名快消及零售品牌的全球多个地区落地。 此外， 公司也成功与欧洲最大的风机运维商之一GEV达成战略合作， 与全球最大的风电整机厂商西门子歌美飒计划共同拓展欧洲、 北美、 非洲等国际市场， 通过计算机视觉新技术改变未来风电市场国际运维格局。',

            '值得一提的是， 扩博智能所服务的全球知名客户要求都很高， 但场景的可复制性更高， 因此集中资源专注服务好头部企业， 满足客户在全球范围内更多的需求， 提高服务质量， 才能用标准化的高质量服务来吸引更多企业用户。',

            '在扩博智能成立不到三年的时间已获得总计4, 300 万美金的融资投入， 未来扩博智能也将会继续在零售、 风电新能源等领域继续纵深拓展， 使用AI技术与传统场景的结合， 进一步提高产品和服务的客户价值。',
        ]
    },
    {
        name: 'NOLO HOME',
        title: '「NOLO VR」完成亿元级A+轮融资，致远资本担任独家财务顾问',
        desc: 'VR交互科技公司NOLO VR宣布完成亿元级A+轮融资，本轮融资由愉悦资本领投，蓝驰、盛景嘉成跟投，致远资本担任独家财务顾问。',
        date: '2019.07.29',
        content: [
            '近日， VR交互科技公司NOLO VR宣布完成亿元级A + 轮融资， 本轮融资由愉悦资本领投， 蓝驰、 盛景嘉成跟投， 致远资本担任独家财务顾问， 本轮融资将主要用于VR交互技术的持续深耕和商业化拓展。',

            'NOLO VR成立于2015年， 专注于VR交互技术领域， 目前， NOLO 在VR交互技术上的研发投入累计超过一亿元， 已拥有海内外专利125件， 国际专利已进入12个主流国家。',

            '产品方面， NOLO VR开发了6DoF Outside - in VR交互硬件NOLO CV1， 同时开发了搭配该硬件产品使用的软件平台NOLO HOME。 NOLO CV1可以适配市面上主流的手机VR头盔、 PC VR头盔以及VR一体机头盔， 主要应用于对价格敏感、 隐私敏感、 功耗敏感、 算力占用敏感、 位置相对固定的VR应用场景， 以家庭、 办公室等C端场景为主。 通过该产品， 用户可以无线便捷地畅玩Steam VR游戏， 而价格在一两千元量级。',

            '该硬件产品其核心技术在于PolarTraq® 声光电定位技术， NOLO VR称该三维空间定位技术能同时实现大范围、 高精度、 高鲁棒、 低功耗、 低延迟、 小体积、 高性价比的特性。',

            '同时， NOLO VR规划开发适用于大范围多人应用场景的6DoF Inside - out、 3 DoF以及全身动作追踪套件的产品， 据悉， 此新品将在不久后上市。',

            '客户方面， NOLOVR已与Google、 华为、 小米、 Pico、 爱奇艺、 创维、 大朋等众多产业链合作伙伴展开合作， 以发展C端市场。 在教育、 文旅、 地产等B端行业也同样有应用。 同时与中国移动、 中国电信的合作也已在多省市落地执行。 目前， NOLO VR用户已经遍布全球70多个国家和地区。',

            '谈到VR行业的发展前景， NOLO VR CEO张道宁认为， VR屏幕必将和电脑屏、 手机屏、 电视屏、 手表屏、 汽车屏一样， 成为5G时代的泛在显示器， 成为重要的基础设施。 在VR屏幕中， 有6DoF交互需求的可能占到20 % 左右， VR交互将成为一个非常垂直的细分赛道。“ 一两年内将会有大量轻薄、 便携、 时尚、 便宜的VR眼镜面世， 随着5G时代的来临， VR市场即将迎来几十倍的爆发式增长。” 他说。',

            '团队方面， NOLO VR的核心研发团队由来自中科院、 北邮的博士领衔。',
        ]
    },
    {
        name: '晓羊教育',
        title: '「晓羊教育」完成1.5亿B轮融资，致远资本担任财务顾问',
        desc: '晓羊教育宣布完成近1.5亿元人民币B轮融资。本轮由新东方教育文化产业基金、新东方教育科技集团、华创资本共同领投，老股东云启资本、阿米巴资本跟投。本次融资由致远资本担任财务顾问。',
        date: '2019.03.28',
        content: [{
                type: 'mb0',
                text: '近日， 基础教育科技创新公司晓羊教育宣布完成近1 .5 亿元人民币B轮融资。 本轮由新东方教育文化产业基金、 新东方教育科技集团、 华创资本共同领投， 老股东云启资本、 阿米巴资本跟投。 本次融资由致远资本担任财务顾问。',
            },
            {
                type: 'mb0',
                text: '晓羊教育成立于2016年5月， 是中高考改革走班教学整体解决方案提供商和国内基础教育信息化技术创新企业。 公司致力于K12教育领域科技创新， 以旗舰产品“ 一人一课表” 为切入点， 整合学校教育基础数据， 构建教育大数据新生态。',
            },
            {
                type: 'mb0',
                text: '公司创始人兼CEO周林博士公司有着近二十年丰富的技术及企业级软件管理经验， 国际团队管理经验， 战略规划以及商业营销经验。 创办晓羊教育之前就职于全球最大教育软件公司Blackboard, 任全球副总裁。 公司其他高管均在各自领域深耕数十年， 具备丰富管理、 销售经验和资深教育背景。 目前公司团队近200人。',
            }, {
                type: 'mb0',
                text: '2014 年9月， 国务院发布《 关于深化考试招生制度改革的实施意见》， 新高考序幕在国内拉开。 晓羊教育研发团队响应国家政策和教育一线需求， 推出“ 一人一课表”、 AI BOX以及智慧校园2 .0 等系列产品， 帮助区域教育局、 学校和教师提升教育管理和教学水平。',
            }, {
                type: 'mb0',
                text: '智慧校园2 .0 系统以课表数据为核心， 采用软件微服务、 硬件微模块的架构开发， 在隐私保护、 数据安全、 系统稳定、 高并发支持等方面均处于国际领先水平。',
            }, {
                type: 'mb0',
                text: '晓羊教育的发展愿景为将排选课作为切入点， 通过先进的大数据处理技术对基础数据进行收集、 交换、 分析， 为区域教育及信息化发展提供决策支撑， 进而建设整体智慧校园， 最终打造覆盖全用户、 全方位的教育生态， 成为未来智慧教育的领航者。',
            }, {
                type: 'mb0',
                text: '截至目前， 晓羊教育业务已覆盖北京、 天津、 四川、 山东等全国大部分地区1600余所学校， 包括北京市第三十五中学， 潞河中学， 成都七中， 福建师大附中， 天津第二南开学校， 深圳实验学校等全国知名学校。 未来晓羊教育将会在包括海外市场的更广泛领域， 继续用科技的力量助力教育信息化。',
            },
            {
                type: 'mb0',
                text: '本轮融资将主要用于支撑公司技术研发投入和优质人才的进一步引进， 同时为公司产品迭代和提升用户体验提供充足的资金保障， 使晓羊教育在未来获得更加快速及持续、 稳定的发展。',
            }, {
                type: 'mb0',
                text: '晓羊教育创始人兼CEO周林博士表示， 在党中央、 国务院发布《 中国教育现代化2035》 的宏大背景下， 晓羊教育通过对教育数据的集成、 管理与分析， 创新大数据应用技术， 持续推进信息技术与教育教学的深度融合。“ 一人一课表” 只是一个起点， 基于这个起点， 晓羊教育将整合各种资源类、 管理类、 教学类数据信息， 成为开放的数据互通共享平台， 进而打造基础教育大数据新生态。',
            }, {
                type: 'mb0',
                text: '对于本轮战略投资， 华创资本管理合伙人吴海燕表示：“ 随着新高考改革的逐步试点、 推进， 全国公立中学对于以智能排选课系统为核心的校园信息化、 智能化需求与日俱增。 晓羊教育由一群教育行业加IT行业老兵创立， 在对学校需求的理解、 产品研发、 深度服务上都有着丰富的实践经验， 其产品目前已是全国广大公立中学响应新高考改革的首选， 拥有广泛的成功实施案例。 我们相信晓羊教育将会成为‘ 校园信息化’ 的领头羊企业。”',
            }, {
                type: 'mb0',
                text: '新东方掌门人俞敏洪也高度重视本次投资， 他认为：“ 人工智能的发展， 在教育上拥有广阔的应用前景。 晓羊教育以先进的人工智能、 大数据、 云计算技术， 顺应国家基础教育改革的发展趋势， 打造K12的新型教育生态， 是教育现代化的积极推进者和领头羊。 晓羊教育的发展模式非常清晰， 针对区域和学校都有非常好的产品服务， 与新东方的业务有很好的契合度， 本次投资是新东方在教育信息化领域的重要战略布局， 必将为基础教育的改革创新注入新的活力！”',
            },
        ]
    },
    {
        name: '高仙机器人',
        title: '「高仙机器人」完成 B 轮亿元级融资，SLAM 机器人年产量将超 1000 台，致远资本担任独家财务顾问',
        desc: '3 月 19 日，「高仙机器人」宣布已完成亿元人民币 B 轮融资。本轮由远东宏信集团旗下远翼投资领投，风险投资基金 KIP 中国跟投，上轮资方蓝驰创投继续跟投，致远资本担任独家财务顾问。',
        date: '2019.03.20',
        hasSub: true,
        content: [
            '截至2017年， 全国60周岁及以上人口已达24090万人， 占总人口17 .3 % ，预计2025年六十岁以上人口将达到3亿， 届时中国将成为超老年型国家； 与此同时， 我国又一直处在城镇化发展阶段—— 房地产行业经历了高速建设期之后， 交付了超过200亿平米的城市建筑， 这些巨量面积的楼宇、 社区、 园区亟需要专业的服务和管理运营...高仙机器人认为， 机器人和无人驾驶则能够帮助物业行业实现服务场景中简单重复性岗位的自动化和机械化。',

            '3 月 19 日，「 高仙机器人」 宣布已完成亿元人民币 B 轮融资。 本轮由远东宏信集团旗下远翼投资领投， 风险投资基金 KIP 中国跟投， 上轮资方蓝驰创投继续跟投， 致远资本担任独家财务顾问； 不到一年前， 高仙曾获融由蓝驰创投领投、 七海跟投的 A 轮千万级美元。',

            '值得一提的是， 远东宏信是国内头部的第三方融资租赁服务提供商， 此次远东宏信旗下远翼投资的参与， 将为高仙在金融与产业端的发展提供帮助。',

            '创始人兼 CEO 程昊天告诉36氪， 本轮资金主要用于提升产能以及产品研发， 2019 年预计生产超过 1000 台清洁机器人， 销售额将超 2 亿元。 此外， 高仙清华大学成立了联合实验室， 将针对楼宇、 安防等场景的 SLAM 和深度学习技术展开前沿性的研究。',

            '36 氪此前曾报道， 成立于 2013 年的高仙机器人， 是一家以 SLAM 技术研发和相关应用开发为主要业务的企业。 2014 年， 高仙推出了第一台商用清洁机器人， 并于两年内在以新加坡为主的海外市场实现了商用落地。',

            '截至 2018 年， 高仙商用清洁机器人已完成五轮迭代， 为 7 个国家、 200 多个客户在提供了超过 25 万多公里的稳定运行服务； 较 2017 年相比， 高仙营收实现了 400 % 的年增长， 且已基本实现盈亏平衡。',

            '在去年的采访中， 程昊天曾向36氪介绍了高仙的“ 三条发展路线”， 即自主品牌清洁机器人、 安防解决方案、 楼宇配送。“ 自主品牌机器人和安防解决方案的发展很顺利， 仅高仙自研的定位导航模块， 在 2018 年就实现了超过 1 万套的量产。 这样的量产规模， 也确保公司在物流配送等其他新领域上， 能够不断探索、 积累业务理解及场景数据， 让底层技术向深度和广度两个方向的迭代。”',

            '楼宇配送方面， 由于针对自动驾驶机器人的楼宇基础设施（ 例如电梯） 的改造限制较多， 所以其发展相对于清洁和安防方向稍慢些， 但高仙仍在努力与相关部门、 地产商协同克服困难。',

            '程昊天表示， 目前公司员工约有 260 人， 其中 40 % 为开发人员， 而企业的产品则以达到国内领先水平。 未来， 高仙还将基于已覆盖的场景， 挖掘更多额外的商业价值。',

            '“ 举例来讲， 我们可以通过为商超中的清洁车加入更多传感器， 并开发相关算法， 为客户提供夜间灯光管理、 温湿度监测、 货品盘点等附加功能， 因为现阶段来看， AI 技术的成熟会使其应用更加碎片化， 所以即使我们将清洁机器人做好， 这个行业仍然拥有机会。',

            '自 2019 年起， 高仙将在北京、 上海、 新加坡建设 3 座研发中心， 加强与包括清华大学、 上海交通大学、 新加坡国立大学等在内的学术研究合作， 并借力包括芯片、 传感器在内的上游技术商， 实现具有生态意义的技术战略布局。',
        ]
    },
    {
        name: '居理新房',
        title: '「居理新房」完成数千万美元B+轮融资，致远资本担任独家财务顾问',
        desc: '新房电商平台“居理新房”近日获得数千万美金的B+轮融资，估值及融资金额均刷新新房电商赛道融资记录。',
        date: '2018.09.07',
        hasSub: true,
        content: [
            '新房电商平台“ 居理新房” 近日获得数千万美金的B + 轮融资， 估值及融资金额均刷新新房电商赛道融资记录。 本轮融资由富达国际旗下斯道资本领投， 源码、 祥峰等老股东跟投， 58 集团给予战略投资， 致远资本担任本轮融资的独家财务顾问。',

            '居理新房起步于2015年， 是国内较早专注于新房交易的互联网公司。 经历过去几年的发展， 电商模式覆盖了绝大多数品类， 但房产电商因其低频、 决策周期长、 商品复杂、 交易流程特殊等原因发展滞后。',

            '居理新房独创数据系统与直营团队双引擎模式， 将房产信息数据化和咨询师全流程服务无缝拼合， 为用户提供“ 省钱买好房” 的购房体验， 也使效率大幅提升。 历经三年的发展， 居理新房在用户满意度、 成交周期、 平均人效等方面， 远远高于行业平均水平。',

            '居理新房CEO王鹏认为：“ 电商的发展历程就是从高频向低频商品， 从轻决策向重决策商品的覆盖过程， 在汽车电商之后， 房产电商也即将进入技术和市场条件的成熟期， 过去房产交易普遍分散、 粗放、 用户体验差， 极大拉低行业的效率。 居理希望能给行业带来一些改变， 让重匹配效率、 数据和服务的电商模式逐步取代传统营销方式。”',

            '2017 年存量房市场下行， 新房市场反而持续增长， 据国家统计局数据显示： 2017 年全国商品房销售额超过13万亿， 增长13 .7 % 。随着一线城市房源总量不足还会保持增加的趋势。 近期掀起热议的北京租金上涨， 也昭示着核心城市房源供应的不足。',

            '中国核心城市群未来还将吸附大量净流入人口， 大量城市当前及未来会增加大量土地供给， 二手房的限售政策也使购房者进入新房市场， 同时围绕着中国城市群的规划和建设， 会催生大量新房需求。',

            '然而开发商的土地成本、 融资难度等压力却在加大， 周转率逐渐成为一项重要指标。 在这种变化趋势下， 传统的营销获客手段或是无法带来实际交易效果， 或是严重降低用户体验伤害品牌， 问题越来越凸显。',

            '在用户方面， 因房产交易的低频、 决策信息复杂等原因， 导致很多决策信息被忽略、 隐瞒， 而用户对这些信息的低甄别能力又可能导致买错或错过房源。',

            '王鹏表示：“ 买错或错过的情况非常多， 而居理拥有的强大的系统和专业的咨询师会按照用户的需求整理好最适合的信息， 支持用户快速有效的进行决策。 同时居理全国首创免费1v1专车看房、 签约辅助、 差价赔付等政策， 也可以让用户非常舒适放心的进行一站式购房， 实现真正的‘ 省钱买好房’。”',

            '在技术投入方面， 居理新房的技术 / 服务人员达到1： 3， 是业内最高的技术 / 服务人员比例。 持续利用大数据和AI等技术手段提高交易体验和效率， 不仅让直营的咨询师团队能够更好地用工具和系统给用户提供有保障的信息和服务， 更能通过算法的优化让整个服务体系运转更加高效， 保证了行业最高的用户满意度和人效， 也为居理新房连续稳定的跨城增长带来了强劲动力。',

            '在直营服务团队的打造方面， 居理新房的咨询师团队是业内985、 211 等高校毕业生占比最高的服务团队团队月均流失率不到5 % ，远低于行业平均水平。',

            '截至目前， 团队已为5万组用户提供了带看服务， 提供带看报告数量超过10万份， 包括推荐楼盘及区域情况、 市场分析以及用户个性化问题解决方案， 由此也形成了广大用户的好评。 据统计， 居理新房已收到用户撰写真实好评达86万字， 大量用户在亲历了服务后均表示愿意向朋友推荐。',

            '本轮领投的斯道资本是富达国际有限公司旗下的自有资产投资机构， 在中国拥有超过20年的风险投资经验， 专注于发展创新和具价值的业务。',

            '作为领投机构， 斯道方面表示， 在行业扫描过程中， 跟踪居理新房近一年的时间， 斯道资本亚洲区管理合伙人臧加纶表示：“ 房产服务和房产科技与消费和金融科技相关， 是斯道资本长期关注的投资领域。 当下开发商和购房人都面临新的痛点和需求， 亟需更加高效优质的交易及其他服务。”',

            '臧加纶认为：“ 居理新房通过线上精准获客， 线下优质服务的模式， 高效服务买卖双方， 在开发商和消费者中建立了极强的口碑。 数据驱动运营的方式， 显著提升了交易效率并可以构建长期壁垒。”',

            '作为老股东， 源码资本一直见证着居理新房的发展， 其执行董事王星石表示：“ 居理新房解构交易的各个环节， 用互联网工具优化各个环节的效率和体验， 使整体交易效率和服务体验得到了极大的提升， 实现了上下游用户、 咨询师的多方共赢发展。”',

            '同样是老股东， 祥峰投资方面也一直看好居理新房在房地产市场的新渠道价值， 其执行合伙人徐颖表示：“ 居理新房经过不断验证， 已经逐渐摸索出了日臻成熟的打法， 通过数据挖掘潜在用户促成交易， 正是从在线化到智能化转变阶段在新房销售垂直领域很好的商业应用， 实现了房产领域的效率提升。”',

            '“ 房产媒体平台或加盟型交易平台都是在技术尚无法覆盖线下场景时的阶段性产物， 终态模型必然是线上线下数据打通的直营媒体 + 交易平台。” 致远资本创始合伙人李悦认为在这一方面居理新房已经走在了行业前端。',

            '对于大部分人来说，“ 住得不好” 都是一个挥之不去的痛点， 对此， 王鹏希望通过技术的力量， 把买房人和房子的连接做到体验最好、 效率最高， 新房交易的技术驱动是一个起点， 他强调: “未来居理新房还会不断加大技术投入， 更好的连接每一个人和他理想的居住场景， 我们所追求的， 就是‘ 居理’ 的品牌含义： 满足人们对居住的一切理想。”',

            '目前， 居理新房已经进入北京、 上海、 天津、 广州、 苏州、 杭州、 成都、 重庆、 深圳、 佛山、 嘉兴11个城市， 月均UV约700万， 累计GMV达300亿。',
        ]
    },
    {
        name: 'Arigin',
        title: '｢昕健医疗｣完成获数千万元A轮融资，致远资本担任独家财务顾问',
        desc: '上海昕健医疗技术有限公司于 2018 年初完成 A 轮融资，致远资本担任本轮投融资的独家财务顾问。',
        date: '2018.04.16',
        hasSub: true,
        content: [
            '近日， 上海昕健医疗技术有限公司宣布已于 2018 年初完成 A 轮融资， 本轮融资由在医疗行业有着丰富风险投资经验的比邻星创投领投， 鼎心资本、 达泰资本跟投， 致远资本担任本轮投融资的独家财务顾问。',

            '昕健医疗技术有限公司成立于 2012 年， 是一家提供医疗 3 D 打印整体解决方案的高科技企业， 致力于为用户提供基于 3 D 打印技术的个性化、 精准化的整体解决方案。 公司创始团队拥有近 20 年的医学 3 D 打印、 人工植入物研发及管理经验， 在美国曾领导和参与包括捷迈 - 邦美、 美敦力公司在内的多家美国跨国医疗器械公司的新型人工植入物的研发及术后评价， 曾获得国际人工关节技术协会 25 周年年度奖“ Hap Paul Award”。',

            '昕健医疗创始人在接受采访时表示， 医学 3 D 打印技术将为目前高达 400 亿美元的国际骨科植入物市场带来一场革命性的变革。 基于患者医学影像的三维模型和其临床数据， 过去几乎完全依靠医生经验的手术， 将由精准的三维术前规划、 个性化的手术导板和个性化植入物所取代， 从而实现骨科手术的个性化和精准化， 使目前不可控的手术结果变成标准化可预测的手术， 大大减小手术难度并提高手术精度， 达到最优化手术的临床结果。',

            '比邻星合伙人孙晓路先生非常看好医学 3 D 打印技术的市场前景。 他表示， 昕健医疗是医学 3 D 打印行业的领航者， 具备专业的创新产品和强大的研发团队， 能够帮助每一位病人定制个性化医疗整体解决方案。 通过 3 D 技术精准高效地打印术前模型、 手术导板、 个性化支具等， 能够在术前对手术进行精准模拟， 并显著提升诊疗效率和术后效果， 具有巨大临床诊疗意义和社会价值。',

            '目前， 昕健医疗拥有独立研发的具有国际领先水平的医疗 3 D 打印软件系统、 医疗 3 D 打印机以及基于 3 D 打印的个性化医疗服务， 已完成包括中国和美国发明专利申请在内的 90 余项知识产权布局， 并成为中国最主要的医学 3 D 打印整体解决方案提供商之一， 合作客户包括国家增材制造创新中心有限公司、 震旦集团 3 D 打印事业部及一批中国一流的三甲医院。',

            '据悉， 昕健医疗在创立之初就已获得达泰资本数千万元人民币的天使轮融资， 此次达泰资本继续追加投资， 表达了对昕健医疗前期工作的认可和后续市场发展前景的信心。 未来， 昕健医疗将利用此次融资， 重点推进医学 3 D 打印技术的临床应用和智能化水平， 完善个性化植入物的设计和生产， 加速三类植入物的临床认证工作。',
        ]
    },
    {
        name: '智齿科技',
        title: '智齿科技完成1.5亿元B+轮投资，致远资本担任财务顾问',
        desc: '智齿科技完成1.5亿元B+轮投资，致远资本担任财务顾问。',
        date: '2018.04.10',
        hasSub: true,
        content: [
            '智能云客服平台提供商“ 智齿科技” 宣布完成 1.5 亿人民币 B + 轮融资。 本轮融资由云启资本领投， 耀途资本、 上海原龙投资、 博雅盛景、 林洪瑞丰投资跟投， B 轮投资方界石投资继续跟投， 致远资本担任财务顾问。',

            '此前， 智齿科技曾获得过真格基金、 华创、 IDG、 拓尔思等风险投资和产业资本青睐， 已完成天使轮、 A 轮、 B 轮、 B + 轮四次融资， 累计融资金额近 2.4 亿人民币。',

            '智齿客服成立于 2014 年 5 月， 专注于提供新一代的全场景智能全客服解决方案， 通过基于云端的通用客服平台帮助企业服务于来自网站、 微信、 微博、 App、 电话、 邮件等多渠道的客户， 致力于通过人工智能技术解放客服生产力， 帮助企业客服提升服务体验和销售， 降低成本。 截止至 2018 年 3 月， 已经服务了包括滴滴出行、 乐视、 宜信、 翼龙贷、 阳光保险、 新东方、 学而思、 蓝港互动、 EMS、 纷享销客等 26 个细分行业的 80000 + 家企业客户。',

            '智齿科技 CEO 徐懿透露： 在客户拓展层面， 截至 2018 年 3 月， 智齿客服注册企业用户超 80000 家， 其中近 2500 家付费客户， 覆盖 26 个细分行业的标杆客户； 业务数据层面， 智齿客服平台每周业务交互量为 15 亿次， 积累了大量细分行业用户对话数据， 智能对话能力的训练和表现出众； 销售数据连续 3 年保持 3 - 5 倍增长， 中大型客户客单价从 2 万提升至 5 万， 并持续增长， 客户留存率为 80 % ，金额复购率达 120 % -150 % 。',

            '徐懿在接受采访时表示， 本轮融资之后， 智齿将把更多的资源和精力投入于内功修炼。 具体强化以下三个方面： 一是加大研发的资源投入， 主要在 AI、 BI 技术研发引进及场景适用性的结合； 二是加大人员优化和能力提升， 进一步优化和提升人员的专业能力和素质； 三是运营及服务体系的优化完善， 智齿将全面提升运营和服务水平， 进一步理顺服务机制并强化客户成功部门的服务能力， 为客户带来更多增值服务。',

            '云启资本董事总经理陈昱表示， 智齿客服运用自然语言处理技术， 构建了以机器人客服和云呼叫中心为代表的客服产品矩阵， 提升了客户体验， 同时降低了企业客服用工成本， 是云启在企业服务领域一直寻找的用技术提高商业效率的投资机会， 云启看好智齿团队的产品和技术商业化能力。',

            '耀途资本创始合伙人杨光也表示， 智能客服可以大幅提升国内客服行业的效率， 同时也是语义识别和智能对话等人工智能技术最好的落地应用场景， 市场发展前景巨大。 智齿科技作为智能客服行业的领军企业， 拥有出色的智能客服产品和技术， 清晰的商业化战略， 以及年轻且充满活力、 综合实力均衡的创业团队， 很有幸能参与本轮投资。',

            '未来， 智齿客服将通过渠道融合、 功能融合、 数据融合， 打造全流程闭环的智能全客服体系； 通过对海量录音和文本类客服数据的挖掘， 提高对客户需求和行为的预测性； 运用图像识别、 声纹识别、 指纹识别、 人脸识别等生物识别技术以及智能化知识库将帮助企业扩张其服务内容范围， 为客户提供更个性化的服务和营销方案。',
        ]
    },
    {
        name: '酷家乐',
        title: '酷家乐完成1亿美元D轮融资，致远资本担任独家财务顾问',
        desc: '酷家乐完成1亿美元D轮融资，致远资本担任独家财务顾问。',
        date: '2018.03.09',
        hasSub: true,
        content: [
            '3 月9日， 家居云设计平台酷家乐宣布完成1亿美元D轮融资， 该轮融资由顺为资本领投、 淡马锡旗下Pavilion Capital跟投， 老股东IDG资本、 GGV纪源资本、 云启资本、 赫斯特资本、 线性资本继续跟投， 致远资本担任独家财务顾问。 本轮融资完成后， 酷家乐估值将达到6亿美金。',

            '顺为资本合伙人程天表示：「 我们非常看好全球家居行业的巨大市场和酷家乐在国内家居设计行业的领先地位。 酷家乐的智能设计工具拥有图像渲染速度快、 操作便捷、 学习门槛低等独特优势， 能显著提高行业效率、 获客能力和盈利能力， 改善业主的装修设计体验。 顺为资本很高兴能与酷家乐团队携手合作， 共同打造一站式家居服务生态链， 推动家居行业的信息化和互联网化。」',

            '酷家乐于2013年11月正式上线。 作为全球最具创新体验的VR智能室内设计平台， 酷家乐致力于云渲染、 云设计、 BIM、 VR、 AR、 AI等技术的研发， 实现「 所见即所得」 的全景VR设计装修新模式， 5 分钟生成装修方案， 10 秒生成效果图， 一键生成VR方案， 彻底提升室内设计行业效率。',

            '2017 年度， 酷家乐全年营收突破3亿元大关。 在规模上， 酷家乐已经成为全球最大的家居云设计平台， 它拥有覆盖全国90 % 的户型库， 吸引了超过300万室内设计师（ 覆盖全国40 % 的室内设计师） 和超1000万业主用户， 形成垄断式的用户壁垒。 平台日均生成100万张渲染效果图， 已累计生产2亿张效果图； 每日产生20万套装修设计方案， 已累计产生3000万套方案。 至今， 酷家乐已服务于小米、 美克美家、 林氏木业、 顾家家居、 博洛尼等12000家品牌企业， 市场覆盖率超过70 % 。',

            '本轮融资完成后， 酷家乐将围绕两大战略重点进行布局： 构建行业生态和国际化战略。',

            '第一， 把控「 设计入口」， 构建行业生态。 酷家乐通过智能云设计工具， 将上万家居品牌和300万设计师聚合在平台上， 牢牢把控了家居行业「 设计入口」。 在此基础上， 酷家乐持续进行生态开放， 全面对接设计、 生产、 营销、 施工、 落地等家居产业链的各个环节， 打造酷家乐智能云设计平台矩阵， 成为整个家居行业的操作系统。',

            '第二， 国际化布局， 将「 所见即所得」 的体验模式普及到全球市场。「 国际化」 在酷家乐的战略版图中占据至关重要的位置， 一方面， 从市场层面考量， 全球的家居市场规模是2万亿美元， 布局全球市场可以扩大酷家乐的业务版图； 另一方面， 通过酷家乐这个设计入口， 将中国家居品牌直接对接到全世界终端消费者手中。 接下来， 酷家乐将率先进入美国、 东南亚和印度市场， 并针对海外市场推出行业领先的国际化产品。',

            '致远资本非常高兴能够参与本轮融资， 相信酷家乐未来将全方位地渗透上下游产业链， 实现「 所见即所得」， 生态驱动全球2万亿美元家居市场。',
        ]
    },
    {
        name: '成长保',
        title: '成长保完成B轮1.5亿人民币融资，致远资本担任独家财务顾问',
        desc: '成长保完成B轮1.5亿人民币融资，致远资本担任独家财务顾问。',
        date: '2018.03.06',
        hasSub: true,
        content: [
            '新年伊始， 在线素质教育「 赛道」 爆出重磅消息—— 3 月6日举行的主题为「 教育新定义， 赋能新未来」 的2018战略发布会上， 成长保宣布获得已经完成B轮融资， 由真格教育基金领投， 歌斐资产、 基因资本、 昆仲资本和达晨创投跟投。 此次融资额为1 .5 亿人民币。 致远资本担任本轮独家财务顾问。 新一轮融资将助成长保开启全新战略计划。',

            '成长保从2015年成立之初， 就专注于4 - 12 岁儿童的在线素质教育， 通过有趣的在线互动直播课堂提供互动学习体验， 训练孩子的语言逻辑、 数理逻辑、 记忆力、 专注力等能力， 据刘阳透露， 截至目前， 成长保已拥有近万名用户， 分布在200多个城市。 2017 年月收入增长率为36 % ，续费率达到83 % 。在快速发展的过程中， CEO刘阳依然保持着创业初衷：「 我们的目标是让孩子成为独立思考的人， 永远保持好奇心， 永远有探索欲， 永远能迎接挑战、 拥抱变化， 我们愿作孩子脚前的路， 路上的光。」',

            '成长保联合创始人榕榕和CTO李波在现场介绍了成长保在品牌和产品战略。 据榕榕介绍， 2018 年， 成长保将携手SMG， 推出多维度的体验产品， 包括游戏化专业思维力训练软件思维喵、 全国首档儿童思维力全媒体互动节目《 思维妙妙妙》 以及「 妙妙杯」 全国少儿思维大赛。 其中，《 思维妙妙妙》 由成长保携手炫动卡通卫视《 欢乐蹦蹦跳》 共同举办， 以先进的互联网 + 电视为载体， 将思维训练、 注意力评估与竞技互动相结合， 有效激发孩子的学习兴趣， 提升儿童的脑力发展；「 妙妙杯」 全国少儿思维大赛由 SMG 旗下炫动卡通卫视携手成长保、「 思维喵」 共同举办， 将以先进的互联网 + 技术为载体， 将互动游戏与思维竞技相结合， 网台互动， 实现互联网教育与融媒体的新型对接。',

            '「 2018 年， 我想我们会不停止的去谈这两个字—— 赋能」， 成长保创始人兼CEO刘阳在谈及2018年战略规划时表示， 成长保的核心战略就是给孩子赋能—— 提升孩子的自主学习能力、 思维能力。 成长保将从几个方面做到赋能， 首先是场景的赋能—— 在学习场景中， 成长保强调个性化学习的解决方案，「 一对一的教学， 其实就是个性化的最小单元。 除了一对一， 成长保还打造了不同班组的小班课， 4 人和6人的， 未来还在考虑有更丰富的组别， 满足不同层级用户的学习需求。 另一个场景是学习内容上实现多元化， 包括内容的多元化、 品类的多元化和技术的多元化。」',

            '其次是体验的赋能， 成长保将重点实现多平台同步的系统升级， 以智能化的数据记录每一个孩子上课的体验。 运用AI和体感交互教学， 实现全自动的排课系统， 做到每一个孩子个性化的课程表， 完全由智能系统来分布课程表。 除了小学员， 讲师也将在体验上有全新的升级。',

            '「 最终， 成长保将通过品牌为用户赋能， 从线上到线下， 从媒体到益智题库， 打造一个穿透力强而又有温度的品牌」， 刘阳表示：「 2018 年， 把课讲好只是我们最基本的目标， 真正要做的， 是给我们的用户创造价值， 让用户的需求推动成长保乘风破浪， 一路领航。」'
        ]
    },
    {
        name: 'AKOKO',
        title: '曲奇品牌AKOKO获5000万A轮融资，致远资本担任独家财务顾问',
        desc: '曲奇品牌AKOKO获5000万A轮融资，致远资本担任独家财务顾问。',
        date: '2018.01.23',
        hasSub: true,
        content: [
            '今日，曲奇品牌AKOKO，宣布获得了由愉悦资本领投、高榕资本跟投的5000万人民币A轮融资，致远资本担任独家财务顾问。本轮融资将用于供应链升级、品牌推广、品类研发与团队扩充等方面。',
            'AKOKO是创始人阿柯于2016年创立的精致曲奇品牌，用一款单品创造了极具差异化的产品体验，入口即化、安全无添加。同时，面对互联网市场及消费者群体的变化，AKOKO建立了独特的打法与渠道布局，实现了高效传播与销售，用单品引爆饼干一个类目，积累起大批忠实且高质的粉丝。',
            '数据显示，AKOKO曲奇三个月内复购率达到60%，充分证明了产品力以及用户对产品的认可度。AKOKO曲奇之所以被称为「国民曲奇品牌」，除了口感极佳外，不加防腐剂、赏味期短、绝不添加和使用泡打粉、入口即化，都是其显著的产品标签。消费升级的大趋势下，消费者对健康提出了更高的需求。AKOKO用产品品质和品牌形象在年轻人的心目中建立了标杆。在天猫上线仅仅两个月，就在天猫做到了曲奇饼干类目第一。',
            '创始人阿柯表示，「在未来的1-2年内，AKOKO会聚焦曲奇饼干类目，垂直深挖、更新迭代，开发酥曲奇、硬曲奇、软曲奇，建立全品类矩阵，不断输出‘让用户尖叫’、更加安全健康的曲奇产品。在品类渗透与人群覆盖足够理想时，适时横向延展品类，开发其他烘焙产品。诸如曲奇巧克力、曲奇月饼、喜饼、慕斯、千层等。在产品序列层面，AKOKO还会研发代早餐系列、下午茶系列、伴手礼系列，为消费者提供更多选择。」',
            '愉悦资本创始及执行合伙人刘二海表示，「创新消费是愉悦资本长期看好、深度耕耘的投资根据地，尤其是互联网技术不仅大大提升了效率，更助推产生了一系列创新的产品和服务。AKOKO团队在短短一年多时间，从无到有做出了一个广受欢迎的品牌，在产品口碑、品牌心智等各个方面都表现出色。我们对团队和产品都十分看好，期待他们开发出更多高品质的烘焙产品。愉悦资本将不断支持AKOKO实现自己的梦想。」',
            '高榕资本副总裁韩锐表示：「AKOKO的产品力有目共睹，团队对产品的用心程度非常打动我们。同时，团队对于核心品牌能力的构建与我们对品牌创业公司的理解不谋而合。我们相信，Akoko在不断突破市场边界的同时，也将为消费者持续带来有惊喜的产品。」',
            '在AKOKO，西式烘焙文化和中式传统糕点不但可以继承，更可以创新和融合。技术以及配方会重新定位中国人对烘焙的味蕾，就像曲奇一样，大多数消费者在吃AKOKO曲奇的第一口，就会颠覆自己对曲奇的以往味觉记忆。AKOKO将以优秀的品质、独特的品牌形象给大众原有的印象重新赋予定义。',
        ]
    },
    {
        name: '心上',
        title: '心上获C轮5000万美元融资，致远资本担任独家财务顾问',
        desc: '心上获C轮5000万美元融资，致远资本担任独家财务顾问。',
        date: '2018.01.08',
        hasSub: true,
        content: [
            '今日，中高端闲置品共享平台「心上」宣布获得了由GGV纪源资本和愉悦资本共同领投、北极光创投跟投的5000万美元C轮融资，创下中高端闲置品交易平台行业融资纪录，致远资本担任本轮独家财务顾问。本轮融资将用于平台新业务线拓展、品类扩充、公司人才计划、品牌建设和后台升级等。',
            '「心上」创办于2015年，是一家围绕中高端闲置正品打造的集「交易、鉴定、回收、养护为一体」的一站式服务平台，目前平台上主要有包袋、腕表、首饰和服装四大品类。「心上」聚焦于中高端商品，保证品质是「心上」的核心竞争力——保证品质主要体现在对商品质量的把控，对用户体验的重视。',
            '数据显示，「心上」 目前每个月保持30%以上的交易额增长。尽管客单价高达3000元左右，也惊人地实现了新客户单月复购率超过30%的好成绩。「心上」目前占据了国内线上中高端闲置品交易市场50%以上的份额，整体看来，「心上」所有数据均优于竞争对手，且重要数据指标甚至是竞争对手的10倍以上量级，就国内市场而言，目前「心上」可谓「执牛耳者」。创始人董博文表示对明年的增长非常有信心，「‘心上’不仅要做开荒者，还要做行业的领路人。」',
            '对于本轮融资，「心上」创始人董博文表示，「资本对‘心上’一直保持高度信任，此次融资对‘心上’而言具有里程碑意义。国内的二手房市和车市近些年蓬勃发展，下一个千亿级甚至万亿级的二手交易市场或许就是中高端闲置品市场。」创始人表示，后续「心上」将在投资人的助力下加速领跑这个万亿级的市场。',
            '麦肯锡在今年发布的《2017中国奢侈品报告》（以下简称「报告」）中提到，仅在2016年就有760万户中国家庭购买了奢侈品，家庭年均奢侈品消费达7.1万元人民币，是法国或意大利家庭的两倍。报告预计，至2025年，全球奢侈品市值将达到2.7万亿元人民币，中国消费者将继续担当主力军，将「买下」44%的全球市场。',
            '创始人董博文表示，今后「心上」会继续在中高端闲置品共享领域尝试更多的商业模式，例如利用租赁模式降低消费者使用中高端商品的门槛。同时，「心上」也会在金融服务上进行创新尝试，寻求机会撬动双边交易平台的爆发式增长。在创始团队不断做出的创新性尝试下，相信今后「心上」的泛共享模式会将使更多的消费者受益，更多追求品质生活的人能在「心上」找到自己的「心中所爱」。',
        ]
    },
    {
        name: '1M',
        title: '医鸣数据完成近亿元人民币B轮投资，由国中创投独家投资，致远资本担任独家财务顾问',
        desc: '医鸣数据完成近亿元人民币B轮投资，致远资本担任独家财务顾问。',
        date: '2017.12.26',
        content: [
            '近日， 中国领先的医疗大数据平台医鸣数据完成由国中创投投资的近亿元人民币B轮融资， 致远资本担任本轮融资独家财务顾问。',

            '医鸣数据是一家专注于医疗大数据领域的高科技企业， 致力于医疗大数据领域的技术与服务， 深耕心脑血管呼吸等慢病领域， 医鸣的无人工录入的数据采集技术及数据复杂计算处理技术大大提高了医院及医生的工作效率， 挖掘了巨大的科研及学术价值。',

            '目前， 医鸣的合作伙伴包括国家卫生和计划生育委员会、 国家呼吸系统疾病临床医学研究中心、 中国心血管健康联盟等学术至高点单位。 医鸣同时也是HL7中国专业委员会成员。 医鸣已经为全国200余家三甲医院、 300 多个科室提供过专业医疗数据服务。',

            '目前， 医鸣已经拥有三项发明专利和若干软件著作权， 先进的技术保障了数据的完整性、 准确性、 及时性与连续性。「 医鸣数据采集系统」 替代传统的人工录入， 可无缝连接院内全系统， 采集患者院内完整信息， 采集一份病历的速度达到秒级别， 完全实现了无人工录入；「 医鸣数据智能处理系统」 实现数据结构化及复杂计算， 在原始数据转化为二次数据过程中应用数据管道， 规则引擎， 自然语义识别技术， 避免不同人脑录入产生的系统误差， 准确性达业界最高水平。「 医鸣数据智能处理系统」 的自然语义处理速度可达3万份 / 秒、 利用神经网络技术清洗数据的速度达10万份 / 秒， 准确率达97 % 。',

            '尤值一提的是， 医鸣近期入驻了微软亚太研究院， 以0 .2 % 的录取率成为微软携手的唯一一家中国医疗大数据公司。 这次合作， 无疑可以为客户在辅助决策系统和人工智能的解决上提供更有价值的服务， 在数据挖掘上更加精准。',

            '据悉， 本轮融资后， 医鸣将覆盖更广范围内的医院和科室， 为更多纵深的专科提供服务。',

            {
                type: 'c-bold-mb0',
                text: '医鸣团队'
            },

            '医鸣创始人、CEO马敏曾服务于辉瑞、诺华等大型外企，合伙人均来自谷歌，阿里等互联网公司，在医疗行业经验与资源，数据处理，市场拓展，运营方面拥有丰富的经验，「跨界」人才保证了团队搭配的合理性。',

            {
                type: 'c-bold-mb0',
                text: '深圳国中创业投资管理有限公司'
            },

            '专业从事创业投资，受托管理国家中小企业发展基金首支实体基金 —— 中小企业发展基金（深圳有限合伙），秉承「诚笃、创新、简单、高效」的价值观，立志成为值得信赖、受人尊重的卓越创投机构。主要投资方向包括信息技术、互联网/新媒体、生物医药/现代农业、新能源/节能环保、化工/新材料、高端装备制造、消费品/现代服务等战略新兴产业。',
        ]
    },
    {
        name: '智课',
        title: '智课教育完成2亿B+轮融资，致远资本担任独家财务顾问',
        desc: '智课教育完成2亿B+轮融资，致远资本担任独家财务顾问。',
        date: '2017.12.25',
        content: [
            '2017 年11月1日， 一站式国际教育服务机构「 智课教育」 宣布获得2亿元B + 轮融资， 投资方为国科嘉和基金， 致远资本担任本轮独家财务顾问。 至此， 连同B轮的2亿元融资， 智课教育整个B轮获得融资总计4亿元。 智课教育董事长兼CEO韦晓亮表示， 在高达64 % 的老客户推荐率和95 % 的学习目标达成率基础上， 将继续坚持智课教育方法论和留学服务方法论， 以学习效果和成长规划为服务核心， 全面升级出国留学服务行业， 打造更强更大的一站式国际教育服务平台。',

            {
                type: 'c-bold-mb0',
                text: '两大方法论获资本肯定'
            },
            {
                type: 'c-bold-mb0',
                text: '智课落地SMART智能教学系统见实效'
            },

            '作为B + 轮的投资方， 国科嘉和基金认为教育行业不能一蹴而就， 技术起到辅助作用， 核心还是在教学内容研发及流程设计、 持续吸纳和培养优秀老师、 精细化的服务体系、 品牌的养护以及专业的企业运营管理。 智课对教育的本质理解比较深入， 人员配置， 产品设计及管理上非常综合， 勇于深耕细作， 有利于长期发展。 从2013年起， 5 年来智课一直在学习方法、 学习习惯和学习动力上不断实践， 探索分数、 能力与实践的变量关系， 以及最终呈现的学习效果， 从而形成了独创的智课教育方法论。 通过「 学、 练、 改、 测、 评」 + 「管」 6 个教学环节， 将师资内容与人工智能技术有机匹配， 让智课的价值渗透在每个教学环节。 在行业平均完成率是4 % 的情况下， 智课的线下翻转课堂课程完成率达97 % ，线上带人工辅导课程约80 % 。',

            '同时， 团队用独有的留学服务方法论及海外智课布局， 把智课打造成了真正的一站式留学服务专家。 韦晓亮认为， 留学服务绝不是过去中介的一锤子买卖， 关系到学生及其家庭一辈子， 在升学服务上， 必须深入到专业、 职业的人生规划服务， 坚持精细化、 个性化和可视化。 智课拥有160位出国考试教学专家， 592 位全职批改外教， 与155位全职教学督导密切配合， 通过智课SMART人工智能教学系统， 融合线上线下多场景， 每天产生150万次教师与学员行为， 实现学生个性化学习路径规划。 智课每个学员都有专属的微信服务群， 群里有老师、 学员、 家长和服务PT， 智课教育服务的亲密度极高。 实践证明， 智课提分效果明显， 平均三个月学习后学生在托福提高21分、 雅思提高1 .5 分、 SAT提高289分。',

            {
                type: 'c-bold-mb0',
                text: '全面升级出国留学服务行业',
            },
            {
                type: 'c-bold-mb0',
                text: '智课力推个性化长线的留学咨询指导和留学后服务',
            },

            '智课B + 轮的财务顾问致远资本创始人李悦表示， 智课精心打磨设计的课程服务体系， 强调教学效果的同时， 还致力于提高学员的学习效率， 而且覆盖全部出国考试科目， 非常难得。 在人工智能和教育的结合方面， 智课在业内率先推出了英语学习的智能适应产品， 真正做到因材施教。 韦晓亮也认为， 目前业内做营销的公司太多， 做内容、 做服务、 做技术应用的深度业务企业太少。 他透露， 智课一开始就选择了与众不同的业务路径， 先潜心打磨产品再做好业务， 将天使轮获得的95 % 融资资金投入到产品研发与内容建设上， 用一种耐得住寂寞的心态生长出差异化竞争能力。 2017 年拿到海外名校offer的同学， 康奈尔大学4位， 哈佛大学、 普林斯顿大学、 斯坦福大学各1位。 智课留学服务累计帮助300多位学员拿到海外名校offer， 其中TOP30学校高达65 % 。',

            '近年来， 智课形成真正以个人需求、 个人问题解决为导向的个性化定制式的教学模式。 该模式能做到个性化课程规划、 全面收集学习行为大数据并实时评价学生能力， 涵盖八大出国考试（ 托福、 雅思、 新SAT、 GRE、 GMAT、 ACT、 AP、 A - Level）， 通过大数据联通线上线下教学， 高效纠错、 实时反馈， 并加入AI引擎， 运用算法和知识图谱， 构建教学者和学习者模型。 而留学后服务是更长线和高品质的业务， 智课着力整合国内与海外资源， 满足中国留学生及其家长的需求， 具体服务涵盖了留学生学习和生活的方方面面， 包括有学习管理、 法律咨询、 金融、 生活、 职业规划、 社交、 医疗、 出入境、 旅行…… 进军美国留学后服务的一年时间里， 智课成果显著： 一方面与77所美国高中、 44 所美国大学以及6所美国小学达成合作关系， 另一方面成为包括哈佛大学、 布兰迪斯大学和波士顿学院等顶尖高校教育系毕业生的指定实习基地。',

            '此次融资发布会上， 作为智课教育产品的总设计师， 韦晓亮还认为， 真正高质量的长线留学服务， 是以学习提升、 人格及能力塑造为核心的高端留学咨询业务。 消费升级后的当下中国， 人们要的是教育资源的个性化和品质化， 智课经过4 - 5 的努力， 云集了一批韦晓亮领衔的国际教育权威专家， 以设计符合时代的产品为导向， 将引领留学服务行业的全面升级。',

            {
                type: 'c-bold-mb0',
                text: '智课推出海啸计划：',
            }, {
                type: 'c-bold-mb0',
                text: '掀起行业内容洗牌风暴',
            }, {
                type: 'c-bold-mb0',
                text: '构建知识共享平台',
            }, {
                type: 'c-bold-mb0',
                text: '多年坚持教育公益',
            },

            '经过5年努力， 智课已成为行业汇聚优质课程和名师资源的标杆平台， 坚持用感性的良心， 理性的科学做教育。 很多同行企业和机构， 都派人购买智课各种课程， 进行学习、 仿制。 2017 年下半年， 智课推出了海啸计划， 160 位顶尖名师、 7200 小时大片级课程、 12800 个知识点， 登陆智课名师课APP上分享， 就能免费学习这些价值1亿的名师专家课程。 这是业内首次全面开放名师课程资源， 不仅造福了广大出国留学的家庭和学生， 智课还从教学内容方面前所未有地拉升了全行业水平， 掀起了国际教育服务行业的内容升级风暴， 构建知识共享平台。',

            '韦晓亮提出的「 教育是个良心活」， 智课团队一直努力践行。 内容建设以及翻转课堂的模式是智课创业布局的第一件事， 也是最重要的事。 在过去四年， 智课一直坚持科学的模式， 高质量的教学研发， 高亲密度的教学服务。 最终导致的结果是， 教学效率更高， 教学效果更好， 用户亲密度更强。',

            '为了促进优质内容共享， 智课多年坚持参与公益， 积极推进「 智课教育公益计划」。 2014 到2016年， 智课捐赠价值5500万元的课程与服务， 受益学子近40万。 2017 到2020年， 智课计划再捐赠价值1亿元高品质课程与教学服务， 覆盖高校和中学分别扩大到800、 300 所， 受益学子增加到200万。',

            {
                type: 'c-bold-mb0',
                text: '口碑效应持续彰显',
            }, {
                type: 'c-bold-mb0',
                text: '用户黏性持续增强',
            }, {
                type: 'c-bold-mb0',
                text: '2017 年智课收入预计突破2亿元',
            },

            '截至2017年10月， 智课网已有近600万注册学员， 线上注册学员近600万， 其中付费学员15万， 线下学习中心2017年付费学员超过3000人。 老客户推荐率高达64 % ，印证了智课持续强劲的口碑效应。 韦晓亮透露， 智课海外校友录和国内智课留学俱乐部这两大精准社群正在建设， 无疑将会进一步加强智课的用户黏性。',

            '目前， 智课出国精英学习中心已经遍布28大城市， 并且在美国波士顿（ 2016 年11月）、 纽约（ 2017 年9月） 先后开设海外学习服务中心。 同时， 智课教育云也进入中国1200多所高校、 300 多所中学。 而从2016年起，「 智课翻转课堂合伙人」 计划开始实施， 投入5000万元成立智课翻转课堂合伙人互助基金， 寻找50名合伙人覆盖全国29个省， 共建「 互联网 + 」教育生态， 已有的智课翻转课堂合伙人共建学习中心覆盖了全国12个省， 未来将进一步开放产品体系、 内容体系和服务体系， 将智课优质资源输送给大量的老师及其学校与机构， 进一步形成智课独有的知识共享平台。 线上线下全面融合推进的智课， 预计2017年收入将突破2亿元。',

            '近10年来， 我国国内城市家庭收入持续提高， 中高收入家庭已不满足于原有的低效粗放的教学方式， 教育需求呈现出信息化、 个性化和国际化三个主要趋势。 到2020年， 国内语言教育相关的教育市场规模将超过6000亿元， 留学市场将达到560亿元。 传统教育机构模式落后低效、 教育消费升级背景下， 市场存在巨大的变革机会。 智课教育董事长兼CEO韦晓亮表示， 在获得资本进一步认可和自身业务继续夯实的前提下， 将在自适应学习系统的研发、 品牌知名度的扩大和人才引进培养方面着重发力。 本轮投资方国科嘉和基金也看好来自多家一线机构， 行业沉淀深厚智课管理团队， 认为智课在内容、 教学、 运营方面经验丰富， 对单纯的在线教育及传统线下教育的痛点有深刻认知， 愿意拥抱先进的技术、 教学理念进行变革。 业内人士也认为， 2018 年将是智课教育的业务爆发期， 或将引领国际教育服务行业的创新与权威变革。',
        ]
    },
    {
        name: '花卷',
        title: '短视频电商「花卷」获四千万美元B轮融资，致远资本担任独家财务顾问',
        desc: '短视频电商「花卷」获四千万美元B轮融资，致远资本担任独家财务顾问。',
        date: '2017.12.24',
        content: [
            '网红短视频电商平台花卷商城近期获得了四千万美金B轮融资，创新工场领投，跟投方包括原有股东 EMC、银泰集团和祥峰投资，还有新跟投方治平资本。致远资本担任独家财务顾问。去年，花卷获得来自滴滴打车天使投资人王刚、主持人李静、法国银泰集团Ventech China的天使轮融资，祥峰投资、EMC、清晗基金投出的A轮融资，以及EMC领投的A+融资。 ',
            '公司创始人兼CEO韩卉表示，这笔资金将主要用于三部分。第一是品类扩展，平台目前品类有约八成集中在美妆，其他则是进口酒类和保健品，公司希望扩大SKU。第二是网红的培育和内容导向的工作方面，这有助于增加花卷作为一家短视频电商平台对资源的把控力。第三部分则是扩展用户和视频分发渠道上。',
            '据花卷方面介绍，平台在去年6月上线，定位是「基于视频的新颖优质商品推广平台」，前端通过KOL在各大社交媒体上分发直播、短视频内容来获客，引导用户进入花卷APP，后端自建非标商品供应链。据韩卉透露，平台目前主要的盈利方式就是售卖自营商品，没有靠广告。',
            '据韩卉介绍，成立了一年多的花卷，目前注册用户量超过百万，DAU（日活用户量）在15万左右，平台最亮眼的数据是从注册到购买的转化率达到70%，全站（六个月之内的）复购率65%，美妆品类复购率35-38%。平台的复购和留存的数据也不错，据韩卉的介绍，花卷没有做大规模的品牌传播，用户第一次接触花卷往往是通过直播和短视频平台等站外平台。进了花卷平台之后就开始了第二层的交叉传播，用户会通过观看商品短视频围观更多的网红，根据平台统计平均每个用户进来之后至少会围观3-5个网红，之后会收到相关的新商品上架和内容更新推送。第三层，对于核心网红的粉丝用户群，都有官方的群运营为用户提供答疑和解难。',
            '花卷的团队目前约有70人，创始人韩卉曾任高端男装公司玛萨玛索和汽车之家市场总监、乐蜂网推广平台总经理，2012年与丈夫韩擘联合创立职场社区网站卧龙阁。',
        ]
    },
    {
        name: '高思教育',
        title: '高思教育宣布完成5.5亿融资，致远资本担任本轮融资财务顾问',
        desc: '高思教育宣布完成5.5亿融资，致远资本担任本轮融资财务顾问。',
        date: '2017.12.23',
        content: [
            '2017 年9月20日消息， 高思教育（ 股票代码： 870155） 发布董事会公告， 宣布已完成5 .5 亿元人民币融资， 由华人文化产业投资基金和沸点资本领投， 以太创服等参与跟投。 致远资本担任本轮融资财务顾问。',

            '高思教育成立于2009年， 于2016年11月30日完成了新三板挂牌， 是一家口碑卓越的专注于中小学阶段的教育培训机构。 在本轮融资前， 高思教育曾获得两轮投资： 2012 年， 高思教育完成A轮投资， 投资方是晨兴资本； 2015 年， 高思教育完成B轮投资， 投资方是九鼎投资。',

            '高思教育创始人兼CEO须佶成表示， 高思教育的战略定位清晰明确， 就是用互联网 + 的方式实施K - 12 培训行业「 CTO」 战略。 C指的是内容（ Content）， T指的是教学（ Teaching）， O指的是开放连接（ Open），「 内容 - 教学 - 开放连接」 就是高思教育未来要长期专注坚持做的事情。',

            '关于高思教育「 CTO」 的战略定位， 须佶成总结了三点：',

            '第一个C（ Content） 指的是优秀的教学内容， 这是高思教育长期积淀所形成的核心能力， 未来我们还将继续精耕细作， 进一步把内容做得更好。 高思教育的内容是一个行业开放平台， 我们愿意以无保留的心态帮助合作方提高教学服务水平， 也会将其他优质内容吸收进入平台以供合作方共同使用， 目前高思教育的爱学习、 爱提分和爱尖子业务正在行业内开展大规模合作。',

            '第二个T（ Teaching） 指的是恰当的教学服务方法， 高思教育不仅仅提供教学内容的工具， 也会协助合作方优化整个教学服务流程， 做到「 内容 + 服务」 的完整业务流程闭环。 通过引导老师采用更好的教学方法， 引导学生培养学习兴趣， 形成良好学习习惯， 最终促进学生产生学习效果。',

            '第三个O（ Open） 指的是高思教育要以开放心态做行业内最好的连接器， 我们希望将高思的资源开放给尽可能多的校长和老师， 通过先为他人创造价值的方式来获得自身价值的提升。 高思教育希望用「 开放连接」 的平台思维做教育行业的底层价值创造者， 用数据化的方式连接更多的家庭、 学生和老师， 让最前沿的教育科技、 教育理念和教育信息更高效地服务于机构、 老师、 学生和家庭。',

            '须佶成认为， 教育行业的变革才刚刚开始， 未来还有很长的路要走。 高思教育定位为以互联网 + 的方式布局K - 12 培训行业「 CTO」 战略， 就是要成为行业的价值创造者， 除内容和教学模式外， 其他领域都交出去与合作方协作， 让合作方自主地成长为独立的公司和平台。「 开放连接， 成人达己」 是高思教育在长期发展过程中形成的价值理念和事业法则， 成为行业的一棵参天大树并不是高思的目标， 成为行业发展肥沃的土壤， 支持千千万万的教育创业者， 为行业的百花齐放做出贡献是高思的理想。',

            '须佶成表示， 自2015年以来， 高思陆续布局了爱学习、 爱提分、 爱尖子等互联网项目， 模式已经初步形成。 至此， 高思已经全面从一家线下的培训机构战略转型为一家互联网教育企业。 今后高思还将持续加大产品和技术的投入， 未来在教育大数据和人工智能方面做更多布局。',

            '作为今年K - 12 行业内最大规模融资之一， 华人文化董事长、 CEO黎瑞刚表示：「 互联网技术在教育领域的应用一直是华人文化关注并看好的方向， 高思教育通过互联网 + 的模式， 结合业内先进的教育理念， 把内容、 技术与教学体系开放给全国各地的K - 12 培训机构， 将为整个行业创造巨大的价值。 华人文化期待未来与公司密切合作， 协助公司更好地推进其战略与业务落地。」',

            '沸点资本管理合伙人于光东表示：「 高思拥有革新传统教育的基础。 首先， 高思作为国内K - 12 领先的教育机构， 在教研、 服务体系方面已经具备成长为大型教育集团的基础， 推出的服务在学生、 家长当中树立了良好的的口碑。',

            '其次， 高思首次将产教研体系产品化地向其他教育机构输出， 首创了教育领域的「 安卓模式」， 让天下没有难做的教育。 我们长期看好高思在产品研发和迭代上巨大的想象空间。 第一， 线上线下的内容生产形成闭环， 产教研实时大数据的利用节约了用户成本也使得课程设计更加智能。 第二， 高思从C端向B断的平台业务拓展， 也是对传统教育体系信息及资源透明化的尝试。 对传统教育领域来说， 解决大数据收集及数据的基础应用已经可以初步实现， 深度挖掘的数据和使用场景会在未来产生更大的价值。」',
        ]
    },
    {
        name: '小麦铺',
        title: '小麦便利店首轮融资1.25亿，致远资本担任独家财务顾问',
        desc: '小麦便利店首轮融资1.25亿，致远资本担任独家财务顾问。',
        date: '2017.12.22',
        content: [
            '近日，小麦公社旗下智能便利店「小麦便利店」获得1.25亿元人民币首轮融资，这也是便利蜂之后，便利店领域最大的一笔融资。该轮融资由洪泰基金、晨山资本联合投资，致远资本担任独家财务顾问。',
            '公司方称，此次融资主要用于供应链建设、产品迭代升级、自有系统研发升级等。小麦公社副总裁全斌表示，目前研发制造还在试测迭代中，但当前版本已经颠覆了传统便利店的成本结构，且通过制造标准化可以快速复制。同时，具备更鲜明的智能硬件属性的新版本店铺将很快投入更广泛的测试中。',
            '据了解，小麦团队自2015年底进入便利店领域，在红杉中国、中通快递、成为资本等老股东的支持下迅速发展。其目前投放的这种约20平方米的便利店，在户外及建筑内均可放置，可移动、可拼装，最快可以实现4小时建店、一日开业；此外，人脸识别、移动支付等技术，可支持其实现「进门刷脸，购物扫码」的无人值守模式，但在现阶段，仍然会有一名工作人员值守，随时帮助消费者解决问题。',
            '海淀区苏州桥店是其在北京投放的第一家店，目前正在测试阶段。对于消费者而言，完整的购物流程只有简单几步：注册会员-人脸识别或扫码进门-微信扫码自购-结算支付离开。',
            '致远资本创始合伙人李悦表示，小麦智能便利店背后的逻辑是新技术的发展带来的便利店行业模式的革新。盒子硬件技术实现了店铺的可拼装可移动，打破了传统便利店铺重资产的限制；人脸识别、移动支付等技术的应用不仅降低了店铺的人力使用成本，同时提升了顾客的消费体验；动态行为数据分析系统反向驱动了店铺的精细化管理，提升整体运营效率。新型智能便利店较传统便利店回收期大幅缩短。',
            '全斌将小麦定义为「智能便利店」而非「无人便利店」，抛除前面的定语，小麦将核心放在了关注便利店本身的运营和客户体验，包括供应链布局、系统优化、运营升级等。举例来说，每个便利店都会依据周边人群的不同提供不同的商品组合，同时依靠店面后期的经营数据，通过大数据等技术手段不断提升客户体验和坪效。',
            '致远资本看好以小麦智能便利店为代表的线下流量新入口。中国互联网在经过十多年的高速发展后，线上流量红利逐步消退，智能化数据化驱动的线下零售新模式不仅将催生出新的消费增长点，同时也将可以精准反哺线上，革命性提升零售行业的运营效率。在这一点上，小麦智能便利店展示了新的美好可能，致远资本对小麦的发展充满期待并将陪伴其一路前行。',
        ]
    },

]

export default list