const configs = {
    bg: require('../../common/imgs/mobile/common_bg.png').default,
    title: require('../../common/imgs/business/title.png').default,
    tabs: [{
            label: '消费'
        },
        {
            label: '高科技&企业服务'
        },
        {
            label: '文娱&教育'
        }
    ],
    /**
     * type:    1-公众号文章，2-网站，3-无操作
     * hasSub:  有下方简介图片
     */
    cases: [
        [{
                name: '美尚股份',
                type: 1,
                hasSub: true,
                title: '「美尚股份」获4亿元B轮融资，致远资本连续担任财务顾问',
                content: [
                    '新锐品牌珂拉琪COLORKEY母公司美尚股份正式宣布完成总额4亿元人民币的最新融资。本次B轮融资由金镒资本领投，高盛、君川等跟投，老股东持续加码。致远资本连续担任财务顾问，这是继2020年4月协助公司顺利完成A轮2亿元融资后，又一次成功合作。',
                    '美尚股份于2018年创立，旗下拥有潮酷彩妆品牌COLORKEY珂拉琪、专业彩妆品牌SUPERFACE秀芭斐、科研护肤品牌LAB101瑞沛。自创立以来，潮酷彩妆品牌COLORKEY珂拉琪致力于引领千禧一代“敢于不同”的当代潮流趋势，主打单品空气唇釉深受市场认可。创始人Cici Lee曾任中外化妆品集团高管职务，拥有20多年丰富的美妆全产业链经验。创立之初，获得红杉资本等近亿元天使轮投资。2020年4月，再获2亿元A轮融资，投资方包括红星美凯龙、创新工场、微光创投和易凯资本。',
                    '美尚股份始终奉行“品牌金字塔矩阵”战略——以差异化定位的多品牌集团模式布局美妆市场，满足中国庞大市场之下不同地域、不同年龄、不同人群的各色需求。2019年初，美尚集团旗下潮酷彩妆品牌COLORKEY珂拉琪正式上线天猫，主打唇釉系列开辟出全新的细分市场，上线3月占领类目第一。随后销售一路高歌猛进，双11渠道销售额突破1.6亿元，成为入驻天猫不到三年双11破亿仅有的三家彩妆品牌公司之一。同步开启全渠道策略，线下新零售渠道迅速覆盖超3000家，始终稳占“唇釉第一”品牌，夯实了金三角底部。',
                    '花开两枝，早在2018年美尚股份便已积极布局“品牌金字塔”腰部。引入韩国及全球市场知名的专业彩妆品牌SUPERFACE秀芭斐，以及重金投入科研护肤品牌LAB101瑞沛。前者将进一步满足于摆脱大众美妆进入追求品质的中端彩妆用户产品升级需求；后者抓住细分护肤领域，更是“疫情”后中国女性转而寻求安全、高效的重要趋势，2021年LAB101瑞沛将打造以研发、生产、推广三大核心的新护城河，并将升级品牌主张，为中、高端消费者提供更科学有效的深度护肤解决方案。',
                    '本次，B轮融资领投方金镒资本是以专业、稳健著称的市场化PE机构，过去15年里专注于投资新零售、消费升级，以及产业升级领域之中最具潜力的龙头企业。因共同看好中国市场的长期发展，尤其在当下，恰逢下沉市场消费升级带来的庞大增长，数字化经济的转型红利，并有着中国供应链产业集群的强大支撑，这正是美妆品牌难得一见的发展机遇。',
                    '“如果说彩妆是短跑，那护肤赛道是马拉松长跑。过去2年我们已经获得短跑的阶段性胜利，接下来我们将重点投入到专业护肤市场的发展。”美尚股份创始人Cici Lee表示：“中国是全球最大市场，但中国化妆品产业链还未走向成熟期，关键在于没出现高端品牌公司。美尚股份整个团队具备十五年以上的行业资深经验，熟悉国际高端市场，又有打造单赛道销冠品牌的成功案例，同时也有着全球最顶尖的供应商加持。有实力、有信心、更有使命，去成为中国美妆行业高端市场拓荒者。”'
                ]
            },
            {
                name: '好麦多',
                type: 1,
                title: '「好麦多」获1.5亿新一轮融资，致远资本担任独家财务顾问',
                content: [
                    '近日，健康食品品牌「好麦多」获得1.5亿元新一轮融资，众为资本领投，清科母基金跟投，老股东星陀资本、前海母基金等持续加持，致远资本担任独家财务顾问。本轮资金将用于加强产品研发和品牌建设，以及供应链的改造等。今年7月，好麦多已获来自星陀资本、前海母基金等数千万元战略融资。',
                    '公司的定位是“时尚健康食品企业”，好麦多品牌从水果麦片来切入，逐步探索围绕谷物食品的新品类，核心用户是18-35岁的都市用户，产品场景以早餐为主、代餐零食为辅，目前主打的水果麦片品类，共有10余个SKU。',
                    '自品牌诞生以来，好麦多的成绩一直很亮眼：在2019年上线首月，线上销售额就突破了200万，3个月后月销售额突破2000万，不到两年累计全渠道销售额超10亿。在疫情期间，好麦多销量增速同比增长超过1000%。',
                    '其中好麦多的酸奶桃桃、桂花胶原等创新谷物产品长期高居各大平台及榜单前列，酸奶桃桃单品更是荣获天猫V榜产品，累计销售超过500万袋；桂花胶原蛋白系列荣获2020年中食展TOP创新产品奖。',
                    '根据第一财经商业数据中心的数据，85后和95后对抗糖需求提升明显，80-90后对谷物型零食消费需求相对较高。好麦多的创始人兼CEO方翔告诉36氪，国内的健康食品品牌还没有成规模地成长起来，欧美有相当一部分健康食品品牌，但是其口味不能满足中国消费者对“美食”的需求，洞察到这一需求后，“更好吃”成为好麦多产品研发的首选考量标准。',
                    '根据日本休闲食品上市公司卡乐比的麦片演进方式，混合麦片可大致分为两类，普通混合麦片（慕斯里Muesli）和水果麦片（Granola谷兰诺拉），好麦多的麦片属于水果麦片，采用特殊工艺，将复合谷物加工使之成为谷物团簇，再配以水果制成不同口感的麦片。',
                    '好麦多最重视的部分在供应链和产品，而这也是公司的核心壁垒。团队有多年的海内外供应链专业背景及从业经验，用相对较优的成本在全球直采到较高品质的原料，并在近日收购了南美哈利斯科奇亚籽产区。产品方面，则沿用了互联网公司的产品思维，通过最小可视化产品原理，每个SKU投入约10万~20万人民币，从口味、原料等不同维度量化，通过数据分析进行敏捷的参数调整，从而实现迭代升级。',
                    '营销方面，好麦多并不推崇拉新、裂变等流量玩法，虽然目前已有关晓彤、宋威龙和谭松韵三位明星作为代言人加持，但好麦多仍然认为产品比营销更重要，立足于对大数据的观察与用户反馈，将更多的利润投入研发。好麦多采用是全渠道模式，线上进驻天猫、京东、抖音、小红书等多家渠道，线下与沃尔玛、Ole、永辉等商超、连锁店合作，采用多消费者触点的方式，而不会依赖单一渠道。',
                    '方翔表示，水果麦片作为新品类不能像其他品类那样标准化是一个遗憾，公司内部制定出一项“HON金标准”，即Healthy好健康、Original好食材、Nice好美味。具体来看，好麦多HON金标准包括：',
                    '一是使配方营养健康，来应对消费者对专业营养搭配的需要以及非油炸、高饱腹、低 GI 糖等基础性需要，好麦多将每餐卡路里摄入由通常的 880Kcal，降低至134Kcal，相当于一个苹果的热量，从而与传统早餐做出差异化定位。',
                    '二是原料渠道的全球优化整合，基于独特的地理环境孕育优质食材的理解，好麦多在近日收购了南美哈利斯科奇亚籽黄金产区土地，从原材料层面提高产品品质的同时建立了竞争优势。',
                    '三是采用先进的技术实现对口感的提升，好麦多的产品采用了双道烘焙非油炸技术保持酥脆口感，并且大力投入水果冷泡技术的研发，让果香和麦片完美融合，在不加防腐剂的状态下还可以保质期长达 10 个月，做到“不用泡都好吃”。',
                    '好麦多创始团队来自清华大学、香港中文大学、加州大学伯克利等中外知名高等学府，除了拥有营养学专业背景，还成功操盘过多项千万级项目，具有丰富的商业营销经验。',
                ]
            },
            {
                name: '素肌良品',
                type: 1,
                title: '「素肌良品」获数千万元A轮融资，致远资本担任独家财务顾问',
                content: [
                    '内衣新锐品牌“素肌良品”完成数千万元A轮融资，本轮由众晖资本投资，致远资本担任独家财务顾问。此轮融资将用于新品研发、市场营销和用户体验升级。',
                    '素肌良品致力打造兼具舒适感与功能性的高端内衣品牌，2019年6月进驻天猫，不到一年即斩获天猫新锐品牌榜及文胸类目TOP20等荣誉，今年销售额同比增长超20倍。今年“双十一”素肌良品9分钟销售额达到去年“双十一”全天销售额。',
                    '随着新一代年轻女性挑选内衣不再将“性感”作为唯一标准，越来越多消费者从有钢圈的功能型内衣转向无钢圈无束缚的舒适型内衣。需求端转变催生供给端快速变革，从有钢圈到无钢圈、从有胸垫到无胸垫，不仅是女性内衣的一次重大革新，也是女性内衣的消费升级。作为贴身衣物，女性内衣最终还是以“品质说话”。',
                    '在内衣“去钢圈”风潮中，素肌良品瞄准注重自身感受，注重商品体验感与高颜值的用户群体，选择了在有钢圈与无钢圈之间的第三种解决方案——“软支撑”内衣。素肌良品爆款“素肌果冻条内衣”，使用了公司首创的“软支撑”工艺。功能上满足了既去掉钢圈，但又是“无钢圈”文胸的升级版，让消费者既不会有传统内衣的束缚感，又能享受到稳定的支撑力，实现“无钢圈”又很有安全感的穿戴体验。',
                    '同时素肌良品主打“奢品质”和“高颜值”，公司团队运用时尚年轻化的剪裁设计，结合低饱和度高级感的配色，更加贴合年轻用户审美。团队还选用了能够对标千元品牌的供应链和欧洲进口面料，满足用户对穿着体验的要求。目前，素肌良品内衣售价在220元左右。',
                    '此外，素肌良品设置了极高的出品标准，每件新产品从新工艺测试到版型的细微调整都有半年以上的打磨期，目前公司每两个月上新一次，并经过大量用户内测反馈，以保证产品的优秀穿着体验。素肌良品对每一批次的产品，都会抽样经过30次洗水测试与拉力机剥离测试。同时通过数据系统赋能，素肌良品的库存周转期已经缩短至30天到60天。',
                    '素肌良品的核心竞争力是对于高端无穿感内衣产品的持续开发能力，创始团队成员均在内衣行业有9-16年从业经验。供应链方面，依托过往多年海内外高端内衣品牌B端业务积累，公司与一线内衣品牌供应商建立了长期战略合作。同时素肌良品主导与供应链合作的新技术开发，目前已拥有数十项知识产权。',
                    '未来，素肌良品将持续推出适应不同场景并兼顾功能性和舒适感的高端内衣。同时2021年公司将会在广州等一线城市布局线下门店。'
                ]
            },
            {
                name: '五爷拌面',
                type: 1,
                title: '「五爷拌面」获高瓴创投A+轮投资，致远资本持续担任独家财务顾问',
                content: [
                    '继2021年6月五爷拌面宣布完成了“餐饮界最大一笔”A轮融资3亿元后，近日五爷拌面又获得了高瓴创投的A+轮投资，这也是高瓴创投首次投资餐饮品牌。本轮由高瓴创投领投，致远资本持续担任独家财务顾问。短短几个月，五爷拌面得到多个头部投资机构青睐，可见其品牌实力雄厚，发展速度迅猛。五爷拌面的本轮融资金将用于加速强化供应链体系、加速自有工厂建设、迭代信息化智能系统、打造国内顶级运营团队及加速布局全国门店网络。',
                    '五爷拌面创立于2018年5月，是国内新兴的创新餐饮连锁品牌。公司通过自营+加盟的方式，以东北三省黑吉辽为起步，已拓展至京津冀、江浙沪、珠三角、山东、内蒙等省市，实现了标准化、规模化高速扩张。据五爷拌面创始人透露，A轮融资后，他们正以飞快的速度扩张全国，预计年末突破1500家店指日可待。届时五爷拌面也将会成为中式面条品牌中门店数量的冠军品牌。据预测，2023年五爷拌面全国门店数量有望突破7000家。',
                    '高瓴创投的投资理念，是坚持长期结构性价值投资，所选择的行业必然是常青且有长期需求的。餐饮行业正是高瓴创投近几年来，深度关注的行业，因为消费领域有稳定市场和固定消费者。高瓴创投一年多以来，研究对比了很多的餐饮品牌，最终确定投资五爷拌面。之所以选择五爷拌面，是因为其具备非常好的品牌的优势和爆发力，运营团队实力极强。业界众所周知高瓴创投出手，必会选择该细分领域的龙头品牌。',
                    '五爷拌面并不是一个泛加盟式的品牌，而是一个有品牌、有服务、标准化的准直营店管理模式的连锁品牌。作为全国优选拌面集合店，五爷拌面融合了全国各地最受欢迎的多种拌面味道及做法，加以改良优化，并严苛把控品质，力求做到“料更好，味更浓”。同时，公司产品组合比较丰富，除了拌面外，也有汤面、米饭等其他产品，还会根据当地口味推出特色菜单，符合更广泛人群的口味需求。同时，五爷拌面正全面打造自己的新零售产品，加强新品及线上流通类产品的研发，目前已在各大电商及各地商超上架销售。',
                    '五爷拌面创始人说，面条赛道市场基数足够大，拥有广泛的大众消费基础。2021年，在餐饮新机遇下，五爷拌面创始人更加看好中式面条品类未来的市场表现和市场容量，并就此为五爷拌面定下了“万家店”的宏大目标。他希望，五爷老爷爷能像肯德基老爷爷一样，遍布全球。让世界爱上中国面！',
                    '本轮投资方高瓴创投表示，五爷拌面团队在创始人的带领下，运用科技工具建立数字化体系，通过领先的信息管理系统和精细化运营为用户带来标准化体验，提升了门店的运营效率。凭借快速迭代能力和超强的执行力，公司在创立短短三年内就实现了门店快速增长。我们期待五爷拌面团队能够发挥自身的禀赋优势，创造更多长期价值，为中国消费者带来更多美好滋味。',
                    'A轮领投方鼎晖VGC基金管理合伙人表示：“星星之火，可以燎原”，五爷拌面完成A轮融资后，团队在五爷拌面创始人的带领下快速成长，外建品牌和供应链，内强体系和组织力，现在单月拓展门店超过300家，已经驶入有质量快速发展的高速公路。对五爷拌面的融资与资本运作，鼎晖一如既往地欢迎优秀投资机构共同参与合作，一起为企业发展不断增砖添瓦，共同成就优秀的企业家，并为改善行业贡献资源和力量。',
                ]
            },
            {
                name: 'AKOKO',
                type: 1,
                hasSub: true,
                title: '曲奇品牌AKOKO获5000万A轮融资，致远资本担任独家财务顾问',
                content: [
                    '今日，曲奇品牌AKOKO，宣布获得了由愉悦资本领投、高榕资本跟投的5000万人民币A轮融资，致远资本担任独家财务顾问。本轮融资将用于供应链升级、品牌推广、品类研发与团队扩充等方面。',
                    'AKOKO是创始人阿柯于2016年创立的精致曲奇品牌，用一款单品创造了极具差异化的产品体验，入口即化、安全无添加。同时，面对互联网市场及消费者群体的变化，AKOKO建立了独特的打法与渠道布局，实现了高效传播与销售，用单品引爆饼干一个类目，积累起大批忠实且高质的粉丝。',
                    '数据显示，AKOKO曲奇三个月内复购率达到60%，充分证明了产品力以及用户对产品的认可度。AKOKO曲奇之所以被称为「国民曲奇品牌」，除了口感极佳外，不加防腐剂、赏味期短、绝不添加和使用泡打粉、入口即化，都是其显著的产品标签。消费升级的大趋势下，消费者对健康提出了更高的需求。AKOKO用产品品质和品牌形象在年轻人的心目中建立了标杆。在天猫上线仅仅两个月，就在天猫做到了曲奇饼干类目第一。',
                    '创始人阿柯表示，「在未来的1-2年内，AKOKO会聚焦曲奇饼干类目，垂直深挖、更新迭代，开发酥曲奇、硬曲奇、软曲奇，建立全品类矩阵，不断输出‘让用户尖叫’、更加安全健康的曲奇产品。在品类渗透与人群覆盖足够理想时，适时横向延展品类，开发其他烘焙产品。诸如曲奇巧克力、曲奇月饼、喜饼、慕斯、千层等。在产品序列层面，AKOKO还会研发代早餐系列、下午茶系列、伴手礼系列，为消费者提供更多选择。」',
                    '愉悦资本创始及执行合伙人刘二海表示，「创新消费是愉悦资本长期看好、深度耕耘的投资根据地，尤其是互联网技术不仅大大提升了效率，更助推产生了一系列创新的产品和服务。AKOKO团队在短短一年多时间，从无到有做出了一个广受欢迎的品牌，在产品口碑、品牌心智等各个方面都表现出色。我们对团队和产品都十分看好，期待他们开发出更多高品质的烘焙产品。愉悦资本将不断支持AKOKO实现自己的梦想。」',
                    '高榕资本副总裁韩锐表示：「AKOKO的产品力有目共睹，团队对产品的用心程度非常打动我们。同时，团队对于核心品牌能力的构建与我们对品牌创业公司的理解不谋而合。我们相信，Akoko在不断突破市场边界的同时，也将为消费者持续带来有惊喜的产品。」',
                    '在AKOKO，西式烘焙文化和中式传统糕点不但可以继承，更可以创新和融合。技术以及配方会重新定位中国人对烘焙的味蕾，就像曲奇一样，大多数消费者在吃AKOKO曲奇的第一口，就会颠覆自己对曲奇的以往味觉记忆。AKOKO将以优秀的品质、独特的品牌形象给大众原有的印象重新赋予定义。',
                ]
            },
            {
                name: '心上',
                type: 1,
                hasSub: true,
                title: '心上获C轮5000万美元融资，致远资本担任独家财务顾问',
                content: [
                    '今日，中高端闲置品共享平台「心上」宣布获得了由GGV纪源资本和愉悦资本共同领投、北极光创投跟投的5000万美元C轮融资，创下中高端闲置品交易平台行业融资纪录，致远资本担任本轮独家财务顾问。本轮融资将用于平台新业务线拓展、品类扩充、公司人才计划、品牌建设和后台升级等。',
                    '「心上」创办于2015年，是一家围绕中高端闲置正品打造的集「交易、鉴定、回收、养护为一体」的一站式服务平台，目前平台上主要有包袋、腕表、首饰和服装四大品类。「心上」聚焦于中高端商品，保证品质是「心上」的核心竞争力——保证品质主要体现在对商品质量的把控，对用户体验的重视。',
                    '数据显示，「心上」 目前每个月保持30%以上的交易额增长。尽管客单价高达3000元左右，也惊人地实现了新客户单月复购率超过30%的好成绩。「心上」目前占据了国内线上中高端闲置品交易市场50%以上的份额，整体看来，「心上」所有数据均优于竞争对手，且重要数据指标甚至是竞争对手的10倍以上量级，就国内市场而言，目前「心上」可谓「执牛耳者」。创始人董博文表示对明年的增长非常有信心，「‘心上’不仅要做开荒者，还要做行业的领路人。」',
                    '对于本轮融资，「心上」创始人董博文表示，「资本对‘心上’一直保持高度信任，此次融资对‘心上’而言具有里程碑意义。国内的二手房市和车市近些年蓬勃发展，下一个千亿级甚至万亿级的二手交易市场或许就是中高端闲置品市场。」创始人表示，后续「心上」将在投资人的助力下加速领跑这个万亿级的市场。',
                    '麦肯锡在今年发布的《2017中国奢侈品报告》（以下简称「报告」）中提到，仅在2016年就有760万户中国家庭购买了奢侈品，家庭年均奢侈品消费达7.1万元人民币，是法国或意大利家庭的两倍。报告预计，至2025年，全球奢侈品市值将达到2.7万亿元人民币，中国消费者将继续担当主力军，将「买下」44%的全球市场。',
                    '创始人董博文表示，今后「心上」会继续在中高端闲置品共享领域尝试更多的商业模式，例如利用租赁模式降低消费者使用中高端商品的门槛。同时，「心上」也会在金融服务上进行创新尝试，寻求机会撬动双边交易平台的爆发式增长。在创始团队不断做出的创新性尝试下，相信今后「心上」的泛共享模式会将使更多的消费者受益，更多追求品质生活的人能在「心上」找到自己的「心中所爱」。',
                ]
            },
            {
                name: '小麦铺',
                type: 1,
                title: '小麦便利店首轮融资1.25亿，致远资本担任独家财务顾问',
                content: [
                    '近日，小麦公社旗下智能便利店「小麦便利店」获得1.25亿元人民币首轮融资，这也是便利蜂之后，便利店领域最大的一笔融资。该轮融资由洪泰基金、晨山资本联合投资，致远资本担任独家财务顾问。',
                    '公司方称，此次融资主要用于供应链建设、产品迭代升级、自有系统研发升级等。小麦公社副总裁全斌表示，目前研发制造还在试测迭代中，但当前版本已经颠覆了传统便利店的成本结构，且通过制造标准化可以快速复制。同时，具备更鲜明的智能硬件属性的新版本店铺将很快投入更广泛的测试中。',
                    '据了解，小麦团队自2015年底进入便利店领域，在红杉中国、中通快递、成为资本等老股东的支持下迅速发展。其目前投放的这种约20平方米的便利店，在户外及建筑内均可放置，可移动、可拼装，最快可以实现4小时建店、一日开业；此外，人脸识别、移动支付等技术，可支持其实现「进门刷脸，购物扫码」的无人值守模式，但在现阶段，仍然会有一名工作人员值守，随时帮助消费者解决问题。',
                    '海淀区苏州桥店是其在北京投放的第一家店，目前正在测试阶段。对于消费者而言，完整的购物流程只有简单几步：注册会员-人脸识别或扫码进门-微信扫码自购-结算支付离开。',
                    '致远资本创始合伙人李悦表示，小麦智能便利店背后的逻辑是新技术的发展带来的便利店行业模式的革新。盒子硬件技术实现了店铺的可拼装可移动，打破了传统便利店铺重资产的限制；人脸识别、移动支付等技术的应用不仅降低了店铺的人力使用成本，同时提升了顾客的消费体验；动态行为数据分析系统反向驱动了店铺的精细化管理，提升整体运营效率。新型智能便利店较传统便利店回收期大幅缩短。',
                    '全斌将小麦定义为「智能便利店」而非「无人便利店」，抛除前面的定语，小麦将核心放在了关注便利店本身的运营和客户体验，包括供应链布局、系统优化、运营升级等。举例来说，每个便利店都会依据周边人群的不同提供不同的商品组合，同时依靠店面后期的经营数据，通过大数据等技术手段不断提升客户体验和坪效。',
                    '致远资本看好以小麦智能便利店为代表的线下流量新入口。中国互联网在经过十多年的高速发展后，线上流量红利逐步消退，智能化数据化驱动的线下零售新模式不仅将催生出新的消费增长点，同时也将可以精准反哺线上，革命性提升零售行业的运营效率。在这一点上，小麦智能便利店展示了新的美好可能，致远资本对小麦的发展充满期待并将陪伴其一路前行。',
                ]
            },
            {
                name: '花卷',
                type: 1,
                title: '短视频电商「花卷」获四千万美元B轮融资，致远资本担任独家财务顾问',
                content: [
                    '网红短视频电商平台花卷商城近期获得了四千万美金B轮融资，创新工场领投，跟投方包括原有股东 EMC、银泰集团和祥峰投资，还有新跟投方治平资本。致远资本担任独家财务顾问。去年，花卷获得来自滴滴打车天使投资人王刚、主持人李静、法国银泰集团Ventech China的天使轮融资，祥峰投资、EMC、清晗基金投出的A轮融资，以及EMC领投的A+融资。 ',
                    '公司创始人兼CEO韩卉表示，这笔资金将主要用于三部分。第一是品类扩展，平台目前品类有约八成集中在美妆，其他则是进口酒类和保健品，公司希望扩大SKU。第二是网红的培育和内容导向的工作方面，这有助于增加花卷作为一家短视频电商平台对资源的把控力。第三部分则是扩展用户和视频分发渠道上。',
                    '据花卷方面介绍，平台在去年6月上线，定位是「基于视频的新颖优质商品推广平台」，前端通过KOL在各大社交媒体上分发直播、短视频内容来获客，引导用户进入花卷APP，后端自建非标商品供应链。据韩卉透露，平台目前主要的盈利方式就是售卖自营商品，没有靠广告。',
                    '据韩卉介绍，成立了一年多的花卷，目前注册用户量超过百万，DAU（日活用户量）在15万左右，平台最亮眼的数据是从注册到购买的转化率达到70%，全站（六个月之内的）复购率65%，美妆品类复购率35-38%。平台的复购和留存的数据也不错，据韩卉的介绍，花卷没有做大规模的品牌传播，用户第一次接触花卷往往是通过直播和短视频平台等站外平台。进了花卷平台之后就开始了第二层的交叉传播，用户会通过观看商品短视频围观更多的网红，根据平台统计平均每个用户进来之后至少会围观3-5个网红，之后会收到相关的新商品上架和内容更新推送。第三层，对于核心网红的粉丝用户群，都有官方的群运营为用户提供答疑和解难。',
                    '花卷的团队目前约有70人，创始人韩卉曾任高端男装公司玛萨玛索和汽车之家市场总监、乐蜂网推广平台总经理，2012年与丈夫韩擘联合创立职场社区网站卧龙阁。',
                ]
            },
            {
                name: '抱抱堂',
                type: 2,
                link: 'http://www.baobaot.com'
            },
            {
                name: '每日优鲜',
                type: 2,
                link: 'https://www.missfresh.cn'
            },
            {
                name: '辣妈帮',
                type: 2,
                link: 'http://www.lamabang.com'
            },
            {
                name: '河狸家',
                type: 2,
                link: 'https://www.helijia.com'
            },
            {
                name: '蘑菇街',
                type: 2,
                link: 'https://www.mogu.com'
            },
            {
                name: 'MissCandy',
                type: 3
            },
            {
                name: '团车网',
                type: 2,
                link: 'http://hrb.tuanche.com'
            },
            {
                name: '达达快送',
                type: 2,
                link: 'https://www.imdada.cn'
            },
            {
                name: '小猪短租',
                type: 2,
                link: 'https://www.xiaozhu.com'
            }
        ],
        [
            {
                name: '犀有数科',
                type: 1,
                title: '「犀有数科」完成A轮超亿元融资，致远资本担任独家财务顾问',
                content: [
                    '近日，犀有数字科技(下称"犀有数科"）宣布完成超亿元A轮融资。本轮融资由新犁资本领投，致远资本跟投，致远资本担任独家财务顾问。本轮所募集资金将用于智慧印染生态升级、产业大数据建设、数字化面料实验室扩容等。',

                    '犀有数科成立于2020年，定位以工艺和研发数字化为抓手，ODM以销定产柔性制造为模式，全面打造面料数字供应链生态，实现数据和技术驱动的纺织行业智能制造云工厂。犀有数科是产业互联网3.0阶段的领军企业，打造“端端链接+商品标准->柔性供应”。',

                    '犀有数科通过数字化体系，定向研发及推荐面料到品牌客户，通过列为中国制造2025标杆的自营智能工厂承接客户的测款首单并沉淀可复制的数字化工艺，然后基于客户销售情况调度工艺及产能最为匹配的生态伙伴协同快反交付犀有数科标准的面料商品。犀有数科CEO张帅预测 “ODM型面料快反的市场增长空间首先会覆盖原现货面料供应链市场，每年会有千亿级的供给替代需求。”',

                    '犀有数科拥有“宽度+深度“兼备的顶配团队。CEO张帅是材料学博士后，作为阿里巴巴犀牛智造联合创始人，完成和见证了犀牛智造”从0到1”的落地，在Decathlon任职期间带领团队研发成功用于冲锋衣的“防风防水透湿透气”的纳米科技微多孔膜。董事长&CPO（首席产品官&首席工艺官）卫金龙先生是中国最早染整专业科班出身，深耕印染近30年，被誉为针织印染行业工匠第1人、数字化印染工厂成功实践第1人，曾领导裕民针织成功上市（HK00844）。COO陶张木先生是前智布互联联合创始人，高级副总裁，拥有10年以上纺织印染行业从业及行业级工业互联网项目成功落地经验，是行业最早成功从0到1打造纺织印染行业数字供应链信息化平台的领军人才。',

                    '新犁资本主管合伙人李绪富表示：“这是我们近三年来布局和打造的第十二个产业生态共同体：以产业为标的、以全局为视野、以数字孪生为特征、自迭代自演绎自成长的生态共同体。与时俱进的是，过往我们更多的从供应链着手，犀有科技已经超越供应链，在数字化研发和数字化工艺的基础上直接切入智能生产和智能驱动，底层驱动也是在积累行业经验、知识、技术、共识的基础上融合了互联网技术、大数据技术和人工智能技术，因而我们的产业生态共同体也迭代为更具象的表达：产业大脑+订单入口+智能驱动+供应链平台，很是期待。”',
                ]
            },{
                name: '金峰集团',
                type: 1,
                title: '「金峰集团」完成超2亿元D轮融资，致远资本持续担任财务顾问',
                content: [
                    {
                        type: 'html',
                        text: '<div style="font-size: 1.75rem; line-height: 3rem">近日，苏州金峰物流设备有限公司(简称"金峰集团"）已顺利完成超2亿元D轮融资。本轮融资由深圳前海母基金、上海道禾资本共同领投，原股东江苏毅达第二次参与跟投，<span style="font-weight: 500">致远资本持续担任财务顾问</span>。</div>'
                    },
                    '本轮融资的主要用途是加大产品研发投入和加强高素质人才队伍建设，拓展海外业务，提升公司海内外综合服务能力和技术创新能力。与此同时，充分利用行业供应链资源优势和技术优势，积极拓展国内外市场，进一步提高公司的核心竞争力和市场占有率，力争成为全球行业知名的物流系统集成商。',
                    {
                        type: 'html',
                        text: '<div style="font-size: 1.38rem; color: #666; line-height: 3rem; margin-bottom: 4rem"> ——本文转载自金峰集团公众号</div>'
                    },
                    {
                        type: 'bold-mb0',
                        text: '三十年经验沉淀厚积薄发'
                    },
                    {
                        type: 'img',
                        url: require('../../common/imgs/development/detail/金峰集团/1.jpg').default
                    },
                    '金峰集团的创始人蔡熙先生在物流行业深耕逾三十年，是物流装备行业中知名的资深专家。曾参与中国邮政第一套交叉带分拣设备系统的引进和建设，与国家邮电部专家共同命名“CROSS BELT SORTER”，官方中文专业名称为“交叉带分拣机”，并全力协助中国邮政制定并实施了“三状（信函、扁平件和包裹）二箱（两个集装容器）一条码”标准，并于1998年在广州邮区枢纽中心中推动实施，为中国邮政后续的自动化建设奠定了基础。',
                    {
                        type: 'bold',
                        text: '智能物流和智能制造“两条腿”走路'
                    },
                    {
                        type: 'mb0',
                        text: '金峰集团是一家专注于智能物流和智能制造领域物流自动化业务的综合方案提供商和关键核心产品制造商。专注为客户提供系统规划设计方案、项目实施运维及售后服务等一体化的解决方案，助力客户向自动化、智能化、数字化升级转型。金峰集团自2012年成立以来，已经累计交付1000+智能物流自动化项目，应用场景覆盖了邮政快递快运、商超物流、电商仓储、机场烟草、医药化妆、鞋服时尚、智能制造等诸多行业。'
                    },
                    {
                        type: 'img',
                        url: require('../../common/imgs/development/detail/金峰集团/2.jpg').default
                    },
                    {
                        type: 'mb0',
                        text: '电商、快递、快运等行业的发展正在带动商业配送物流自动化行业加速崛起，同时，工业4.0智能制造、柔性生产制造等使得工业生产物流自动化和智能化升级转型也成为势不可挡的趋势。自此，中国物流自动化装备市场已全面进入蓬勃发展的智能化与无人化新纪元。同时，伴随着新一轮的科技革命和产业变革，制造业正在不断突破新技术、催生新业态，智能制造已成为推动制造业转型升级、加快制造业高速发展的重要抓手。'
                    },
                    {
                        type: 'mb0',
                        text: '金峰提供从设备到解决方案完整产业链服务，涵盖物流系统整体集成、系统化整体方案设计、智能化电控与信息技术、自动化物流装备，适用不同领域的各种场景。金峰作为一站式智能工厂自动化解决方案提供商，为智能制造、智慧物流行业提供最具有成本效率及最优化的自动化解决方案，全球领先集成商采用的管理模式，采用Stage-Gate的管理模型，由高层管理员层层把关，实现零失误。'
                    },
                    {
                        type: 'img',
                        url: require('../../common/imgs/development/detail/金峰集团/3.jpg').default
                    },
                    '金峰集团凭借顶尖的产品能力和技术研发实力，致力于全面推动行业创新，打造行业最佳实践，引领行业发展。金峰集团高度重视技术研发，专利数量领先同行。在国内获得数百项专利和软件著作权，拥有完整的自主知识产权。金峰集团以创新型自动分拣系统切入，自主研发先进实用的物流自动化系统，构筑强大的产品与技术壁垒。金峰集团提供从设备到解决方案完整的产业链服务，不断开拓和深耕不同领域的各种场景。',
                    {
                        type: 'bold',
                        text: '“一带一路”政策下积极布局海外'
                    },
                    '近年来，跨境电商行业政策环境利好，国家加大对外开放力度，加强与海外国家的交流，大力鼓励跨境电商的发展，这也刺激和推动跨境物流自动化的兴起。金峰集团顺势而为，乘势而上，借着跨境电商崛起的东风，积极布局海外市场。2021年因疫情原因，海外仓交易量不断上升，为了积极应对海外市场激增的需求，金峰集团将国内先进的物流技术服务于广阔的“一带一路”沿线国家市场，让中国品牌助力东南亚电商快递行业快速发展。继成功助力越南邮政上线首套交叉带分拣机系统之后，又再次承接了泰国虾皮在东南亚的首个大型自动分拣项目。截至今年9月，大量优质海外客户寻找到金峰洽谈合作，目前，海外业务量占到金峰总业绩的25%左右。金峰不断助力客户提升自动化、智能化能力，大大提高了场站的运作效率和分拣的准确率，降低了物流人工成本，从而促进了跨境电商的高速发展。',
                    {
                        type: 'bold',
                        text: '人才永远是第一资源'
                    },
                    '金峰集团一直专注于物流自动化系统集成领域，经过长期实践和经验积累，金峰集团所提供的智能分拣设备及系统的运行效率、准确率及稳定性等方面在行业中已获得广大客户的高度认可和一致好评。未来，金峰集团将不断进行技术创新和管理创新，着重加快人才建设步伐，让事业激励人才，让人才成就事业，竭力打造成全球知名物流系统集成商和服务商。',
                    {
                        type: 'bold',
                        text: '致远观点'
                    },
                    '致远资本创始合伙人李悦女士表示，“经济增长促进物流行业全面发展，催生了物流自动化装备产业链市场的巨大需求。我们持续看好金峰在此领域的发展机会和头部地位。金峰从智能分拣切入，提供领先的智能输送分拣系统整体解决方案。凭借顶尖的产品能力和技术研发实力，打造行业最佳实践，引领和推动行业的创新与发展。金峰始终以先进的创新产品为根基，以智能科技为核心，以“使物流更畅通”为使命，致力于成为国际物流自动化装备和服务行业的领军企业。”'
                ]
            },
            {
                name: '融慧金科',
                type: 1,
                title: '「融慧金科」获数千万美元B+轮融资，致远资本担任独家财务顾问',
                content: [
                    '近日， 融慧金科宣布完成数千万美元B + 轮融资。 本轮融资由优山资本领投， 澳洲电信投资跟投， 致远资本担任独家财务顾问。 截至目前， 融慧金科共计完成了四轮融资， 投资方囊括了红杉资本中国、 澳洲电信投资、 华创资本、 优山资本等一线明星投资机构。 本轮融资主要用于技术研发、 大数据研发投入和产品服务体系升级打造等。',

                    '融慧金科成立于2017年， 三位创始人王劲、 张羽和盛军均具有长达二十年的国内外甲方风控经验。 自成立之初， 便确定了致力于为持牌金融机构提供一站式金融科技风控服务的路径， 是行业中极少数具备“ 闭环服务” 能力的金融科技公司。',

                    '在金融科技风控赛道， 基于辅助运营BOT模式（ Build - Operate - Transfer）， 融慧金科首创三个行业第一。 在产品交付中， 更是坚持以合规性引领金融科技市场， 并严格要求自身以甲方的标准在做乙方的业务， 致力于做最懂甲方的合作伙伴。 值得一提的是， 在提供产品及服务的过程中， 融慧金科保持高度开放的态度， 首创“ 白盒服务” 模式， 乐于和金融机构分享实战中的技术能力及经验沉淀， 助力其快速发展。',

                    '正是由于自身专业的金融风控经验、 深厚的技术积累、 完善的企业级产品布局、 成熟的全流程闭环服务体系， 以及开放心态的助力， 尽管成立时间只有短短四年， 融慧金科却已经实现了上百家包括国有银行、 股份制银行、 头部城商行、 持牌消金、 头部互金等在内的持牌金融机构客户的方案交付， 成功跻身金融科技赛道第一梯队。',

                    '融慧金科创始人兼CEO王劲表示， 融慧金科是一个基于自主、 快速、 健康基因而诞生的绿色金融科技企业， 我们的使命是利用端到端的全流程闭环服务， 协助银行、 消金等持牌机构获得业务上持续、 快速、 健康的增长。',

                    '优山资本合伙人顾锐表示， 互联网消费信贷历经多年发展， 已成长为规模超4万亿的巨大市场。 相较于传统的消费信贷形式， 其及时性、 便利性的特点更受到年轻群体的青睐， 未来行业需求将持续增长。 伴随行业监管逐步完善， 我们相信互联网消费信贷行业将向着持牌机构主导的正规化、 良性化的方向发展。 大数据风控是互联网消费信贷行业核心的业务环节， 以大数据风控为主的金融科技服务具备超百亿的市场空间。 在持牌金融机构逐渐主导消费信贷行业进程中， 围绕着持牌机构风控需求， 提供定制化、 端到端的大数据分析和软件服务将具备巨大的价值创造空间。 在行业发展趋势的判断上， 融慧金科与我们是一致的。',
                ]
            },
            {
                name: '新核云',
                type: 1,
                title: '「新核云」完成C轮近2亿元融资，致远资本连续担任独家财务顾问',
                content: [
                    '近日，「 新核云」 宣布完成2亿元C轮融资， 由小米战投、 顺为资本领投， 北极光创投与同创伟业持续加码， 致远资本连续担任独家财务顾问。 这是今年以来， 这家工业智能软件服务商完成的第二轮融资， 年初新核云刚刚完成由同创伟业、 IDG资本领投， 北极光创投和蓝江投资跟投的1 .5 亿元B + 轮融资。',

                    '新核云为上海纽酷信息科技有限公司（ 纽酷） 打造的一款面向离散型制造业工厂的数字化系统产品， 其包括云MES、 云ERP、 TPM、 TQM四大模块， 可通过数据采集、 分析、 决策助力工厂完成数字化转型， 目前产品主要落地在汽车零部件制造领域， 客户包括： 新奥集团、 中轴集团等',

                    '纽酷是一家工业智能化软件服务商， 成立于2014年， 企业专注于工业物联网、 工业大数据、 以及AI技术在工业场景中的落地应用， 赋能工厂逐步实现向数字化、 联网化、 智能化的升级。',

                    '新核云的MES系统本质上是一套面向制造企业车间执行层的管理系统， 通过将设备联网， 可向企业提供包括制造数据管理、 计划排产管理、 生产调度管理、 库存管理、 质量管理等多种功能模块。 即使一家大型企业可能会使用到CRM、 PLM、 ERP、 MES等多种数字化管理系统， 但对于制造业企业来说， MES系统服务于车间制造流程， 是执行层与管理层的连接中枢， 是数字化生产的核心。 我国早在2016年印发的《 关于开展2016年智能制造试点示范项目推荐的通知》 中就指出， 离散型智能制造模式和流程型智能制造模式必须建立MES系统。',

                    '近年来MES系统受到了资本和市场的双方青睐。 据公开资料显示， 2020 年MES系统在我国工业软件市场中占比为29 % ，在今年上半年， 国内市场获得融资的MES企业近十家， 其中就包括了新核云。 市场方面， 随着产业发展， 大量的中小型工厂也正在逐渐摆脱“ 催物料、 吵计划、 抄记录” 的境况， 这极大刺激了国内制造业企业对MES系统的需求。',

                    '今年2月初， 工业软件入选了科技部国家重点研发计划首批重点专项， 这标志着工业软件已成为国家科技领域最高级别的战略部署。 同时， 已经持续近两年新冠疫情加速了制造业尤其是中小型制造业数字化转型的进程， 企业对生产管理系统的付费意愿明显提升， 并希望利用管理系统提高自身的生产效率。',

                    '新核云的云MES系统可在车间层对排产、 报工、 质量、 生产等业务流程实现数字化， 并可通过云ERP系统将车间信息与其他协同系统打通， 解决了管理与执行之间的割裂问题。 结合自主研发的工业网关， 新核云在进行解决方案部署时可以更加高效地帮助用户采集、 分析设备数据。',

                    '黄培博士的《 2020 智能工厂建设与MES应用报告》 指出， 目前国内MES系统的产品化程度仍较低， 制造企业更多以自主研发或合作开发的方式实施MES系统。 一些MES系统提供商由于缺少行业经验， 无法真正理解用户的需求， 用户的工程人员和提供商的IT人员需要进行长期的诉求确认， 在这种情况下， 以项目形式推动解决方案的落地将存在很大风险。',

                    '新核云CEO陶滨江表示：“ 很多MES系统项目落地的周期都非常长， 有一些甚至会不了了之。”',

                    '新核云的MES系统以SaaS模式， 为领域制造企业提供云MES、 云ERP、 TPM、 TQM业务服务。 除了面向大型客户可提供定制化的总包服务外， 面对行业腰部客户， 新核云的产品可独立成60多个功能模组进行单独售卖， 如生产管理、 WEB端报工、 过程质量管理、 来料质量、 订单、 采购、 库存、 库存结存、 成本核算等等。 用户可根据自身实际需求自主选择， 避免交付周期长和项目烂尾情况。',

                    '随着制造业的数字化开始向生产环节的上下游渗透， 游新核云作为零部件制造领域的数字化解决方案提供商， 也正通过开放接口， 引入各领域合作伙伴来打造数字化生态体系。 目前已实现与CRM、 OA、 ERP、 PLM、 PDM、 数字孪生、 MDC、 SCRM等细分领域服务商的产品联通， 可为用户提供覆盖企业多部门。 多业务场景的集成方案。',

                    '根据新核云提供的数据显示， 采用新核云MES产品或解决方案后， 企业设备OEE平均提升10 % 以上； 库存周转次数平均提升15 % 以上； 设备故障率平均下降3 % ；质量报废率平均下降15 % 。目前新核云拥有用户超过1000家， 其营收大部分也来源于产品年费， 2020 年全年营收超亿元。',

                    '新核云在汽车零部件制造业的长期积累使得新核云的产品使用门槛较低， 相关用户可以快速上线。 事实上， 专注于某一行业也是国内MES系统厂商需要重点布局的方向， 例如摩尔元数聚焦于电子组装、 PCBA行业； 黑湖聚焦于食品、 医药领域等。',

                    '业界普遍认为， MES系统的可复制性较低， 需要依托细分行业进行发展。 长远来看， MES系统或很难做到跨多个行业的标准化， 尤其对于国产MES系统厂商来说， 专注某一领域是建立行业壁垒的重要途径。',
                ]
            },
            {
                name: '星辰天合',
                type: 1,
                title: '「XSKY」完成E轮7.1亿元融资，致远资本连续担任财务顾问',
                content: [
                    '2021 年9月3日， 星辰天合（ 北京） 数据科技有限公司（“ XSKY星辰天合”） 宣布完成E轮7 .1 亿元人民币股权融资。 本轮融资由博裕投资领投， 君联资本、 中金甲子、 博华资本、 昆仑资本、 恒生电子、 苏州毅商等跟投， 原股东北极光创投、 启明创投等亦参与跟投， 致远资本连续担任财务顾问。',
                    'XSKY星辰天合成立于2015年， 是一家专注于软件定义基础架构业务的中国高新技术企业。 在六年时间里成长为国内领先的数据基础设施技术平台提供商， 产品和服务获得了超1000家大型政企机构的大规模商业化部署， 客户涵盖政府、 金融、 运营商、 科技、 医疗、 制造、 航天、 司法、 广电、 教育、 能源、 交通、 物流等数字经济社会的关键领域， 支撑着行业云、 私有云、 桌面云、 数据库资源池、 海量媒体数据、 影像数据、 智能制造数据， Hadoop 大数据等不同类型的应用场景。',

                    'IDC数据显示， 星辰天合在2020年SDS中国市场整体份额分布中， 是榜单前五中唯一一家专业的软件定义存储厂商。 特别在大数据和云存储有关的对象存储领域， 星辰天合连续3年占据国内市场第一位。 公司目前申请和获得350项自主知识产权， 约70项核心算法专利， 并多次牵头参与云存储资源管理、 分布式存储等国家标准及行业标准的制定。 在2021年7月23日工信部公布的《 关于第三批专精特新“ 小巨人” 企业名单的公示》 中， XSKY星辰天合位列其中。',

                    '公司将利用本次所融资金进一步加大核心技术研发投入， 拓展云边协同的新产品形态和商业模式， 加强对关键行业客户的服务能力。 未来， 公司将持续为以数字基础设施为核心的“ 新基建” 注入新动能， 并以数据常青为使命， 致力于成为全球领先的数据基础设施技术平台提供商。',
                ]
            },
            {
                name: '九章云极',
                type: 1,
                title: '「九章云极」获3亿元C轮融资，致远资本担任独家财务顾问',
                content: [
                    '近日， 九章云极DataCanvas宣布完成C轮融资， 由尚珹资本、 赛富投资基金领投， 君紫投资、 领沨资本等投资机构跟投， 融资金额3亿元， 致远资本担任独家财务顾问。 本轮融资备受一线投资机构认可， 资方长期关注科技创新领域， 青睐人工智能领域中技术壁垒高、 产品领先优势显著、 业务发展潜力巨大的头部企业。',
        
                    '九章云极DataCanvas此次C轮融资距前次B + 轮1 .2 亿元融资不到一年， 充分体现了资本市场对九章云极DataCanvas业务战略和未来资本路径的信心。',
        
                    '九章云极DataCanvas创始人兼董事长方磊表示， 此轮融资将进一步夯实公司在人工智能平台领域的领头羊地位： 九章云极以自主研发的“ 硬科技” 立身， 守正出新， 以自主研发的标准化AI基础架构软件为基石， 通过将AI技术从研发到生产化过程中各个环节“ 自动化”， 不断开创各行各业新业务新场景的领先应用； 同时， 以发展的眼光赋能AI生态， 开源一系列国际领先的AutoML模型算法、 工具和架构， 通过提供统一的开发标准和规则， 加速跨行业AI应用的研发和AI能力的沉淀。',
        
                    {
                        type: 'mb0',
                        text: '自主AI， 赋能企业数智化升级'
                    },
                    '作为国际领先的数据科学平台提供商， 九章云极DataCanvas坚守以数据科学赋能企业AI 的使命， 通过低代码、 自动化的人工智能平台实现AI技术的企业级应用， 帮助企业客户沉淀自主AI建设能力、 积累数据资产、 提升数智化升级能效。 近年来， 九章云极DataCanvas的数据科学产品市场占有率稳居中国前三， 已服务金融、 通信、 航空、 制造、 交通、 政府等众多头部客户， 各项业务在持续高速增长之中。',
        
                    '九章云极的核心产品线—— DataCanvas数据科学平台家族—— 为企业客户自主研发AI应用提供坚实基础架构的同时， 通过ModelOps为客户提供云原生、 可解释、 大规模、 直击业务痛点的AI模型全生命周期管理服务。 这是企业数智化升级过程中自主创新、 高效协同、 加快AI生产化并提升业务收益的重大创新。',
        
                    {
                        type: 'mb0',
                        text: '硬科技 + 标准化， 定义AI基础架构未来'
                    },
                    '自主研发的AI技术、 创新的业务应用和良好的客户口碑使得九章云极DataCanvas在千变万化的市场环境下实现业务超预期增长， 凭借多个行业的深耕经验和技术与业务知识的成熟融合， 其标准化的AI平台产品实现了更大规模的覆盖， 大客户数量较去年翻倍增长， 并在通信行业成功复制应用； 同时， 九章云极DataCanvas正在加速产品线的创新和迭代， 以服务与日俱增的巨量数智化升级市场需求。',
        
                    '以“ 硬科技” 立身的九章云极DataCanvas在技术创新方面成果瞩目， 多项创新技术频频收获国际机构认可， 此前凭借独创的开源项目DeepTables， 在齐聚1万3千多家参赛队伍的全球数据科学顶级Kaggle竞赛中， 摘获第一名， 全新定义了深度学习技术在结构化数据分析领域的价值。 基于持续的技术自主创新， 九章云极DataCanvas目前已积累200多件自主知识产权， 其中包含50多件发明专利。',
        
                    '据悉， 新一轮融资将用于进一步完善AI基础架构软件的版图， 九章云极DataCanvas将推出新一代HSAP实时数据库， 满足人工智能时代对新一代数据库的需求， 成为AI数据库的定义者和领导者。 与此同时， 九章云极DataCanvas将通过颠覆性的AutoML、 AutoDL技术创新， 强化在金融、 通信、 政府等行业应用优势， 为各行业企业数智化升级提供新动能。',
                ]
            },
            {
                name: '德风科技',
                type: 1,
                title: '「德风科技」完成8000万A轮融资，致远资本担任独家财务顾问',
                content: [
                    '全栈工业互联网产品提供商“ 德风科技” 宣布完成8000万人民币A轮融资， 由招商局创投与创新工场联合领投， 原股东云启资本跟投， 致远资本担任独家财务顾问。 据了解， 本轮资金将主要用于“ 新基建” 工业互联网产品研发升级及行业生态拓展。 此前， 德风科技曾获上市公司创始人天使轮投资， 云启资本Pre - A轮投资。',
        
                    '德风科技是一家专注于能源电力、 石油化工、 烟草、 工业制造业等行业的工业互联网技术公司， 提供先进的工业互联网平台、 智能安全物联网平台、 工业大数据平台等全栈产品和解决方案， 如5G多站融合解决方案、 综合能源互联网云平台、 智慧电厂、 智慧油田、 数字孪生、 智能安全物联网方案等。',
                    {
                        type: 'bold-mb0',
                        text: '构建“工业大脑”'
                    },
                    {
                        type: 'bold',
                        text: '押注千亿市场空间'
                    },
        
                    '开年以来，“ 新基建” 成为经济热词， 人工智能、 工业互联网等新基建七大领域站在风口， 但真正落地且可以大规模应用的场景仍屈指可数， 而传统产业等经典场景融合人工智能和工业互联网的技术进行转型升级， 释放出巨大优势和市场价值。',
        
                    '不过各个行业特点不一， 在经过深度的市场调研之后， 德风科技选择能源电力、 石油化工、 烟草等作为工业互联网的切入点。',
        
                    '德风科技创始人兼CEO王清杰认为， 首先， 能源电力、 烟草、 石油化工等传统行业抗经济周期能力极强， 进入门槛高、 壁垒深； 其次， 这三大行业存在业务数字化、 商业安全管理信息化、 降本提效等诸多行业痛点， 数字化升级需求亟待满足， 信息化预算充足， 付费能力强， 通过工业互联网、 人工智能和大数据去赋能这三个传统行业， 可拥有千亿元价值的市场空间。',
        
                    '“ 以降本提效为例， 目前石油化工企业维护成本在桶油操作费中占比达到25 % 左右， 仅大数据分析的预测维护技术就可将维护成本降低13 % 。”王清杰说。',
        
                    '德风科技成立5年， 已拿下中国烟草、 国家电网、 中石油、 中海油、 中国中车等多个传统巨头的订单。 王清杰表示， 能拿下巨头订单， 德风有几个核心竞争优势。',
        
                    '首先是团队的技术落地实力。 德风科技核心团队来自IBM、 西门子、 普华永道、 华为等业内复合型人才， 平均具备超过15年的工业互联网产品经验与能源电力、 烟草、 石油化工行业经验， 拥有30多项专利， 70 多个软著。 团队擅长技术产品化， 同时能高效落地到使用场景， 切实提供企业的生产效率。 德风CEO王清杰是IBM前高管， 负责产品及解决方案， 具有18年经营管理经验， 丰富的能源电力、 工业制造、 石油化工行业的客户资源和项目经验。',
        
                    '其次是能提供工业互联网全栈产品及解决方法。 德风科技提出了工业互联网4PE模型， 即基于工业互联网平台（ Platform）， 通过顶层设计（ Planning） 为客户规划完整的工业互联网技术路线图， 结合生产（ Production） 互联和产品（ Product） 互联， 做到生态（ Ecosystem） 互联。',
        
                    '以工业互联网平台为支撑， 德风科技为客户解决三个层面痛点： 第一是生产设备层（ APM）， 包括设备状态监测、 PHM（ 故障预测与健康管理）、 预测性维修保障、 质量缺陷分析及优化、 可靠性分析及预测等； 第二是生产运营层（ OPM）， 包括能源管理及能耗监测分析、 线损治理及耗损分析、 安全双控管理等； 第三是经营决策层（ ET）， 包括工业大脑、 数字化运营监测、 应急指挥调度、 安全预警预判等。',
        
                    '“ 德风产品能力覆盖终端数据采集层、 边缘计算层、 工业PaaS平台层及应用SaaS层， 通过工业大数据、 安全物联网、 工业互联网平台三大产品矩阵， 为行业客户构建工业大脑。” 王清杰介绍， 德风产品有几个特点： 一是德风科技方案层次完整。 自主研发多层次解决方案， 可独立完成系统设计、 产品跨层积木式组装、 可快速满足企业客户需要； 二是产品化能力强。 抽象客户需求， 定义标准、 产品模块， 极大程度降低定制化交付成本； 三是数据赋能企业。 通过大数据和AI能力提升企业客户运营效果， 沉淀海量客户数据和场景， 更高效匹配社会资源。',
        
                    '据了解，“ 智慧能源” 是德风科技以工业互联网、 人工智能赋能能源电力行业的经典案例。 电力公司采用“ 智慧能源管理” 解决方案， 可以通过互联网和物联网实现能源设备集中控制； 同时， 通过PHM历史数据模式学习， 实现保养计划的智能制定； 通过历史发电数据的机器学习， 提升发电量3 % 以上， 最终让公司运维成本降低30 % 。',
        
                    '而在烟草行业， 德风科技已经与国家烟草局全国23个省份烟草工业和商业客户达成合作， 在安全物联网和安全大数据方面市场份额处于第一。“ 经评测， 德风产品落地场景在烟草安全生产三维定位、 安全领导管理驾驶舱、 安全大数据移动应用、 全方位安全管控及安全大数据分析等方面， 能把烟草生产安全事故率降低15 .6 % 。”王清杰说。',
        
                    {
                        type: 'mb0',
                        text: '年复合增长率近100 %'
                    },
                    '打造“ 中国工业互联网SAP”',
                    {
                        type: 'mb0',
                        text: '德风科技作为一家TO B端的高科技初创企业， 目前已经建立具备了造血能力。',
                    },
        
                    '王清杰透露， 德风科技自成立以来， 每年保持近100 % 的复合增长率， 服务超过200家大型企业， 并连续盈利。 经过四年多的发展， 德风科技已具备从物联网数据采集、 处理、 存储、 分析、 深度挖掘、 场景应用和算法优化的综合一体化服务和项目落地能力。',
        
                    '值得一提的是， 在此次疫情期间， 德风科技一季度业绩相比2019年同期， 增长了约80 % 。',
        
                    '“我们的造血能力有两大方面。 一是商业落地能力。 凭借团队对行业的深刻理解与领先的技术水平， 公司具备极强的商业落地能力， 收入规模以及增长率、 复购率行业领先。 二是有可持续的收入模式。 德风为石油化工、 能源电力、 烟草以及智能制造等多个500强企业提供全套IT + OT的解决方案， 服务头部客户形成强大的标杆效应， 可实现规模化横向复制拓展。” 王清杰提到。',
        
                    '而此次融资的资金将用于产品研发、 业务拓展、 人才建设与营运资金补充。“ 我们的下一个目标是成为中国工业互联网领域的SAP。” 王清杰介绍。',
        
                    '本轮投资机构招商局创投总经理吕克俭表示：“ 工业互联网是中国“ 新基建” 七大核心之一， 目前已经进入了高速发展期。 我们希望德风科技将其“ 工业互联网最佳实践” 深耕行业， 为中国大型企业的智能化和数字化贡献一份积极的力量。 招商局创投也将从资本、 市场、 人才等方面， 为德风科技提供持续支持， 共同助力我国新基建工业互联网的发展。“',
        
                    '创新工场董事长李开复表示：“ 从传统IT到IIoT工业互联网、 基于数据积累和算法优化的人工智能为基础， 德风科技把IT与OT高度融合， 为企业解决了“ 降本、 提质、 增效” 的痛点， 工业互联网是助力企业数字化升级及产业转型的有效工具， 德风科技作为领先的工业互联网产品商将为企业转型升级提供支持及保障。 创新工场十分看好德风科技的行业经验和发展前景， 将与德风科技一道协助能源电力、 石油化工、 烟草工商等行业更好地实现数字化转型， 并整合创新工场旗下云服务、 大数据和人工智能等技术资源， 打造中国“ 新基建” 工业互联网的生态体系。”',
        
                    '本轮投资机构云启资本创始合伙人毛丞宇表示：“ 云启资本长期关注“ 技术赋能产业升级” 早中期投资， 深耕布局“ 新基建” 的多个关键领域， 其中“ 工业互联网” 是重点关注方向之一， 我们看好德风科技团队在2B领域耕耘了十余年的行业Know - How和核心技术， 并且正在协助能源电力、 石油化工、 烟草等行业进行赋能提升和产业升级， 相信在中国大型企业未来十年间向数字化智能化转型的新时代新机遇下， 德风可以创造更大的商业和社会价值。”',
                ]
            },
            {
                name: '芯翼信息科技',
                type: 1,
                title: '「芯翼信息科技」完成B轮5亿元融资，致远资本连续担任财务顾问',
                content: [
                    '近日， 物联网智能终端系统SoC芯片提供商芯翼信息科技（ 上海） 有限公司（ 以下简称： 芯翼信息科技或公司） 完成近5亿元B轮融资， 资金主要用于加强芯片产品研发、 完善生产制造供应链、 扩充核心团队等。 本轮投资由中金甲子、 招银国际联合领投， 招商局资本、 宁水集团、 亚昌投资等跟投， 另外老股东峰瑞资本、 晨道资本、 华睿资本等持续加注， 致远资本持续担任公司财务顾问参与本轮融资。',

                    '芯翼信息科技是一家专注于物联网智能终端系统SoC芯片研发的高新技术企业。 公司创始人及核心研发团队来自于美国博通、 迈凌、 瑞昱、 海思、 展锐、 中兴等全球知名芯片设计和通信公司， 毕业于TAMU、 UCLA、 UT Dallas、 NUS、 北大、 浙大、 东南、 电子科大等海内外知名高校， 具有专业技术完备且国际顶尖的芯片研发能力。 2020 年6月， 公司牵头获得了科技部“ 国家重点研发计划” 光电子与微电子器件及集成重点专项项目， 成为为数极少的获此殊荣的初创企业。',

                    '2018 年， 芯翼信息科技推出的NB - IoT系统SoC芯片XY1100， 是全球首颗片内集成CMOS PA的NB - IoT芯片， 具有超高集成度、 超低功耗、 灵活性强、 成本低等四大核心优势。 这款芯片率先以完全自主研发、 自主创新的架构， 突破了全球蜂窝通信芯片开发的集成度瓶颈， 首次流片即获成功、 顺利实现量产， 并引领了截止目前全球NB - IoT芯片的技术发展潮流。 目前已广泛用于智能表计（ 燃气表、 水表等）、 智能烟感、 资产追踪、 智能换电、 共享电单车管理、 智能家居等物联网应用场景。',

                    '同时， 公司自主研发的下一代NB - IoT系统SoC芯片XY2100， 以替代外置低功耗MCU的独家创新， 再一次突破了业界在片内集成独立式MCU的架构， 解决了在极致低功耗和极致睡眠唤醒时间的性能瓶颈问题。 目前， XY2100芯片已经研发成功， 预计将在不久后推向市场。 另外， 科技部项目支持的片内集成了NB - IoT / GNSS / BLE等的XY3100芯片， 可实现高性能、 低功耗、 多模块自适应切换以及低成本， 预计明年商用。 除此之外， 公司还可针对物联网不同应用场景的差异化需求， 为客户提供定制开发服务。',

                    '除了NB - IoT系列产品之外， 高度集成的Cat .1 系统SoC芯片也正在紧锣密鼓研发中， 目前进展顺利， 预计将于明年推向市场。 同时， 随着5G时代的到来， 公司也已经率先投入5G中速的RedCap的研发。',

                    '展望未来， 满足物联网智能终端各项需求的系统SoC芯片将成为刚需， 并将拥有比物联网连接芯片更加广阔的市场空间。 芯翼信息科技创始人兼CEO肖建宏博士表示：“ 芯翼信息科技的核心研发团队大部分来自海内外知名的芯片设计公司， 具有丰富的研发经验， 同时我们有着自初代产品以来形成的技术创新精神， 因此， 我们独家提出了矢志成为业界领先的物联网智能终端系统SoC芯片企业的愿景。 在新一轮融资的加持下， 公司将一如既往的开拓创新、 引领发展， 为传统行业智能化、 物理世界数字化升级赋能， 为共同富裕和美好生活赋能。”',

                    '中金甲子创始团队成员、 执行总经理周伟表示： 物联网智能终端芯片未来将融合感知、 通信、 边缘计算、 安全、 节能等多维能力， 使得数据的传输和使用实现闭环， 将更多商业价值体现出来。 芯翼信息科技兼具国际顶尖的芯片研发能力与产业化经验， 我们相信公司在创始人肖博士的带领下， 将抓住这一历史性的机遇、 不断取得突破， 推动中国经济的数字化转型升级。',

                    '招银国际执行董事汪灿博士表示： 广域的中低速物联网终端将成为继PC、 手机、 汽车之后下一个海量终端市场， 芯翼信息科技团队在初代产品NB - IoT领域展现出极强的技术功底、 创新精神和落地能力， 我们看好其成为全球广域物联网终端系统SoC领导者的潜力。',
                ]
            },
            {
                name: '南芯半导体',
                type: 2,
                link: 'http://www.southchip.com'
            },
            {
                name: '领筑智造',
                type: 1,
                title: '「Indeco」完成C轮2000万美元融资，致远资本担任独家财务顾问',
                content: [
                    '国内一站式互联网空间解决方案供应商inDeco领筑智造， 近期已完成2000万美元的C轮融资， 本轮融资由斯道资本领投， 两家全球500强企业： 化工企业巴斯夫公司和建筑材料企业圣戈班集团跟投， 老股东源码资本、 高利盛竹继续加码， 致远资本担任独家财务顾问。',
        
                    '2018 年， 公司曾获得源码资本领投， 真格基金、 光大安石、 巢生资本跟投的A轮融资， 19 年初完成由彤程新材、 艾想投资、 高利盛竹、 上海千章等机构参与的B轮融资。 加上本轮， 总融资额2 .4 亿元， 是目前已公开披露的信息中， 互联网公装行业融资金额最多的企业。',
        
                    '传统公装一直以来都面临着两个问题： 一是对人的依赖程度高， 同时又面临工人老龄化、 用工荒等问题； 二是信息化程度低， 施工管理过程基本上靠手动填写表格来完成。 这两个问题导致整个行业利润较低且难以形成规模化效应。',
        
                    'inDeco领筑智造的互联网公装解决方案通过技术手段为生产环节降本增效， 解放个人生产力。 公司的技术发展大致会经历三个阶段：',
        
                    {
                        type: 'bold-mb0',
                        text: '· inDeco 1.0：实现业务流程全面线上化，信息化'
                    },
        
                    'inDeco在2019年上线“内部BIM“系统，实现业务流程全面线上化，包括商务系统、工程管理系统、供应链系统、成本系统等，实现一键式报价，一键式发包等的业务动作，同时解决因为项目服务流程长、时间长导致的前后信息不一致。流程线上化可以很大程度上推动项目管理及服务标准化，而在系统中沉淀下来的数据，也将为下一步自动化解决方案打下基础。',
        
                    {
                        type: 'bold-mb0',
                        text: '· inDeco 2.0：加速研发一站式自动化解决方案'
                    },
        
                    '科学的内部流程可以极大的提高人效。 对此， inDeco将加速研发公装一站式自动化方案， inDeco 创始人Randy介绍， 今年4月份会推出施工图自动化设计系统， 5 月份会推出自动化报价系统和排期系统， 今年会加速整个平台实现自动化。',
        
                    '在生产环节， 公司也尽可能利用自动化技术， 减少纯机械性或纯体力工作。 比如， 在设计环节， 利用AI图像识别技术将客户需求转化成设计语言， 在后台跟供应链系统打通， 系统根据客户喜欢的风格自动识别材料， 提升设计师选型效率。',
        
                    {
                        type: 'bold-mb0',
                        text: '· inDeco 3.0 智能化布局，进一步降本增效'
                    },
        
                    '传统装修对人的依赖很大， 不确定性高，“ 去人工化” 是解决行业问题的关键。 Randy认为， 智能硬件可以代替其中部分工作的基础环节。 比如， 在场地测量环节， 相较于传统量房模式， 依靠3D扫描设备可以节约2个人力和一半的时间， 测量结束后同步生成图像， 并在后期直接与供应链打通， 快速进入生产。',
        
                    '目前， 公司总客户数在1500个， 地域主要分布在北京、 上海、 深圳。 互联网行业客户居多， 也有像海底捞这样的连锁餐饮客户。 今年， 公司会将业务拓展至成都。 平均来看， 公司的客端价在80万元， 平均项目交付周期在35天左右。',
        
                    '在收入利润方面， inDeco预计今年营收会在7亿左右， 并有望实现盈利。 在过去三年， 公司的营收都实现了翻倍的增长， Randy认为， 实现这一增长的核心要素在于inDeco为客户提供的业务价值清晰， 老客户复购率高， 并且通过口碑传播和市场营销， 做到了持续的获客。',
        
                    '团队方面， inDeco员工已有260人， 其中研发团队占20 % ，此次融资的40 % 将用于技术人才和相关研发工作的投入。 30 % 的资金用于搭建供应链。 其余将用于市场开拓和品牌建设。',
                ]
            },
            {
                name: '秒优科技',
                type: 1,
                title: '「秒优科技」完成Pre-A轮5000万融资，致远资本担任独家财务顾问',
                content: [
                    '秒优科技已完成5000万元Pre - A轮融资， 投资方为绿洲资本， 天使轮投资机构源码资本追投， 致远资本担任独家财务顾问。 本轮融资资金， 秒优科技将主要用于对服装云工厂相关产品和技术的研发， 和市场团队的搭建。',

                    '秒优科技是一家服装领域提供智能制造解决方案的企业， 成立于2009年。 早年以管理咨询 + 软件服务起步， 逐步形成了全套智能制造解决方案， 包括服装云供应链管理系统、 服装智能制造执行系统（ MES）、 服装标准工时系统（ GST） 等， 作为软件厂商服务的服装生产企业累计达200余家。',

                    '自2019年起， 秒优科技试水新业务—— 云工厂， 整合多家服装生产企业的厂能， 通过承接服装品牌的订单， 将订单拆分给合作的工厂， 从而建立起具备柔性快反能力的服装供应链网络， 最快 "7天"可完成一款新品的设计生产交付周期， 而行业内的顶尖水平维持在21天。',

                    '随着服装领域电商渠道、 电商品牌的兴起， 以及消费者个性化消费的趋势加强， 服装行业对供应链提出了新需求—— 柔性、 快反， 前者要求灵活生产， 后者要求小批量。',

                    '而秒优科技的思路， 正是通过云工厂进行社会产能的整合与调度， 提高整个供应链的柔性和响应速度。',

                    '具体来说， 基于过去十年秒优科技服务服装生产企业的经验和数据， 掌握着大量服装品类、 工艺、 企业生产特征等信息， 秒优科技通过订单集约化之后， 将工艺重合度高的品类集中分配给专业化工厂进行生产。 由于成衣的工序复杂多样， 为了能够通过算法对订单进行整合归类， 秒优科技将成衣生产工艺拆分成118个标准动作并打上标签， 从而进行同类项合并。',

                    '咨询出身的秒优科技擅长通过精益流程管理为企业提效。 罗建军指出， 在服装企业里， 从设计打样、 面辅料采购到生产、 物流， 要经历近四十个环节， 差不多有十几个部门协同合作， 而在秒优科技过去的成绩里， 平均能通过整套解决方案为企业提效20 % -30 % ，最早甚至达到100 % 。',

                    '除了生产环节的效率提升， 秒优科技同样重视全流程的效率提升， 为了配合云工厂这一新业务的发展， 秒优科技正逐渐从软件业务中抽调人员进行轻量化SaaS的研发， 提供给生产企业、 电商服装企业使用， 优化工厂原有的生产排单系统和服装企业内部的供应链管理系统， 从而将以往串行工作流调整为并行工作流。 罗建军认为， 通过一整套解决方案的应用， 秒优科技有望将生产周期从7天压缩至5 - 6 天。',

                    '罗建军表示， 市场上对能够有质量实现柔性快反的供应链有强烈需求， 而供给端无法满足， 因而秒优科技的 "7天"效率就是强有力的支点。 而效率的维持与进一步提升上， 秒优科技优势正在于过去十年积累了庞大的数据基础， 并能够基于对服装行业的了解， 对数据进行清理和建立算法模型， 真正发挥数据的价值， 同时， 在秒优科技的团队中， 有大量来自华为、 腾讯的产品和技术人员， 这样的团队组成在市场上本身是稀缺的。',

                    '在市场方面， 以往秒优科技主要通过口碑获客。 罗建军认为， 2 B生意中产品是根本， 通过营销拉新的客户如果缺少承接能力， 则毫无意义。 因而秒优科技会重点打磨产品和技术， 让长处更长， 形成马太效应， 但与此同时， 秒优科技也引进了一位市场方面的副总裁。',

                    '绿洲资本张津剑表示：“ 过去互联网是先有规模再有质量， 今天产业是先有质量再有规模。 很多产业里面十年磨一剑的质量公司迎来规模红利， 秒优科技是一个典型代表。”',
                ]
            },
            {
                name: '斗象科技',
                type: 1,
                title: '「斗象科技」完成数亿元C轮融资，致远资本担任独家财务顾问',
                content: [
                    '斗象科技近日宣布完成C轮数亿元融资， 由钟鼎资本领投， 同创伟业、 惠友资本、 云栖创投、 线性资本跟投， 致远资本担任独家财务顾问。 本轮融资后， 斗象科技将进一步推进智能网络安全产品矩阵的研发和服务能力提升， 加速数据中心至云端的解决方案覆盖， 加大力度打造行业社区平台开放生态。',

                    '斗象科技为用户提供互联网化的网络安全服务以及智能安全分析产品解决方案。 业务包括网络安全众测服务平台“ 漏洞盒子”， 全息安全监测与数据分析产品“ 斗象智能安全平台”。 斗象科技旗下还运营着老牌网络安全社区“ FreeBuf”， 积累了70万网安从业者用户， 形成了广泛的行业影响力和良好的业界口碑， 是全球知名的网络安全技术社区领导品牌。',

                    '截至2019年底， 斗象科技的产品与服务已经广泛应用于金融、 互联网、 零售、 航空、 物联网 & 车联网、 政府等多个领域超过600家大中型客户， 为众多行业龙头加筑网络安全防线， 为企业数字化转型保驾护航， 为政府信息化安全保障提供支撑。 通过创新的产品服务与过硬的技术实力， 斗象科技在2014年创立至2019年间， 营收实现每年超过90 % 的复合增长率， 斗象的业务增速和客户数量持续领跑同行业创业厂商。',

                    '斗象科技致力于将服务客户的一线经验， 快速落地转化为解决方案。 2019 年， 斗象科技围绕PRS - NTA全流量大数据智能安全分析系统、 ARS漏洞智能扫描系统、 CRS云端安全中心等多款产品构建的智能安全平台， 与“ 白帽专家驱动” 的漏洞盒子安全众测服务、 VMS漏洞管理平台等产品实现数据交融打通， 在当下“ 重攻防， 重实战” 的行业趋势背景下， 帮助客户切实提高网络安全攻防实战能力， 显著提升威胁分析、 风险数据挖掘以及自动化安全运营能力。',

                    '对于此次C轮融资， 斗象科技CEO谢忱表示：“ 我们很荣幸得到新老股东的认可与支持， 斗象将始终以客户第一为己任， 进一步完善产品服务体系， 打造兼顾创新与落地性的行业解决方案， 成为新一代网络安全领头羊。”',
                ]
            },
            {
                name: '住逻辑',
                type: 2,
                link: 'https://www.zhulogic.com'
            },
            {
                name: '51World',
                type: 2,
                link: 'https://www.51aes.com'
            },
            {
                name: '优地科技',
                type: 1,
                title: '「优地科技」完成数千万B2轮融资，致远资本担任独家财务顾问',
                content: [
                    '深圳优地科技有限公司（ 以下简称： 优地科技） 今日宣布完成数千万B2轮融资， 投资方为上海索道投资管理有限公司， 致远资本担任独家财务顾问。 本轮融资资金将用于产能扩充、 市场拓展， 加速优地科技在室内配送领域的市场拓展及室外配送的落地应用。',

                    '深圳优地科技有限公司成立于2013年3月， 是全球领先的配送服务机器人制造商， 也是业内最早实现无人驾驶设备量产商用的企业之一， 其自有品牌机器人已经遍布全国50多个城市， 为近千家商业场所， 提供平均每天超50000人次的引领、 配送等服务。 同时， 优地科技也已经利用其成熟的技术， 为众多业内公司提供机器人行走方案。',

                    '目前， 优地科技已推出的产品有： 楼宇配送机器人、 酒店配送机器人、 KTV配送机器人、 送餐机器人、 室外配送机器人， 截止2019年第三季度， 已累计上线运营的机器人数量突破1500台。“ 优小妹” 和“ 优小弟” 在KTV场景的市场占有率雄居第一。 室外机器人“ 优小哥” 自2018年推出市场以来， 已展开多项落地测试， 目前已升级至第三代产品， 正在进行5G落地应用的实测。',

                    '据中物联 & 美团点评发布的《 2018 中国即时配送行业发展报告》 显示， 中国即时配送行业2017年年收入突破800亿元， 订单量89亿件， 预计2018年订单量将超过120亿件； 2017 年全年中国外卖市场交易额近3000亿元， 预计2018年将突破3600亿元。 随着人力成本上涨， 即时配送面临着派送人员流动性大、 管理困难、 供需平衡难以匹配等困难和问题， 优地科技认为， 在100米—— 3000 米的末端配送场景内尚无服务机器人独角兽企业， 但是4000亿劳务市场 / 年的前景是非常可观的， 人机协作不仅可以提升效率， 也可以从一定程度上降低配送成本。',

                    '上海索道投资管理有限公司创立于2016年， 专注于投资布局人工智能、 智能机器人领域， 具有持续发展潜质的大赛道和赛道中领先的团队， 通过前瞻性的产业布局形成被投企业之间的协同效应。',

                    '索道投资石总认为“ 室内配送本质上是自动驾驶的一个分支技术落地场景， 而且目前看来是最先落地的场景， 未来通过配送机器人网络， 甚至会形成类似互联网一样的网络效应， 带来流量、 数据、 供应链整合的强大壁垒和竞争优势。 而优地科技来自UT斯达康， 具有成熟的管理团队， 因此在短短的3年内就完成了在KTV和酒店场景的大批量场景落地， 在此过程中我们看到了团队对行业的深刻理解， 具有强大的市场推广和团队组织能力。 我们非常看好优地科技未来能够成为具有综合配送能力的机器人配送公司， 而不仅仅只是一家机器人产品研发生产公司”。',

                    '优地科技董事长卢鹰表示“ 我们从2013年成立至今， 获得了天使轮、 A轮、 B轮和本次的B2轮融资， 感谢投资人的认可。 我们看好机器人在未来的整个产业布局， 也坚信我们定位于室内外配送领域的方向是正确的， 相信由此将带来真实的经济效益和社会价值。 我们的使命是“ 让广大的劳动者不再劳累”， 而我们也将继续保持着激动之心、 敬畏之心、 专注之心， 做好产品与服务， 坚定地追寻人工智能造福人类的伟大梦想！”',

                    '优地科技曾于2018年4月获得元禾润新和君联资本联合领投的数千万B轮融资， 2016 年9月获得北京新恒基投资管理集团有限公司(简称新恒基) 及科大讯飞的数千万A轮融资， 2013 年获得来自UT斯达康的天使轮。',
                ]
            },
            {
                name: '扩博智能',
                type: 1,
                title: '「扩博智能」完成Pre-B轮融资，致远资本担任独家财务顾问',
                content: [
                    '全球领先的机器视觉技术公司扩博智能近期宣布完成PRE - B轮2, 200 万美元的融资。 本轮融资由CMC资本（ CMC Capital） 和中华开发（ CDIB） 领投， GGV纪源资本、 开发国际、 首建投资本跟投， 致远资本担任独家财务顾问。 融资完成后， 扩博智能将持续推进以机器视觉技术为核心的产品服务， 在零售、 风电新能源等领域继续纵深拓展， 此外进一步加码在海外市场， 特别是美国和欧洲市场的拓展， 服务更多优质的全球客户。',
        
                    '同时， 原中国国务院参事， 原科技部党组成员、 秘书长, 中国可再生能源学会理事长石定寰先生加入扩博智能公司的战略委员会， 未来将在新能源领域的战略规划、 科技推动等方面为扩博智能添砖加瓦。',
        
                    {
                        type: 'mb0',
                        text: '成功并非侥幸“ 技术领先” 第一位'
                    },
                    '目前， 扩博智能已经在人工智能技术赋能传统业务上实现了规模化、 产品化、 商业化、 效能化的横向落地和纵深发展。 在“ AI + 场景” 引领市场的背后， 是实力的体现—— 拥有多项自主知识产权的技术， 申请及获得相关专利80余项。 同时， 扩博智能“ 软硬结合” 的技术实力独树一帜， 不论是零售还是风电新能源领域， 自主可控的硬件作为数据采集的入口， 保障提供高质量的数据， 软件作为处理和服务的载体， 同时搭配硬件为用户提供一个“ 端到端” 的解决方案。 而扩博智能的半自动化人工智能标注学习系统， 则比传统纯人工辅助方法效率提高了40倍。',
        
                    '比起“ 无所不能” 的人工智能概念， 将人工智能真正在传统行业落地才是硬道理， 扩博智能通过与产业深度结合， 利用先进技术来解决真正的业务痛点， 而不是创造痛点， 这一点着实不易。',
        
                    {
                        type: 'mb0',
                        text: 'AI + 多个场景落地 掷地有声'
                    },
                    '扩博智能主要业务涵盖风电新能源和零售两大版块。 在风电领域， 扩博智能自主研发了全球首个基于无人机全自动飞行与机器视觉技术的风机叶片巡检数据采集和数据服务平台—— 通过特种飞行机器人与人工智能技术的有效结合， 能够100 % 全自动完成巡检， 相比传统方式效率提升10倍。 目前该套解决方案已经服务于欧洲和中国地区的客户， 并正在美国地区积极拓展业务。',
        
                    '而在零售行业， 公司通过人工智能技术， IoT边缘计算， 和商业模式帮助传统零售行业实时获取动态市场数据， 商品识别准确率达95 % 。零售行业的数字化进程虽然今非昔比， 厂商、 零售商和消费者都仍各自苦于“ 语言不通、 渠道不畅”， 造成的信息不对称、 欠流动、 少透明度， 各方都因此损失了时间、 机会， 效率与体验。 扩博智能的智慧零售方案以“ 机器视觉 + 智能硬件” 为技术核心， 对图像快速准确解析、 智能分析和实时沟通， 搭建起产品、 店铺与消费者之间的智慧桥梁， 实现三赢。 零售业务的解决方案目前服务于中国、 东南亚， 以及北美等地区的客户， 并在积极拓展南美市场。',
        
                    {
                        type: 'mb0',
                        text: '从中国起步 拓展全球'
                    },
                    '在过去的两年， 扩博智能已成功在包括可口可乐、 沃尔玛、 宝洁等多个国际知名快消及零售品牌的全球多个地区落地。 此外， 公司也成功与欧洲最大的风机运维商之一GEV达成战略合作， 与全球最大的风电整机厂商西门子歌美飒计划共同拓展欧洲、 北美、 非洲等国际市场， 通过计算机视觉新技术改变未来风电市场国际运维格局。',
        
                    '值得一提的是， 扩博智能所服务的全球知名客户要求都很高， 但场景的可复制性更高， 因此集中资源专注服务好头部企业， 满足客户在全球范围内更多的需求， 提高服务质量， 才能用标准化的高质量服务来吸引更多企业用户。',
        
                    '在扩博智能成立不到三年的时间已获得总计4, 300 万美金的融资投入， 未来扩博智能也将会继续在零售、 风电新能源等领域继续纵深拓展， 使用AI技术与传统场景的结合， 进一步提高产品和服务的客户价值。',
                ]
            },
            {
                name: 'NOLO HOME',
                type: 1,
                title: '「NOLO VR」完成亿元级A+轮融资，致远资本担任独家财务顾问',
                content: [
                    '近日， VR交互科技公司NOLO VR宣布完成亿元级A + 轮融资， 本轮融资由愉悦资本领投， 蓝驰、 盛景嘉成跟投， 致远资本担任独家财务顾问， 本轮融资将主要用于VR交互技术的持续深耕和商业化拓展。',

                    'NOLO VR成立于2015年， 专注于VR交互技术领域， 目前， NOLO 在VR交互技术上的研发投入累计超过一亿元， 已拥有海内外专利125件， 国际专利已进入12个主流国家。',

                    '产品方面， NOLO VR开发了6DoF Outside - in VR交互硬件NOLO CV1， 同时开发了搭配该硬件产品使用的软件平台NOLO HOME。 NOLO CV1可以适配市面上主流的手机VR头盔、 PC VR头盔以及VR一体机头盔， 主要应用于对价格敏感、 隐私敏感、 功耗敏感、 算力占用敏感、 位置相对固定的VR应用场景， 以家庭、 办公室等C端场景为主。 通过该产品， 用户可以无线便捷地畅玩Steam VR游戏， 而价格在一两千元量级。',

                    '该硬件产品其核心技术在于PolarTraq® 声光电定位技术， NOLO VR称该三维空间定位技术能同时实现大范围、 高精度、 高鲁棒、 低功耗、 低延迟、 小体积、 高性价比的特性。',

                    '同时， NOLO VR规划开发适用于大范围多人应用场景的6DoF Inside - out、 3 DoF以及全身动作追踪套件的产品， 据悉， 此新品将在不久后上市。',

                    '客户方面， NOLOVR已与Google、 华为、 小米、 Pico、 爱奇艺、 创维、 大朋等众多产业链合作伙伴展开合作， 以发展C端市场。 在教育、 文旅、 地产等B端行业也同样有应用。 同时与中国移动、 中国电信的合作也已在多省市落地执行。 目前， NOLO VR用户已经遍布全球70多个国家和地区。',

                    '谈到VR行业的发展前景， NOLO VR CEO张道宁认为， VR屏幕必将和电脑屏、 手机屏、 电视屏、 手表屏、 汽车屏一样， 成为5G时代的泛在显示器， 成为重要的基础设施。 在VR屏幕中， 有6DoF交互需求的可能占到20 % 左右， VR交互将成为一个非常垂直的细分赛道。“ 一两年内将会有大量轻薄、 便携、 时尚、 便宜的VR眼镜面世， 随着5G时代的来临， VR市场即将迎来几十倍的爆发式增长。” 他说。',

                    '团队方面， NOLO VR的核心研发团队由来自中科院、 北邮的博士领衔。',
                ]
            },
            {
                name: '高仙机器人',
                type: 1,
                hasSub: true,
                title: '「高仙机器人」完成 B 轮亿元级融资，SLAM 机器人年产量将超 1000 台，致远资本担任独家财务顾问',
                content: [
                    '截至2017年， 全国60周岁及以上人口已达24090万人， 占总人口17 .3 % ，预计2025年六十岁以上人口将达到3亿， 届时中国将成为超老年型国家； 与此同时， 我国又一直处在城镇化发展阶段—— 房地产行业经历了高速建设期之后， 交付了超过200亿平米的城市建筑， 这些巨量面积的楼宇、 社区、 园区亟需要专业的服务和管理运营...高仙机器人认为， 机器人和无人驾驶则能够帮助物业行业实现服务场景中简单重复性岗位的自动化和机械化。',

                    '3 月 19 日，「 高仙机器人」 宣布已完成亿元人民币 B 轮融资。 本轮由远东宏信集团旗下远翼投资领投， 风险投资基金 KIP 中国跟投， 上轮资方蓝驰创投继续跟投， 致远资本担任独家财务顾问； 不到一年前， 高仙曾获融由蓝驰创投领投、 七海跟投的 A 轮千万级美元。',

                    '值得一提的是， 远东宏信是国内头部的第三方融资租赁服务提供商， 此次远东宏信旗下远翼投资的参与， 将为高仙在金融与产业端的发展提供帮助。',

                    '创始人兼 CEO 程昊天告诉36氪， 本轮资金主要用于提升产能以及产品研发， 2019 年预计生产超过 1000 台清洁机器人， 销售额将超 2 亿元。 此外， 高仙清华大学成立了联合实验室， 将针对楼宇、 安防等场景的 SLAM 和深度学习技术展开前沿性的研究。',

                    '36 氪此前曾报道， 成立于 2013 年的高仙机器人， 是一家以 SLAM 技术研发和相关应用开发为主要业务的企业。 2014 年， 高仙推出了第一台商用清洁机器人， 并于两年内在以新加坡为主的海外市场实现了商用落地。',

                    '截至 2018 年， 高仙商用清洁机器人已完成五轮迭代， 为 7 个国家、 200 多个客户在提供了超过 25 万多公里的稳定运行服务； 较 2017 年相比， 高仙营收实现了 400 % 的年增长， 且已基本实现盈亏平衡。',

                    '在去年的采访中， 程昊天曾向36氪介绍了高仙的“ 三条发展路线”， 即自主品牌清洁机器人、 安防解决方案、 楼宇配送。“ 自主品牌机器人和安防解决方案的发展很顺利， 仅高仙自研的定位导航模块， 在 2018 年就实现了超过 1 万套的量产。 这样的量产规模， 也确保公司在物流配送等其他新领域上， 能够不断探索、 积累业务理解及场景数据， 让底层技术向深度和广度两个方向的迭代。”',

                    '楼宇配送方面， 由于针对自动驾驶机器人的楼宇基础设施（ 例如电梯） 的改造限制较多， 所以其发展相对于清洁和安防方向稍慢些， 但高仙仍在努力与相关部门、 地产商协同克服困难。',

                    '程昊天表示， 目前公司员工约有 260 人， 其中 40 % 为开发人员， 而企业的产品则以达到国内领先水平。 未来， 高仙还将基于已覆盖的场景， 挖掘更多额外的商业价值。',

                    '“ 举例来讲， 我们可以通过为商超中的清洁车加入更多传感器， 并开发相关算法， 为客户提供夜间灯光管理、 温湿度监测、 货品盘点等附加功能， 因为现阶段来看， AI 技术的成熟会使其应用更加碎片化， 所以即使我们将清洁机器人做好， 这个行业仍然拥有机会。',

                    '自 2019 年起， 高仙将在北京、 上海、 新加坡建设 3 座研发中心， 加强与包括清华大学、 上海交通大学、 新加坡国立大学等在内的学术研究合作， 并借力包括芯片、 传感器在内的上游技术商， 实现具有生态意义的技术战略布局。',
                ]
            },
            {
                name: '晓羊教育',
                type: 1,
                title: '「晓羊教育」完成1.5亿B轮融资，致远资本担任财务顾问',
                content: [{
                    type: 'mb0',
                    text: '近日， 基础教育科技创新公司晓羊教育宣布完成近1.5 亿元人民币B轮融资。 本轮由新东方教育文化产业基金、 新东方教育科技集团、 华创资本共同领投， 老股东云启资本、 阿米巴资本跟投。 本次融资由致远资本担任财务顾问。',
                },
                {
                    type: 'mb0',
                    text: '晓羊教育成立于2016年5月， 是中高考改革走班教学整体解决方案提供商和国内基础教育信息化技术创新企业。 公司致力于K12教育领域科技创新， 以旗舰产品“ 一人一课表” 为切入点， 整合学校教育基础数据， 构建教育大数据新生态。',
                },
                {
                    type: 'mb0',
                    text: '公司创始人兼CEO周林博士公司有着近二十年丰富的技术及企业级软件管理经验， 国际团队管理经验， 战略规划以及商业营销经验。 创办晓羊教育之前就职于全球最大教育软件公司Blackboard, 任全球副总裁。 公司其他高管均在各自领域深耕数十年， 具备丰富管理、 销售经验和资深教育背景。 目前公司团队近200人。',
                }, {
                    type: 'mb0',
                    text: '2014 年9月， 国务院发布《 关于深化考试招生制度改革的实施意见》， 新高考序幕在国内拉开。 晓羊教育研发团队响应国家政策和教育一线需求， 推出“ 一人一课表”、 AI BOX以及智慧校园2 .0 等系列产品， 帮助区域教育局、 学校和教师提升教育管理和教学水平。',
                }, {
                    type: 'mb0',
                    text: '智慧校园2 .0 系统以课表数据为核心， 采用软件微服务、 硬件微模块的架构开发， 在隐私保护、 数据安全、 系统稳定、 高并发支持等方面均处于国际领先水平。',
                }, {
                    type: 'mb0',
                    text: '晓羊教育的发展愿景为将排选课作为切入点， 通过先进的大数据处理技术对基础数据进行收集、 交换、 分析， 为区域教育及信息化发展提供决策支撑， 进而建设整体智慧校园， 最终打造覆盖全用户、 全方位的教育生态， 成为未来智慧教育的领航者。',
                }, {
                    type: 'mb0',
                    text: '截至目前， 晓羊教育业务已覆盖北京、 天津、 四川、 山东等全国大部分地区1600余所学校， 包括北京市第三十五中学， 潞河中学， 成都七中， 福建师大附中， 天津第二南开学校， 深圳实验学校等全国知名学校。 未来晓羊教育将会在包括海外市场的更广泛领域， 继续用科技的力量助力教育信息化。',
                },
                {
                    type: 'mb0',
                    text: '本轮融资将主要用于支撑公司技术研发投入和优质人才的进一步引进， 同时为公司产品迭代和提升用户体验提供充足的资金保障， 使晓羊教育在未来获得更加快速及持续、 稳定的发展。',
                }, {
                    type: 'mb0',
                    text: '晓羊教育创始人兼CEO周林博士表示， 在党中央、 国务院发布《 中国教育现代化2035》 的宏大背景下， 晓羊教育通过对教育数据的集成、 管理与分析， 创新大数据应用技术， 持续推进信息技术与教育教学的深度融合。“ 一人一课表” 只是一个起点， 基于这个起点， 晓羊教育将整合各种资源类、 管理类、 教学类数据信息， 成为开放的数据互通共享平台， 进而打造基础教育大数据新生态。',
                }, {
                    type: 'mb0',
                    text: '对于本轮战略投资， 华创资本管理合伙人吴海燕表示：“ 随着新高考改革的逐步试点、 推进， 全国公立中学对于以智能排选课系统为核心的校园信息化、 智能化需求与日俱增。 晓羊教育由一群教育行业加IT行业老兵创立， 在对学校需求的理解、 产品研发、 深度服务上都有着丰富的实践经验， 其产品目前已是全国广大公立中学响应新高考改革的首选， 拥有广泛的成功实施案例。 我们相信晓羊教育将会成为‘ 校园信息化’ 的领头羊企业。”',
                }, {
                    type: 'mb0',
                    text: '新东方掌门人俞敏洪也高度重视本次投资， 他认为：“ 人工智能的发展， 在教育上拥有广阔的应用前景。 晓羊教育以先进的人工智能、 大数据、 云计算技术， 顺应国家基础教育改革的发展趋势， 打造K12的新型教育生态， 是教育现代化的积极推进者和领头羊。 晓羊教育的发展模式非常清晰， 针对区域和学校都有非常好的产品服务， 与新东方的业务有很好的契合度， 本次投资是新东方在教育信息化领域的重要战略布局， 必将为基础教育的改革创新注入新的活力！”',
                },
            ]
            },
            {
                name: '洛丁森',
                type: 2,
                link: 'http://www.rocksensor.com.cn'
            },
            {
                name: '智齿科技',
                type: 1,
                title: '智齿科技完成1.5亿元B+轮投资，致远资本担任财务顾问',
                content: [
                    '智能云客服平台提供商“ 智齿科技” 宣布完成 1.5 亿人民币 B + 轮融资。 本轮融资由云启资本领投， 耀途资本、 上海原龙投资、 博雅盛景、 林洪瑞丰投资跟投， B 轮投资方界石投资继续跟投， 致远资本担任财务顾问。',

                    '此前， 智齿科技曾获得过真格基金、 华创、 IDG、 拓尔思等风险投资和产业资本青睐， 已完成天使轮、 A 轮、 B 轮、 B + 轮四次融资， 累计融资金额近 2.4 亿人民币。',

                    '智齿客服成立于 2014 年 5 月， 专注于提供新一代的全场景智能全客服解决方案， 通过基于云端的通用客服平台帮助企业服务于来自网站、 微信、 微博、 App、 电话、 邮件等多渠道的客户， 致力于通过人工智能技术解放客服生产力， 帮助企业客服提升服务体验和销售， 降低成本。 截止至 2018 年 3 月， 已经服务了包括滴滴出行、 乐视、 宜信、 翼龙贷、 阳光保险、 新东方、 学而思、 蓝港互动、 EMS、 纷享销客等 26 个细分行业的 80000 + 家企业客户。',

                    '智齿科技 CEO 徐懿透露： 在客户拓展层面， 截至 2018 年 3 月， 智齿客服注册企业用户超 80000 家， 其中近 2500 家付费客户， 覆盖 26 个细分行业的标杆客户； 业务数据层面， 智齿客服平台每周业务交互量为 15 亿次， 积累了大量细分行业用户对话数据， 智能对话能力的训练和表现出众； 销售数据连续 3 年保持 3 - 5 倍增长， 中大型客户客单价从 2 万提升至 5 万， 并持续增长， 客户留存率为 80 % ，金额复购率达 120 % -150 % 。',

                    '徐懿在接受采访时表示， 本轮融资之后， 智齿将把更多的资源和精力投入于内功修炼。 具体强化以下三个方面： 一是加大研发的资源投入， 主要在 AI、 BI 技术研发引进及场景适用性的结合； 二是加大人员优化和能力提升， 进一步优化和提升人员的专业能力和素质； 三是运营及服务体系的优化完善， 智齿将全面提升运营和服务水平， 进一步理顺服务机制并强化客户成功部门的服务能力， 为客户带来更多增值服务。',

                    '云启资本董事总经理陈昱表示， 智齿客服运用自然语言处理技术， 构建了以机器人客服和云呼叫中心为代表的客服产品矩阵， 提升了客户体验， 同时降低了企业客服用工成本， 是云启在企业服务领域一直寻找的用技术提高商业效率的投资机会， 云启看好智齿团队的产品和技术商业化能力。',

                    '耀途资本创始合伙人杨光也表示， 智能客服可以大幅提升国内客服行业的效率， 同时也是语义识别和智能对话等人工智能技术最好的落地应用场景， 市场发展前景巨大。 智齿科技作为智能客服行业的领军企业， 拥有出色的智能客服产品和技术， 清晰的商业化战略， 以及年轻且充满活力、 综合实力均衡的创业团队， 很有幸能参与本轮投资。',

                    '未来， 智齿客服将通过渠道融合、 功能融合、 数据融合， 打造全流程闭环的智能全客服体系； 通过对海量录音和文本类客服数据的挖掘， 提高对客户需求和行为的预测性； 运用图像识别、 声纹识别、 指纹识别、 人脸识别等生物识别技术以及智能化知识库将帮助企业扩张其服务内容范围， 为客户提供更个性化的服务和营销方案。',
                ]
            },
            {
                name: '酷家乐',
                type: 1,
                hasSub: true,
                title: '酷家乐完成1亿美元D轮融资，致远资本担任独家财务顾问',
                content: [
                    '3 月9日， 家居云设计平台酷家乐宣布完成1亿美元D轮融资， 该轮融资由顺为资本领投、 淡马锡旗下Pavilion Capital跟投， 老股东IDG资本、 GGV纪源资本、 云启资本、 赫斯特资本、 线性资本继续跟投， 致远资本担任独家财务顾问。 本轮融资完成后， 酷家乐估值将达到6亿美金。',

                    '顺为资本合伙人程天表示：「 我们非常看好全球家居行业的巨大市场和酷家乐在国内家居设计行业的领先地位。 酷家乐的智能设计工具拥有图像渲染速度快、 操作便捷、 学习门槛低等独特优势， 能显著提高行业效率、 获客能力和盈利能力， 改善业主的装修设计体验。 顺为资本很高兴能与酷家乐团队携手合作， 共同打造一站式家居服务生态链， 推动家居行业的信息化和互联网化。」',

                    '酷家乐于2013年11月正式上线。 作为全球最具创新体验的VR智能室内设计平台， 酷家乐致力于云渲染、 云设计、 BIM、 VR、 AR、 AI等技术的研发， 实现「 所见即所得」 的全景VR设计装修新模式， 5 分钟生成装修方案， 10 秒生成效果图， 一键生成VR方案， 彻底提升室内设计行业效率。',

                    '2017 年度， 酷家乐全年营收突破3亿元大关。 在规模上， 酷家乐已经成为全球最大的家居云设计平台， 它拥有覆盖全国90 % 的户型库， 吸引了超过300万室内设计师（ 覆盖全国40 % 的室内设计师） 和超1000万业主用户， 形成垄断式的用户壁垒。 平台日均生成100万张渲染效果图， 已累计生产2亿张效果图； 每日产生20万套装修设计方案， 已累计产生3000万套方案。 至今， 酷家乐已服务于小米、 美克美家、 林氏木业、 顾家家居、 博洛尼等12000家品牌企业， 市场覆盖率超过70 % 。',

                    '本轮融资完成后， 酷家乐将围绕两大战略重点进行布局： 构建行业生态和国际化战略。',

                    '第一， 把控「 设计入口」， 构建行业生态。 酷家乐通过智能云设计工具， 将上万家居品牌和300万设计师聚合在平台上， 牢牢把控了家居行业「 设计入口」。 在此基础上， 酷家乐持续进行生态开放， 全面对接设计、 生产、 营销、 施工、 落地等家居产业链的各个环节， 打造酷家乐智能云设计平台矩阵， 成为整个家居行业的操作系统。',

                    '第二， 国际化布局， 将「 所见即所得」 的体验模式普及到全球市场。「 国际化」 在酷家乐的战略版图中占据至关重要的位置， 一方面， 从市场层面考量， 全球的家居市场规模是2万亿美元， 布局全球市场可以扩大酷家乐的业务版图； 另一方面， 通过酷家乐这个设计入口， 将中国家居品牌直接对接到全世界终端消费者手中。 接下来， 酷家乐将率先进入美国、 东南亚和印度市场， 并针对海外市场推出行业领先的国际化产品。',

                    '致远资本非常高兴能够参与本轮融资， 相信酷家乐未来将全方位地渗透上下游产业链， 实现「 所见即所得」， 生态驱动全球2万亿美元家居市场。',
                ]
            },
            {
                name: '无讼',
                type: 2,
                link: 'https://www.wusong.com'
            },
            {
                name: '同盾科技',
                type: 2,
                link: 'https://www.tongdun.cn'
            },
        ],
        [
            {
                name: '鱼子酱文化',
                type: 3
            },
            {
                name: '天矢禾念',
                type: 2,
                link: 'http://www.thstars.com'
            },
            {
                name: '银河酷娱',
                type: 3
            },
            {
                name: '二更文化',
                type: 2,
                link: 'https://www.ergengtv.com'
            },
            {
                name: '知乎',
                type: 2,
                link: 'https://www.zhihu.com/explore'
            },
            {
                name: '今日头条',
                type: 2,
                link: 'https://www.toutiao.com/?wid=1632635642269'
            },
            {
                name: '映客直播',
                type: 2,
                link: 'https://www.inke.cn'
            },
            {
                name: '小学宝',
                type: 3
            },
            {
                name: '智课',
                type: 1,
                title: '智课教育完成2亿B+轮融资，致远资本担任独家财务顾问',
                content: [
                    '2017 年11月1日， 一站式国际教育服务机构「 智课教育」 宣布获得2亿元B + 轮融资， 投资方为国科嘉和基金， 致远资本担任本轮独家财务顾问。 至此， 连同B轮的2亿元融资， 智课教育整个B轮获得融资总计4亿元。 智课教育董事长兼CEO韦晓亮表示， 在高达64 % 的老客户推荐率和95 % 的学习目标达成率基础上， 将继续坚持智课教育方法论和留学服务方法论， 以学习效果和成长规划为服务核心， 全面升级出国留学服务行业， 打造更强更大的一站式国际教育服务平台。',
        
                    {
                        type: 'c-bold-mb0',
                        text: '两大方法论获资本肯定'
                    },
                    {
                        type: 'c-bold-mb0',
                        text: '智课落地SMART智能教学系统见实效'
                    },
        
                    '作为B + 轮的投资方， 国科嘉和基金认为教育行业不能一蹴而就， 技术起到辅助作用， 核心还是在教学内容研发及流程设计、 持续吸纳和培养优秀老师、 精细化的服务体系、 品牌的养护以及专业的企业运营管理。 智课对教育的本质理解比较深入， 人员配置， 产品设计及管理上非常综合， 勇于深耕细作， 有利于长期发展。 从2013年起， 5 年来智课一直在学习方法、 学习习惯和学习动力上不断实践， 探索分数、 能力与实践的变量关系， 以及最终呈现的学习效果， 从而形成了独创的智课教育方法论。 通过「 学、 练、 改、 测、 评」 + 「管」 6 个教学环节， 将师资内容与人工智能技术有机匹配， 让智课的价值渗透在每个教学环节。 在行业平均完成率是4 % 的情况下， 智课的线下翻转课堂课程完成率达97 % ，线上带人工辅导课程约80 % 。',
        
                    '同时， 团队用独有的留学服务方法论及海外智课布局， 把智课打造成了真正的一站式留学服务专家。 韦晓亮认为， 留学服务绝不是过去中介的一锤子买卖， 关系到学生及其家庭一辈子， 在升学服务上， 必须深入到专业、 职业的人生规划服务， 坚持精细化、 个性化和可视化。 智课拥有160位出国考试教学专家， 592 位全职批改外教， 与155位全职教学督导密切配合， 通过智课SMART人工智能教学系统， 融合线上线下多场景， 每天产生150万次教师与学员行为， 实现学生个性化学习路径规划。 智课每个学员都有专属的微信服务群， 群里有老师、 学员、 家长和服务PT， 智课教育服务的亲密度极高。 实践证明， 智课提分效果明显， 平均三个月学习后学生在托福提高21分、 雅思提高1 .5 分、 SAT提高289分。',
        
                    {
                        type: 'c-bold-mb0',
                        text: '全面升级出国留学服务行业',
                    },
                    {
                        type: 'c-bold-mb0',
                        text: '智课力推个性化长线的留学咨询指导和留学后服务',
                    },
        
                    '智课B + 轮的财务顾问致远资本创始人李悦表示， 智课精心打磨设计的课程服务体系， 强调教学效果的同时， 还致力于提高学员的学习效率， 而且覆盖全部出国考试科目， 非常难得。 在人工智能和教育的结合方面， 智课在业内率先推出了英语学习的智能适应产品， 真正做到因材施教。 韦晓亮也认为， 目前业内做营销的公司太多， 做内容、 做服务、 做技术应用的深度业务企业太少。 他透露， 智课一开始就选择了与众不同的业务路径， 先潜心打磨产品再做好业务， 将天使轮获得的95 % 融资资金投入到产品研发与内容建设上， 用一种耐得住寂寞的心态生长出差异化竞争能力。 2017 年拿到海外名校offer的同学， 康奈尔大学4位， 哈佛大学、 普林斯顿大学、 斯坦福大学各1位。 智课留学服务累计帮助300多位学员拿到海外名校offer， 其中TOP30学校高达65 % 。',
        
                    '近年来， 智课形成真正以个人需求、 个人问题解决为导向的个性化定制式的教学模式。 该模式能做到个性化课程规划、 全面收集学习行为大数据并实时评价学生能力， 涵盖八大出国考试（ 托福、 雅思、 新SAT、 GRE、 GMAT、 ACT、 AP、 A - Level）， 通过大数据联通线上线下教学， 高效纠错、 实时反馈， 并加入AI引擎， 运用算法和知识图谱， 构建教学者和学习者模型。 而留学后服务是更长线和高品质的业务， 智课着力整合国内与海外资源， 满足中国留学生及其家长的需求， 具体服务涵盖了留学生学习和生活的方方面面， 包括有学习管理、 法律咨询、 金融、 生活、 职业规划、 社交、 医疗、 出入境、 旅行…… 进军美国留学后服务的一年时间里， 智课成果显著： 一方面与77所美国高中、 44 所美国大学以及6所美国小学达成合作关系， 另一方面成为包括哈佛大学、 布兰迪斯大学和波士顿学院等顶尖高校教育系毕业生的指定实习基地。',
        
                    '此次融资发布会上， 作为智课教育产品的总设计师， 韦晓亮还认为， 真正高质量的长线留学服务， 是以学习提升、 人格及能力塑造为核心的高端留学咨询业务。 消费升级后的当下中国， 人们要的是教育资源的个性化和品质化， 智课经过4 - 5 的努力， 云集了一批韦晓亮领衔的国际教育权威专家， 以设计符合时代的产品为导向， 将引领留学服务行业的全面升级。',
        
                    {
                        type: 'c-bold-mb0',
                        text: '智课推出海啸计划：',
                    }, {
                        type: 'c-bold-mb0',
                        text: '掀起行业内容洗牌风暴',
                    }, {
                        type: 'c-bold-mb0',
                        text: '构建知识共享平台',
                    }, {
                        type: 'c-bold-mb0',
                        text: '多年坚持教育公益',
                    },
        
                    '经过5年努力， 智课已成为行业汇聚优质课程和名师资源的标杆平台， 坚持用感性的良心， 理性的科学做教育。 很多同行企业和机构， 都派人购买智课各种课程， 进行学习、 仿制。 2017 年下半年， 智课推出了海啸计划， 160 位顶尖名师、 7200 小时大片级课程、 12800 个知识点， 登陆智课名师课APP上分享， 就能免费学习这些价值1亿的名师专家课程。 这是业内首次全面开放名师课程资源， 不仅造福了广大出国留学的家庭和学生， 智课还从教学内容方面前所未有地拉升了全行业水平， 掀起了国际教育服务行业的内容升级风暴， 构建知识共享平台。',
        
                    '韦晓亮提出的「 教育是个良心活」， 智课团队一直努力践行。 内容建设以及翻转课堂的模式是智课创业布局的第一件事， 也是最重要的事。 在过去四年， 智课一直坚持科学的模式， 高质量的教学研发， 高亲密度的教学服务。 最终导致的结果是， 教学效率更高， 教学效果更好， 用户亲密度更强。',
        
                    '为了促进优质内容共享， 智课多年坚持参与公益， 积极推进「 智课教育公益计划」。 2014 到2016年， 智课捐赠价值5500万元的课程与服务， 受益学子近40万。 2017 到2020年， 智课计划再捐赠价值1亿元高品质课程与教学服务， 覆盖高校和中学分别扩大到800、 300 所， 受益学子增加到200万。',
        
                    {
                        type: 'c-bold-mb0',
                        text: '口碑效应持续彰显',
                    }, {
                        type: 'c-bold-mb0',
                        text: '用户黏性持续增强',
                    }, {
                        type: 'c-bold-mb0',
                        text: '2017 年智课收入预计突破2亿元',
                    },
        
                    '截至2017年10月， 智课网已有近600万注册学员， 线上注册学员近600万， 其中付费学员15万， 线下学习中心2017年付费学员超过3000人。 老客户推荐率高达64 % ，印证了智课持续强劲的口碑效应。 韦晓亮透露， 智课海外校友录和国内智课留学俱乐部这两大精准社群正在建设， 无疑将会进一步加强智课的用户黏性。',
        
                    '目前， 智课出国精英学习中心已经遍布28大城市， 并且在美国波士顿（ 2016 年11月）、 纽约（ 2017 年9月） 先后开设海外学习服务中心。 同时， 智课教育云也进入中国1200多所高校、 300 多所中学。 而从2016年起，「 智课翻转课堂合伙人」 计划开始实施， 投入5000万元成立智课翻转课堂合伙人互助基金， 寻找50名合伙人覆盖全国29个省， 共建「 互联网 + 」教育生态， 已有的智课翻转课堂合伙人共建学习中心覆盖了全国12个省， 未来将进一步开放产品体系、 内容体系和服务体系， 将智课优质资源输送给大量的老师及其学校与机构， 进一步形成智课独有的知识共享平台。 线上线下全面融合推进的智课， 预计2017年收入将突破2亿元。',
        
                    '近10年来， 我国国内城市家庭收入持续提高， 中高收入家庭已不满足于原有的低效粗放的教学方式， 教育需求呈现出信息化、 个性化和国际化三个主要趋势。 到2020年， 国内语言教育相关的教育市场规模将超过6000亿元， 留学市场将达到560亿元。 传统教育机构模式落后低效、 教育消费升级背景下， 市场存在巨大的变革机会。 智课教育董事长兼CEO韦晓亮表示， 在获得资本进一步认可和自身业务继续夯实的前提下， 将在自适应学习系统的研发、 品牌知名度的扩大和人才引进培养方面着重发力。 本轮投资方国科嘉和基金也看好来自多家一线机构， 行业沉淀深厚智课管理团队， 认为智课在内容、 教学、 运营方面经验丰富， 对单纯的在线教育及传统线下教育的痛点有深刻认知， 愿意拥抱先进的技术、 教学理念进行变革。 业内人士也认为， 2018 年将是智课教育的业务爆发期， 或将引领国际教育服务行业的创新与权威变革。',
                ]
            },
            {
                name: '高思教育',
                type: 1,
                title: '高思教育宣布完成5.5亿融资，致远资本担任本轮融资财务顾问',
                content: [
                    '2017 年9月20日消息， 高思教育（ 股票代码： 870155） 发布董事会公告， 宣布已完成5 .5 亿元人民币融资， 由华人文化产业投资基金和沸点资本领投， 以太创服等参与跟投。 致远资本担任本轮融资财务顾问。',

                    '高思教育成立于2009年， 于2016年11月30日完成了新三板挂牌， 是一家口碑卓越的专注于中小学阶段的教育培训机构。 在本轮融资前， 高思教育曾获得两轮投资： 2012 年， 高思教育完成A轮投资， 投资方是晨兴资本； 2015 年， 高思教育完成B轮投资， 投资方是九鼎投资。',

                    '高思教育创始人兼CEO须佶成表示， 高思教育的战略定位清晰明确， 就是用互联网 + 的方式实施K - 12 培训行业「 CTO」 战略。 C指的是内容（ Content）， T指的是教学（ Teaching）， O指的是开放连接（ Open），「 内容 - 教学 - 开放连接」 就是高思教育未来要长期专注坚持做的事情。',

                    '关于高思教育「 CTO」 的战略定位， 须佶成总结了三点：',

                    '第一个C（ Content） 指的是优秀的教学内容， 这是高思教育长期积淀所形成的核心能力， 未来我们还将继续精耕细作， 进一步把内容做得更好。 高思教育的内容是一个行业开放平台， 我们愿意以无保留的心态帮助合作方提高教学服务水平， 也会将其他优质内容吸收进入平台以供合作方共同使用， 目前高思教育的爱学习、 爱提分和爱尖子业务正在行业内开展大规模合作。',

                    '第二个T（ Teaching） 指的是恰当的教学服务方法， 高思教育不仅仅提供教学内容的工具， 也会协助合作方优化整个教学服务流程， 做到「 内容 + 服务」 的完整业务流程闭环。 通过引导老师采用更好的教学方法， 引导学生培养学习兴趣， 形成良好学习习惯， 最终促进学生产生学习效果。',

                    '第三个O（ Open） 指的是高思教育要以开放心态做行业内最好的连接器， 我们希望将高思的资源开放给尽可能多的校长和老师， 通过先为他人创造价值的方式来获得自身价值的提升。 高思教育希望用「 开放连接」 的平台思维做教育行业的底层价值创造者， 用数据化的方式连接更多的家庭、 学生和老师， 让最前沿的教育科技、 教育理念和教育信息更高效地服务于机构、 老师、 学生和家庭。',

                    '须佶成认为， 教育行业的变革才刚刚开始， 未来还有很长的路要走。 高思教育定位为以互联网 + 的方式布局K - 12 培训行业「 CTO」 战略， 就是要成为行业的价值创造者， 除内容和教学模式外， 其他领域都交出去与合作方协作， 让合作方自主地成长为独立的公司和平台。「 开放连接， 成人达己」 是高思教育在长期发展过程中形成的价值理念和事业法则， 成为行业的一棵参天大树并不是高思的目标， 成为行业发展肥沃的土壤， 支持千千万万的教育创业者， 为行业的百花齐放做出贡献是高思的理想。',

                    '须佶成表示， 自2015年以来， 高思陆续布局了爱学习、 爱提分、 爱尖子等互联网项目， 模式已经初步形成。 至此， 高思已经全面从一家线下的培训机构战略转型为一家互联网教育企业。 今后高思还将持续加大产品和技术的投入， 未来在教育大数据和人工智能方面做更多布局。',

                    '作为今年K - 12 行业内最大规模融资之一， 华人文化董事长、 CEO黎瑞刚表示：「 互联网技术在教育领域的应用一直是华人文化关注并看好的方向， 高思教育通过互联网 + 的模式， 结合业内先进的教育理念， 把内容、 技术与教学体系开放给全国各地的K - 12 培训机构， 将为整个行业创造巨大的价值。 华人文化期待未来与公司密切合作， 协助公司更好地推进其战略与业务落地。」',

                    '沸点资本管理合伙人于光东表示：「 高思拥有革新传统教育的基础。 首先， 高思作为国内K - 12 领先的教育机构， 在教研、 服务体系方面已经具备成长为大型教育集团的基础， 推出的服务在学生、 家长当中树立了良好的的口碑。',

                    '其次， 高思首次将产教研体系产品化地向其他教育机构输出， 首创了教育领域的「 安卓模式」， 让天下没有难做的教育。 我们长期看好高思在产品研发和迭代上巨大的想象空间。 第一， 线上线下的内容生产形成闭环， 产教研实时大数据的利用节约了用户成本也使得课程设计更加智能。 第二， 高思从C端向B断的平台业务拓展， 也是对传统教育体系信息及资源透明化的尝试。 对传统教育领域来说， 解决大数据收集及数据的基础应用已经可以初步实现， 深度挖掘的数据和使用场景会在未来产生更大的价值。」',
                ]
            },
            {
                name: '贝乐学科英语',
                type: 3
            }
        ],
    ]
}

export default configs