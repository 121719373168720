const configs = {
    bg: require('../../common/imgs/common_bg2.png').default,
    title: require('../../common/imgs/team/title.png').default,
    left: require('../../common/imgs/left.png').default,
    right: require('../../common/imgs/right.png').default,
    close: require('../../common/imgs/close.png').default,
    list: [
        {
            name: '李悦',
            active: require('../../common/imgs/team/active/李悦.png').default,
            default: require('../../common/imgs/team/default/李悦.png').default,
            color: '#BA9D6E',
            enName: 'Cynthia',
            title: '创始人&CEO',
            experience: [
                '17年私募融资财务顾问经验。2004年入行，亲历中国股权投资一级市场的兴起和发展；',
                '2018年初创立致远资本，专注服务高科技和大消费行业的优秀成长期企业。几年间已成功完成60+笔私募融资交易，累计交易金额超过200亿人民币；',
                '代表案例包括：酷家乐、XSKY星辰天合、新核云、金峰物流、九章云极、翼菲自动化、晓羊科技、德风科技、51WORLD、芯翼信息科技、斗象科技、扩博智能、同盾科技、Colorkey、好麦多、五爷拌面、河狸家、高思教育、居理新房等；',
                '2005年初作为创始员工加入华兴资本， 曾任华兴资本最年轻且唯一女性执行董事，带领华兴建立消费、电商、O2O、教育等行业。领导完成多个融资项目，包括光线传媒、美团、优信集团、当当网、途家、酒仙网、乐蜂、易到用车等；',
                '曾作为联合创始人创办以太创服，开创了一级市场投融资数字化、平台化运营之先河；',
                '曾任职于精品投行Latitude乐通集团，专注TMT领域并购和私募交易；',
                '曾任职于全球四大会计师事务所Ernst&Young安永华明；',
                '本科毕业于北京外国语大学，获得英语学士学位；研究生毕业于LSE伦敦政治经济学院，获得金融硕士学位。'
            ],
            theme: 'light',
            needCover: true
        },
        {
            name: '高倩',
            active: require('../../common/imgs/team/active/高倩.png').default,
            default: require('../../common/imgs/team/default/高倩.png').default,
            color: '#333333',
            enName: 'Sonia',
            title: '执行董事',
            experience: [
                '7年私募融资财务顾问经验，负责致远资本在消费、文化等领域的私募股权交易；',
                '代表案例：Colorkey、好麦多、五爷拌面、素肌良品、BlankME、一旬一味、天矢禾念、银河酷娱、贝壳视频、智课、小麦公社等；',
                '在加入致远前，拥有多年TMT行业从业经验，曾就职于腾讯、爱奇艺，从事互联网产品建设工作；',
                '本科毕业南开大学法语专业，获得文学学士学位；研究生毕业于北京大学国际关系学院，获得法学硕士学位。'
            ],
            theme: 'dark'
        },
        // {
        //     name: '陈梅一',
        //     active: require('../../common/imgs/team/active/陈梅一.png').default,
        //     default: require('../../common/imgs/team/default/陈梅一.png').default,
        //     color: '#FFFFFF',
        //     enName: 'Meiyi',
        //     title: '执行董事',
        //     experience: [
        //         '6年私募融资财务顾问经验，负责致远资本在企业服务、科技领域的私募股权交易；',
        //         '代表案例：XSKY星辰天合、新核云、九章云极、翼菲自动化、德风科技、超维创新、金峰物流、晓羊科技、校宝在线、因爱科技等；',
        //         '在加入致远前，拥有战略咨询与PE私募投资从业经验；',
        //         '本科毕业于上海财经大学，获得国际经济学学士学位；研究生毕业于复旦大学与米兰博科尼大学，获得国际商务硕士学位。'
        //     ],
        //     theme: 'light'
        // },
        {
            name: '邱周钰',
            active: require('../../common/imgs/team/active/邱周钰.png').default,
            default: require('../../common/imgs/team/default/邱周钰.png').default,
            color: '#000000',
            enName: 'Rachel',
            title: '副总裁',
            experience: [
                '4年私募融资财务顾问经验，负责致远资本在企业服务、科技领域的私募股权交易；',
                '代表案例：XSKY星辰天合、九章云极、翼菲自动化、德风科技、金峰物流、晓羊科技、51WORLD等；',
                '在加入致远前，拥有投行与PE私募投资从业经验；',
                '本科毕业于中国人民大学，获得经济学学士学位；研究生毕业于中国人民大学，获得经济学硕士学位。'
            ],
            theme: 'dark'
        },
        {
            name: '康璐琳',
            active: require('../../common/imgs/team/active/康璐琳.png').default,
            default: require('../../common/imgs/team/default/康璐琳.png').default,
            color: '#FFFFFF',
            enName: 'Shine',
            title: '副总裁',
            experience: [
                '5年私募融资财务顾问经验，负责致远资本在大消费私募股权交易；',
                '代表案例：Colorkey、五爷拌面、素肌良品、Akoko、须眉、信天翁、心上、会过、秒优科技、犀有数科、扩博智能、融慧金科、酷家乐等；',
                '研究生毕业于西南财经大学，获得金融硕士学位。'
            ],
            theme: 'light',
            needCover: true
        },
    ]
}

export default configs