import React, { Fragment } from 'react'
import './index.less'
import configs from './config'
import classnames from 'classnames'
import Header from '../../components/mobile/header'
import Detail from '../../components/mobile/detail'
import { withRouter } from 'react-router-dom'
import { report, count } from '../../common/js/tools'
report()
count()

class Pc extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...configs,
            clientHeight: 0,
            current: 0,
            lastIndex: 0,
            selectedIndex: -1,
            showDetail: false,
            detail: {},
            type: 'up',
            firstLoad: true,
            showMenu: false,
            w: 0,
            h: 0,
            bt: 0
        }
    }

    moving = false
    initY = 0
    

    componentDidMount() {
        this.calcFix()
        window.addEventListener('touchstart', this.touchStart)
        window.addEventListener('touchend', this.touchEnd)
        window.addEventListener('touchmove', this.touchMove)
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart)
        window.removeEventListener('touchend', this.touchEnd)
        window.removeEventListener('touchmove', this.touchMove)
    }

    calcFix() {
        if (this.state.current !== 0) return
        let home = document.getElementById('home')
        let { clientWidth, clientHeight } = home
        let w = clientWidth
        let h = clientHeight
        let bt = 0
        let per = clientWidth / clientHeight
        let ratio = 750 / 1334 //图片尺寸
        console.log(per, ratio)
        if (per < ratio) {
            w = h * ratio
        } else {
            h = clientWidth / ratio
        }
        console.log(w, h)
        this.setState({
            w,
            h,
            bt
        })
    }

    touchStart = (e) => {
        this.moving = true
    }

    touchEnd = (e) => {
        this.moving = false
        this.initY = 0
    }

    touchMove = (e) => {
        if (!this.moving) return
        let initY = this.initY
        let currentY = e.touches[0].pageY
        if (initY === 0) {
            this.initY = currentY
            return
        }
        let diffY = currentY - initY
        console.log(initY, currentY)
        if (Math.abs(diffY) > 1) {
            this.handleTouch(diffY)
            this.moving = false
            this.initY = 0
        }
    }

    handleTouch(e) {
        let { showDetail, showMenu } = this.state
        if (this.pending || showDetail || showMenu) return
        let { current } = this.state
        let lastIndex = current
        let type = 'up'
        if (e < 0) {
            current < 4 && current++
        } else if (e > 0) {
            if (current > 0) {
                current--
                type = 'down'
            } else {
                return
            }
        }
        this.pending = true
        this.setState({
            current,
            type,
            firstLoad: false,
            lastIndex
        })
        setTimeout(() => {
            this.pending = false
        }, 1500)
    }

    handleMenu() {
        console.log('menu')
    }

    handleClickItem(item, index) {
        let { name, type, hasSub } = item
        if (type === 1) {
            item.main = require(`../../common/imgs/business/main/${name}.png`).default
            hasSub && (item.sub = require(`../../common/imgs/business/sub/${name}.png`).default)
            this.setState({
                detail: item,
                selectedIndex: index,
                showDetail: true
            })
        } else if (type === 2) {
            window.open(item.link, '_blank')
        }
    }

    handleChangeItem(type) {
        let { selectedIndex, screen5 } = this.state
        let cases = screen5.cases
        let len = cases.length
        let item
        let func = function () {
            if (type === 'left') {
                selectedIndex = selectedIndex === 0 ? len - 1 : selectedIndex - 1
            } else {
                selectedIndex = selectedIndex === len - 1 ? 0 : selectedIndex + 1
            }
            item = cases[selectedIndex]
        }
        func()
        while (item.type > 1) {
            func()
        }
        this.handleClickItem(item, selectedIndex)
    }

    handleChild() {
        this.setState({
            showDetail: false,
            selectedIndex: -1
        })
    }

    handleChildMenu(e) {
        this.setState({
            showMenu: e
        })
    }

    handleNext() {
        let { current } = this.state
        let lastIndex = current
        current++
        this.setState({
            type: 'up',
            current,
            lastIndex,
            firstLoad: false
        })
    }

    render() {
        let { pageList, screen1, screen2, screen3, screen4, screen5, selectedIndex, showDetail, detail, current, type, firstLoad, lastIndex, w, h, bt, beian } = this.state
        return (
            <div className="mobile-home-fragment">
                <div className={classnames('mobile-home', { [`${type}`]: true, 'first-load': firstLoad })} id="home">
                    <Detail showDetail={showDetail} parent={this} detail={detail} />
                    {pageList.map((item, index) => {
                        return (
                            <div key={index} className={classnames('page-item flex-column flex-center', { 'extra-style': index > 0, 'active': current === index, 'before': lastIndex === index })} style={{ 'paddingBottom': `${index === 0 ? 20 : 0}rem` }}>
                                <Header theme={index > 0 ? 'dark' : 'light'} isHome="true" menuPrevent="true" parent={this} />
                                {item.type === 'fix' ? <img src={item.url} className="screen-bg1" id="fix" style={{ width: `${w}px`, height: `${h}px`, bottom: `${bt}rem` }} alt="" /> : <img src={item.url} className='screen-bg' alt="" />}
                                {index === 0 && (
                                    <Fragment>
                                        <div className="screen1-title">以投行专业服务能力汇聚资本力量</div>
                                        <img src={screen1.content} className="screen1-content" alt="" />
                                        <img src={screen1.line} className="screen1-line" alt="" />
                                        <div className="screen1-desc">初心如磐,笃行致远;行而不辍,未来可期</div>
                                    </Fragment>
                                )}
                                {index === 1 && (
                                    <Fragment>
                                        <img className="screen2-title" src={screen2.title} alt="" />
                                        <div className="screen2-desc">专注服务新经济行业的精品投行</div>
                                        <div className="screen2-content">
                                            {screen2.cases.map((item2, index2) => {
                                                return (
                                                    <div key={index2} className="flex-row screen2-item">
                                                        <div className="item2-content"><img src={item2.url} className="item2-content-bg" alt="" />{item2.content}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Fragment>
                                )}
                                {index === 2 && (
                                    <Fragment>
                                        <img src={screen3.title} className="screen3-title" alt="" />
                                        <div className="service-tip">以专业的服务、完善的流程，助力企业飞速成长</div>
                                        <div className="service-bottom flex-column flex-row">
                                            <div className="flex-row">
                                                <div className="step-item">
                                                    <img src={screen3.steps[0].url} className="step-icon" alt="" />
                                                    <div className="step-text">{screen3.steps[0].text}</div>
                                                </div>
                                                <img src={screen3.right} className="x-arrow" alt="" />
                                                <div className="step-item">
                                                    <img src={screen3.steps[1].url} className="step-icon" alt="" />
                                                    <div className="step-text">{screen3.steps[1].text}</div>
                                                </div>
                                            </div>
                                            <div className="y-box">
                                                <img src={screen3.down} className="y-arrow" alt="" style={{'right': '2.88rem'}} />
                                            </div>
                                            <div className="flex-row">
                                                <div className="step-item">
                                                    <img src={screen3.steps[3].url} className="step-icon" alt="" />
                                                    <div className="step-text">{screen3.steps[3].text}</div>
                                                </div>
                                                <img src={screen3.left} className="x-arrow" alt="" />
                                                <div className="step-item">
                                                    <img src={screen3.steps[2].url} className="step-icon" alt="" />
                                                    <div className="step-text">{screen3.steps[2].text}</div>
                                                </div>
                                            </div>
                                            <div className="y-box">
                                                <img src={screen3.down} className="y-arrow" alt="" style={{'left': '2.88rem'}} />
                                            </div>
                                            <div className="flex-row">
                                                <div className="step-item">
                                                    <img src={screen3.steps[4].url} className="step-icon" alt="" />
                                                    <div className="step-text">{screen3.steps[4].text}</div>
                                                </div>
                                                <img src={screen3.right} className="x-arrow" alt="" />
                                                <div className="step-item">
                                                    <img src={screen3.steps[5].url} className="step-icon" alt="" />
                                                    <div className="step-text">{screen3.steps[5].text}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                {index === 3 && (
                                    <Fragment>
                                        <img src={screen4.title} className="screen4-title1" alt="" />
                                        <div className="screen4-title2">自2018年初成立至今，已成功完成</div>
                                        <div className="screen4-p1">
                                            <img src={screen4.p1} alt="" />
                                            <div>200<span>亿+</span></div>
                                        </div>
                                        <div className="screen4-p2">
                                            <img src={screen4.p2} alt="" />
                                            <div>80+</div>
                                        </div>
                                    </Fragment>
                                )}
                                {index === 4 && (
                                    <Fragment>
                                        <img src={screen5.title} alt="" className="screen5-title" />
                                        <div className="screen5-content">
                                            {screen5.cases.map((item4, index4) => {
                                                return (
                                                    <div key={index4} className={classnames('screen5-item pointer', {
                                                        'selected-item': selectedIndex === index4
                                                    })} onClick={this.handleClickItem.bind(this, item4, index4)}>
                                                        <div className="item4-top flex-center">
                                                            <img src={item4.url} style={{ width: `${item4.w}rem`, height: `${item4.h}rem` }} alt="" />
                                                        </div>
                                                        <div className="item4-bottom flex-center">{item4.name}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="screen5-btn pointer flex-center" onClick={() => {
                                            this.props.history.push('/business')
                                        }}>查看所有案例</div>
                                        <div className="bottom-tip">
                                           <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502047550" rel="noreferrer"><img className="beian" src={beian} alt="" /><div>京公网安备 11010502047550号</div></a>
                                            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noreferrer">京ICP备2021031266号-1</a> 
                                        </div>
                                        
                                    </Fragment>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default withRouter(Pc)