import React, { Component } from "react";
import Header from '../../components/mobile/header'
import Detail from '../../components/mobile/detail'
import './index.less'
import configs from "./config"

export default class Team extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...configs,
            showDetail: false, 
            selectedIndex: -1,
            detail: {},
            transType: '',
            coverH: 0,
            showHeader: true,
            lastTop: 0
        }
    }

    componentDidMount() {
        let container = document.getElementById('team-content')
        container.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        let container = document.getElementById('team-content')
        container.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = (e) => {
        let { scrollTop } = e.srcElement
        let lastTop = this.state.lastTop
        if (scrollTop > lastTop && scrollTop > 1) {
            this.setState({
                lastTop: scrollTop,
                showHeader: false
            })
        } else {
            this.setState({
                lastTop: scrollTop,
                showHeader: true
            })
        }
    }

    handleClick(index) {
        let item = this.state.list[index]
        item.avatar = require(`../../common/imgs/mobile/team/${item.name}1.png`).default
        this.setState({
            showDetail: true,
            selectedIndex: index,
            detail: item
        })
    }

    handleChild() {
        this.setState({
            selectedIndex: -1,
            showDetail: false
        })
    }

    doNothing(e) {
        e.stopPropagation();
    }

    handleChangeItem(type) {
        let { selectedIndex, list }  = this.state
        let len = list.length
        if (type==='left') {
            selectedIndex = selectedIndex===0 ? len - 1 : selectedIndex-1
        } else {
            selectedIndex = selectedIndex===len-1 ? 0 : selectedIndex+1
        }
        this.handleClick(selectedIndex)
    }

    render() {
        const { bg, title, list, showDetail, detail, showHeader } = this.state
        let type = 2
        return (
            <div className="mobile-page mobile-team">
                {showHeader && <Header theme="dark" id="header" />}
                <Detail showDetail={showDetail} parent={this} detail={detail} type={type} />
                <img src={bg} className="bg" alt="" />
                <div className="top-cover" id="top-cover" style={{'background': `${showHeader?'#fff':'transparent'}`}}></div>
                <div className="page-content1 flex-column flex-row" id="team-content">
                    <img src={title} className="page-title" alt="" />
                    <div className="team-tip">有思想有温度有担当、服务精良、锐意进取、追求卓越</div>
                    <div className="team-imgs flex-column flex-row">
                        {list.map((item ,index) => {
                            return (
                                <img key={index} src={item.url} alt="" className="item-img" onClick={this.handleClick.bind(this, index)} />
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}