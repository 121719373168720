import React, { Fragment } from 'react'
import './index.less'
import configs from './config'
import classnames from 'classnames'
import Header from '../../components/pc/header'
import Detail from '../../components/pc/detail'
import { withRouter } from 'react-router-dom'
import { report, count } from '../../common/js/tools'
report()
count()

class Pc extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...configs,
            clientHeight: 0,
            current: 0,
            lastIndex: 0,
            selectedIndex: -1,
            showDetail: false,
            detail: {},
            type: 'up',
            firstLoad: true,
            showMenu: false,
            w: 0,
            h: 0,
            bt: 0
        }
    }

    componentDidMount() {
        let container = document.getElementById('home')
        this.calcFix()
        container.addEventListener('wheel', this.bindHandleScroll)
        window.addEventListener('keydown', this.bindKeydown)
        window.addEventListener('resize', this.calcFix)
    }

    componentWillUnmount() {
        document.getElementById('home').removeEventListener('wheel', this.bindHandleScroll)
        window.removeEventListener('keydown', this.bindKeydown)
        window.removeEventListener('resize', this.calcFix)
    }

    calcFix = (e) => {
        console.log('calcFix')
        if (this.state.current !== 0) return
        let home = document.getElementById('home')
        let { clientWidth, clientHeight } = home
        let w = clientWidth
        let h = clientHeight
        let bt = 0
        console.log(clientWidth, clientHeight)
        let per = clientWidth / clientHeight
        if (per > 1.5) {
            h = w / 1.5
        } else {
            w = h * 1.5
        }
        if (per > 1.6) bt = -5
        console.log(w,h)
        this.setState({
            w, 
            h,
            bt
        })
    }

    bindKeydown = (e) => {
        let { keyCode } = e
        let { current } = this.state
        let lastIndex = current
        let type = 'up'
        if (keyCode === 40) {
            current < 4 && current++
        } else if (keyCode === 38) {
            current > 0 && current--
            type = 'down'
        }
        console.log(current)
        this.pending = true
        this.setState({
            current,
            type,
            firstLoad: false,
            lastIndex
        })
        setTimeout(() => {
            current !== lastIndex && (this.pending = false)
        }, 1500)
    }

    bindHandleScroll = (e) => {
        let { showDetail, showMenu } = this.state
        if (this.pending || showDetail || showMenu) return
        let { wheelDelta } = e
        let { current } = this.state
        let lastIndex = current
        let type = 'up'
        if (wheelDelta < 0) {
            current < 4 && current++
        } else if (wheelDelta > 0) {
            current > 0 && current--
            type = 'down'
        }
        this.pending = true
        this.setState({
            current,
            type,
            firstLoad: false,
            lastIndex
        })
        setTimeout(() => {
            this.pending = false
        }, 1500)
    }

    handleMenu() {
        console.log('menu')
    }

    handleClickItem(item, index) {
        let { name, type, hasSub } = item
        if (type === 1) {
            item.main = require(`../../common/imgs/business/main/${name}.png`).default
            hasSub && (item.sub = require(`../../common/imgs/business/sub/${name}.png`).default)
            this.setState({
                detail: item,
                selectedIndex: index,
                showDetail: true
            })
        } else if (type === 2) {
            window.open(item.link, '_blank')
        }
    }

    handleChangeItem(type) {
        let { selectedIndex, screen5 }  = this.state
        let cases = screen5.cases
        let len = cases.length
        let item
        let func = function() {
            if (type==='left') {
                selectedIndex = selectedIndex===0 ? len - 1 : selectedIndex-1
            } else {
                selectedIndex = selectedIndex===len-1 ? 0 : selectedIndex+1
            }
            item = cases[selectedIndex]
        }
        func()
        while (item.type > 1) {
            func()
        }
        this.handleClickItem(item, selectedIndex)
    }

    handleChild() {
        this.setState({
            showDetail: false,
            selectedIndex: -1
        })
    }

    handleChildMenu(e) {
        this.setState({
            showMenu: e
        })
    }

    handleNext() {
        let { current } = this.state
        let lastIndex = current
        current++
        this.setState({
            type: 'up',
            current,
            lastIndex,
            firstLoad: false
        })
    }

    render() {
        let { pageList, screen2, screen3, screen4, screen5, selectedIndex, showDetail, detail, current, type, firstLoad, lastIndex, w, h, bt, beian } = this.state
        return (
            <div className="pc-home-fragment">
                <div className={classnames('pc-home', {[`${type}`]: true, 'first-load': firstLoad})} id="home">
                    <div className="scroll-track">
                        <div className="scroll-bar" style={{top: `${current * 20}vh`, background: `${current===0?'#fff':'#ba9d6e'}`}}></div>
                    </div>
                    <Detail showDetail={showDetail} parent={this} detail={detail} />
                    {pageList.map((item, index) => {
                        return (
                            <div key={index} className={classnames('page-item flex-column flex-center', {'extra-style': index===1, 'screen3-content': index===2, 'active': current===index, 'before': lastIndex===index})} style={{'paddingBottom': `${index===0?20:0}rem`}}>
                                <Header theme={index>0?'dark':'light'} isHome="true" menuPrevent="true" parent={this} />
                                {item.type === 'fix' ? <img src={item.url} className="screen-bg1" id="fix" style={{width: `${w}px`, height: `${h}px`, bottom: `${bt}rem`}} alt="" /> : <img src={item.url} className='screen-bg' alt="" />}
                                <img src={item.page} className="page-num" alt="" />
                                {/* {item.next && (
                                    <img src={item.next} className="next-arrow" alt="" onClick={this.handleNext.bind(this)} />
                                )} */}
                                {index === 0 && (
                                    <Fragment>
                                        {/* <div className="screen-video-cover"></div> */}
                                        <div className="screen1-title">以投行专业服务能力汇聚资本力量</div>
                                        <div className="screen1-title">推动新经济商业变革，成就新时代伟大企业</div>
                                        <div className="screen1-desc">初心如磐，笃行致远。行而不辍，未来可期。</div>
                                    </Fragment>
                                )}
                                {index === 1 && (
                                    <Fragment>
                                        <img className="screen2-title" src={screen2.title} alt="" />
                                        <div className="screen2-content">
                                            {screen2.cases.map((item2, index2) => {
                                                return (
                                                    <div key={index2} className="flex-row screen2-item">
                                                        <img src={item2.url} alt="" className="item2-img" />
                                                        <div className="item2-content"><img src={screen2.content_bg} className="item2-content-bg" alt="" />{item2.content}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Fragment>
                                )}
                                {index === 2 && (
                                    <Fragment>
                                        <img src={screen3.title} className="screen3-title" alt="" />
                                        <div className="service-tip">致远，始终以精致的服务、专业的流程，助力企业飞速成长</div>
                                        <div className="service-bottom flex-column flex-row">
                                            <div className="bottom-title">全流程专业化融资解决方法</div>
                                            <div className="steps-box">
                                                <img src={screen3.path} className="path" alt="" />
                                                {screen3.steps.map((item, index) => {
                                                    return (
                                                        <div key={index} className="step-item" style={{ left: `${14.32 * index - 2.815}rem`, [`${index % 2 ? 'top' : 'bottom'}`]: '-2.815rem' }}>
                                                            <div className="step-text" style={{ [`${index % 2 ? 'top' : 'bottom'}`]: '-3.88rem' }}>{item.text}</div>
                                                            <img src={item.url} className="step-icon" alt="" />
                                                            <div className={classnames('detail-box', {[`${index%2 ? 'bottom': 'top'}-detail`]: true})}>
                                                                <img src={item.bg} className="detail-bg" alt="" />
                                                                <div className="detail-text" style={{top: `${item.space[0]}rem`, left: `${item.space[1]}rem`}}>
                                                                    <div className="detail-title">{item.text}</div>
                                                                    <div className="detail-divider"></div>
                                                                    <div className="detail-content">{item.detail}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                {index === 3 && (
                                    <Fragment>
                                        <div className="screen4-title1">致远资本专注于服务高科技、企业服务、大消费等行业的成长期优秀企业</div>
                                        <div className="screen4-title2">自2018年初成立至今，已成功完成</div>
                                        <div className="flex screen4-content">
                                            <div className="screen4-item">
                                                <div className="screen4-label">交易金额（元）</div>
                                                <div className="screen4-num flex">200<div className="screen4-unit">亿+</div></div>
                                                <img src={screen4[0]} alt="" />
                                            </div>
                                            <div className="screen4-item">
                                                <div className="screen4-label">私募融资交易（笔）</div>
                                                <div className="screen4-num">80+</div>
                                                <img src={screen4[1]} alt="" />
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                {index === 4 && (
                                    <Fragment>
                                        <img src={screen5.title} alt="" className="screen5-title" />
                                        <div className="screen5-content">
                                            {screen5.cases.map((item4, index4) => {
                                                return (
                                                    <div key={index4} className={classnames('screen5-item pointer', {
                                                        'selected-item': selectedIndex === index4
                                                    })} onClick={this.handleClickItem.bind(this, item4, index4)}>
                                                        <div className="item4-top flex-center">
                                                            <img src={item4.url} style={{width: `${item4.w}rem`, height: `${item4.h}rem`}} alt="" />
                                                        </div>
                                                        <div className="item4-bottom flex-center">{item4.name}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="screen5-btn pointer flex-center" onClick={() => {
                                            this.props.history.push('/business')
                                        }}>查看所有案例</div>
                                        <div className="bottom-tip">
                                           <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502047550" rel="noreferrer"><img className="beian" src={beian} alt="" /><div>京公网安备 11010502047550号</div></a>
                                            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noreferrer">京ICP备2021031266号-1</a> 
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default withRouter(Pc)