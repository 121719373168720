import React, { Component } from "react";
import Header from '../../components/pc/header'
import Detail from "../../components/pc/detail";
import './index.less'
import configs from "./config";
import classnames from 'classnames'
import { report, count } from '../../common/js/tools'
report()
count()

export default class Business extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...configs,
            tabIndex: 0,
            selectedIndex: -1,
            per: 0,
            showDetail: false,
            currentCases: [],
            detail: {}
        }
    }

    componentDidMount() {
        this.handleCases()
    }

    componentWillUnmount() {
        document.getElementById('cases').removeEventListener('scroll', this.bindHandleScroll.bind(this))
    }

    initScroll() {
        let container = document.getElementById('cases')
        document.getElementById('cases').scrollTop = 0
        let { clientHeight, scrollHeight } = container
        let per = clientHeight / scrollHeight * 100
        this.setState({
            clientHeight,
            scrollHeight,
            per
        })
        container.addEventListener('scroll', this.bindHandleScroll.bind(this))
    }

    bindHandleScroll(e) {
        let { scrollTop } = e.srcElement
        let { clientHeight, scrollHeight } = this.state
        let x = scrollTop + clientHeight
        let per = x / scrollHeight * 100
        this.setState({
            per
        })
    }

    handleCases() {
        let { tabIndex, cases } = this.state
        let currentCases = cases[tabIndex].map((item, index) => {
            let { name } = item
            item.url = require(`../../common/imgs/business/logo/${name}.png`).default
            return item
        })
        this.setState({
            currentCases
        }, () => {
            this.initScroll()
        })
    }

    handleClickTab(index) {
        this.setState({
            tabIndex: index
        }, () => {
            this.handleCases()
        })
    }

    handleClickItem(item, index) {
        let { name, type, hasSub } = item
        if (type === 1) {
            item.main = require(`../../common/imgs/business/main/${name}.png`).default
            hasSub && (item.sub = require(`../../common/imgs/business/sub/${name}.png`).default)
            this.setState({
                detail: item,
                selectedIndex: index,
                showDetail: true
            })
        } else if (type === 2) {
            window.open(item.link, '_blank')
        }
    }

    handleChangeItem(type) {
        let { selectedIndex, currentCases }  = this.state
        let len = currentCases.length
        let item
        let func = function() {
            if (type==='left') {
                selectedIndex = selectedIndex===0 ? len - 1 : selectedIndex-1
            } else {
                selectedIndex = selectedIndex===len-1 ? 0 : selectedIndex+1
            }
            item = currentCases[selectedIndex]
        }
        func()
        while (item.type > 1) {
            func()
        }
        this.handleClickItem(item, selectedIndex)
    }

    handleChild() {
        this.setState({
            showDetail: false,
            selectedIndex: -1
        })
    }

    render() {
        const { bg, title, tabs, tabIndex, currentCases, selectedIndex, per, showDetail, detail } = this.state
        return (
            <div className="pc-page pc-business">
                <Header theme="dark" />
                <Detail showDetail={showDetail} parent={this} detail={detail} />
                <img src={bg} className="bg" alt="" />
                <div className="page-content flex-column flex-row">
                    <img src={title} className="page-title" alt="" />
                    <div className="business-tip">近年来，致远已助力逾60家企业，完成了超过200亿元的融资</div>
                    <div className="business-container flex">
                        <div className="tabs">
                            {tabs.map((tItem, tIndex) => {
                                return <div key={tIndex} className={classnames('tab-item', { 'selected-tab': tabIndex === tIndex })} onClick={this.handleClickTab.bind(this, tIndex)}>{tItem.label}</div>
                            })}
                        </div>
                        <div className="divider">
                            <div className="progress" style={{ height: `${per}%` }}></div>
                            <div className="circle"></div>
                        </div>
                        <div className="cases" id="cases">
                            {currentCases.map((cItem, cIndex) => {
                                return (
                                    <div key={cIndex} className={classnames('business-item pointer', {
                                        'selected-item': selectedIndex === cIndex
                                    })} onClick={this.handleClickItem.bind(this, cItem, cIndex)}>
                                        <div className="business-top flex-center">
                                            <img src={cItem.url} className="business-img" alt="" />
                                        </div>
                                        <div className="business-bottom flex-center">{cItem.name}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}