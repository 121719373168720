import Pc from './pc-pages/home/index'
import Mobile from './mobile-pages/home/index'
import React, {Component} from 'react';
import {judgeIsMobile} from './common/js/tools'

export default class App extends Component {
  render() {
    return (
      <div className="App" style={{'height': '100%'}}>
          {judgeIsMobile() && <Mobile />}
          {!judgeIsMobile() && <Pc />}
      </div>
    );
  }
}