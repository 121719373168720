import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import 'antd/dist/antd.css';
import {judgeIsMobile} from './common/js/tools'

let routers

if(judgeIsMobile()) {
  console.log('移动端')
  require('./mobile.less')
  document.body.style.height = '100%'
  document.body.style.overflow = 'hidden'
  routers = require('./mobile-routers').routers
} else {
  console.log('pc端')
  require('./pc.less')
  document.body.minWidth = '800px'
  routers = require('./pc-routers').routers
}

ReactDOM.render(
  <Router>
    {routers}
	</Router>,
  document.getElementById('root')
);
