import React, { Fragment } from 'react'
import './index.less'
import classnames from 'classnames'

class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            isOut: false,
            close: require('../../../common/imgs/close.png').default,
            left: require('../../../common/imgs/left.png').default,
            right: require('../../../common/imgs/right.png').default,
            transType: '',
            lastTop: 0,
            showExtra: true
        }
    }

    static defaultProps = {
        showDetail: false,
        detail: {},
        type: 1
    }

    UNSAFE_componentWillUpdate(e) {
        if (e.showDetail) {
            setTimeout(() => {
                let container = document.getElementById('detail-outer')
                container.addEventListener('scroll', this.handleDetailScroll.bind(this))
            },1)
        }
    }

    handleDetailScroll(e) {
        let { scrollTop } = e.srcElement
        let lastTop = this.state.lastTop
        if (scrollTop > lastTop && scrollTop > 1) {
            this.setState({
                lastTop: scrollTop,
                showExtra: false
            })
        } else if (scrollTop < lastTop) {
            this.setState({
                lastTop: scrollTop,
                showExtra: true
            })
        }
    }

    handleDetail() {
        let showDetail = false
        let waitTime = 500
        this.setState({
            isOut: true
        })
        setTimeout(() => {
            this.props.parent.handleChild(false)
        }, 400)
        setTimeout(() => {
            this.setState({
                showDetail,
                isOut: false
            })
        }, waitTime)
    }

    handleChange(type) {
        this.setState({
            transType: type
        }, () => {
            setTimeout(() => {
                this.setState({
                    transType: ''
                })
                let container = document.getElementById('detail-outer')
                container.scrollTop = 0
                this.props.parent.handleChangeItem(type)
            }, 400)
        })
    }

    render() {
        const { isOut, close, left, right, transType, showExtra } = this.state
        const { showDetail, detail, type } = this.props
        console.log(detail)
        return (
            <Fragment>
                {showDetail && (
                    <div className={classnames('mobile-detail-container', {
                        'detail-out': isOut
                    })}>
                        <div className="right">
                            <div className="top-tab" style={{'display': showExtra ? 'block' : 'none'}}>
                                <img src={close} className="close" alt="" onClick={this.handleDetail.bind(this)} />
                            </div>
                            <div className="outer" id="detail-outer">
                                <div className={classnames('outer-content', { [`to-${transType}`]: !!transType })}>
                                    {type === 1 && (
                                        <Fragment>
                                            {detail.maim && <img src={detail.main} className="detail-img" alt="" />}
                                            <div className="detail-title">{detail.title}</div>
                                            {detail.hasSub && <img src={detail.sub} className="sub-img" alt="" />}
                                            {detail.content.map((item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {(typeof item === 'object' && (item.type==='img' || item.type==='html')) ? (
                                                            <Fragment>
                                                                {(item.type === 'img') && (
                                                                    <img src={item.url} className="content-img" style={{ 'width': '100%' }} alt="" />
                                                                )}
                                                                {(item.type === 'html') && (
                                                                    <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                                                )}
                                                            </Fragment>
                                                        ) : (
                                                            <div key={index} className={classnames('detail-text', { 'first-text': index === 0, [`${typeof item === 'string' ? 'normal' : item.type}`]: true })}>{typeof item === 'string' ? item : item.text}</div>
                                                        )}
                                                    </Fragment>
                                                )
                                            })}
                                        </Fragment>
                                    )}
                                    {type === 2 && (
                                        <Fragment>
                                            <div className="flex team-top">
                                                <img src={detail.avatar} className="team-avatar" alt="" />
                                                <div className="team-info">
                                                    <div className="cn-name">{detail.name}</div>
                                                    <div className="en-name">{detail.enName}</div>
                                                    <div className="team-title">{detail.title}</div>
                                                </div>
                                            </div>
                                            <div className="team-experience">
                                                {detail.experience.map((eItem, eIndex) => {
                                                    return (
                                                        <div key={eIndex} className="flex experience-item">
                                                            {eItem}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                            <div className="bottom-bar flex-row flex-between" style={{'display': showExtra ? 'flex' : 'none'}}>
                                <div className="change-btn flex-row" onClick={this.handleChange.bind(this, 'left')}>
                                    <img src={left} className="left-arrow" alt="" />
                                    上一篇
                                </div>
                                <div className="change-btn flex-row" onClick={this.handleChange.bind(this, 'right')}>
                                    下一篇
                                    <img src={right} className="right-arrow" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}

export default Detail