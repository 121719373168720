import React, {
    Component,
    Fragment
} from "react";
import Header from '../../components/mobile/header'
import Detail from "../../components/mobile/detail";
import './index.less'
import {
    Pagination
} from 'antd'
import list from './config'
import { report, count } from '../../common/js/tools'
report()
count()

export default class Development extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bg: require('../../common/imgs/mobile/common_bg.png').default,
            topTitle: require('../../common/imgs/mobile/development/title.png').default,
            showDetail: false,
            list,
            opacity: 1,
            currentPage: 1,
            currentList: [],
            scrollTop: 0,
            selectedIndex: -1,
            showHeader: true,
            lastTop: 0
        }
    }

    componentDidMount() {
        this.handleList()
        let container = document.getElementById('development')
        container.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        let container = document.getElementById('development')
        container.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = (e) => {
        let { scrollTop } = e.srcElement
        let lastTop = this.state.lastTop
        if (scrollTop > lastTop && scrollTop > 1) {
            this.setState({
                lastTop: scrollTop,
                showHeader: false
            })
        } else {
            this.setState({
                lastTop: scrollTop,
                showHeader: true
            })
        }
    }

    handleList() {
        let {
            currentPage,
            list
        } = this.state
        let currentList = list.slice((currentPage - 1) * 10, currentPage * 10)
        currentList = currentList.map((item, index) => {
            item.cover = require(`../../common/imgs/development/cover/${item.name}.png`).default

            return item
        })
        document.getElementById('development').scrollTop = 0
        this.setState({
            currentList,
            topScroll: 0,
            opacity: 1
        })
    }

    handleClick(item, index) {
        console.log(item)
        if (!item.noMain) item.main = require(`../../common/imgs/business/main/${item.name}.png`).default
        if (item.hasSub) item.sub = require(`../../common/imgs/business/sub/${item.name}.png`).default
        this.setState({
            showDetail: true,
            detail: item,
            selectedIndex: index
        })
    }

    handleChild() {
        this.setState({
            showDetail: false
        })
    }

    onChange(page) {
        this.setState({
            currentPage: page
        }, () => {
            this.handleList()
        })
    }

    handleChangeItem(type) {
        let {
            selectedIndex,
            currentList,
            list,
            currentPage
        } = this.state
        if (type === 'left') {
            if (selectedIndex === 0) {
                currentPage = currentPage === 1 ? 4 : currentPage - 1
                currentList = list.slice((currentPage - 1) * 10, currentPage * 10)
                selectedIndex = currentList.length - 1
            } else {
                selectedIndex--
            }
        } else {
            if (selectedIndex === currentList.length - 1) {
                currentPage = currentPage === 4 ? 1 : currentPage + 1
                currentList = list.slice((currentPage - 1) * 10, currentPage * 10)
                selectedIndex = 0
            } else {
                selectedIndex++
            }
        }
        let item = currentList[selectedIndex]
        this.setState({
            currentPage,
            currentList
        })
        this.handleList()
        this.handleClick(item, selectedIndex)
    }

    render() {
        const {
            bg,
            topTitle,
            showDetail,
            list,
            currentList,
            detail,
            showHeader
        } = this.state
        return (
            <div className="mobile-page mobile-development" >
                {showHeader && <Header theme="dark" id="header" />}
                <Detail showDetail={showDetail} parent={this} detail={detail} />
                <img src={bg} className="bg" alt="" />
                <div className="top-cover" id="top-cover" style={{'background': `${showHeader?'#fff':'transparent'}`}}></div>
                <div className="page-content1 flex-column flex-row" id="development" >
                    <img src={topTitle} className="page-title" alt="" />
                    <div className="development-bottom" >
                        { currentList.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div className="item-container" onClick={ this.handleClick.bind(this, item, index) }>
                                            <div className="item-title" > {item.title} </div>
                                            <div className="item-desc" > { item.desc } </div>
                                            <div className="container-bottom flex-row flex-between">
                                                <div className="item-date" > { item.date } </div>
                                                <div className="item-btn flex-center">了解更多</div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            })
                        }
                        <div className="pagination" >
                            <Pagination total={list.length} onChange={ this.onChange.bind(this) } />
                        </div >
                    </div >
                </div>
            </div >
        )
    }
}