import React, { Component, Fragment } from "react";
import Header from '../../components/mobile/header'
import './index.less'
import configs from './config'
import classnames from 'classnames'
import { report, count } from '../../common/js/tools'
report()
count()

export default class Join extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...configs,
            opacity: 1,
            current: 0,
            firstLoad: true,
            showMenu: false,
            type: 'up',
            lastIndex: 0,
            canNext: false,
            showPlus: false,
            showHeader: true,
            lastTop: 0
        }
    }

    moving = false
    initY = 0

    componentDidMount() {
        this.handlePage()
        let container = document.getElementById('join-content')
        container.addEventListener('scroll', this.handleScroll)
        window.addEventListener('touchstart', this.touchStart)
        window.addEventListener('touchend', this.touchEnd)
        window.addEventListener('touchmove', this.touchMove)
    }

    componentWillUnmount() {
        let container = document.getElementById('join-content')
        container.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('touchstart', this.touchStart)
        window.removeEventListener('touchend', this.touchEnd)
        window.removeEventListener('touchmove', this.touchMove)
    }

    handleScroll = (e) => {
        let { scrollTop } = e.srcElement
        let lastTop = this.state.lastTop
        if (scrollTop > lastTop && scrollTop > 1) {
            this.setState({
                lastTop: scrollTop,
                showHeader: false
            })
        } else {
            this.setState({
                lastTop: scrollTop,
                showHeader: true
            })
        }
    }

    touchStart = (e) => {
        this.moving = true
    }

    touchEnd = (e) => {
        this.moving = false
        this.initY = 0
    }

    touchMove = (e) => {
        let join = document.getElementById('join-content')
        let { scrollTop, clientHeight, scrollHeight } = join
        let canNext = !!(scrollTop + clientHeight >= scrollHeight - 1)
        if (!this.moving) return
        let initY = this.initY
        let currentY = e.touches[0].pageY
        if (initY === 0) {
            this.initY = currentY
            return
        }
        // console.log(canNext, initY, currentY)
        if (!canNext && this.state.current===0) return
        let diffY = currentY - initY
        if (Math.abs(diffY) > 1) {
            this.handleTouch(diffY)
            this.moving = false
            this.initY = 0
        }
    }

    handlePage() {
        
    }

    handleTouch(e) {
        let { showMenu, current } = this.state
        if (this.pending || showMenu) return
        let before = current
        let lastIndex = current
        let type = 'up'
        if (e < 0) {
            current < 1 && current++
        } else if (e > 0) {
            current > 0 && current--
            type = 'down'
        }
        this.pending = true
        let p = {
            current,
            type,
            firstLoad: false,
            lastIndex,
            showHeader: false
        }
        if (current===0 && before !== current) {
            document.getElementById('join-content').scrollTop = 0
        }
        this.setState(p)
        setTimeout(() => {
            this.setState({
                showHeader: true
            })
        }, 500)
        setTimeout(() => {
            this.pending = false
        }, 1500)
    }

    handleChildMenu(e) {
        this.setState({
            showMenu: e
        })
    }

    handlePlus() {
        this.setState({
            showPlus: !this.state.showPlus
        })
    }

    render() {
        const { pageList, screen1, screen2, current, firstLoad, type, showPlus, lastIndex, showHeader } = this.state
        return (
            <div className="mobile-join-fragment">
                <div className={classnames('mobile-join', { 'first-load': firstLoad, [`${type}`]: true })} id="join">
                    {current===0 && <Header theme="dark" menuPrevent="true" parent={this} showHeader={showHeader} id="header" />}
                    {pageList.map((item, index) => {
                        return (
                            <div key={index} className={classnames('page-item flex-column flex-row', { 'active': current === index, 'before': lastIndex === index })}>
                                {index===1 && <Header theme="dark" menuPrevent="true" parent={this} id="header" />}
                                <img src={item.bg} className="screen-bg" alt="" />
                                {index === 0 && (
                                    <Fragment>
                                        <div className="top-cover" style={{'background': `${showHeader?'#fff':'transparent'}`}}></div>
                                        <div className="join-content flex-column flex-row" id="join-content">
                                            <img className="join-title" src={screen1.topTitle} alt="" />
                                            {screen1.list.map((item1, index1) => {
                                                return (
                                                    <Fragment key={index1}>
                                                        <div className="item-container" style={{'marginTop': `${index1===0?'3.13rem':'0'}`}}>
                                                            <div className="post">{item1.post}</div>
                                                            <div className="post-title">岗位职责：</div>
                                                            {item1.duty.map((dItem, dIndex) => {
                                                                return (
                                                                    <div key={dIndex} className="post-item">{dIndex + 1}.{dItem}</div>
                                                                )
                                                            })}
                                                            <div className="post-title">职位要求：</div>
                                                            {item1.require.map((rItem, rIndex) => {
                                                                return (
                                                                    <div key={rIndex} className="post-item">{rIndex + 1}.{rItem}</div>
                                                                )
                                                            })}
                                                        </div>
                                                    </Fragment>
                                                )
                                            })}
                                        </div>
                                    </Fragment>
                                )}
                                {index === 1 && (
                                    <Fragment>
                                        <div className="contact-content flex-column flex-row">
                                            <img src={screen2.title} className="contact-title" alt="" />
                                            <div className="contact-bottom">
                                                <div>
                                                    <div className="label">简历投递</div>
                                                    <div className="text">hr@zhiyuancap.com</div>
                                                </div>
                                                <div>
                                                    <div className="label">公司地址</div>
                                                    <div className="text">北京市朝阳区建国路甲92号世茂大厦B座4层</div>
                                                </div>
                                                <div>
                                                    <div className="label">关注公众号</div>
                                                    <img src={screen2.code} className="code pointer" alt="" onClick={this.handlePlus.bind(this)} />
                                                </div>
                                            </div>
                                        </div>
                                        {showPlus && <div className="plus-outer flex-center" onClick={this.handlePlus.bind(this)}><img src={screen2.code} className="plus-code" alt="" /></div>}
                                    </Fragment>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}