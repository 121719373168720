import React, { Component, Fragment } from "react";
import Header from '../../components/pc/header'
import './index.less'
import configs from './config'
import classnames from 'classnames'
import { report, count } from '../../common/js/tools'
report()
count()

export default class Join extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...configs,
            opacity: 1,
            current: 0,
            firstLoad: true,
            showMenu: false,
            type: 'up',
            lastIndex: 0,
            canNext: false,
            showPlus: false
        }
    }

    componentDidMount() {
        this.handlePage()
        window.addEventListener('resize', this.handleResize.bind(this))
        window.addEventListener('keydown', this.bindKeydown.bind(this))
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize.bind(this))
        window.removeEventListener('keydown', this.bindKeydown.bind(this))
        document.getElementById('join-content').removeEventListener('scroll', this.bindHandleScroll)
        document.getElementById('join').removeEventListener('wheel', this.bindHandleWheel)
        document.getElementById('join').removeEventListener('mousewheel', this.bindHandleWheel)
        document.getElementById('join').removeEventListener('DOMMouseScroll', this.bindHandleWheel);
    }

    bindKeydown(e) {
        let { keyCode } = e
        let { current } = this.state
        let lastIndex = current
        let type = 'up'
        if (keyCode === 40) {
            current < 1 && current++
        } else if (keyCode === 38) {
            current > 0 && current--
            type = 'down'
        }
        console.log(current)
        this.pending = true
        this.setState({
            current,
            type,
            firstLoad: false,
            lastIndex
        })
        setTimeout(() => {
            current !== lastIndex && (this.pending = false)
        }, 1500)
    }

    handleResize(e) {
        this.handlePage()
    }

    handlePage() {
        let container = document.getElementById('join')
        let c1 = document.getElementById('join-content')
        let { clientHeight, scrollHeight } = c1
        let overHeight = scrollHeight - clientHeight
        let top = document.getElementById('top')
        let offsetTop = top.offsetTop
        offsetTop = overHeight
        console.log(overHeight, offsetTop===0)
        this.setState({
            offsetTop,
            canNext: offsetTop===0
        })
        c1.addEventListener('scroll', this.bindHandleScroll.bind(this))
        container.addEventListener('wheel', this.bindHandleWheel)
        container.addEventListener('mousewheel', this.bindHandleWheel)
        container.addEventListener('DOMMouseScroll', this.bindHandleWheel);
    }

    bindHandleScroll = (e) => {
        let { scrollTop } = e.srcElement
        let { offsetTop } = this.state
        let opacity
        let topScroll = 0
        if (scrollTop < offsetTop) {
            opacity = 1 - Math.pow((scrollTop / offsetTop), 2)
            topScroll = scrollTop
        } else {
            opacity = 0
            topScroll = offsetTop
        }
        this.setState({
            topScroll,
            opacity, 
            canNext: false
        })
        console.log('scrollTop,offsetTop', scrollTop, offsetTop)
        if (scrollTop === offsetTop) {
            this.pending = true
            // setTimeout(() => {
                this.setState({
                    canNext: true
                }, () => {
                    this.pending = false
                })
            // }, 100)
        }
    }

    bindHandleWheel = (e) => {
        console.log(e)
        let { wheelDelta } = e
        let { showMenu, current, offsetTop } = this.state
        // if (this.pending || showMenu || (current===0 && (!canNext || (canNext && wheelDelta > 0)))) return
        if (this.pending || showMenu) return
        let before = current
        let lastIndex = current
        let type = 'up'
        if (wheelDelta < 0) {
            current < 1 && current++
        } else if (wheelDelta > 0) {
            current > 0 && current--
            type = 'down'
        }
        this.pending = true
        let p = {
            current,
            type,
            firstLoad: false,
            lastIndex
        }
        if (current===0 && before !== current) {
            p.topScroll = 0
            p.opacity = 1
            p.canNext = !!(offsetTop===0)
            document.getElementById('join-content').scrollTop = 0
        }
        this.setState(p)
        setTimeout(() => {
            this.pending = false
        }, 1500)
    }

    handleChildMenu(e) {
        this.setState({
            showMenu: e
        })
    }

    handlePlus() {
        console.log(1)
        this.setState({
            showPlus: !this.state.showPlus
        })
    }

    render() {
        const { pageList, screen1, screen2, opacity, topScroll, current, firstLoad, type, showPlus } = this.state
        return (
            <div className="pc-join-fragment">
                <div className={classnames('pc-join', { 'first-load': firstLoad, [`${type}`]: true })} id="join">
                    <div className="scroll-track">
                        <div className="scroll-bar" style={{top: `${current * 50}vh`}}></div>
                    </div>
                    {pageList.map((item, index) => {
                        return (
                            <div key={index} className={classnames('page-item flex-column flex-row', { 'active': current === index })}>
                                <Header theme="dark" menuPrevent="true" parent={this} />
                                <img src={item.bg} className="screen-bg" alt="" />
                                <img src={item.page} className="page-num" alt="" />
                                {index === 0 && (
                                    <Fragment>
                                        <div className="top-cover"></div>
                                        <div className="join-content flex-column flex-row" id="join-content">
                                            <img className="top-title" src={screen1.topTitle} alt="" id="top" style={{ opacity: `${opacity}`, top: `-${topScroll}px` }} />
                                            <div className="join-bottom">
                                                <div className="bottom-left">
                                                    <img src={screen1.leftTitle} className="left-title" alt="" style={{ opacity: `${1 - opacity}` }} />
                                                </div>
                                                <div className="bottom-right" id="join-bottom">
                                                    {screen1.list.map((item1, index1) => {
                                                        return (
                                                            <Fragment key={index1}>
                                                                <div className="item-container">
                                                                    <div className="post">职位介绍 | {item1.post}</div>
                                                                    <div className="post-title">岗位职责：</div>
                                                                    {item1.duty.map((dItem, dIndex) => {
                                                                        return (
                                                                            <div key={dIndex} className="post-item">{dIndex + 1}.{dItem}</div>
                                                                        )
                                                                    })}
                                                                    <div className="post-title">职位要求：</div>
                                                                    {item1.require.map((rItem, rIndex) => {
                                                                        return (
                                                                            <div key={rIndex} className="post-item">{rIndex + 1}.{rItem}</div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                {index === 1 && (
                                    <Fragment>
                                        <div className="contact-content flex-column flex-row">
                                            <img src={screen2.title} className="contact-title" alt="" />
                                            <div className="contact-bottom">
                                                <div>
                                                    <div className="label">简历投递</div>
                                                    <div className="text">hr@zhiyuancap.com</div>
                                                </div>
                                                <div>
                                                    <div className="label">公司地址</div>
                                                    <div className="text">北京市朝阳区建国路甲92号世茂大厦B座4层</div>
                                                </div>
                                                <div>
                                                    <div className="label">关注公众号</div>
                                                    <img src={screen2.code} className="code pointer" alt="" onClick={this.handlePlus.bind(this)} />
                                                </div>
                                            </div>
                                        </div>
                                        {showPlus && <div className="plus-outer flex-center" onClick={this.handlePlus.bind(this)}><img src={screen2.code} className="plus-code" alt="" /></div>}
                                    </Fragment>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}