import React, { Component } from "react";
import Header from '../../components/pc/header'
import './index.less'
import configs from "./config";
import classnames from 'classnames'
import { report, count } from '../../common/js/tools'
report()
count()

export default class Team extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...configs,
            selectedIndex: -1,
            showDetail: false, 
            detail: {},
            transType: ''
        }
    }

    componentDidMount() {
        
    }

    handleClick(index) {
        let item = this.state.list[index]
        this.setState({
            selectedIndex: index,
            showDetail: true,
            detail: item
        })
    }

    handleChild() {
        this.setState({
            showDetail: false,
            selectedIndex: -1
        })
    }

    handleChange(type) {
        let { selectedIndex }  = this.state
        if (type==='left') {
            selectedIndex = selectedIndex===0 ? 4 : selectedIndex-1
        } else {
            selectedIndex = selectedIndex===4 ? 0 : selectedIndex+1
        }
        this.setState({
            transType: type
        }, () => {
            setTimeout(() => {
                this.setState({
                    transType: ''
                })
                this.handleClick(selectedIndex)
            }, 400)
        })
    }

    doNothing(e) {
        e.stopPropagation();
    }

    render() {
        const { bg, title, list, selectedIndex, showDetail, detail, left, right, close, transType } = this.state
        return (
            <div className="pc-page pc-team">
                <Header theme="dark" />
                <img src={bg} className="bg" alt="" />
                <div className="page-content flex-column flex-row">
                    <img src={title} className="page-title" alt="" />
                    <div className="team-tip">致远资本拥有一支有思想有温度有担当、服务精良、锐意进取、追求卓越的专业团队</div>
                    <div className="team-imgs">
                        {list.map((item ,index) => {
                            return (
                                <div key={index} className={classnames('img-box', {'selected': selectedIndex===index})} onClick={this.handleClick.bind(this, index)}>
                                    <img src={item.default} alt="" className="default" />
                                    <img src={item.active} alt="" className="active" />
                                    {item.needCover && <div className="name-cover"></div>}
                                    <div className={classnames('name', {[`${item.theme}`]: true})}><div className="name-line"></div>{item.name}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {showDetail && (
                    <div className="mask" onClick={this.handleChild.bind(this)}>
                        <div className="detail-wrapper" onClick={this.doNothing.bind(this)}>
                            <img src={close} alt="" className="close" onClick={this.handleChild.bind(this)} />
                            <img src={left} alt="" className="arrow" onClick={this.handleChange.bind(this, 'left')} />
                            <div className="detail-box">
                                <div className={classnames('box-content', {[`${transType}`]: !!transType})}>
                                    <img src={detail.active} alt="" className="avatar" />
                                    <div className="detail-right">
                                        <div className="cn-name">{detail.name}</div>
                                        <div className="en-name">{detail.enName}</div>
                                        <div className="team-title">{detail.title}</div>
                                        <div className={classnames('team-content', {'extra': selectedIndex===0})}>
                                        {detail.experience.map((eItem, eIndex) => {
                                            return (
                                                <div key={eIndex} className="flex experience-item">
                                                    <div className="item-text">{eItem}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <img src={right} alt="" className="arrow" onClick={this.handleChange.bind(this, 'right')} />
                        </div>
                    </div>
                )}
            </div>
        )
    }
}