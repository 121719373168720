import React, {
    Component,
    Fragment
} from "react";
import Header from '../../components/pc/header'
import Detail from "../../components/pc/detail";
import './index.less'
import {
    Pagination
} from 'antd'
import list from './config'
import { report, count } from '../../common/js/tools'
report()
count()

export default class Development extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bg: require('../../common/imgs/common_bg3.png').default,
            topTitle: require('../../common/imgs/development/top_title.png').default,
            leftTitle: require('../../common/imgs/development/left_title.png').default,
            showDetail: false,
            list,
            opacity: 1,
            currentPage: 1,
            currentList: [],
            scrollTop: 0,
            selectedIndex: -1
        }
    }

    componentDidMount() {
        this.handleList()
    }

    componentWillUnmount() {
        document.getElementById('development').removeEventListener('scroll', this.bindHandleScroll)
    }

    initScroll() {
        let container = document.getElementById('development')
        let {
            clientHeight,
            scrollHeight
        } = container
        let overHeight = scrollHeight - clientHeight
        let top = document.getElementById('top')
        let offsetTop = top.offsetTop
        if (overHeight < offsetTop) offsetTop = overHeight
        this.setState({
            offsetTop
        })
        container.addEventListener('scroll', this.bindHandleScroll)
    }

    bindHandleScroll = (e) => {
        let {
            scrollTop
        } = e.srcElement
        let {
            offsetTop
        } = this.state
        let opacity
        let topScroll = 0
        if (scrollTop < offsetTop) {
            opacity = 1 - Math.pow((scrollTop / offsetTop), 2)
            topScroll = scrollTop
        } else {
            opacity = 0
            topScroll = offsetTop
        }
        this.setState({
            topScroll,
            opacity
        })
    }

    handleList() {
        let {
            currentPage,
            list
        } = this.state
        let currentList = list.slice((currentPage - 1) * 10, currentPage * 10)
        currentList = currentList.map((item, index) => {
            item.cover = require(`../../common/imgs/development/cover/${item.name}.png`).default

            return item
        })
        document.getElementById('development').scrollTop = 0
        this.setState({
            currentList,
            topScroll: 0,
            opacity: 1
        }, () => {
            this.initScroll()
        })
    }

    handleClick(item, index) {
        console.log(item)
        if (!item.noMain) item.main = require(`../../common/imgs/business/main/${item.name}.png`).default
        if (item.hasSub) item.sub = require(`../../common/imgs/business/sub/${item.name}.png`).default
        this.setState({
            showDetail: true,
            detail: item,
            selectedIndex: index
        })
    }

    handleChild() {
        this.setState({
            showDetail: false
        })
    }

    onChange(page) {
        this.setState({
            currentPage: page
        }, () => {
            this.handleList()
        })
    }

    handleChangeItem(type) {
        let {
            selectedIndex,
            currentList,
            list,
            currentPage
        } = this.state
        if (type === 'left') {
            if (selectedIndex === 0) {
                currentPage = currentPage === 1 ? 4 : currentPage - 1
                currentList = list.slice((currentPage - 1) * 10, currentPage * 10)
                selectedIndex = currentList.length - 1
            } else {
                selectedIndex--
            }
        } else {
            if (selectedIndex === currentList.length - 1) {
                currentPage = currentPage === 4 ? 1 : currentPage + 1
                currentList = list.slice((currentPage - 1) * 10, currentPage * 10)
                selectedIndex = 0
            } else {
                selectedIndex++
            }
        }
        let item = currentList[selectedIndex]
        this.setState({
            currentPage,
            currentList
        })
        this.handleList()
        this.handleClick(item, selectedIndex)
    }

    render() {
        const {
            bg,
            topTitle,
            leftTitle,
            showDetail,
            list,
            opacity,
            currentList,
            detail,
            topScroll
        } = this.state
        return (
            <div className="pc-page pc-development" >
                <Header theme="dark" />
                <Detail showDetail={showDetail} parent={this} detail={detail} />
                <img src={bg} className="bg" alt="" />
                <div className="top-cover"></div>
                <div className="page-content1 flex-column flex-row" id="development" >
                    <img src={topTitle} className="page-title" alt="" style={{ opacity: `${opacity}`, top: `-${topScroll}px` }} id="top" />
                    <div className="development-bottom" >
                        <div className="bottom-left" >
                            <img src={leftTitle} className="left-title" alt="" style={{ opacity: `${1 - opacity}` }} />
                        </div>
                        <div className="bottom-right" id="right" >
                            { currentList.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div className="item-container flex pointer" onClick={ this.handleClick.bind(this, item, index) } >
                                                <img className="item-img" src={ item.cover } alt="" style={{'height': `${item.extraHeight || 15}rem`}} />
                                                <div className="item-content flex-column flex-between" >
                                                    <div>
                                                        <div className="item-title" > {item.title} </div>
                                                        <div className="item-desc" > { item.desc } </div> 
                                                    </div >
                                                    <div className="item-date" > { item.date } </div>
                                                </div >
                                            </div> 
                                            { (index < currentList.length - 1) && (<div className="divider" > </div>)} 
                                        </Fragment>
                                    )
                                })
                            }
                            <div className="pagination" >
                                <Pagination total={list.length} onChange={ this.onChange.bind(this) } />
                            </div >
                        </div>
                    </div >
                </div>
            </div >
        )
    }
}