const configs = {
    pageList: [{
            bg: require('../../common/imgs/common_bg2.png').default,
            page: require('../../common/imgs/join/p1.png').default
        },
        {
            bg: require('../../common/imgs/common_bg2.png').default,
            page: require('../../common/imgs/join/p2.png').default
        }
    ],
    screen1: {
        topTitle: require('../../common/imgs/join/top_title.png').default,
        leftTitle: require('../../common/imgs/join/left_title.png').default,
        list: [{
            post: '分析师/投资经理',
            duty: [
                '对特定行业能够进行系统性研究和跟踪，并对行业有深入理解；',
                '协助进行行业内新兴公司的寻找和跟踪，较为独立的负责投资项目的执行，包括项目筛选与分析、客户接触与走访及后续投融资相关事宜；',
                '协助起草/撰写商业计划书，筹备财务分析及公司价值评估等材料，为客户提供融资方案；',
                '负责维护客户、投资人及公司内部相关部门关系；',
                '负责在整个投资交易过程中，把控风险、提供多角度咨询服务；',
                '拓展和维护投资人资源。'
            ],
            require: [
                '工作经验1年以上，知名院校本科及以上学历，理工科+金融复合背景优先；',
                '有风投、投行、咨询公司或互联网公司从业经历者优先；',
                '较强的研究分析能力和清晰的逻辑思维能力；',
                '较强的沟通能力与抗压能力。'
            ]
        }, {
            post: '实习生',
            duty: [
                '对特定行业能够进行系统性研究和跟踪，并对行业有深入理解；',
                '协助进行行业内新兴公司的寻找和跟踪，协助投资项目的执行，包括项目筛选与分析、客户接触与走访及后续投融资相关事宜；',
                '协助起草/撰写商业计划书，筹备财务分析及公司价值评估等材料，为客户提供融资方案。'
            ],
            require: [
                '本科及以上优秀在读和应届生均可，理工科+金融复合背景优先；',
                '较强的研究分析能力和清晰的逻辑思维能力。'
            ]
        }]
    },
    screen2: {
        title: require('../../common/imgs/contact/title.png').default,
        code: require('../../common/imgs/contact/code.png').default,
    }
}

export default configs