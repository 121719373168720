import React, { Fragment } from 'react'
import './index.less'
import classnames from 'classnames'

class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            isOut: false,
            close: require('../../../common/imgs/close.png').default,
            left: require('../../../common/imgs/left.png').default,
            right: require('../../../common/imgs/right.png').default,
            transType: ''
        }
    }

    static defaultProps = {
        showDetail: false,
        detail: {}
    }

    handleDetail() {
        let showDetail = false
        let waitTime = 500
        this.setState({
            isOut: true
        })
        setTimeout(() => {
            this.props.parent.handleChild(false)
        }, 400)
        setTimeout(() => {
            this.setState({
                showDetail,
                isOut: false
            })
        }, waitTime)
    }

    handleChange(type) {
        this.setState({
            transType: type
        }, () => {
            setTimeout(() => {
                this.setState({
                    transType: ''
                })
                let container = document.getElementById('outer')
                container.scrollTop = 0
                this.props.parent.handleChangeItem(type)
            }, 400)
        })
    }

    render() {
        const { isOut, close, left, right, transType } = this.state
        const { showDetail, detail } = this.props
        return (
            <Fragment>
                {showDetail && (
                    <div className={classnames('pc-detail-container flex', {
                        'detail-out': isOut
                    })}>
                        <div className="left" onClick={this.handleDetail.bind(this)}></div>
                        <div className="right">
                            <img src={close} className="close" alt="" onClick={this.handleDetail.bind(this)} />
                            <img src={left} alt="" className="arrow" onClick={this.handleChange.bind(this, 'left')} />
                            <div className="outer" id="outer">
                                <div className={classnames('outer-content', { [`to-${transType}`]: !!transType })}>
                                    {detail.main && <img src={detail.main} className="detail-img" alt="" />}
                                    <div className="detail-title">{detail.title}</div>
                                    {detail.hasSub && <img src={detail.sub} className="sub-img" alt="" />}
                                    {detail.content.map((item, index) => {
                                        return (
                                            <Fragment key={index}>
                                                {(typeof item === 'object' && (item.type==='img' || item.type==='html')) ? (
                                                    <Fragment>
                                                        {(item.type === 'img') && (
                                                            <img src={item.url} className="content-img" style={{'width': '100%'}} alt="" />
                                                        )}
                                                        {(item.type === 'html') && (
                                                            <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <div key={index} className={classnames('detail-text', { 'first-text': index === 0, [`${typeof item === 'string' ? 'normal' : item.type}`]: true })}>{typeof item === 'string' ? item : item.text}</div>
                                                )}
                                            </Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                            <img src={right} alt="" className="arrow" onClick={this.handleChange.bind(this, 'right')} />
                        </div>
                        <div className="right1"></div>
                        <div className="right2"></div>
                    </div>
                )}
            </Fragment>
        )
    }
}

export default Detail