import React from 'react'
import './index.less'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            logo: {
                light: require('../../../common/imgs/light_logo.png').default,
                dark: require('../../../common/imgs/dark_logo.png').default
            },
            menu: {
                light: require('../../../common/imgs/light_menu.png').default,
                dark: require('../../../common/imgs/dark_menu.png').default
            },
            close: require('../../../common/imgs/close.png').default,
            menuList: [
                {
                    label: '关于致远',
                    path: '/'
                },
                {
                    label: '致远团队',
                    path: '/team'
                },
                {
                    label: '致远交易',
                    path: '/business'
                },
                {
                    label: '致远动态',
                    path: '/development'
                },
                {
                    label: '加入我们',
                    path: '/join'
                },
            ],
            showMenu: false,
            isOut: false,
            currentPath: '/'
        }
    }

    isMoving = false

    static defaultProps = {
        theme: 'light',
        isHome: false,
        menuPrevent: false,
        showHeader: true
    }

    componentDidMount() {
        let currentPath = this.props.match.path
        console.log('currentPath', currentPath)
        this.setState({
            currentPath
        })
    }

    handleMenu() {
        if (this.isMoving) return
        this.isMoving = true
        let showMenu = !this.state.showMenu
        let waitTime = showMenu ? 0 : 400
        this.setState({
            isOut: !showMenu
        })
        this.props.menuPrevent && this.props.parent.handleChildMenu(showMenu)
        setTimeout(() => {
            this.setState({
                showMenu,
                isOut: false
            }, () => {
                this.isMoving = false
            })
        }, waitTime)
    }

    handleClickItem(item) {
        let path = this.props.match.path
        if (path === item.path) {
            window.location.reload()
        } else {
            this.props.history.push(item.path)
        }
    }

    render() {
        const { theme, isHome, showHeader } = this.props
        const { logo, menu, showMenu, isOut, menuList, close, currentPath } = this.state
        return (
            <div className="mobile-header" style={{'opacity': showHeader ? 1 : 0}}>
                <img src={logo[theme]} className={classnames('logo', {'home-logo': isHome})} alt="" onClick={this.handleClickItem.bind(this, {path: '/'})} />
                <img src={menu[theme]} className={classnames('menu', {'home-menu': isHome})} alt="" onClick={this.handleMenu.bind(this)} />
                {showMenu && (
                    <div className={classnames('menu-container flex', {
                        'menu-out': isOut
                    })}>
                        <div className="left" onClick={this.handleMenu.bind(this)}></div>
                        <div className="right flex-column flex-row">
                            <img src={close} className="close" alt="" onClick={this.handleMenu.bind(this)} />
                            <img src={logo.dark} className="menu-logo" alt="" onClick={this.handleClickItem.bind(this, {path: '/'})} />
                            <div className="menu-list flex-column flex-row">
                                {menuList.map((item, index) => {
                                    return (
                                        <div key={index} className={classnames('menu-item', {'active-item': currentPath === item.path})} onClick={this.handleClickItem.bind(this, item)}>{item.label}</div>
                                    )
                                })}
                            </div>
                            {/* <div> */}
                                {/* <div className="bottom-tip"> 1998-2021 BeyondCapital.  All Rights Reserved. 致远资本 版权所有</div> */}
                                {/* <a className="bottom-tip" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noreferrer">京ICP备2021031266号-1</a> */}
                            {/* </div> */}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(Header)