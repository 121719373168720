export const judgeIsMobile = () => {
    return !!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian)/i))
}

export const report = () => {
    console.log('上报百度')
    var bp = document.createElement('script');
    var curProtocol = window.location.protocol.split(':')[0];
    if (curProtocol === 'https') {
        bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
    }
    else {
        bp.src = 'http://push.zhanzhang.baidu.com/push.js';
    }
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(bp, s);
}

export const count = () => {
    console.log('百度统计')
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?cd963090755ae4121999a59ff78b8266";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
}