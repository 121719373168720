const configs = {
    beian: require('../../common/imgs/备案.png').default,
    pageList: [{
            type: 'fix',
            url: require('../../common/imgs/screen1.jpg').default,
            page: require('../../common/imgs/p1.png').default,
            next: require('../../common/imgs/next1.png').default
        },
        {
            type: 'img',
            url: require('../../common/imgs/screen2.png').default,
            page: require('../../common/imgs/p2.png').default,
            next: require('../../common/imgs/next2.png').default
        },
        {
            type: 'img',
            url: require('../../common/imgs/common_bg2.png').default,
            page: require('../../common/imgs/p3.png').default,
            next: require('../../common/imgs/next2.png').default
        },
        {
            type: 'img',
            url: require('../../common/imgs/screen4.png').default,
            page: require('../../common/imgs/p4.png').default,
            next: require('../../common/imgs/next2.png').default
        },
        {
            type: 'img',
            url: require('../../common/imgs/screen5.png').default,
            page: require('../../common/imgs/p5.png').default
        }
    ],
    screen2: {
        title: require('../../common/imgs/screen2/title.png').default,
        content_bg: require('../../common/imgs/screen2/content_bg.png').default,
        cases: [{
                content: '专注服务高科技、企业服务、大消费等行业，致力于为新经济行业提供专业财务顾问服务',
                url: require('../../common/imgs/screen2/1.png').default
            },
            {
                content: '团队成员来自国内一线金融机构和新经济公司，具有非常丰富的投资和投行经验',
                url: require('../../common/imgs/screen2/2.png').default
            },
            {
                content: '和资本市场主流投资机构建立紧密合作关系，精准高效完成私募融资服务',
                url: require('../../common/imgs/screen2/3.png').default
            }
        ]
    },
    screen3: {
        title: require('../../common/imgs/service/title.png').default,
        path: require('../../common/imgs/service/path.png').default,
        steps: [{
                text: '业务深度理解，准备融资材料',
                url: require('../../common/imgs/service/1.png').default,
                bg: require('../../common/imgs/service/bg1.png').default,
                detail: '深度理解公司商业模式及业务状况，梳理及确定资本市场故事。准备商业计划书、财务模型等全部融资材料',
                space: [1.63, 2.38]
            },
            {
                text: '遴选优质投资人做项目推介及路演',
                url: require('../../common/imgs/service/2.png').default,
                bg: require('../../common/imgs/service/bg2.png').default,
                detail: '综合考虑投资人领域、偏好、决策能力等因素，为创业者推介最合适的投资人',
                space: [2.25, 2.38]
            },
            {
                text: '充分沟通，谈判及签署投资意向书',
                url: require('../../common/imgs/service/3.png').default,
                bg: require('../../common/imgs/service/bg3.png').default,
                detail: '作为创业企业的资本市场代言人，与投资人充分沟通、设计交易结构，协助谈判和签署投资条款，争取最优方案',
                space: [2.38, 2.75]
            },
            {
                text: '全程协助尽职调查',
                url: require('../../common/imgs/service/4.png').default,
                bg: require('../../common/imgs/service/bg4.png').default,
                detail: '全程协助尽职调查，准备相关材料，推动和组织各相关机构的工作，确保尽职调查顺利进行',
                space: [2, 2.38]
            },
            {
                text: '谈判及签署交易文件',
                url: require('../../common/imgs/service/5.png').default,
                bg: require('../../common/imgs/service/bg5.png').default,
                detail: '综合考虑投资条款，协调多方利益，保证交割按时顺利完成',
                space: [1.69, 2.5]
            },
            {
                text: '投后媒体传播服务',
                url: require('../../common/imgs/service/6.png').default,
                bg: require('../../common/imgs/service/bg6.png').default,
                detail: '免费协助进行媒体传播及公关策略建议，融资新闻发布和深度采访推荐',
                space: [2.31, 2.38]
            }
        ]
    },
    screen4: [
        require('../../common/imgs/screen4/line1.png').default,
        require('../../common/imgs/screen4/line2.png').default
    ],
    screen5: {
        title: require('../../common/imgs/screen5/title.png').default,
        cases: [{
                name: '犀有数科',
                url: require('../../common/imgs/screen5/犀有数科.png').default,
                w: 8.75,
                h: 4.75,
                type: 1,
                title: '「犀有数科」完成A轮超亿元融资，致远资本担任独家财务顾问',
                content: [
                    '近日，犀有数字科技(下称"犀有数科"）宣布完成超亿元A轮融资。本轮融资由新犁资本领投，致远资本跟投，致远资本担任独家财务顾问。本轮所募集资金将用于智慧印染生态升级、产业大数据建设、数字化面料实验室扩容等。',

                    '犀有数科成立于2020年，定位以工艺和研发数字化为抓手，ODM以销定产柔性制造为模式，全面打造面料数字供应链生态，实现数据和技术驱动的纺织行业智能制造云工厂。犀有数科是产业互联网3.0阶段的领军企业，打造“端端链接+商品标准->柔性供应”。',

                    '犀有数科通过数字化体系，定向研发及推荐面料到品牌客户，通过列为中国制造2025标杆的自营智能工厂承接客户的测款首单并沉淀可复制的数字化工艺，然后基于客户销售情况调度工艺及产能最为匹配的生态伙伴协同快反交付犀有数科标准的面料商品。犀有数科CEO张帅预测 “ODM型面料快反的市场增长空间首先会覆盖原现货面料供应链市场，每年会有千亿级的供给替代需求。”',

                    '犀有数科拥有“宽度+深度“兼备的顶配团队。CEO张帅是材料学博士后，作为阿里巴巴犀牛智造联合创始人，完成和见证了犀牛智造”从0到1”的落地，在Decathlon任职期间带领团队研发成功用于冲锋衣的“防风防水透湿透气”的纳米科技微多孔膜。董事长&CPO（首席产品官&首席工艺官）卫金龙先生是中国最早染整专业科班出身，深耕印染近30年，被誉为针织印染行业工匠第1人、数字化印染工厂成功实践第1人，曾领导裕民针织成功上市（HK00844）。COO陶张木先生是前智布互联联合创始人，高级副总裁，拥有10年以上纺织印染行业从业及行业级工业互联网项目成功落地经验，是行业最早成功从0到1打造纺织印染行业数字供应链信息化平台的领军人才。',

                    '新犁资本主管合伙人李绪富表示：“这是我们近三年来布局和打造的第十二个产业生态共同体：以产业为标的、以全局为视野、以数字孪生为特征、自迭代自演绎自成长的生态共同体。与时俱进的是，过往我们更多的从供应链着手，犀有科技已经超越供应链，在数字化研发和数字化工艺的基础上直接切入智能生产和智能驱动，底层驱动也是在积累行业经验、知识、技术、共识的基础上融合了互联网技术、大数据技术和人工智能技术，因而我们的产业生态共同体也迭代为更具象的表达：产业大脑+订单入口+智能驱动+供应链平台，很是期待。”',
                ]
            },
            {
                name: '星辰天合',
                url: require('../../common/imgs/screen5/星辰天合.png').default,
                w: 11.81,
                h: 3.06,
                type: 1,
                title: '「XSKY」完成E轮7.1亿元融资，致远资本连续担任财务顾问',
                content: [
                    '2021 年9月3日， 星辰天合（ 北京） 数据科技有限公司（“ XSKY星辰天合”） 宣布完成E轮7 .1 亿元人民币股权融资。 本轮融资由博裕投资领投， 君联资本、 中金甲子、 博华资本、 昆仑资本、 恒生电子、 苏州毅商等跟投， 原股东北极光创投、 启明创投等亦参与跟投， 致远资本连续担任财务顾问。',
                    'XSKY星辰天合成立于2015年， 是一家专注于软件定义基础架构业务的中国高新技术企业。 在六年时间里成长为国内领先的数据基础设施技术平台提供商， 产品和服务获得了超1000家大型政企机构的大规模商业化部署， 客户涵盖政府、 金融、 运营商、 科技、 医疗、 制造、 航天、 司法、 广电、 教育、 能源、 交通、 物流等数字经济社会的关键领域， 支撑着行业云、 私有云、 桌面云、 数据库资源池、 海量媒体数据、 影像数据、 智能制造数据， Hadoop 大数据等不同类型的应用场景。',

                    'IDC数据显示， 星辰天合在2020年SDS中国市场整体份额分布中， 是榜单前五中唯一一家专业的软件定义存储厂商。 特别在大数据和云存储有关的对象存储领域， 星辰天合连续3年占据国内市场第一位。 公司目前申请和获得350项自主知识产权， 约70项核心算法专利， 并多次牵头参与云存储资源管理、 分布式存储等国家标准及行业标准的制定。 在2021年7月23日工信部公布的《 关于第三批专精特新“ 小巨人” 企业名单的公示》 中， XSKY星辰天合位列其中。',

                    '公司将利用本次所融资金进一步加大核心技术研发投入， 拓展云边协同的新产品形态和商业模式， 加强对关键行业客户的服务能力。 未来， 公司将持续为以数字基础设施为核心的“ 新基建” 注入新动能， 并以数据常青为使命， 致力于成为全球领先的数据基础设施技术平台提供商。',
                ]
            },
            {
                name: '新核云',
                url: require('../../common/imgs/screen5/新核云.png').default,
                w: 14,
                h: 4.5,
                type: 1,
                title: '「新核云」完成C轮近2亿元融资，致远资本连续担任独家财务顾问',
                content: [
                    '近日，「 新核云」 宣布完成2亿元C轮融资， 由小米战投、 顺为资本领投， 北极光创投与同创伟业持续加码， 致远资本连续担任独家财务顾问。 这是今年以来， 这家工业智能软件服务商完成的第二轮融资， 年初新核云刚刚完成由同创伟业、 IDG资本领投， 北极光创投和蓝江投资跟投的1 .5 亿元B + 轮融资。',

                    '新核云为上海纽酷信息科技有限公司（ 纽酷） 打造的一款面向离散型制造业工厂的数字化系统产品， 其包括云MES、 云ERP、 TPM、 TQM四大模块， 可通过数据采集、 分析、 决策助力工厂完成数字化转型， 目前产品主要落地在汽车零部件制造领域， 客户包括： 新奥集团、 中轴集团等',

                    '纽酷是一家工业智能化软件服务商， 成立于2014年， 企业专注于工业物联网、 工业大数据、 以及AI技术在工业场景中的落地应用， 赋能工厂逐步实现向数字化、 联网化、 智能化的升级。',

                    '新核云的MES系统本质上是一套面向制造企业车间执行层的管理系统， 通过将设备联网， 可向企业提供包括制造数据管理、 计划排产管理、 生产调度管理、 库存管理、 质量管理等多种功能模块。 即使一家大型企业可能会使用到CRM、 PLM、 ERP、 MES等多种数字化管理系统， 但对于制造业企业来说， MES系统服务于车间制造流程， 是执行层与管理层的连接中枢， 是数字化生产的核心。 我国早在2016年印发的《 关于开展2016年智能制造试点示范项目推荐的通知》 中就指出， 离散型智能制造模式和流程型智能制造模式必须建立MES系统。',

                    '近年来MES系统受到了资本和市场的双方青睐。 据公开资料显示， 2020 年MES系统在我国工业软件市场中占比为29 % ，在今年上半年， 国内市场获得融资的MES企业近十家， 其中就包括了新核云。 市场方面， 随着产业发展， 大量的中小型工厂也正在逐渐摆脱“ 催物料、 吵计划、 抄记录” 的境况， 这极大刺激了国内制造业企业对MES系统的需求。',

                    '今年2月初， 工业软件入选了科技部国家重点研发计划首批重点专项， 这标志着工业软件已成为国家科技领域最高级别的战略部署。 同时， 已经持续近两年新冠疫情加速了制造业尤其是中小型制造业数字化转型的进程， 企业对生产管理系统的付费意愿明显提升， 并希望利用管理系统提高自身的生产效率。',

                    '新核云的云MES系统可在车间层对排产、 报工、 质量、 生产等业务流程实现数字化， 并可通过云ERP系统将车间信息与其他协同系统打通， 解决了管理与执行之间的割裂问题。 结合自主研发的工业网关， 新核云在进行解决方案部署时可以更加高效地帮助用户采集、 分析设备数据。',

                    '黄培博士的《 2020 智能工厂建设与MES应用报告》 指出， 目前国内MES系统的产品化程度仍较低， 制造企业更多以自主研发或合作开发的方式实施MES系统。 一些MES系统提供商由于缺少行业经验， 无法真正理解用户的需求， 用户的工程人员和提供商的IT人员需要进行长期的诉求确认， 在这种情况下， 以项目形式推动解决方案的落地将存在很大风险。',

                    '新核云CEO陶滨江表示：“ 很多MES系统项目落地的周期都非常长， 有一些甚至会不了了之。”',

                    '新核云的MES系统以SaaS模式， 为领域制造企业提供云MES、 云ERP、 TPM、 TQM业务服务。 除了面向大型客户可提供定制化的总包服务外， 面对行业腰部客户， 新核云的产品可独立成60多个功能模组进行单独售卖， 如生产管理、 WEB端报工、 过程质量管理、 来料质量、 订单、 采购、 库存、 库存结存、 成本核算等等。 用户可根据自身实际需求自主选择， 避免交付周期长和项目烂尾情况。',

                    '随着制造业的数字化开始向生产环节的上下游渗透， 游新核云作为零部件制造领域的数字化解决方案提供商， 也正通过开放接口， 引入各领域合作伙伴来打造数字化生态体系。 目前已实现与CRM、 OA、 ERP、 PLM、 PDM、 数字孪生、 MDC、 SCRM等细分领域服务商的产品联通， 可为用户提供覆盖企业多部门。 多业务场景的集成方案。',

                    '根据新核云提供的数据显示， 采用新核云MES产品或解决方案后， 企业设备OEE平均提升10 % 以上； 库存周转次数平均提升15 % 以上； 设备故障率平均下降3 % ；质量报废率平均下降15 % 。目前新核云拥有用户超过1000家， 其营收大部分也来源于产品年费， 2020 年全年营收超亿元。',

                    '新核云在汽车零部件制造业的长期积累使得新核云的产品使用门槛较低， 相关用户可以快速上线。 事实上， 专注于某一行业也是国内MES系统厂商需要重点布局的方向， 例如摩尔元数聚焦于电子组装、 PCBA行业； 黑湖聚焦于食品、 医药领域等。',

                    '业界普遍认为， MES系统的可复制性较低， 需要依托细分行业进行发展。 长远来看， MES系统或很难做到跨多个行业的标准化， 尤其对于国产MES系统厂商来说， 专注某一领域是建立行业壁垒的重要途径。',
                ]
            },
            {
                name: '芯翼信息科技',
                url: require('../../common/imgs/screen5/芯翼信息科技.png').default,
                w: 6.19,
                h: 5.81,
                type: 1,
                title: '「芯翼信息科技」完成B轮5亿元融资，致远资本连续担任财务顾问',
                content: [
                    '近日， 物联网智能终端系统SoC芯片提供商芯翼信息科技（ 上海） 有限公司（ 以下简称： 芯翼信息科技或公司） 完成近5亿元B轮融资， 资金主要用于加强芯片产品研发、 完善生产制造供应链、 扩充核心团队等。 本轮投资由中金甲子、 招银国际联合领投， 招商局资本、 宁水集团、 亚昌投资等跟投， 另外老股东峰瑞资本、 晨道资本、 华睿资本等持续加注， 致远资本持续担任公司财务顾问参与本轮融资。',

                    '芯翼信息科技是一家专注于物联网智能终端系统SoC芯片研发的高新技术企业。 公司创始人及核心研发团队来自于美国博通、 迈凌、 瑞昱、 海思、 展锐、 中兴等全球知名芯片设计和通信公司， 毕业于TAMU、 UCLA、 UT Dallas、 NUS、 北大、 浙大、 东南、 电子科大等海内外知名高校， 具有专业技术完备且国际顶尖的芯片研发能力。 2020 年6月， 公司牵头获得了科技部“ 国家重点研发计划” 光电子与微电子器件及集成重点专项项目， 成为为数极少的获此殊荣的初创企业。',

                    '2018 年， 芯翼信息科技推出的NB - IoT系统SoC芯片XY1100， 是全球首颗片内集成CMOS PA的NB - IoT芯片， 具有超高集成度、 超低功耗、 灵活性强、 成本低等四大核心优势。 这款芯片率先以完全自主研发、 自主创新的架构， 突破了全球蜂窝通信芯片开发的集成度瓶颈， 首次流片即获成功、 顺利实现量产， 并引领了截止目前全球NB - IoT芯片的技术发展潮流。 目前已广泛用于智能表计（ 燃气表、 水表等）、 智能烟感、 资产追踪、 智能换电、 共享电单车管理、 智能家居等物联网应用场景。',

                    '同时， 公司自主研发的下一代NB - IoT系统SoC芯片XY2100， 以替代外置低功耗MCU的独家创新， 再一次突破了业界在片内集成独立式MCU的架构， 解决了在极致低功耗和极致睡眠唤醒时间的性能瓶颈问题。 目前， XY2100芯片已经研发成功， 预计将在不久后推向市场。 另外， 科技部项目支持的片内集成了NB - IoT / GNSS / BLE等的XY3100芯片， 可实现高性能、 低功耗、 多模块自适应切换以及低成本， 预计明年商用。 除此之外， 公司还可针对物联网不同应用场景的差异化需求， 为客户提供定制开发服务。',

                    '除了NB - IoT系列产品之外， 高度集成的Cat .1 系统SoC芯片也正在紧锣密鼓研发中， 目前进展顺利， 预计将于明年推向市场。 同时， 随着5G时代的到来， 公司也已经率先投入5G中速的RedCap的研发。',

                    '展望未来， 满足物联网智能终端各项需求的系统SoC芯片将成为刚需， 并将拥有比物联网连接芯片更加广阔的市场空间。 芯翼信息科技创始人兼CEO肖建宏博士表示：“ 芯翼信息科技的核心研发团队大部分来自海内外知名的芯片设计公司， 具有丰富的研发经验， 同时我们有着自初代产品以来形成的技术创新精神， 因此， 我们独家提出了矢志成为业界领先的物联网智能终端系统SoC芯片企业的愿景。 在新一轮融资的加持下， 公司将一如既往的开拓创新、 引领发展， 为传统行业智能化、 物理世界数字化升级赋能， 为共同富裕和美好生活赋能。”',

                    '中金甲子创始团队成员、 执行总经理周伟表示： 物联网智能终端芯片未来将融合感知、 通信、 边缘计算、 安全、 节能等多维能力， 使得数据的传输和使用实现闭环， 将更多商业价值体现出来。 芯翼信息科技兼具国际顶尖的芯片研发能力与产业化经验， 我们相信公司在创始人肖博士的带领下， 将抓住这一历史性的机遇、 不断取得突破， 推动中国经济的数字化转型升级。',

                    '招银国际执行董事汪灿博士表示： 广域的中低速物联网终端将成为继PC、 手机、 汽车之后下一个海量终端市场， 芯翼信息科技团队在初代产品NB - IoT领域展现出极强的技术功底、 创新精神和落地能力， 我们看好其成为全球广域物联网终端系统SoC领导者的潜力。',
                ]
            },
            {
                name: '九章云极',
                url: require('../../common/imgs/screen5/九章云极.png').default,
                w: 14.38,
                h: 4.25,
                type: 1,
                title: '「九章云极」获3亿元C轮融资，致远资本担任独家财务顾问',
                content: [
                    '近日， 九章云极DataCanvas宣布完成C轮融资， 由尚珹资本、 赛富投资基金领投， 君紫投资、 领沨资本等投资机构跟投， 融资金额3亿元， 致远资本担任独家财务顾问。 本轮融资备受一线投资机构认可， 资方长期关注科技创新领域， 青睐人工智能领域中技术壁垒高、 产品领先优势显著、 业务发展潜力巨大的头部企业。',

                    '九章云极DataCanvas此次C轮融资距前次B + 轮1 .2 亿元融资不到一年， 充分体现了资本市场对九章云极DataCanvas业务战略和未来资本路径的信心。',

                    '九章云极DataCanvas创始人兼董事长方磊表示， 此轮融资将进一步夯实公司在人工智能平台领域的领头羊地位： 九章云极以自主研发的“ 硬科技” 立身， 守正出新， 以自主研发的标准化AI基础架构软件为基石， 通过将AI技术从研发到生产化过程中各个环节“ 自动化”， 不断开创各行各业新业务新场景的领先应用； 同时， 以发展的眼光赋能AI生态， 开源一系列国际领先的AutoML模型算法、 工具和架构， 通过提供统一的开发标准和规则， 加速跨行业AI应用的研发和AI能力的沉淀。',

                    '自主AI， 赋能企业数智化升级',
                    '作为国际领先的数据科学平台提供商， 九章云极DataCanvas坚守以数据科学赋能企业AI 的使命， 通过低代码、 自动化的人工智能平台实现AI技术的企业级应用， 帮助企业客户沉淀自主AI建设能力、 积累数据资产、 提升数智化升级能效。 近年来， 九章云极DataCanvas的数据科学产品市场占有率稳居中国前三， 已服务金融、 通信、 航空、 制造、 交通、 政府等众多头部客户， 各项业务在持续高速增长之中。',

                    '九章云极的核心产品线—— DataCanvas数据科学平台家族—— 为企业客户自主研发AI应用提供坚实基础架构的同时， 通过ModelOps为客户提供云原生、 可解释、 大规模、 直击业务痛点的AI模型全生命周期管理服务。 这是企业数智化升级过程中自主创新、 高效协同、 加快AI生产化并提升业务收益的重大创新。',

                    '硬科技 + 标准化， 定义AI基础架构未来',
                    '自主研发的AI技术、 创新的业务应用和良好的客户口碑使得九章云极DataCanvas在千变万化的市场环境下实现业务超预期增长， 凭借多个行业的深耕经验和技术与业务知识的成熟融合， 其标准化的AI平台产品实现了更大规模的覆盖， 大客户数量较去年翻倍增长， 并在通信行业成功复制应用； 同时， 九章云极DataCanvas正在加速产品线的创新和迭代， 以服务与日俱增的巨量数智化升级市场需求。',

                    '以“ 硬科技” 立身的九章云极DataCanvas在技术创新方面成果瞩目， 多项创新技术频频收获国际机构认可， 此前凭借独创的开源项目DeepTables， 在齐聚1万3千多家参赛队伍的全球数据科学顶级Kaggle竞赛中， 摘获第一名， 全新定义了深度学习技术在结构化数据分析领域的价值。 基于持续的技术自主创新， 九章云极DataCanvas目前已积累200多件自主知识产权， 其中包含50多件发明专利。',

                    '据悉， 新一轮融资将用于进一步完善AI基础架构软件的版图， 九章云极DataCanvas将推出新一代HSAP实时数据库， 满足人工智能时代对新一代数据库的需求， 成为AI数据库的定义者和领导者。 与此同时， 九章云极DataCanvas将通过颠覆性的AutoML、 AutoDL技术创新， 强化在金融、 通信、 政府等行业应用优势， 为各行业企业数智化升级提供新动能。',
                ]
            },
            {
                name: '高仙机器人',
                url: require('../../common/imgs/screen5/高仙机器人.png').default,
                w: 4.5,
                h: 5.88,
                type: 1,
                hasSub: true,
                title: '「高仙机器人」完成 B 轮亿元级融资，SLAM 机器人年产量将超 1000 台，致远资本担任独家财务顾问',
                content: [
                    '截至2017年， 全国60周岁及以上人口已达24090万人， 占总人口17 .3 % ，预计2025年六十岁以上人口将达到3亿， 届时中国将成为超老年型国家； 与此同时， 我国又一直处在城镇化发展阶段—— 房地产行业经历了高速建设期之后， 交付了超过200亿平米的城市建筑， 这些巨量面积的楼宇、 社区、 园区亟需要专业的服务和管理运营...高仙机器人认为， 机器人和无人驾驶则能够帮助物业行业实现服务场景中简单重复性岗位的自动化和机械化。',

                    '3 月 19 日，「 高仙机器人」 宣布已完成亿元人民币 B 轮融资。 本轮由远东宏信集团旗下远翼投资领投， 风险投资基金 KIP 中国跟投， 上轮资方蓝驰创投继续跟投， 致远资本担任独家财务顾问； 不到一年前， 高仙曾获融由蓝驰创投领投、 七海跟投的 A 轮千万级美元。',

                    '值得一提的是， 远东宏信是国内头部的第三方融资租赁服务提供商， 此次远东宏信旗下远翼投资的参与， 将为高仙在金融与产业端的发展提供帮助。',

                    '创始人兼 CEO 程昊天告诉36氪， 本轮资金主要用于提升产能以及产品研发， 2019 年预计生产超过 1000 台清洁机器人， 销售额将超 2 亿元。 此外， 高仙清华大学成立了联合实验室， 将针对楼宇、 安防等场景的 SLAM 和深度学习技术展开前沿性的研究。',

                    '36 氪此前曾报道， 成立于 2013 年的高仙机器人， 是一家以 SLAM 技术研发和相关应用开发为主要业务的企业。 2014 年， 高仙推出了第一台商用清洁机器人， 并于两年内在以新加坡为主的海外市场实现了商用落地。',

                    '截至 2018 年， 高仙商用清洁机器人已完成五轮迭代， 为 7 个国家、 200 多个客户在提供了超过 25 万多公里的稳定运行服务； 较 2017 年相比， 高仙营收实现了 400 % 的年增长， 且已基本实现盈亏平衡。',

                    '在去年的采访中， 程昊天曾向36氪介绍了高仙的“ 三条发展路线”， 即自主品牌清洁机器人、 安防解决方案、 楼宇配送。“ 自主品牌机器人和安防解决方案的发展很顺利， 仅高仙自研的定位导航模块， 在 2018 年就实现了超过 1 万套的量产。 这样的量产规模， 也确保公司在物流配送等其他新领域上， 能够不断探索、 积累业务理解及场景数据， 让底层技术向深度和广度两个方向的迭代。”',

                    '楼宇配送方面， 由于针对自动驾驶机器人的楼宇基础设施（ 例如电梯） 的改造限制较多， 所以其发展相对于清洁和安防方向稍慢些， 但高仙仍在努力与相关部门、 地产商协同克服困难。',

                    '程昊天表示， 目前公司员工约有 260 人， 其中 40 % 为开发人员， 而企业的产品则以达到国内领先水平。 未来， 高仙还将基于已覆盖的场景， 挖掘更多额外的商业价值。',

                    '“ 举例来讲， 我们可以通过为商超中的清洁车加入更多传感器， 并开发相关算法， 为客户提供夜间灯光管理、 温湿度监测、 货品盘点等附加功能， 因为现阶段来看， AI 技术的成熟会使其应用更加碎片化， 所以即使我们将清洁机器人做好， 这个行业仍然拥有机会。',

                    '自 2019 年起， 高仙将在北京、 上海、 新加坡建设 3 座研发中心， 加强与包括清华大学、 上海交通大学、 新加坡国立大学等在内的学术研究合作， 并借力包括芯片、 传感器在内的上游技术商， 实现具有生态意义的技术战略布局。',
                ]
            },
            {
                name: '斗象科技',
                url: require('../../common/imgs/screen5/斗象科技.png').default,
                w: 10.63,
                h: 5.06,
                type: 1,
                title: '「斗象科技」完成数亿元C轮融资，致远资本担任独家财务顾问',
                content: [
                    '斗象科技近日宣布完成C轮数亿元融资， 由钟鼎资本领投， 同创伟业、 惠友资本、 云栖创投、 线性资本跟投， 致远资本担任独家财务顾问。 本轮融资后， 斗象科技将进一步推进智能网络安全产品矩阵的研发和服务能力提升， 加速数据中心至云端的解决方案覆盖， 加大力度打造行业社区平台开放生态。',

                    '斗象科技为用户提供互联网化的网络安全服务以及智能安全分析产品解决方案。 业务包括网络安全众测服务平台“ 漏洞盒子”， 全息安全监测与数据分析产品“ 斗象智能安全平台”。 斗象科技旗下还运营着老牌网络安全社区“ FreeBuf”， 积累了70万网安从业者用户， 形成了广泛的行业影响力和良好的业界口碑， 是全球知名的网络安全技术社区领导品牌。',

                    '截至2019年底， 斗象科技的产品与服务已经广泛应用于金融、 互联网、 零售、 航空、 物联网 & 车联网、 政府等多个领域超过600家大中型客户， 为众多行业龙头加筑网络安全防线， 为企业数字化转型保驾护航， 为政府信息化安全保障提供支撑。 通过创新的产品服务与过硬的技术实力， 斗象科技在2014年创立至2019年间， 营收实现每年超过90 % 的复合增长率， 斗象的业务增速和客户数量持续领跑同行业创业厂商。',

                    '斗象科技致力于将服务客户的一线经验， 快速落地转化为解决方案。 2019 年， 斗象科技围绕PRS - NTA全流量大数据智能安全分析系统、 ARS漏洞智能扫描系统、 CRS云端安全中心等多款产品构建的智能安全平台， 与“ 白帽专家驱动” 的漏洞盒子安全众测服务、 VMS漏洞管理平台等产品实现数据交融打通， 在当下“ 重攻防， 重实战” 的行业趋势背景下， 帮助客户切实提高网络安全攻防实战能力， 显著提升威胁分析、 风险数据挖掘以及自动化安全运营能力。',

                    '对于此次C轮融资， 斗象科技CEO谢忱表示：“ 我们很荣幸得到新老股东的认可与支持， 斗象将始终以客户第一为己任， 进一步完善产品服务体系， 打造兼顾创新与落地性的行业解决方案， 成为新一代网络安全领头羊。”',
                ]
            },
            {
                name: '同盾科技',
                url: require('../../common/imgs/screen5/同盾科技.png').default,
                w: 6.06,
                h: 5.13,
                type: 2,
                link: 'https://www.tongdun.cn'
            },
            {
                name: '酷家乐',
                url: require('../../common/imgs/screen5/酷家乐.png').default,
                w: 6.5,
                h: 5.81,
                type: 1,
                hasSub: true,
                title: '酷家乐完成1亿美元D轮融资，致远资本担任独家财务顾问',
                content: [
                    '3月9日， 家居云设计平台酷家乐宣布完成1亿美元D轮融资， 该轮融资由顺为资本领投、 淡马锡旗下Pavilion Capital跟投， 老股东IDG资本、 GGV纪源资本、 云启资本、 赫斯特资本、 线性资本继续跟投， 致远资本担任独家财务顾问。 本轮融资完成后， 酷家乐估值将达到6亿美金。',

                    '顺为资本合伙人程天表示：「 我们非常看好全球家居行业的巨大市场和酷家乐在国内家居设计行业的领先地位。 酷家乐的智能设计工具拥有图像渲染速度快、 操作便捷、 学习门槛低等独特优势， 能显著提高行业效率、 获客能力和盈利能力， 改善业主的装修设计体验。 顺为资本很高兴能与酷家乐团队携手合作， 共同打造一站式家居服务生态链， 推动家居行业的信息化和互联网化。」',

                    '酷家乐于2013年11月正式上线。 作为全球最具创新体验的VR智能室内设计平台， 酷家乐致力于云渲染、 云设计、 BIM、 VR、 AR、 AI等技术的研发， 实现「 所见即所得」 的全景VR设计装修新模式， 5 分钟生成装修方案， 10 秒生成效果图， 一键生成VR方案， 彻底提升室内设计行业效率。',

                    '2017 年度， 酷家乐全年营收突破3亿元大关。 在规模上， 酷家乐已经成为全球最大的家居云设计平台， 它拥有覆盖全国90 % 的户型库， 吸引了超过300万室内设计师（ 覆盖全国40 % 的室内设计师） 和超1000万业主用户， 形成垄断式的用户壁垒。 平台日均生成100万张渲染效果图， 已累计生产2亿张效果图； 每日产生20万套装修设计方案， 已累计产生3000万套方案。 至今， 酷家乐已服务于小米、 美克美家、 林氏木业、 顾家家居、 博洛尼等12000家品牌企业， 市场覆盖率超过70 % 。',

                    '本轮融资完成后， 酷家乐将围绕两大战略重点进行布局： 构建行业生态和国际化战略。',

                    '第一， 把控「 设计入口」， 构建行业生态。 酷家乐通过智能云设计工具， 将上万家居品牌和300万设计师聚合在平台上， 牢牢把控了家居行业「 设计入口」。 在此基础上， 酷家乐持续进行生态开放， 全面对接设计、 生产、 营销、 施工、 落地等家居产业链的各个环节， 打造酷家乐智能云设计平台矩阵， 成为整个家居行业的操作系统。',

                    '第二， 国际化布局， 将「 所见即所得」 的体验模式普及到全球市场。「 国际化」 在酷家乐的战略版图中占据至关重要的位置， 一方面， 从市场层面考量， 全球的家居市场规模是2万亿美元， 布局全球市场可以扩大酷家乐的业务版图； 另一方面， 通过酷家乐这个设计入口， 将中国家居品牌直接对接到全世界终端消费者手中。 接下来， 酷家乐将率先进入美国、 东南亚和印度市场， 并针对海外市场推出行业领先的国际化产品。',

                    '致远资本非常高兴能够参与本轮融资， 相信酷家乐未来将全方位地渗透上下游产业链， 实现「 所见即所得」， 生态驱动全球2万亿美元家居市场。',
                ]
            },
            {
                name: '五爷拌面',
                url: require('../../common/imgs/screen5/五爷拌面.png').default,
                w: 5.06,
                h: 5.81,
                type: 1,
                title: '「五爷拌面」获高瓴创投A+轮投资，致远资本持续担任独家财务顾问',
                content: [
                    '继2021年6月五爷拌面宣布完成了“餐饮界最大一笔”A轮融资3亿元后，近日五爷拌面又获得了高瓴创投的A+轮投资，这也是高瓴创投首次投资餐饮品牌。本轮由高瓴创投领投，致远资本持续担任独家财务顾问。短短几个月，五爷拌面得到多个头部投资机构青睐，可见其品牌实力雄厚，发展速度迅猛。五爷拌面的本轮融资金将用于加速强化供应链体系、加速自有工厂建设、迭代信息化智能系统、打造国内顶级运营团队及加速布局全国门店网络。',
                    '五爷拌面创立于2018年5月，是国内新兴的创新餐饮连锁品牌。公司通过自营+加盟的方式，以东北三省黑吉辽为起步，已拓展至京津冀、江浙沪、珠三角、山东、内蒙等省市，实现了标准化、规模化高速扩张。据五爷拌面创始人透露，A轮融资后，他们正以飞快的速度扩张全国，预计年末突破1500家店指日可待。届时五爷拌面也将会成为中式面条品牌中门店数量的冠军品牌。据预测，2023年五爷拌面全国门店数量有望突破7000家。',
                    '高瓴创投的投资理念，是坚持长期结构性价值投资，所选择的行业必然是常青且有长期需求的。餐饮行业正是高瓴创投近几年来，深度关注的行业，因为消费领域有稳定市场和固定消费者。高瓴创投一年多以来，研究对比了很多的餐饮品牌，最终确定投资五爷拌面。之所以选择五爷拌面，是因为其具备非常好的品牌的优势和爆发力，运营团队实力极强。业界众所周知高瓴创投出手，必会选择该细分领域的龙头品牌。',
                    '五爷拌面并不是一个泛加盟式的品牌，而是一个有品牌、有服务、标准化的准直营店管理模式的连锁品牌。作为全国优选拌面集合店，五爷拌面融合了全国各地最受欢迎的多种拌面味道及做法，加以改良优化，并严苛把控品质，力求做到“料更好，味更浓”。同时，公司产品组合比较丰富，除了拌面外，也有汤面、米饭等其他产品，还会根据当地口味推出特色菜单，符合更广泛人群的口味需求。同时，五爷拌面正全面打造自己的新零售产品，加强新品及线上流通类产品的研发，目前已在各大电商及各地商超上架销售。',
                    '五爷拌面创始人说，面条赛道市场基数足够大，拥有广泛的大众消费基础。2021年，在餐饮新机遇下，五爷拌面创始人更加看好中式面条品类未来的市场表现和市场容量，并就此为五爷拌面定下了“万家店”的宏大目标。他希望，五爷老爷爷能像肯德基老爷爷一样，遍布全球。让世界爱上中国面！',
                    '本轮投资方高瓴创投表示，五爷拌面团队在创始人的带领下，运用科技工具建立数字化体系，通过领先的信息管理系统和精细化运营为用户带来标准化体验，提升了门店的运营效率。凭借快速迭代能力和超强的执行力，公司在创立短短三年内就实现了门店快速增长。我们期待五爷拌面团队能够发挥自身的禀赋优势，创造更多长期价值，为中国消费者带来更多美好滋味。',
                    'A轮领投方鼎晖VGC基金管理合伙人表示：“星星之火，可以燎原”，五爷拌面完成A轮融资后，团队在五爷拌面创始人的带领下快速成长，外建品牌和供应链，内强体系和组织力，现在单月拓展门店超过300家，已经驶入有质量快速发展的高速公路。对五爷拌面的融资与资本运作，鼎晖一如既往地欢迎优秀投资机构共同参与合作，一起为企业发展不断增砖添瓦，共同成就优秀的企业家，并为改善行业贡献资源和力量。',
                ]
            },
            {
                name: '好麦多',
                url: require('../../common/imgs/screen5/好麦多.png').default,
                w: 13.31,
                h: 4.69,
                type: 1,
                title: '「好麦多」获1.5亿新一轮融资，致远资本担任独家财务顾问',
                content: [
                    '近日，健康食品品牌「好麦多」获得1.5亿元新一轮融资，众为资本领投，清科母基金跟投，老股东星陀资本、前海母基金等持续加持，致远资本担任独家财务顾问。本轮资金将用于加强产品研发和品牌建设，以及供应链的改造等。今年7月，好麦多已获来自星陀资本、前海母基金等数千万元战略融资。',
                    '公司的定位是“时尚健康食品企业”，好麦多品牌从水果麦片来切入，逐步探索围绕谷物食品的新品类，核心用户是18-35岁的都市用户，产品场景以早餐为主、代餐零食为辅，目前主打的水果麦片品类，共有10余个SKU。',
                    '自品牌诞生以来，好麦多的成绩一直很亮眼：在2019年上线首月，线上销售额就突破了200万，3个月后月销售额突破2000万，不到两年累计全渠道销售额超10亿。在疫情期间，好麦多销量增速同比增长超过1000%。',
                    '其中好麦多的酸奶桃桃、桂花胶原等创新谷物产品长期高居各大平台及榜单前列，酸奶桃桃单品更是荣获天猫V榜产品，累计销售超过500万袋；桂花胶原蛋白系列荣获2020年中食展TOP创新产品奖。',
                    '根据第一财经商业数据中心的数据，85后和95后对抗糖需求提升明显，80-90后对谷物型零食消费需求相对较高。好麦多的创始人兼CEO方翔告诉36氪，国内的健康食品品牌还没有成规模地成长起来，欧美有相当一部分健康食品品牌，但是其口味不能满足中国消费者对“美食”的需求，洞察到这一需求后，“更好吃”成为好麦多产品研发的首选考量标准。',
                    '根据日本休闲食品上市公司卡乐比的麦片演进方式，混合麦片可大致分为两类，普通混合麦片（慕斯里Muesli）和水果麦片（Granola谷兰诺拉），好麦多的麦片属于水果麦片，采用特殊工艺，将复合谷物加工使之成为谷物团簇，再配以水果制成不同口感的麦片。',
                    '好麦多最重视的部分在供应链和产品，而这也是公司的核心壁垒。团队有多年的海内外供应链专业背景及从业经验，用相对较优的成本在全球直采到较高品质的原料，并在近日收购了南美哈利斯科奇亚籽产区。产品方面，则沿用了互联网公司的产品思维，通过最小可视化产品原理，每个SKU投入约10万~20万人民币，从口味、原料等不同维度量化，通过数据分析进行敏捷的参数调整，从而实现迭代升级。',
                    '营销方面，好麦多并不推崇拉新、裂变等流量玩法，虽然目前已有关晓彤、宋威龙和谭松韵三位明星作为代言人加持，但好麦多仍然认为产品比营销更重要，立足于对大数据的观察与用户反馈，将更多的利润投入研发。好麦多采用是全渠道模式，线上进驻天猫、京东、抖音、小红书等多家渠道，线下与沃尔玛、Ole、永辉等商超、连锁店合作，采用多消费者触点的方式，而不会依赖单一渠道。',
                    '方翔表示，水果麦片作为新品类不能像其他品类那样标准化是一个遗憾，公司内部制定出一项“HON金标准”，即Healthy好健康、Original好食材、Nice好美味。具体来看，好麦多HON金标准包括：',
                    '一是使配方营养健康，来应对消费者对专业营养搭配的需要以及非油炸、高饱腹、低 GI 糖等基础性需要，好麦多将每餐卡路里摄入由通常的 880Kcal，降低至134Kcal，相当于一个苹果的热量，从而与传统早餐做出差异化定位。',
                    '二是原料渠道的全球优化整合，基于独特的地理环境孕育优质食材的理解，好麦多在近日收购了南美哈利斯科奇亚籽黄金产区土地，从原材料层面提高产品品质的同时建立了竞争优势。',
                    '三是采用先进的技术实现对口感的提升，好麦多的产品采用了双道烘焙非油炸技术保持酥脆口感，并且大力投入水果冷泡技术的研发，让果香和麦片完美融合，在不加防腐剂的状态下还可以保质期长达 10 个月，做到“不用泡都好吃”。',
                    '好麦多创始团队来自清华大学、香港中文大学、加州大学伯克利等中外知名高等学府，除了拥有营养学专业背景，还成功操盘过多项千万级项目，具有丰富的商业营销经验。',
                ]
            },
            {
                name: '美尚股份',
                url: require('../../common/imgs/screen5/美尚股份.png').default,
                w: 16.5,
                h: 4.44,
                type: 1,
                hasSub: true,
                title: '「美尚股份」获4亿元B轮融资，致远资本连续担任财务顾问',
                content: [
                    '新锐品牌珂拉琪COLORKEY母公司美尚股份正式宣布完成总额4亿元人民币的最新融资。本次B轮融资由金镒资本领投，高盛、君川等跟投，老股东持续加码。致远资本连续担任财务顾问，这是继2020年4月协助公司顺利完成A轮2亿元融资后，又一次成功合作。',
                    '美尚股份于2018年创立，旗下拥有潮酷彩妆品牌COLORKEY珂拉琪、专业彩妆品牌SUPERFACE秀芭斐、科研护肤品牌LAB101瑞沛。自创立以来，潮酷彩妆品牌COLORKEY珂拉琪致力于引领千禧一代“敢于不同”的当代潮流趋势，主打单品空气唇釉深受市场认可。创始人Cici Lee曾任中外化妆品集团高管职务，拥有20多年丰富的美妆全产业链经验。创立之初，获得红杉资本等近亿元天使轮投资。2020年4月，再获2亿元A轮融资，投资方包括红星美凯龙、创新工场、微光创投和易凯资本。',
                    '美尚股份始终奉行“品牌金字塔矩阵”战略——以差异化定位的多品牌集团模式布局美妆市场，满足中国庞大市场之下不同地域、不同年龄、不同人群的各色需求。2019年初，美尚集团旗下潮酷彩妆品牌COLORKEY珂拉琪正式上线天猫，主打唇釉系列开辟出全新的细分市场，上线3月占领类目第一。随后销售一路高歌猛进，双11渠道销售额突破1.6亿元，成为入驻天猫不到三年双11破亿仅有的三家彩妆品牌公司之一。同步开启全渠道策略，线下新零售渠道迅速覆盖超3000家，始终稳占“唇釉第一”品牌，夯实了金三角底部。',
                    '花开两枝，早在2018年美尚股份便已积极布局“品牌金字塔”腰部。引入韩国及全球市场知名的专业彩妆品牌SUPERFACE秀芭斐，以及重金投入科研护肤品牌LAB101瑞沛。前者将进一步满足于摆脱大众美妆进入追求品质的中端彩妆用户产品升级需求；后者抓住细分护肤领域，更是“疫情”后中国女性转而寻求安全、高效的重要趋势，2021年LAB101瑞沛将打造以研发、生产、推广三大核心的新护城河，并将升级品牌主张，为中、高端消费者提供更科学有效的深度护肤解决方案。',
                    '本次，B轮融资领投方金镒资本是以专业、稳健著称的市场化PE机构，过去15年里专注于投资新零售、消费升级，以及产业升级领域之中最具潜力的龙头企业。因共同看好中国市场的长期发展，尤其在当下，恰逢下沉市场消费升级带来的庞大增长，数字化经济的转型红利，并有着中国供应链产业集群的强大支撑，这正是美妆品牌难得一见的发展机遇。',
                    '“如果说彩妆是短跑，那护肤赛道是马拉松长跑。过去2年我们已经获得短跑的阶段性胜利，接下来我们将重点投入到专业护肤市场的发展。”美尚股份创始人Cici Lee表示：“中国是全球最大市场，但中国化妆品产业链还未走向成熟期，关键在于没出现高端品牌公司。美尚股份整个团队具备十五年以上的行业资深经验，熟悉国际高端市场，又有打造单赛道销冠品牌的成功案例，同时也有着全球最顶尖的供应商加持。有实力、有信心、更有使命，去成为中国美妆行业高端市场拓荒者。”'
                ]
            }
        ]
    },
}

export default configs