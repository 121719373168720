import React from "react";
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import App from './App'
import Team from "./pc-pages/team";
import Business from "./pc-pages/business";
import Development from "./pc-pages/development";
import Join from "./pc-pages/join";

const routers =
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={App}></Route>
            <Route path="/team" component={Team}></Route>
            <Route path="/business" component={Business}></Route>
            <Route path="/development" component={Development}></Route>
            <Route path="/join" component={Join}></Route>
        </Switch>
    </BrowserRouter>

export {routers}