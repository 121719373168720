import React from 'react'
import './index.less'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            beian: require('../../../common/imgs/备案.png').default,
            logo: {
                light: require('../../../common/imgs/light_logo.png').default,
                dark: require('../../../common/imgs/dark_logo.png').default
            },
            menu: {
                light: require('../../../common/imgs/light_menu.png').default,
                dark: require('../../../common/imgs/dark_menu.png').default
            },
            close: require('../../../common/imgs/close.png').default,
            menuList: [
                {
                    label: '关于致远',
                    path: '/'
                },
                {
                    label: '致远团队',
                    path: '/team'
                },
                {
                    label: '致远交易',
                    path: '/business'
                },
                {
                    label: '致远动态',
                    path: '/development'
                },
                {
                    label: '加入我们',
                    path: '/join'
                },
            ],
            showMenu: false,
            isOut: false
        }
    }

    static defaultProps = {
        theme: 'light',
        isHome: false,
        menuPrevent: false
    }

    handleMenu() {
        let showMenu = !this.state.showMenu
        let waitTime = showMenu ? 0 : 400
        this.setState({
            isOut: !showMenu
        })
        this.props.menuPrevent && this.props.parent.handleChildMenu(showMenu)
        setTimeout(() => {
            this.setState({
                showMenu,
                isOut: false
            })
        }, waitTime)
    }

    handleClickItem(item) {
        let path = this.props.match.path
        if (path === item.path) {
            window.location.reload()
        } else {
            this.props.history.push(item.path)
        }
    }

    render() {
        const { theme, isHome } = this.props
        const { logo, menu, showMenu, isOut, menuList, close, beian } = this.state
        return (
            <div className="pc-header">
                <img src={logo[theme]} className={classnames('logo', {'home-logo': isHome})} alt="" onClick={this.handleClickItem.bind(this, {path: '/'})} />
                <img src={menu[theme]} className={classnames('menu', {'home-menu': isHome})} alt="" onClick={this.handleMenu.bind(this)} />
                {showMenu && (
                    <div className={classnames('menu-container flex', {
                        'menu-out': isOut
                    })}>
                        <div className="left" onClick={this.handleMenu.bind(this)}></div>
                        <div className="right flex-column flex-row flex-between">
                            <img src={close} className="close" alt="" onClick={this.handleMenu.bind(this)} />
                            <img src={logo.dark} className="menu-logo" alt="" onClick={this.handleClickItem.bind(this, {path: '/'})} />
                            <div className="menu-list flex-column flex-row">
                                {menuList.map((item, index) => {
                                    return (
                                        <div key={index} className="menu-item" onClick={this.handleClickItem.bind(this, item)}>{item.label}</div>
                                    )
                                })}
                            </div>
                            <div className="menu-bottom-tip">
                                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502047550" rel="noreferrer"><img className="beian" src={beian} alt="" /><div>京公网安备 11010502047550号</div></a>
                                <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noreferrer">京ICP备2021031266号-1</a> 
                            </div>
                        </div>
                        <div className="right1"></div>
                        <div className="right2"></div>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(Header)